import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { ConfigService } from './config.service';

// Declare the global `gtag` function for Google Analytics
declare var gtag: Function;

@Injectable({
  providedIn: 'root'  // Service is provided at the root level, making it available throughout the application
})
export class GoogleAnalyticsService {

  constructor(private router: Router, private configService: ConfigService) { 
    // Initialize the route change tracking when the service is instantiated
    this.onRouteChange();
  }

  /**
   * Track a custom event with Google Analytics
   * @param eventName - Name of the event to be tracked
   * @param eventDetails - Additional data related to the event (parameters)
   */
  trackEvent(eventName: string, eventDetails: unknown) {
    // Use the global `gtag` function to send the event data to Google Analytics
    gtag('event', eventName, eventDetails);
  }

  /**
   * Listen to route changes in the Angular app and send page view data to Google Analytics
   */
  onRouteChange() {    
    // Subscribe to router events
    this.router.events.subscribe((event) => {
      // Check if the navigation event is NavigationEnd (page change completed)
      if (event instanceof NavigationEnd) {
        // Send page view data to Google Analytics
        gtag('config', this.configService.getConfig.googleAnalyticsId, {
          page_path: event.urlAfterRedirects,  // Include the new URL after redirects
        });
      }
    });
  }
}