import { Injectable } from '@angular/core';
import { APIUrlConfig, GraphQLService } from 'shared-ui/src/lib/core/graphql';
import { GET_FAQ, GET_HOUSING_REQUEST_LIST_BY_STATUS, CREATE_LEAD, TRAVELER_LIST } from './client-query';
import { IFaqModel } from '../modules/faq/models/faq.model';
import { ReservationDetailsModel, ReservationDetailsResult } from '../modules/booking-history/models/reservation.models';
import { cloneDeep } from '@apollo/client/utilities';
import { PaginationModel, UserProfile } from 'shared-ui/src/lib/models';
import { LeadErrorResponse, LeadRequestModel } from './api-response-model/lead.model';
import { TravelerListFilter,TravelerListResponse } from './api-response-model/traveller-list.model';
import { CdnContentService, getDayFromDate, LoggingService, StorageService } from '@synergy-application/shared-ui';
import { LocalStorageKeys } from 'shared-ui/src/lib/constants/local-storage.constant';
import { source } from 'shared-ui/src/lib/constants/shared-constant';
import { environment } from '../../environments/environment';
import { LogLevel } from 'shared-ui/src/lib/enum/logger';

@Injectable({
  providedIn: 'root' // Makes this service available throughout the application
})
export class ClientApiService {

  constructor(
    private graphQLService: GraphQLService,
    private storageService: StorageService,
    private cdnContentService: CdnContentService,
    private loggingService: LoggingService
  ) { }

  /**
   * Fetches a list of FAQs by calling the GET_FAQ GraphQL query.
   * @returns A promise with an array of FAQ models.
   */
  getFaq(): Promise<IFaqModel[]> {
    return new Promise((resolve, reject) => {
      const query = `${GET_FAQ}`; // GraphQL query to fetch FAQ data
      this.graphQLService.query(query, undefined, APIUrlConfig.PORTAL).then((result: any) => {
        resolve(result?.data?.getFAQ); // Resolve the promise with the FAQ data
      }).catch(error => {
        console.error('Error fetching data:', error); // Log any errors that occur
        this.loggingService.logException(error, LogLevel.Error)
        reject(error); // Reject the promise with the error
      });
    });
  }

  /**
   * Creates a lead by sending a mutation with the provided lead request data.
   * @param variable The data for creating a lead (LeadRequestModel).
   * @returns A promise with the created lead data.
   */
  createLead(variable: LeadRequestModel): Promise<LeadErrorResponse> {
    const userInfo = JSON.parse(this.storageService.get(LocalStorageKeys.userInfo)) as UserProfile;
    variable.leadInfo.source = userInfo && userInfo.client && userInfo.client.clientId ? source.CLIENT_PORTAL : source.SYNERGY
    return new Promise((resolve, reject) => {
    const query = `${CREATE_LEAD}`; // GraphQL mutation to create a lead
    this.graphQLService.mutate(query, variable,APIUrlConfig.LEAD).then((result: any) => {
      resolve(result); // Resolve with the newly created lead
    }).catch(error => {
      console.error('Error fetching data:', error); // Log errors during the mutation
      this.loggingService.logException(error, LogLevel.Error)
      reject(error); // Reject the promise with the error
    });
  });
  }

/**
 * Fetches a list of travelers by calling the TRAVELER_LIST GraphQL query.
 * @returns A promise with an array of traveler list models.
 */
getTravelerList(travelerListFilter : TravelerListFilter): Promise < TravelerListResponse > {
  return new Promise((resolve, reject) => {
    const query = `${TRAVELER_LIST}`; // GraphQL query to fetch traveler list
    this.graphQLService.query(query,travelerListFilter).then((result: any) => {
      resolve(cloneDeep(result?.data?.getTravelerList)); // Resolve with a deep clone of the traveler list
    }).catch(error => {
      console.error('Error fetching data:', error); // Log any errors that occur
      this.loggingService.logException(error, LogLevel.Error)
      reject(error); // Reject the promise with the error
    });
  });
}

/**
 * Fetches a list of housing requests by status. The status is provided in the variable parameter.
 * This method processes the response and transforms it into a more usable format.
 * @param variable The request parameters, including the status filter.
 * @returns A promise with an array of ReservationDetailsModel objects.
 */
getHousingRequestListByStatus(variable: any): Promise < ReservationDetailsResult > {
  return new Promise((resolve, reject) => {
    const query = `${GET_HOUSING_REQUEST_LIST_BY_STATUS}`; // GraphQL query to fetch housing requests by status
    this.graphQLService.query(query, variable, APIUrlConfig.LEAD) // Execute the query with the provided variables
      .then((result: any) => {
        const data = cloneDeep(result?.data?.getHousingRequestListByStatus); // Clone the response data
        let resultData: ReservationDetailsModel[] = data.lead.reduce((acc: Array<any>, request: any) => {
          // Map over the leads and create a new array of ReservationDetailsModel
          acc.push({
            propertyImage : request?.property?.attachments?.length ? `${this.cdnContentService.blobServiceUrl}${environment.config.azure.publicContainerName}/${request?.property?.attachments[0].imageUrl}` : '' ,
            propertyName :request?.property?.propertyName,
            propertyAddress : request?.property?.address?.fullAddress || request.location,
            requestNumber: request.leadId || '', // Lead ID
            state: request.state || '', // State of the request
            city: request.city || '', // City of the request
            country: request.location || '', // Country of the request
            units: request.noOfUnits || 0,
            currencyCode: request.currencyCode || '',// Number of units requested
            guests: request.occupants || 0, // Number of guests
            moveIn: request.arrivalDate || '', // Move-in date
            moveOut: request.departureDate || '', // Move-out date
            guestName: `${request.firstName} ${request.lastName}` || '', // Guest's first name
            bookingId: request.bookingId || '', // Booking ID
            viewHousingOption: request.viewHousingOption || false, // Option to view housing
            price: request.price || '', // Price per night
            budget: request.budget || '', // User's budget
            status: request.statusId, // Status of the request
            isRequestDenied: request.isRequestDenied || false, // Flag if the request was denied
            requestDeniedReason: request.denialInfo || '', // Reason for denial
            createdAt: request.createdAt || '', // Request creation timestamp
            updatedAt: request.updatedAt || '', // Last update timestamp
            isDeleted: request.isDeleted || '', // Flag indicating if the request was deleted
            dateDetails: {
              fromDates: request.arrivalDate || '', // Start date of the stay
              toDates: request.departureDate || '', // End date of the stay
              fromDays: getDayFromDate(request.arrivalDate) || '', // Number of days for the stay
              toDays: getDayFromDate(request.departureDate), // End date in days
              price: request.costPerNight || '', // Cost per night
              numberOfNights: request.los || '', // Length of stay (number of nights)
              currencyCode : request.currencyCode
            }
          });
          return acc;
        }, []);
        const response: ReservationDetailsResult = {
          lead: [...resultData],
          pagination: { ...(data as any).pagination as PaginationModel }
        }
        resolve(response);
      })
      .catch(error => {
        console.error('Error fetching housing request list:', error); // Log errors
        this.loggingService.logException(error, LogLevel.Error)
        reject(error); // Reject the promise with the error
      });
  });
}
}
