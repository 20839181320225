const GET_PROPERTY_BY_ID = `query GetPropertyById($propertyId: String!) {
  getPropertyById(propertyId: $propertyId) {
    propertyId
    propertyName
    codeOneRef
    propertyType
    createdDate
    updatedDate
    description        
    supplierOrganizationIDF
    propertyCode
    propertyApprovalStatus
    deniedReason
    propertyNum
    isOnBehalf
    createdBy
    updatedBy
    isCreatedInCodeOne
    responseCodeFromCodeOne
    responseStatusMessage
    address {
      id
      name
      createdDate
      updatedDate
      address1
      address2
      city
      postalCode
      countyCode
      country
      fullAddress
      description
      phone
      email
      latitude
      longitude
    }
    amenities {
      amenitiesType
      amenities {
        id
        createdDate
        updatedDate
        name
        type
        iconPath
        svgPath
        amenitiesCategoryId
        categoryName
      }
    }
    attachments {
      attachmentId
      fileName
      description
      imageUrl
      imageOrder
    }
    bookingInfo {
      minLOS
      cancellationPolicy
    }
    units {
      type
      noOfUnitTypes
      startingAtRate
    }
  }
}`;

const GET_AVAILABLE_PROPERTIES = `query GetAvailableProperties(
  $searchReq: AvailabilitySearchInput! 
) {
  getAvailableProperties(
    searchReq: $searchReq
  ) {
    properties {
      propertyName
      propertyId
      codeOneRefId
      noOfBedrooms
      latitude
      longitude
      availableDate
      distanceInMiles
      rtbAvailable
      attachments {
        attachmentId
        fileName
        description
        imageUrl
        imageOrder
      }
      attachments {
        attachmentId
        fileName
        description
        imageUrl
        imageOrder
      }
      amenities {
        amenitiesType
        amenities {
          id
          createdDate
          updatedDate
          name
          type
          iconPath
          svgPath
          amenitiesCategoryId
          categoryName
        }
      }
      units {
        type
        noOfUnitTypes
        startingAtRate
      }      
      pricePerNight
    }
    pagination {
      page
      pageSize
      totalCount
    }
  }
}`;

const GET_AMENITIES_LIST = `query getAmenitiesList($pagination: PaginationInput!) {
  getAmenitiesList(
    pagination: $pagination
  ) {
    amenitiesList {
      amenitiesCategoryId
      categoryName
      createdDate
      iconPath
      svgPath
      id
      name
      type
      updatedDate
    }
    pagination {
      page
      pageSize
      totalCount
    }
  }
}
`;

const GET_PROPERTY_TYPE = `query GetPropertyType {
  getPropertyType {
    pagination {
      page
      pageSize
      totalCount
    }
    propertyList {
      id
      propertyType
      createdAt
      createdBy
      updatedAt
      updatedBy
    }
  }
}`;

const GET_MY_PROFILE = `query GetMyProfile {
    getMyProfile {
        id
        name
        email
        mobileNo
        designation
        companyName
        region
        branch
        manager
        privacyPolicyAccepted
        image
        costCenter
        roles
        firstName
        lastName
        client {
            clientId
            clientDisplayName
            clientName
        }
        address {
            id
            name
            createdDate
            updatedDate
            address1
            address2
            city
            postalCode
            countyCode
            country
            fullAddress
            phone
            email
            latitude
            longitude
            description
        }
    }
}`;


const GET_USER_BY_ID = `query GetUserById($id: String!) {
    getUserById(id: $id) {
      id
      name
      firstName
      lastName
      email
      mobileNo
      designation
      companyName
      region
      branch
      manager
      privacyPolicyAccepted
      image
      costCenter
      roles
      address {
        id
        name
        createdDate
        updatedDate
        address1
        address2
        city
        postalCode
        countyCode
        country
        fullAddress
        phone
        email
        latitude
        longitude
      }
      client {
        clientId
        clientDisplayName
        clientName
      }
    }
  }
`;

const GET_FAQ = `query GetFAQ {
  getFAQ {
    id
    question
    answer
    sortOrder
    clientId
    isActive
    createdAt
    updatedAt
  }
}`;

const GET_LOGIN = `mutation {
  login(
    credentials: { 
      email: "dhineshpandi@vthink.co.in", 
      password: "welcome" 
    }
  )
}`;


const LOGIN = `mutation ValidateMicrosoftToken($token: String!) {
  validateMicrosoftToken(input: {
      token: $token
  })
}`


const GET_SAS_TOKEN = `mutation GenerateSasToken($permission: String!) {
    generateSasToken(permission: $permission)
}`


const GET_CLIENT_LOCATION = `query getClientLocation($pagination: PaginationInput!, $search: CommonSearch) {
  getClientLocation(
    pagination: $pagination
    search: $search
  ) {
    clientLocation {
      addressId
      clientId
      createdAt
      id
      latitude
      location
      longitude
      updatedAt
    }
    pagination {
      page
      pageSize
      totalCount
    }
  }
}
`;

const GET_UNIT_TYPE = `query GetUnitTypeList {
  getUnitTypeList {
      unitTypeId
      unitType
      orderBy
      createdDate
      createdBy
  }
}`

const GET_CURRENCY = `query GetCurrencies($pagination: PaginationInput!, $search: CommonSearch) {
  getCurrencies(
    pagination: $pagination
    search: $search
  ) {
    currenciesList {
        id
        currencyId
        currencySymbol
        currencyCode
    }
    pagination {
        page
        pageSize
        totalCount
    }
  }
}`;

const GET_COUNTRY = `query GetCountriesList($pagination: PaginationInput, $search: CommonSearch) {
  getCountriesList(
    pagination: $pagination
    search: $search
  ) {
    countries {
        id
        countryName
        countryCode
        mobileNumber
        imageUrl
        pattern
        placeHolder
    }
    pagination {
        page
        pageSize
        totalCount
    }
  }
}`

const ACCEPT_POLICY = `mutation updatePrivacyPolicyAccepted{
  updatePrivacyPolicyAccepted
}`;

const GET_LEAD_FILTERS = `query GetLeadFilters($request: FilterInput!) {
    getLeadFilters(request: $request) {
        pagination {
            page
            pageSize
            totalCount
        }
        filtersList {
            key
            value
        }
    }
}`;

const SSO_LOGIN =  `mutation AuthenticateUser($accessToken: String!, $idToken: String!) {
  authenticateUser(
      accessToken: $accessToken,
      idToken: $idToken
  )
}`

const  GET_DROPDOWN_VALUES =  `query GetDropDownValues($request: DropDownInput) {
          getDropDownValues(request: $request) {
              data {
                  key
                  value
                  iconPath
                  svgPath
                  countryCode
                  imageUrl
                  placeHolder
                  pattern
                  currencyId
                  currencySymbol
                  firstName
                  lastName
                  email
                  languageCode
                  region
                  mobileNumber
              }
              pagination {
                  page
                  pageSize
                  totalCount
              }
          }
      }
  `

export {
  GET_PROPERTY_BY_ID,
  GET_AMENITIES_LIST,
  GET_AVAILABLE_PROPERTIES,
  GET_PROPERTY_TYPE,
  GET_MY_PROFILE,
  GET_FAQ,
  GET_USER_BY_ID,
  GET_LOGIN,
  GET_CLIENT_LOCATION,
  LOGIN,
  GET_CURRENCY,
  ACCEPT_POLICY,
  GET_UNIT_TYPE,
  GET_SAS_TOKEN,
  GET_COUNTRY,
  GET_LEAD_FILTERS,
  SSO_LOGIN,
  GET_DROPDOWN_VALUES
};
