import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, TemplateRef } from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';
import { AppConstants, ImagePath, InputConstants } from '../../constants/shared-constant';
import { AppFormsService } from '../../services/forms.service';
import { LocationItem, OptionModel } from '../../models';
import { LocationService } from '../../services/location.service';

@Component({
  selector: 'lib-custom-form',
  templateUrl: './custom-form.component.html',
  styleUrl: './custom-form.component.scss',
})
export class CustomFormComponent implements OnInit {
  @Input() data: any;

  formGroup!: FormGroup;

  constants = AppConstants;

  object = Object;

  imagePath = ImagePath;

  @Input({
    required: false
  }) companyTemplateForm!: TemplateRef<unknown>;

  @Output() ngClick: EventEmitter<any> = new EventEmitter<any>();

  language = {
    useCurrentLocation: "Use Current Location"
  }

  constructor(
    private appFormsService: AppFormsService,
    private locationService: LocationService,
  ) {

  }
  ngOnInit() {
    this.initiateForm();
  }

  initiateForm() {
    this.formGroup = this.appFormsService.getFormGroup(this.data);
    this.applyRules();
  }

  openAccordian(group: string) { 
    this.data[group][InputConstants.close] = !this.data[group][InputConstants.close] ?? true;
  }

  applyRules() {
    if (this.data) { 
      Object.keys(this.data).forEach((group) => {
        const fields = this.data[group].fieldType;
        const dateFields = fields?.filter((el: { type: string, apiKey: string }) => el.type === AppConstants.fieldType.date || el.apiKey === 'los' ||
          el.apiKey === 'bringingPet' || el.apiKey === 'mtm');
        if (dateFields) {
          dateFields.forEach((field: any) => {
            const rules = field.rules ?? undefined;
            if (rules?.minDate === 'today') {
              const today = new Date();
              today.setDate(today.getDate() + 1);
              today.setHours(0, 0, 0, 0);
              field.minDate = today;
            }

            if (rules?.minDateRule) {
              const relatedControl = this.formGroup.get(group)?.get(rules.minDateRule);
              relatedControl?.valueChanges.subscribe((value: Date) => {
                const minDateWithOffset = new Date(value);
                minDateWithOffset.setDate(minDateWithOffset.getDate() + 1);
                minDateWithOffset.setHours(0, 0, 0, 0);
                field.minDate = minDateWithOffset;
              })
            }

            if (rules?.maxDateRule) {
              const relatedControl = this.formGroup.get(group)?.get(rules.maxDateRule);
              relatedControl?.valueChanges.subscribe((value: Date) => {
                field.maxDate = value
              })
            }

            if (field.apiKey === 'los') {
              this.formGroup.get(group)?.get('arrivalDate')?.valueChanges.subscribe((arrivalDate: Date) => {
                const departureControl = this.formGroup.get(group)?.get('departureDate');
                this.updateLOS(arrivalDate, departureControl?.value ?? null, group);
              });

              this.formGroup.get(group)?.get('departureDate')?.valueChanges.subscribe((departureDate: Date) => {
                const arrivalControl = this.formGroup.get(group)?.get('arrivalDate')
                this.updateLOS(arrivalControl?.value ?? null, departureDate, group);
              });
            }

            if (field.apiKey === 'bringingPet') { 
              this.formGroup.get(group)?.get('bringingPet')?.valueChanges.subscribe((status: OptionModel) => {
                const { value } = status;
                if (typeof value === "boolean") this.updateDisabledFields(value, 'petDetails',group);
              })
            }

            if (field.apiKey === 'mtm') { 
              this.formGroup.get(group)?.get('mtm')?.valueChanges.subscribe((status: OptionModel) => {
                const { value } = status;
                if (typeof value === "boolean") { 
                  this.updateDisabledFields(value, 'ntvDaysRequired', group);
                  this.updateDisabledFields(value, 'ntvReceived', group);
                }
              })
            }
          })
        }
      })
    }
   
  }

  updateDisabledFields(status: boolean, controlName: string, group: string) {
    const field = this.formGroup.get(group)?.get(controlName);
    if (status) {
      field?.setValidators(Validators.required); // Set validators to required
      field?.enable(); // Ensure the field is enabled
    } else {
      field?.clearValidators(); // Clear validators
      field?.disable(); // Disable the field if status is false
    }
    field?.updateValueAndValidity();
  }


  // Helper function to calculate and update LOS
  updateLOS(arrivalDate: Date | null, departureDate: Date | null, group: string) {
    const losControl = this.formGroup.get(group)?.get('los');
    if (arrivalDate && departureDate) {
      const arrival = new Date(arrivalDate);
      const departure = new Date(departureDate);
      arrival.setHours(0, 0, 0, 0);
      departure.setHours(0, 0, 0, 0);
      const nights = (departure.getTime() - arrival.getTime()) / (1000 * 60 * 60 * 24);
      losControl?.setValue(nights > 0 ? nights : 0, { emitEvent: false });
    } else {
      losControl?.setValue(0, { emitEvent: false });
    }
  }

  loadDropDownValues(event: any, field: any) {
    this.ngClick.emit({ event, field });
  }

  getControl(group: string, j: number) {
    return this.formGroup.get(group)?.get(j.toString())
  }

  locationSearch(event: string, field: any) {
    if (event) {
      this.locationService.getAutoLocationApi(event)
        .then((response: LocationItem[]) => {
          field.groups[0].items = [...response];
        }).catch((err: Error) => {
          console.error(err);
        });
    } else field.groups[0].items = [];
  }

  getFormValue(): FormGroup {
    return this.formGroup;
  }
} 
