import { Component, ElementRef, OnInit, Renderer2, ViewChild } from '@angular/core';
import { CdnContentService } from '../../../../../../../../shared-ui/src/lib/services/cdn-content.service';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { ROUTESPATH } from 'apps/booking-portal/src/app/core/routes-constant';
import { AppLoaderService, getSubDomain, LoggingService, LoginService, SharedApiService, StorageService } from '@synergy-application/shared-ui';
import { CheckBoxInput } from 'shared-ui/src/lib/models';
import { AppConstants, BlobName } from 'apps/booking-portal/src/app/core/app-constant';
import { ModalController } from '@ionic/angular';
import { LanguageService } from '../../../shared/services/language.service';
import { LocalStorageKeys } from 'shared-ui/src/lib/constants/local-storage.constant';
import { BlobAccess } from 'shared-ui/src/lib/constants/shared-constant';
import { environment } from 'apps/booking-portal/src/environments/environment';
import { CommonService } from '../../../shared/services/common.service';

@Component({
  selector: 'app-policy',
  templateUrl: './policy.component.html',
  styleUrl: './policy.component.scss',
})
export class PolicyComponent implements OnInit {

  @ViewChild('dynamicContent', { static: true }) dynamicContent!: ElementRef;
  isPopup = false;

  safeHTML!: SafeHtml;

  languageLabel: any;

  data: CheckBoxInput = {
    isChecked: false, // Initial state of the checkbox (unchecked).
    label: ''
  }

  isRendered: boolean = false;
  sidePadding: string;

  constructor
    (private languageService: LanguageService,
      private modalCtrl: ModalController,
      private cdnContentService: CdnContentService,
      private loaderService: AppLoaderService,
      private router: Router,
      private domSanitizer: DomSanitizer,
      private sharedApiService: SharedApiService,
      private commonServiceBookingPortal: CommonService,
      private loginService:LoginService
    ) { }

  ngOnInit() {
    this.loginService.headerSub.next(false);
    this.getTravelPolicyHTML();
    this.showSideBar();
  }
  showSideBar() {
    this.sidePadding = this.commonServiceBookingPortal.applyLeftPadding()?.sidePadding;
    // Check if any role from userole is in the role array
  }

  /**
  * Retrieves and returns the HTML content for the travel policy section.
  */
  async getTravelPolicyHTML() {
    this.languageLabel = this.languageService.getPolicyKey();
    this.data.label = this.languageLabel.acceptText;
    this.loaderService.showLoader(true);
    // let token = await this.sharedApiService.getSasToken(BlobAccess.read);
    let domain = getSubDomain();
    await this.cdnContentService.getBlobData(`${domain}/${BlobName.travelerPolicy}`, environment.config.azure.configContainerName)
      .then((data: any) => {
        this.loaderService.showLoader(false);
        this.safeHTML = this.domSanitizer.bypassSecurityTrustHtml(data);
        this.isRendered = true;
      })
      .catch(error => {
        LoggingService.instance?.logException(error as Error)
        this.isRendered = false;
        this.loaderService.showLoader(false);
      })
  }

  closeSlider() {
    this.modalCtrl.dismiss(false);
  }

  agreePolicy() {
    this.loaderService.showLoader(true);
    this.sharedApiService.acceptPrivacyPolicy()
      .then(res => {
        this.loaderService.showLoader(false);
        this.modalCtrl.dismiss(true);
      })
      .catch(err => {
        console.error(err);
        this.loaderService.showLoader(false);
        this.router.navigate([ROUTESPATH.LOGIN]);
      })
  }
}