import { Pipe, PipeTransform } from '@angular/core';

/**
 * A custom pipe to map data and API responses by dynamically adding a key-value mapping.
 * 
 * The pipe checks each item in the `data` array or object, compares it with the provided 
 * `apiResponse` using a specified `key`, and adds a `value` key to the item based on the 
 * corresponding value in the API response.
 */
@Pipe({
  name: 'keyValueMapper'
})
export class KeyValueMapperPipe implements PipeTransform {
   /**
   * Transforms the input `data` by mapping values from `apiResponse` and adding them under 
   * a dynamically assigned key.
   *
   * @param data - The data to be transformed. Can be an array or object.
   * @param apiResponse - The API response used to map values. Can be an array or object.
   * @param key - The key used to look up corresponding values in `apiResponse`.
   * @param name - A key name for dynamic mapping in the `data`.
   * @returns The transformed data with an additional `value` key added if a match is found.
   */
  transform(data: any, apiResponse: any, key: string, name: string): any {
    if (!data || !apiResponse) return data;

    if (Array.isArray(data)) {
      return data.map((item) => this.addValueKey(item, apiResponse, key, name));
    } else if (typeof data === 'object') {
      return this.addValueKey(data, apiResponse, key, name);
    }

    // If the data is not an object or array, return it as is.
    return data;
  }

  private addValueKey(data: any, apiResponse: any, key: string, name: string): any {
    const result: any = { ...data };

    // Check if the apiKey exists in the data and if it exists in the apiResponse
    if (data[key] ?? data.apiKey) {
      if (Array.isArray(apiResponse)) {
        // If the apiResponse is an array, find the object with the matching key
        const matchingItem = apiResponse.find(item => item[data[key] ?? data.apiKey] !== undefined);
        if (matchingItem) {
          result[name] = matchingItem[data[key] ?? data.apiKey]; // Add the `value` key with the matched value
        }
      } else if (apiResponse[data[key] ?? data.apiKey]) {
        // If the apiResponse is an object, directly add the matched value
        result[name] = apiResponse[data[key] ?? data.apiKey]; // Add the `value` key with the matched value
      }
    }

    return result;
  }
}
