import { AppConstants } from "./shared-constant";

export const SharedData = {
     countryOption: [
          { "id": 1, "name": "New York", "code": "NY", "icon": "/assets/flags/new-york.svg", "phoneCode": "+1" }, // New York, USA
          { "id": 2, "name": "India", "code": "IN", "icon": "/assets/flags/india.svg", "phoneCode": "+91" }, // India
          { "id": 3, "name": "United States", "code": "US", "icon": "/assets/flags/us.svg", "phoneCode": "+1" }, // United States
          { "id": 4, "name": "Rome", "code": "RM", "icon": "/assets/flags/rome.svg", "phoneCode": "+39" }, // Italy
          { "id": 5, "name": "London", "code": "LDN", "icon": "/assets/flags/london.svg", "phoneCode": "+44" }, // United Kingdom
          { "id": 6, "name": "Istanbul", "code": "IST", "icon": "/assets/flags/istanbul.svg", "phoneCode": "+90" }, // Turkey
          { "id": 7, "name": "Paris", "code": "PRS", "icon": "/assets/flags/paris.svg", "phoneCode": "+33" }, // France
          { "id": 8, "name": "Germany", "code": "DE", "icon": "/assets/flags/germany.svg", "phoneCode": "+49" }, // Germany
          { "id": 9, "name": "Spain", "code": "ES", "icon": "/assets/flags/spain.svg", "phoneCode": "+34" }, // Spain
          { "id": 10, "name": "Brazil", "code": "BR", "icon": "/assets/flags/brazil.svg", "phoneCode": "+55" }, // Brazil
          { "id": 11, "name": "Canada", "code": "CA", "icon": "/assets/flags/canada.svg", "phoneCode": "+1" }, // Canada
          { "id": 12, "name": "Australia", "code": "AU", "icon": "/assets/flags/australia.svg", "phoneCode": "+61" }, // Australia
          { "id": 13, "name": "Japan", "code": "JP", "icon": "/assets/flags/japan.svg", "phoneCode": "+81" }, // Japan
          { "id": 14, "name": "China", "code": "CN", "icon": "/assets/flags/china.svg", "phoneCode": "+86" }, // China
          { "id": 15, "name": "Russia", "code": "RU", "icon": "/assets/flags/russia.svg", "phoneCode": "+7" }, // Russia
          { "id": 16, "name": "South Africa", "code": "ZA", "icon": "/assets/flags/south-africa.svg", "phoneCode": "+27" }, // South Africa
          { "id": 17, "name": "Mexico", "code": "MX", "icon": "/assets/flags/mexico.svg", "phoneCode": "+52" }, // Mexico
          { "id": 18, "name": "Argentina", "code": "AR", "icon": "/assets/flags/argentina.svg", "phoneCode": "+54" }, // Argentina
          { "id": 19, "name": "Netherlands", "code": "NL", "icon": "/assets/flags/netherlands.svg", "phoneCode": "+31" }, // Netherlands
          { "id": 20, "name": "Sweden", "code": "SE", "icon": "/assets/flags/sweden.svg", "phoneCode": "+46" }, // Sweden
          { "id": 21, "name": "Norway", "code": "NO", "icon": "/assets/flags/norway.svg", "phoneCode": "+47" }, // Norway
          { "id": 22, "name": "Switzerland", "code": "CH", "icon": "/assets/flags/switzerland.svg", "phoneCode": "+41" }, // Switzerland
          { "id": 23, "name": "South Korea", "code": "KR", "icon": "/assets/flags/south-korea.svg", "phoneCode": "+82" }, // South Korea
          { "id": 24, "name": "Thailand", "code": "TH", "icon": "/assets/flags/thailand.svg", "phoneCode": "+66" }, // Thailand
          { "id": 25, "name": "Singapore", "code": "SG", "icon": "/assets/flags/singapore.svg", "phoneCode": "+65" }, // Singapore
          { "id": 26, "name": "United Arab Emirates", "code": "AE", "icon": "/assets/flags/uae.svg", "phoneCode": "+971" }, // UAE
     ],

     profileDynamicForm: [{
          "sections": [
               {
                    "fields":
                         [{
                              "name": "firstName",
                              "label": "First Name",
                              "type": "text",
                              "maxLength": 50,
                              "minLength": 3,
                              "placeholder": "Enter First Name",
                              "errorMessage": "First Name is required",
                              "isRegexErrorMessage": "Invalid First Name",
                              "isRequired": true,
                              "value": "",
                              "isRegex": false,
                              "isShowErrorMessage": false,
                              "leadRef": "firstName",
                              size: 6
                         },
                         {
                              "name": "lastName",
                              "label": "Last Name",
                              "type": "text",
                              "maxLength": 100,
                              "minLength": 3,
                              "placeholder": "Enter Last Name",
                              "errorMessage": "Last Name is required",
                              "isRegexErrorMessage": "Invalid Last Name",
                              "isRequired": true,
                              "value": "",
                              "isRegex": false,
                              "isShowErrorMessage": false,
                              "leadRef": "lastName",
                              size: 6
                         },
                         {
                              "name": "email",
                              "label": "Email ID",
                              "type": "text",
                              "maxLength": 100,
                              "minLength": 3,
                              "placeholder": "Enter the Email ",
                              "errorMessage": "Email is required",
                              "isRegexErrorMessage": "Invalid Email",
                              "isRequired": true,
                              "value": "",
                              "isRegex": true,
                              "isShowErrorMessage": false,
                              "leadRef": "email",
                              size: 6
                         },
                         {
                              "name": "phoneNumber",
                              "label": "Phone Number",
                              "type": "phoneNumber",
                              "maxLength": 100,
                              "minLength": 3,
                              "placeholder": "Enter the Phone Number",
                              "errorMessage": "Phone Number is required",
                              "isRegexErrorMessage": "Invalid Phone Number",
                              "isRequired": true,
                              "value": "",
                              "isRegex": true,
                              "isShowErrorMessage": false,
                              "leadRef": "mobileNo",
                              size: 6
                         },
                         {
                              "name": "employeeId",
                              "label": "Employee ID",
                              "type": "text",
                              "maxLength": 100,
                              "minLength": 3,
                              "placeholder": "Enter the Employee ID",
                              "errorMessage": "Employee ID is required",
                              "isRegexErrorMessage": "Invalid Employee ID",
                              "isRequired": true,
                              "value": "",
                              "isRegex": false,
                              "isShowErrorMessage": false,
                              "leadRef": "id",
                              size: 6
                         },
                         {
                              "name": "companyName",
                              "label": "Company Name",
                              "type": "text",
                              "maxLength": 50,
                              "minLength": 3,
                              "placeholder": "Enter the Company Name",
                              "errorMessage": "Company Name is required",
                              "isRegexErrorMessage": "Invalid Company Name",
                              "isRequired": true,
                              "value": "",
                              "isRegex": false,
                              "isShowErrorMessage": false,
                              "leadRef": "companyName",
                              size: 6
                         },
                         {
                              "name": "region",
                              "label": "Region",
                              "type": "text",
                              "maxLength": 50,
                              "minLength": 3,
                              "placeholder": "Enter the Region",
                              "errorMessage": "Region is required",
                              "isRegexErrorMessage": "Invalid Region",
                              "isRequired": true,
                              "value": "",
                              "isRegex": false,
                              "isShowErrorMessage": false,
                              "leadRef": "region",
                              size: 6
                         },
                         {
                              "name": "branch",
                              "label": "Branch",
                              "type": "text",
                              "maxLength": 50,
                              "minLength": 3,
                              "placeholder": "Enter the Branch",
                              "errorMessage": "Branch is required",
                              "isRegexErrorMessage": "Invalid Branch",
                              "isRequired": true,
                              "value": "",
                              "isRegex": false,
                              "isShowErrorMessage": false,
                              "leadRef": "branch",
                              size: 6
                         },
                         {
                              "name": "designation",
                              "label": "Designation",
                              "type": "text",
                              "maxLength": 50,
                              "minLength": 3,
                              "placeholder": "Enter the Designation",
                              "errorMessage": "Designation is required",
                              "isRegexErrorMessage": "Invalid Designation",
                              "isRequired": true,
                              "value": "",
                              "isRegex": false,
                              "isShowErrorMessage": false,
                              "leadRef": "designation",
                              size: 6
                         },
                         {
                              "name": "manager",
                              "label": "Manager",
                              "type": "text",
                              "maxLength": 50,
                              "minLength": 3,
                              "placeholder": "Enter the Manager",
                              "errorMessage": "Manager is required",
                              "isRegexErrorMessage": "Invalid Manager",
                              "isRequired": true,
                              "value": "",
                              "isRegex": false,
                              "isShowErrorMessage": false,
                              "leadRef": "manager",
                              size: 6
                         },
                         {
                              "name": "costCenter",
                              "label": "Cost Center",
                              "type": "text",
                              "maxLength": 50,
                              "minLength": 3,
                              "placeholder": "Enter the Cost Center",
                              "errorMessage": "Cost Center is required",
                              "isRegexErrorMessage": "Invalid Cost Center",
                              "isRequired": true,
                              "value": "",
                              "isRegex": false,
                              "isShowErrorMessage": false,
                              "leadRef": "costCenter",
                              size: 6
                         },
                         {
                              "name": "streetAddress",
                              "label": "Street Address",
                              "type": "text",
                              "maxLength": 50,
                              "minLength": 3,
                              "placeholder": "Enter the Street Address",
                              "errorMessage": "Street Address is required",
                              "isRegexErrorMessage": "Invalid Street Address",
                              "isRequired": true,
                              "value": "",
                              "isRegex": false,
                              "isShowErrorMessage": false,
                              "leadRef": "fullAddress",
                              size: 6
                         },
                         {
                              "name": "country",
                              "label": "Country",
                              "type": "dropdown",
                              "maxLength": 50,
                              "minLength": 3,
                              "placeholder": "Enter the Country",
                              "errorMessage": "Country is required",
                              "isRegexErrorMessage": "Invalid Country",
                              "isRequired": true,
                              "value": "",
                              "isRegex": false,
                              "isShowErrorMessage": false,
                              "leadRef": "country",
                              size: 6
                         },
                         {
                              "name": "state",
                              "label": "State",
                              "type": "dropdown",
                              "maxLength": 50,
                              "minLength": 3,
                              "placeholder": "Enter the State",
                              "errorMessage": "State is required",
                              "isRegexErrorMessage": "Invalid State",
                              "isRequired": true,
                              "value": "",
                              "isRegex": false,
                              "isShowErrorMessage": false,
                              "leadRef": "state",
                              size: 6
                         },
                         {
                              "name": "city",
                              "label": "City",
                              "type": "text",
                              "maxLength": 50,
                              "minLength": 3,
                              "placeholder": "Enter the City",
                              "errorMessage": "City is required",
                              "isRegexErrorMessage": "Invalid City",
                              "isRequired": true,
                              "value": "",
                              "isRegex": false,
                              "isShowErrorMessage": false,
                              "leadRef": "city",
                              size: 6
                         },
                         {
                              "name": "zipcode",
                              "label": "Zipcode",
                              "type": "text",
                              "maxLength": 50,
                              "minLength": 3,
                              "placeholder": "Enter the Zipcode",
                              "errorMessage": "Zipcode is required",
                              "isRegexErrorMessage": "Invalid Zipcode",
                              "isRequired": true,
                              "value": "",
                              "isRegex": false,
                              "isShowErrorMessage": false,
                              "leadRef": "zipcode",
                              size: 6
                         },
                         ]
               }
          ]
     }],

     options: [
          { id: 1, name: 'Last 7 days' },
          { id: 2, name: 'Last 1 month' },
          { id: 3, name: 'Last 6 months' },
          { id: 4, name: 'Last 1 year' }
     ],

     tabLabel: [
          { id: 1, label: 'Active Requests', key: "activeRequest" },
          { id: 2, label: 'Past Requests', key: "pastRequest" }
     ],
     roleBasedTabLabel: [
          { id: 1, label: 'Traveler', key: "traveler" },
          { id: 2, label: 'Manager', key: "manager" }
     ],

     myReservationTabs: [
          { id: 1, label: 'Active Bookings', key: "activeBookings" },
          { id: 2, label: 'Upcoming Bookings', key: "upcomingBookings" },
          { id: 3, label: 'Past Bookings', key: "pastBookings" }
     ],
     leadDetailsTabs: [
          { id: 1, label: 'Details', key: 'leadDetails' },
          { id: 2, label: 'Attachments', key: 'leadAttachments' },
          // { id: 3, label: 'Lead Activities', key: 'leadActivities' },
          { id: 4, label: 'Logs', key: 'logs' }
     ]
}