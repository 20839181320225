import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AppLoaderService {
  private subject = new Subject<boolean>();


  /**
   * Description -> to observable the value
   * 
   */
  getLoader(): Observable<boolean> {
    return this.subject.asObservable();
  }


  /**
   * Description -> get the boolean value to show loader 
   * 
   */
  showLoader(loader: boolean) {
    this.subject.next(loader);
  }
}
