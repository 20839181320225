import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PopoverController } from '@ionic/angular';

@Component({
  selector: 'lib-popover',
  templateUrl: './popover.component.html',
  styleUrl: './popover.component.scss',
})
export class PopoverComponent {
  @Input() popoverData: any;

  constructor(private popoverController: PopoverController) { }

  /**
   * Close the popover
   * @param data 
   */
  closePopover(data: string) {
    this.popoverController.dismiss();
  }
}