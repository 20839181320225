import { Component, Input, OnInit } from '@angular/core';
import { getDayFromDate, calculateDateDifference } from '../../../../../../../../shared-ui/src/lib/core/helper';
import { DatePipe } from '@angular/common';
import { LanguageService } from '../../services/language.service';
import { DaysInput } from '../../../booking-history/models/reservation.models';
import { AppConstants } from 'apps/booking-portal/src/app/core/app-constant';

@Component({
  selector: 'app-days-and-date',
  templateUrl: './days-and-date.component.html',
  styleUrls: ['./days-and-date.component.scss'], 
})
export class DaysAndDateComponent implements OnInit {
  @Input() dateDetails!: DaysInput;
  dateLabel: any;
  appConstants = AppConstants;

  constructor(private languageService: LanguageService, private datePipe: DatePipe) { }

  ngOnInit() {
    this.dateLabel = this.languageService.getDateKey();
  }

}
