import { Component, Input, OnInit, ChangeDetectorRef } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Subscription } from 'rxjs';
import { AppLoaderService } from '../../services/app-loader.service';

@Component({
  selector: 'lib-loader',
  templateUrl: './loader.component.html',
  styleUrl: './loader.component.scss',
})
export class LoaderComponent implements OnInit {

  showLoader = false;

  private loaderServiceSubscription!: Subscription;

  constructor(private loaderService: AppLoaderService,
    private changeDetector: ChangeDetectorRef) { }


  /**
   * Description -> to get subscribe the value
   * 
   */
  ngOnInit() {
    this.loaderServiceSubscription = this.loaderService.getLoader()
      .subscribe((options) => {
        this.showLoader = options;
        this.changeDetector.detectChanges();
      });
  }



  /**
   * Description -> to get unsubscribe the value
   * 
   */
  ngOnDestroy() {
    this.loaderServiceSubscription.unsubscribe();
  }
}

