/* eslint-disable no-useless-catch */
import { Component, OnInit } from '@angular/core';
import { LanguageService } from '../../../../shared/services/language.service';
import { LoggingService, SharedData } from '@synergy-application/shared-ui';
import { TabInput } from 'shared-ui/src/lib/models';
import { DaysInput } from '../../../models/reservation.models';
import { ModalController } from '@ionic/angular';
import { ModifyReservationModalComponent } from '../../../components/modify-reservation-modal/modify-reservation-modal.component';
import { LogLevel } from 'shared-ui/src/lib/enum/logger';

@Component({
  selector: 'app-my-reservation',
  templateUrl: './my-reservation.component.html',
  styleUrl: './my-reservation.component.scss',
})
export class MyReservationComponent implements OnInit {
  reservationConfigurations: Record<string, any> = {}; 

  tabLists: TabInput[] = SharedData.myReservationTabs;

  checkIn: DaysInput =  {
    fromDates: new Date().toString(),
    toDates: new Date('11/28/2024').toString(),
    fromDays: '1',
    toDays: '8',
    price: '$300',
    numberOfNights: ' 7',
    currencyCode: '$'
  }

  constructor(
    private languageService: LanguageService,
    private modalController: ModalController,
    private loggingService: LoggingService
  ) {}

  ngOnInit(): void {
      this.intializeComponent();
  }

  intializeComponent() {
    try {
      this.reservationConfigurations = this.languageService.getReservationKey();
    } catch(error) {
      throw error;
    }
  }

  async openModifyReservationModal() {
    try {
      const modal = await this.modalController.create({
        component: ModifyReservationModalComponent,
        cssClass: 'modify-reservation',
        componentProps: {
          isPopup: true,
        },
        backdropDismiss: false
      });
      modal.present();
    } catch(error) {
      this.loggingService.logException(error as Error, LogLevel.Error)
      throw error;
    }
  }

}
