import { Directive, ElementRef, Input, OnChanges, Renderer2, SimpleChanges } from '@angular/core';
import { statusLabel } from '../enum/table';
import { PickValueFromObjectPipe } from '../pipe/pick-object.pipe';

@Directive({
  selector: '[libChipStyle]'
})
export class ChipStyleDirective implements OnChanges {
  @Input() status!: unknown;

  @Input() statusConfiguration: Record<string | number, { label: string, class: string }> = statusLabel;

  constructor(private el: ElementRef, private renderer: Renderer2, private picValueFromObject: PickValueFromObjectPipe) { }

  ngOnChanges(changes: SimpleChanges): void {

    if ('status' in changes) this.updateClass();
  }

  private updateClass(): void {
    try {
      const chipConfiguration = this.getStatusConfiguration();
      if(chipConfiguration) {
        this.renderer.setAttribute(this.el.nativeElement, 'class', `regular-sm-text chip-container flex-align-center font-color ${chipConfiguration.class}`);
        this.renderer.setProperty(this.el.nativeElement.querySelector('.label'), 'textContent', chipConfiguration.label)
      }
    } catch(error) {
      throw error;
    }
  }

  getStatusConfiguration(): { label: string, class: string } | null {
    const status = typeof this.status === 'string' || typeof this.status === 'number' ? this.status.toString() : '';
    if(status) return this.picValueFromObject.transform(this.statusConfiguration, status);
    return null;
  }
}
