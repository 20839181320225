export const ErrorConstants = {
    locationServiceError: {
        noDataError: 'No Data',
        notFoundError: 'Not found',
        noAddressFoundError: 'No address found',
        fetchingPlaceDetailsError: 'Error fetching place details:',
        unableToRetrieveCoordinatesError: 'Unable to retrieve coordinates'
    },
    apiServiceError: {
        fetchingProfileDataError: 'Error fetching profile data:',
        fetchingDataError: 'Error fetching data:'
    }
}