import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PopoverModel } from '../../models/popover.model';
import { ModalController, PopoverController } from '@ionic/angular';

@Component({
  selector: 'lib-popover-menu',
  templateUrl: './popover-menu.component.html',
  styleUrl: './popover-menu.component.scss',
})
  
export class PopoverMenuComponent{
  data!: PopoverModel[] // Allow passing data to the popover

  constructor(
    private popoverController: PopoverController
  ) { 
  }
  
  // Dismiss the popover when a menu item is clicked
  clickedMenu(menu: PopoverModel) { 
    this.popoverController.dismiss(menu);
  }
}
