<ion-content *ngIf="languageLabel && imageData" scroll-y="false" [fullscreen]="true">
  <div class="landing-main position-relative {{sidePadding}}">

    <!--Image card section-->
    <div class="image-card-div position-relative {{sideNavApplied}}">
      <app-image-card [buttonUI]="buttonUI" [data]="imageData" [language]="languageLabel"
        (searchEmit)="onSearchEvent($event)"></app-image-card>
    </div>

    <!--Text card section-->
    <div class="padding-top-bottom-20 page-content max-80">
      <app-text-card [cardData]="section2"></app-text-card>
    </div>

    <!--Parallax section-->
    <div class="padding-top-bottom-20 page-content {{sideNavApplied}}">
      <app-parallax-content class="parallax-column" [list]="section3" [header]="section3Heading" [sliceStartFirst]="0"
        [sliceEndFirst]="4" [sliceStartSecond]="3"></app-parallax-content>
    </div>

    <!-- 360 view for property image-->
    <div *ngIf="imageTour" class="padding-top-bottom-20 page-content image-tour">
      <ion-label class="semiBold-xl-text-20px font-color">{{ imageTourLabel.label }}</ion-label>
      <lib-image-tour-viewer [data]="imageTour"></lib-image-tour-viewer>
    </div>

    <!-- property-list-section -->
    <div class="padding-top-bottom-20 page-content pdb-8 {{sideNavApplied}}">
      <div class="mt_8">
        <ion-label class="semiBold-xl-text-20px">{{propertyHeader}}</ion-label>
      </div>
      <!-- <ion-row>
        <ion-col class="landing-carousel p-relative" size-xs="12" size-sm="12" size-md="6" size-xl="3" size-lg="3"
          *ngFor="let property of propertyLists">
          <div class="likeImage-class">
            <lib-icon name="like" [className]="'medium'"></lib-icon>
          </div>

          <lib-gallery [attachments]="property.propertyImage" [showItemNavigators]="true" [showThumbnails]="false"
            [showIndicators]="true" [showIndicatorsOnItem]="true" [containerStyle]="{ 'width':'100%' }"></lib-gallery>
          <div class="property-card">
            <div class="flex-4">
              <ion-label class="semiBold-md-text">{{property.propertyName}}</ion-label>

              <div class="rating-location regular-md-text">
                <ion-icon [src]="star" class="small"></ion-icon>
                <span class="bold-md-text">{{ property.rating }}</span>/5
                <div class="vertical-line"></div>
                <span class="span-align"> {{
                  property.location}}</span>
              </div>
            </div>

            <div class="stayInfo-class">
              <ion-label class="regular-sm-text">{{property.stayInfo}}</ion-label>
            </div>
          </div>
        </ion-col>
      </ion-row> -->
      <div class="featured-list">
        <div class="landing-carousel p-relative" *ngFor="let property of propertyLists">
          <div class="likeImage-class">
            <lib-icon name="like" [className]="'medium'"></lib-icon>
          </div>

          <lib-gallery [attachments]="property.propertyImage" [showItemNavigators]="true" [showThumbnails]="false"
            [showIndicators]="true" [showIndicatorsOnItem]="true" [containerStyle]="{ 'width':'100%' }"></lib-gallery>
          <div class="property-card">
            <div class="flex-4">
              <ion-label class="semiBold-md-text">{{property.propertyName}}</ion-label>

              <div class="rating-location regular-md-text">
                <ion-icon [src]="star" class="small"></ion-icon>
                <span class="bold-md-text">{{ property.rating }}</span>/5
                <div class="vertical-line"></div>
                <span class="span-align"> {{
                  property.location}}</span>
              </div>
            </div>

            <div class="stayInfo-class">
              <ion-label class="regular-sm-text">{{property.stayInfo}}</ion-label>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Parallax Section -->
    <div class="padding-top-bottom-20 page-content pt-0 {{sideNavApplied}}">
      <app-parallax-content class="section-4" [list]="section4" [header]="section4Heading" [sliceStartFirst]="0"
        [sliceEndFirst]="4" [sliceStartSecond]="4"></app-parallax-content>
    </div>
    <div class="powered-by-css">
      <div class="align-center display-flex-justify-center img-div">
        <ion-label class="regular-sm-text">{{languageLabel?.poweredBy ?? 'Powered by'}}</ion-label>
        <img class="synergy-logo" src="/assets/icon/synergy-header.svg" alt="" />
      </div>
    </div>
    <!-- <div *ngIf="!environment?.production">
      Build no: {{ environment?.build }}
    </div> -->
  </div>
</ion-content>