<ion-grid class="info-window-grid">
    @if(attachments.length) {
    <div class="attachments-container">
        <lib-carousel [value]="attachments" [numVisible]="1" [numScroll]="1"></lib-carousel>
    </div>
    }

    <div class="info-window-grid-details" *ngIf="placeDetails">
        <div class="location-details">
            <ion-label class="semiBold-md-text font-color ellipsis">{{ placeDetails.name ?? '' }}</ion-label>
            <ion-text class="regular-sm-text font-color ellipsis">
                {{ placeDetails.formatted_address ?? ''}}
            </ion-text>
        </div>
        <div class="horizontal-line"></div>
        <div class="flex-8">
            <div class="icon-container">
                @for(item of distanceMatrix; track item.id) {
                <div [class.active]="selectedIcon === item.id" class="icon cursor-p" (click)="iconClick(item)">
                    <ion-icon [name]="item.icon" class="medium"></ion-icon>
                </div>
                }
            </div>
            <ion-text class="regular-sm-text font-colo ellipsis">{{ distanceMatrix ?
                distanceMatrix[selectedIcon].duration : '0'
                }} from the property ({{ distanceMatrix[selectedIcon].distance}}).</ion-text>
        </div>
    </div>
</ion-grid>