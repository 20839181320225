<ion-content scroll-y="false" [fullscreen]="true">
  <ion-grid class="user-grid page-content table-align {{sidePadding}}">
    <ion-row>
      <ion-col *ngIf="isShowTab">
        <lib-tab class="plr-40 pad-top-24" [tabInput]="tabLabel"
            (dataToParent)="onTabChange($event)"></lib-tab>
      </ion-col>
    </ion-row>
    <ion-row>
      <ion-col size-xs="12" size-sm="12" size-md="6" size-lg="6" class="search-column">
        <lib-search class="search-align" (searchChange)="onsearchUser($event)"
          [placeholder]="languageLabel.searchPlaceHolder"></lib-search>
      </ion-col>
      <!-- <ion-col size-xs="12" size-sm="12" size-md="6" size-lg="6" class="button-column">
      <lib-button is-secondary is-medium icon="/assets/svg/filter-icon.svg" [image]="false">
        Filters
      </lib-button>
    </ion-col> -->
    </ion-row>
    <!-- <ion-row class="row-align" *ngIf="selectedCount > 0">
      <ion-col size-xs="12" size-sm="12" size-md="6" size-lg="6">
        <ion-label class="medium-sm-text">{{ selectedCount }}{{languageLabel.travelerLabel}}</ion-label>
      </ion-col>
    </ion-row> -->
    <lib-table-view [goToPageInput]=1 [itemsPerPage]="itemPerPage" [pagination] = "pagination"
      [totalItems]="pagination?.totalCount || 0" (selectAll)="toggleSelectAll($event)"
      (itemPerPage)="itemPerPageEvent($event)" [styleConfig]="tableStyles" (rowSelection)="onRowSelectionChange($event)"
      [key]="key" [selectedRows]="selectedRows" (apiCalled)="getUser($event)" (sort)="sortData($event)"
      [data]="userList" (moreAction)="onMoreAction($event)" [headers]="tableConfig"></lib-table-view>
    <div></div>
  </ion-grid>
</ion-content>