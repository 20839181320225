<ion-content scroll-y="false" [fullscreen]="true">
    <ion-grid class="profile-main">
        <h1 class="semiBold-xl-text-24px">{{ isEdit ? languageLabel.editProfileDetails : languageLabel.myProfileDetails
            }}</h1>
        <!--Profile Section-->
        <div class="profile-container">
            <div class="avatar align-items  display-flex-justify-center">


                @if (profileData?.firstName) {
                <span class="initials align-items semiBold-xl-text-36px display-flex-justify-center">
                    {{profileData?.firstName?.slice(0,!profileData?.lastName ? 2 :1) |
                    uppercase}}{{profileData?.lastName?.slice(0,1) |
                    uppercase
                    }}</span>
                } @else {
                <span class="initials align-items semiBold-xl-text-36px display-flex-justify-center">
                    {{profileData?.name?.slice(0,2) | uppercase}}</span>
                }
                <!-- <ion-icon *ngIf="isEdit" class="icon" src="/assets/svg/edit-pencil.svg"
                    (click)="imageClick()"></ion-icon> -->
            </div>
            <!--Display the profile details view-->
            <div *ngIf="isEdit">
                <div [class.no-action]="isEdit" *ngFor="let data of editData.sections">
                    <lib-dynamic-form [value]="data"></lib-dynamic-form>
                </div>
            </div>


            <div *ngIf="!isEdit" class="container">
                <div *ngFor="let section of data" class="row">
                    <ion-label class="regular-md-text">{{ section.label }}</ion-label>
                    <p class="semiBold-md-text">{{ section.value ? section.value : '--' }}</p>
                </div>
            </div>

            <!--Footer section-->
            <ion-footer class="footer-grid align-items ">
                <ion-row class="row-gap" *ngIf="isEdit">
                    <div>
                        <lib-button is-medium is-secondary class="semiBold-md-text"
                            (click)="onCancel()">{{languageLabel.cancelLabel}}</lib-button>
                    </div>
                    <div>
                        <lib-button [disabled]="isEdit" is-medium is-primary class="semiBold-md-text"
                            (click)="!isEdit && onSave()">{{languageLabel.saveLabel}}</lib-button>
                    </div>
                </ion-row>

                <ion-row>
                    <div *ngIf="!isEdit">
                        <lib-button is-medium is-primary class="semiBold-md-text"
                            (click)="onEdit()">{{languageLabel.editLabel}}</lib-button>
                    </div>
                </ion-row>
            </ion-footer>
        </div>
    </ion-grid>
</ion-content>