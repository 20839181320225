<!-- browse-file-component -> to upload the file and drag and drop the file -->
<div class="upload-container flex-gap-8" (dragover)="onDragOver($event)" (dragleave)="onDragLeave($event)" (drop)="onDrop($event)">
  <lib-icon className="upload-cloud" [source]="(icon | pickValueFromObject: 'upload')" ></lib-icon>
  <ion-text class="regular-md-text">{{appConstants.browseLabel}}</ion-text>
  <lib-button is-medium is-secondary class="semiBold-md-text button-align"
    (click)="fileInput.click()">{{appConstants.fileLabel}}</lib-button>
  <input multiple type="file" #fileInput hidden (change)="onFileSelected($event)" />
  <ion-text class="supported-types regular-md-text">{{appConstants.supportedLabel}}</ion-text>
</div>



<div class="thumbnail-grid" *ngIf="fileList?.length">
  @for(file of fileList; track file) {
    @if(!file.isDeleted) {
      <lib-attached-file-detail 
        [showDelete]=true [file]="file" (fileRemoved)="askForConfirmation(file)"
      ></lib-attached-file-detail>
    }
  }
</div>