import { Component, EventEmitter, HostListener, Input, OnInit, output, Output } from '@angular/core';
import { SideNavModel } from '../../models/side-nav-bar.model';
import { Router } from '@angular/router';
import { AlertModel, OrientationEnum } from 'shared-ui/src/lib/models';
import { ModalController } from '@ionic/angular';
import { HttpClient } from '@angular/common/http';
import sideNavDataList from '../../../lib/mock/side-nav.mock.json';
import sideNavBottomList from '../../../lib/mock/side-nav-bottom.mock.json';
import { AlertService } from '../../services/alert.service';
import { AppConstants } from '../../constants/shared-constant';

@Component({
  selector: 'lib-side-nav',
  templateUrl: './side-nav.component.html',
  styleUrl: './side-nav.component.scss',
})
export class SideNavComponent {
  constructor(private router: Router, private modalController: ModalController, private http: HttpClient, private alertService: AlertService) { }

  isMobile: boolean = false;
  isExpand: boolean = false;
  isHamberg: boolean = false;
  initialValue: string = '';
  expandedIcon: string = 'chevron-forward-outline';
  selectedIndex: number | string | null = null;
  expandedItems: { [key: string]: boolean } = {};
  selectedParent: number | string | null = null;
  @Output() bottomMenuClick = new EventEmitter();
  @Output() landingNavigation = new EventEmitter<void>();
  @Output() profileNavigation = new EventEmitter<void>();
  @Input({ required: false }) navBarData: SideNavModel = {
    clientDetails: {
      icon: "/assets/svg/lead.svg",
      expandIcon: "/assets/svg/lead.svg",
    },
    profileDetails: {
      name: 'John Doe',
      role: 'Admin'
    },
    menuItem: sideNavDataList,
    bottomMenu: sideNavBottomList
  }
  appConstants = AppConstants;
  selectedMenu(item: any, index: string | number) {
    this.isExpand = false;
    this.selectedParent = index;
    this.selectedIndex = index;
    this.navBarData.menuItem.forEach((menuItem: any, i: number | string) => {
      if (i !== index) {
        this.expandedItems[i] = false;
      }
    });
    this.expandedItems[index] = !this.expandedItems[index];
    if (item?.openType == 'popup') {

      this.bottomMenuClick.emit(item)
    }
    else {
      this.router.navigateByUrl(item.root)
    }
  }

  isExpanded(index: number): boolean {
    return this.expandedItems[index] || false;
  }
  trackByLabel(index: number, item: any): string {
    return item.menuId;
  }



  // Utility function to get initials from the user's name
  getInitials(name: string): string {
    // Split the name by spaces, take the first letter of each word, and join them
    const initials = name
      .split(' ')
      .map(word => word[0])
      .join('');
    return initials.toUpperCase();
  }

  // Function to toggle the expansion state of the sidebar
  toggleExpand() {
    this.isExpand = !this.isExpand;
  }

  // Function to toggle the hamburger menu in mobile view
  menuExpand() {
    this.isHamberg = !this.isHamberg;
  }

  // Function to handle navigation item selection
  selectedNav(event: any) {
    if (event.label == 'Logout') {
      this.bottomMenuClick.emit(event)
    }
    // Set the selected nav item to active and navigate to the specified URL
    event.isSelected = true;
    this.router.navigateByUrl(event.href);
  }

  // Function to determine if the current view is on a mobile device
  checkIfMobile() {
    this.isMobile = window.innerWidth <= 480;
  }

  // Update `isMobile` value on window resize events
  @HostListener('window:resize', ['$event'])
  onResize() {
    this.checkIfMobile();
  }

  // Function to open a confirmation alert for logging out
  async openLogoutAlert(data: any) {
    // Define modal data for the logout confirmation alert
    const modalData: AlertModel = {
      title: data.content[0].title,
      description: data.content[0].description,
      type: 'confirmation',
      position: OrientationEnum.Center
    };
    this.alertService.confirmation(modalData)
  }

  // Emit event for landing page navigation
  navigateTo() {
    this.landingNavigation.emit();
    this.isExpand = false;
  }

  // Emit event for profile page navigation
  navigateToProfile() {
    this.profileNavigation.emit();
    this.isExpand = false;
  }
}



