<div class="filter_container" [ngStyle]="{'padding': padding}" *ngIf="data.length">
    <ion-row class="chip_header">
        <div class="filter_body_container" *ngFor="let item of data; trackBy: trackByFn; let index = index">
            <ion-row class="child_row">
                <label class="font_size_12">{{item.name}}</label>
                <div class="chip_container" *ngIf="item.value && (item.value | isArray)">
                    <div class="p-l-8" *ngFor="let value of item.value; let childIndex = index">
                        <lib-chip [value]="value.displayName" (close)="removeChip(index, childIndex)" />
                    </div>
                </div>
                <div class="p-l-8 chip_container" *ngIf="!(item.value | isArray) && item.value">
                    <lib-chip [value]="item.value" (close)="removeChip(index)" />
                </div>
                <ion-icon name="close-circle-outline" class="p-l-8 cursor-pointer circle_close"
                    (click)="removeFilterDetails(index)"></ion-icon>
            </ion-row>
        </div>

        <button class="remove_btn cursor-pointer" (click)="removeAll()">Remove All</button>
    </ion-row>
</div>