import { DropdownOptions } from "./shared-common.model";

export interface CompanyFormsModel {
  header: string;
  company: Array<CompanyFieldParentModel>
}

export interface CompanyFieldParentModel extends CompanyFieldCommonMoodel {
  child: Array<CompanyChildModel>
}

export interface CompanyChildModel extends CompanyFieldCommonMoodel {
  isBookerCompany?: boolean;
  isBooker?: boolean;
  isResident?: boolean;
  personCompany?: CompanyChildModel
}

export interface CompanyFieldCommonMoodel {
  group?: string,
  header?: string,
  label: string,
  value: DropdownOptions | null,
  type: string
  options: DropdownOptions[],
}
export class childTemplate implements CompanyChildModel {

  isBookerCompany?: boolean | undefined;
  isBooker?: boolean | undefined;
  isResident?: boolean | undefined;
  personCompany?: childTemplate | undefined;
  group?: string | undefined;
  header?: string | undefined;
  label: string;
  value: DropdownOptions | null;
  type: string;
  options: DropdownOptions[];

  constructor(length: number, isBooker: boolean = false) {
    this.label = `person ${length || 1}`;
    this.value = null;
    this.type = 'dropdown';
    this.options = [
      {
        id: 1,
        name: "Netflix"
      }
    ];
    this.header = length === 0 ? "Persons" : ""
    this.group = 'Person';
    this.personCompany = {
      group: "company",
      label: "Billing Parties",
      type: "dropdown",
      value: null,
      options: [
        {
          id: 1,
          name: "Hotstar"
        }
      ],
      isBooker: isBooker,
      isResident: false
    }
  }
}

export class newBillingParty {
  child: Array<CompanyChildModel>
  constructor() {
    this.child = [{
      group: "company",
      header: "select",
      label: "Billing Parties",
      type: "dropdown",
      value: null,
      options: [
        {
          id: 1,
          name: "Netflix"
        }
      ],
      isBookerCompany: false
    }]
    const temp = new childTemplate(0) as CompanyChildModel
    this.child.push(temp);
  }
}

export class newCompanyTemplate implements CompanyFieldParentModel {

  label: string;
  value: DropdownOptions | null;
  type: string;
  options: DropdownOptions[];
  child: CompanyChildModel[];

  constructor() {
    this.label = "Company";
    this.value = null;
    this.type = "dropdown";
    this.options = [
      { "id": 1, "name": "Netflix" }
    ];
    this.child = []
  }
}

export interface fieldCommonModel {
  label: string;
  value: any;
  type: string;
  name: string;
  isRequired?: boolean;
  isDisabled?: boolean;
}