<!-- Description input with change handler. -->
<div *ngIf="inputData" [attr.id]="inputData.leadRef" class="input-containers position-relative">
  <ion-label class="textarea-label medium-md-text label-color-text" *ngIf=" inputData.label">{{ inputData.label }} 
    @if(infoMsg) {
    <div class="icon-container">
      <ion-icon class="info-icon" [src]=iconPath.iIcon alt=""></ion-icon>
      <div class="tooltip">{{infoMsg}}</div>
    </div>
    } <span *ngIf="inputData.isRequired" class="red-color-text">*</span>
  </ion-label>
  <textarea [maxlength]="inputData.maxLength" class="input-text regular-sm-text textarea-field" [(ngModel)]="inputData.value"
    [placeholder]="inputData.placeholder" [id]="inputData.label" name="textareaText" [disabled]="inputData.isDisabled ?? false"
    (ngModelChange)="onInputChange($event,inputData)" (focusout)="focusoutInput(inputData)"></textarea>
  <span class="remaining-textarea-count">{{ inputData.value ? inputData.value.length : 0 }}/{{ inputData.maxLength }}</span>
  <small *ngIf="inputData.isShowErrorMessage && inputData.showErrorMessage"
    class="red-color-text">{{inputData.showErrorMessage }}</small>
</div>