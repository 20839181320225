import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LanguageService } from '../modules/shared/services/language.service';
import { AppConstants, IconPath } from '../core/app-constant';
import { ModalController, PopoverController } from '@ionic/angular';
import { CdnContentService } from 'shared-ui/src/lib/services/cdn-content.service';

@Component({
  selector: 'app-user',
  templateUrl: './user.component.html',
  styleUrl: './user.component.scss',
})
export class UserComponent implements OnInit {
  @ViewChild('fileInput') fileInput!: ElementRef<HTMLInputElement>;
  @Input() profileData: any;
  @Input() mode!: string;
  @Input() name!: string ;
  languageLabel: any;
  iconPath = IconPath;
  profileImage: string | ArrayBuffer | null = null;
  appConstants = AppConstants
  constructor(private cdnContentService: CdnContentService,private languageService: LanguageService, private modalCtrl: ModalController) {

  }
  ngOnInit() {
    this.languageLabel = this.languageService.getProfileKey();
  }
  get isEdit(): boolean {
    return this.mode === this.appConstants.editMode;
  }
  get isAdd(): boolean {
    return this.mode === this.appConstants.addMode;
  }
  imageClick() {

  }

  onCancel() {
    this.modalCtrl.dismiss();
  }

  onSave() {

  }

  addImage() {

  }

  async onFileSelected(event: Event) {
    const input = event.target as HTMLInputElement;
    if (input.files && input.files.length > 0) {
      const file = input.files[0];
     this.profileImage = await this.cdnContentService.uploadBlob(file,'client','profile',file.name);
     this.profileImage = this.profileImage.split('?')[0];
  }
}
}
