import { Component, Input, OnInit } from '@angular/core';
import { PropertyDetailRequest } from '../../../request/models/rfh.model';

@Component({
  selector: 'app-request-property',
  templateUrl: './request-property.component.html',
  styleUrl: './request-property.component.scss',
})
export class RequestPropertyComponent implements OnInit {
  @Input() data!: PropertyDetailRequest;

  constructor() { }
  ngOnInit() {
  }

}
