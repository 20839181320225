import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class StorageService {
  storage = localStorage;

  /**
   * Retrieves item from the storage
   * @param key 
   * @returns 
   */
  get(key: string) {
    let value;
    const item = this.storage.getItem(key);

    if (item) {
      value = JSON.parse(item);
    } else {
      value = null;
    }

    return value;
  }

  /**
   * Sets the item in the storage
   * @param key 
   * @param value 
   */
  set(key: string, value: string) {
    this.storage.setItem(key, JSON.stringify(value));
  }

  /**
   * Check the value exists or not
   * @param key 
   * @returns 
   */
  checkValueExists(key: string) {
    return !!this.storage.getItem(key);
  }

  /**
   * Remove the item from the storage
   * @param key 
   */
  remove(key: string) {
    this.storage.removeItem(key);
  }
}