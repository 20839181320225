import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { AlertService } from '../../services/alert.service';
import { AlertModel, AlertButtonModel, TextAreaInput, OrientationEnum } from '../../models';
import { AppConstants } from '../../constants/shared-constant';

@Component({
  selector: 'lib-alert',
  templateUrl: './alert.component.html',
  styleUrl: './alert.component.scss',
})
export class AlertComponent implements OnInit, OnDestroy {

  options!: AlertModel | undefined;

  alertSubscription!: Subscription;

  textAreaOption!: TextAreaInput;

  orientation = OrientationEnum

  constructor(
    private alertService: AlertService
  ) { }
  ngOnInit() {
    this.alertSubscription = this.alertService.subject.subscribe((option: AlertModel) => {
      // Copy incoming options and set default position if not provided.
      this.options = { ...option };
      this.options.position = option.position ?? OrientationEnum.Center
      // Configure textarea options if the alert requires a textarea input.
      if (this.options && this.options.textarea) {
        this.textAreaOption = {
          placeholder: this.options.textarea.placeholder ?? AppConstants.defaultPlaceholder,
          value: this.options.textarea.value ?? '',
          label: this.options.textarea.label ?? '',
          minLength: this.options.textarea.minLength ?? AppConstants.defaultMinLengthTextarea,
          maxLength: this.options.textarea.maxLength ?? AppConstants.defaultMaxLengthTextarea,
          type: AppConstants.fieldType.textarea,
          isRequired: this.options.textarea.isRequired ?? false
        }
      }
    })
  }

  /**
  * Lifecycle hook that cleans up the component.
  * Unsubscribes from the alert subscription to prevent memory leaks.
  */
  ngOnDestroy() {
    this.alertSubscription.unsubscribe();
  }

  /**
   * Utility function to check if a button contains a specific attribute.
   * @param attribute - The attribute to search for.
   * @param button - The button to check.
   * @returns `true` if the button contains the attribute, otherwise `false`.
   */
  findAttribute(attribute: string, button: AlertButtonModel): boolean {
    return button.attributes.includes(attribute);
  }

  /**
   * Dismisses the alert by resetting the options and clearing the textarea values.
   */
  dismiss() {
    this.options = undefined;
    this.textAreaOption = {
      ...this.textAreaOption,
      value: '',
      isRequired: false
    };
  }

  /**
   * Handles the click event for a button within the alert.
   * Executes the button's handler and passes relevant data.
   * @param button - The button that was clicked.
   */
  clickListener(button: AlertButtonModel) {
    const data: { [key: string]: unknown } = { ...button };
    // Include textarea data if present.
    if (this.options?.textarea) data[AppConstants.fieldType.textarea] = { value: this.textAreaOption.value };
    // Include textarea data if present.
    if(button.handler) button.handler({ ...data });
    // Dismiss the alert after the button is handled.
    this.dismiss();
  }
}
