import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AppConstants } from '../../constants/shared-constant';

@Component({
  selector: 'lib-option-picker',
  templateUrl: './option-picker.component.html',
  styleUrl: './option-picker.component.scss',
})
export class OptionPickerComponent implements OnInit {
  /**
   * Option selected
  */
  @Input()
  selection = 5;

  /**
   * Options already taken/selected
  */
  @Input()
  optionsAlreadyTaken: Array<number> = [];

  /**
   * Maximum prefrence
  */
  @Input()
  maxSelection?: number = AppConstants.pickerMaxSelection;

  /**
   *option list
  */
  options: Array<number> = [];

  /**
   * Emit the selection option to parent(for two binding)
  */
  @Output()
  selectionChange: EventEmitter<number> = new EventEmitter<number >();

  /**
   * Life cycle hook - invoked when compenent pushed to the DOM
  */
  ngOnInit(): void {
   this.createOptions();
  }

  /**
   * Create the options list
  */
  createOptions() {
    this.options = Array.from({ length: this.maxSelection || AppConstants.pickerMaxSelection }, (_, i) => i + 1);
  }

  /**
   * option change handler -> Emit the value back to the parent component
  */
  handleOptionChange(optionSelected: number) {
    if(optionSelected === this.selection) this.selection = -1;
    else this.selection = optionSelected;
    this.selectionChange.emit(this.selection);
  }
}
