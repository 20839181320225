<!-- Attached-file-detail component -> To display the detail about uploaded file with file name, size, uploaded By, date and we can delete the uploaded file also -->
<div *ngIf="file">
  <div class="image-card-container">
    <div class="image-card">
      <div class="space-align">
        <div class="row-align">
          <img [src]="file.imageUrl" alt="" class="image-thumbnail">
          <div class="image-info">
            <div class="image-details">
              <p class="semiBold-md-text m-0">{{ file.name }}</p>
              <!-- <p class="regular-sm-text m-0 color-grey">{{ file.size }}</p> -->
            </div>
          </div>
        </div>

        <div *ngIf="showDelete">
          <img 
            class='delete-icon' src="assets/icons/delete-with-line-icon.svg" alt="no-image" 
            (click)="removeFile()"
          >
        </div>
      </div>

      <ion-row class="space-align">
        <div>
          <ion-label class="semiBold-md-text">{{file.uploadedBy}}</ion-label>
        </div>
        <div>
          <ion-label class="regular-sm-text color-grey">{{ file.lastModifiedDate | date: 'MMM d, yyyy' }}</ion-label>
        </div>
      </ion-row>
    </div>
  </div>
</div>