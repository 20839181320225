export interface LoginResponse {
    token : string
    firstName :string
    lastName:string
}

export interface CorporateLogin {
    logo: string,
    img1: string,
    welcomeText: string,
    domainName: string,
    colorScheme: string
}
