import { Component, EventEmitter, HostListener, Inject, Input, OnInit, Output } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { checkScreenSize } from '@synergy-application/shared-ui';
import { LanguageService } from '../../services/language.service';
import { ElementRef } from '@angular/core';
import { CorporateLogin, SideNavModel, TopNavInput, UserProfile } from 'shared-ui/src/lib/models';
import { ROUTESPATH } from 'apps/booking-portal/src/app/core/routes-constant';
import { AppConstants, IconPath } from 'apps/booking-portal/src/app/core/app-constant';
import { ModalController } from '@ionic/angular';
import { UserComponent } from 'apps/booking-portal/src/app/user/user.component';
import { UserService } from '../../../user-management/service/user.service';
import { ROUTESLABEL } from 'apps/booking-portal/src/app/enum/common';


@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  appConstants = AppConstants;

  @Input() loginDisplay = false;

  @Input() showHeader = true;

  @Input() tenantInfo!: CorporateLogin;

  @Input() userInfo!: UserProfile;

  @Input() isShowSideMenu = false;

  @Input() sideNavDataList!: SideNavModel;

  @Output() logoutEvent: EventEmitter<object> = new EventEmitter();

  @Output() emitNav: EventEmitter<string> = new EventEmitter()

  requestlabel = ROUTESLABEL;

  isMobile = false;

  subHeaderText = 'Home';

  headerOptions!: Array<TopNavInput>;

  profileOption!: Array<TopNavInput>;

  showDropdown = false;

  bellIcon = "/assets/svg/bell.svg";

  userManagementLabel: any;

  iconPath = IconPath;

  constructor(private userService: UserService, private router: Router, private elementRef: ElementRef, private modalController: ModalController,
    private languageService: LanguageService) { }


  /**
   * On initialization of the page, display the page elements based on the user state
   */
  ngOnInit(): void {
    this.userManagementLabel = this.languageService.getUserKey();
    this.initializeVariable();
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.subHeaderText = this.requestlabel[event?.url  as keyof typeof  ROUTESLABEL] || 'Home'
        this.emitNav.emit(event.url)
      }
    })
  }
  async addNewUser() {
    const modal = await this.modalController.create({
      component: UserComponent,
      cssClass: 'view-edit-content-popup',
      componentProps: {
        profileData: await this.userService.mapToResponse(),
        mode: this.appConstants.addMode
      },
    });
    modal.onDidDismiss().then(data => {

    });

    modal.present();
  }
  /**
   * Initializes header options from language service data.
   */
  async initializeVariable() {
    const key: any = await this.languageService.getHeaderKey();
    this.profileOption = [{
      label: key.profile,
      img: '/assets/icon/profile.svg',
      url: ROUTESPATH.PROFILE
    },
    {
      label: key.logout,
      img: '/assets/icon/loggedOut.svg'
    }]
    this.headerOptions = [
      {
        label: key.manageReservation,
        img: '',
        dropdownOptions: [
          // { label: key.bookOnRecommendation, url: ROUTESPATH.RESERVATION }, // implement on future
          { label: key.requestAnAccommodation, url: ROUTESPATH.RFHREQUEST },
          // { label: key.myReservations, url: '' }, // implement on future
          { label: key.myHousingRequests, url: ROUTESPATH.MYREQUEST },
          // { label: key.myReservation, url: ROUTESPATH.MYRESERVATION }
        ]
      },
      {
        label: key.travelPolicy,
        img: '',
        url: ROUTESPATH.POLICY
      },
      {
        label: key.faq,
        img: '',
        url: ROUTESPATH.FAQ
      }
    ];
  }

  /**
   * Handles actions when the window or element is resized.
   * @param event 
   */
  @HostListener('window:resize', ['$event'])
  onResize(event: Event): void {
    this.isMobile = checkScreenSize();
  }

  /**
  * Closes the dropdown if a click occurs outside the component.
  * @param target - The target element that was clicked.
  */
  @HostListener('document:click', ['$event.target'])
  onDocumentClick(target: HTMLElement) {
    const clickedInside = this.elementRef.nativeElement.contains(target);
    if (!clickedInside) {
      this.showDropdown = false;
    }
  }

  /**
   *  Navigates the user to the specified page.
   * @param data 
   */
  navigateToPage(data: TopNavInput) {
    this.showDropdown = false;
    if (data.url) {
      this.router.navigateByUrl(data.url)
    } else {
      const logoutData = {
        content: [
          {
            title: this.userManagementLabel.title,
            description: this.userManagementLabel.description
          }
        ]
      };
      this.logoutEvent.emit(logoutData);
    }
  }

  /**
   * Shows the dropdown when the input is clicked
   */
  onClick(flag: boolean) {
    this.showDropdown = !flag;
  }

  goToLanding() {
    this.router.navigateByUrl(ROUTESPATH.LANDING);
  }
}