<div class="detail-card">
  <ion-grid class="lead-info">
    <!-- Loop over groups in the viewModel -->
    <div id="groups" *ngFor="let group of viewModel?.group">
      <!-- Group Header Item (clickable to toggle show/hide) -->
      <span class="expand-content">
        <ion-label class="semiBold-xl-text-20px">{{ group.name }}</ion-label>
        <ion-icon class="expand-icon cursor-p" [name]="group.showDetails ? 'chevron-down' : 'chevron-forward'"
          *ngIf="useExpandCollapse" (click)="fieldDetailsToShow(group)">
        </ion-icon>
      </span>
      <!-- Grouped Fields (toggle visibility based on showDetails) -->
      <div class="field-text">
        <ion-row *ngIf="group.showDetails || group.showMore ">
          <ion-col [size]="field.size ?? 3" *ngFor="let field of getFieldsToShow(group)" class="field"
            [ngClass]="field.type === 'checkBox' ? 'position-relative' : ''">
            <ion-icon [src]="field.icon" class="field-icon"></ion-icon>
            <div class="field-type" [ngClass]="{
              'checkbox-ui': field.type === 'checkBox',
              'toggle-ui': field.type === 'toggle'
          }">
              <div class="field-type">
                <ion-label class="regular-md-text">{{ field.displayName }} </ion-label>
                <span class="medium-md-text"
                  *ngIf="field.type !== 'date'  && field.type != 'dropDown' && field.type !== viewType.dateTime && field.type !== viewType.CHECKBOX_GROUP">{{
                  field.value || '-' }}</span>
              </div>
              <ng-container [ngSwitch]="field.type">
                <span class="regular-md-text" *ngSwitchCase="viewType.dateTime">
                  <lib-date-picker (dateObj)="selectedDate($event)"  [inputData]="{
                                                      label: '',
                                                      placeholder: field.placeholder ?? '',
                                                      value: field.value,
                                                      mode: field.mode,
                                                      iconPosition: datePickerConfig.iconPosition,
                                    iconSize: datePickerConfig.iconSize,
                                    iconName: datePickerConfig.iconName,
                                    isBorder: datePickerConfig.isBorder,
                                    height: datePickerConfig.height
                                                    }" (dateObj)="selectedDate($event)">

                  </lib-date-picker>
                </span>
                <span class="medium-md-text toggle-content" *ngSwitchCase="viewType.TOGGLE">
                  <lib-toggle [data]="toggleDetails" (toggleChange)="onToggleChange($event, field)"></lib-toggle>
                </span>
                <span class="regular-md-text" *ngSwitchCase="viewType.CHECKBOX">
                  <lib-checkbox class="field-icon" 
                    (selectedValueChange)="onFieldChange($event)"></lib-checkbox>
                </span>
                <span class="regular-md-text checkbox-group" *ngSwitchCase="viewType.CHECKBOX_GROUP">
                  <ng-container *ngFor="let option of field.checkBoxOptions">
                    <lib-checkbox [data]="option"
                      (selectedValueChange)="checkboxChangeGroup($event, field.checkBoxOptions)"></lib-checkbox>
                  </ng-container>
                </span>
                <span class="medium-md-text" *ngSwitchCase="viewType.DROPDOWN">
                  <lib-dropdown [data]="field.options ?? []" [(ngModel)]="field.value" 
                    (ngModelChange)="onDropdownChange($event)" displayBy="value" value="id"></lib-dropdown>
                </span>
                <span class="medium-md-text" *ngSwitchCase="viewType.DATE">
                  <ion-label class="medium-md-text">{{ field.value ? (field.value | formatDate: field.dateFormat) : '-'
                    }}
                  </ion-label>
                </span>

                <div *ngSwitchCase="viewType.VIEW" class="display-flex">
                  <ion-label class="semiBold-sm-text cursor-p" (click)="onViewClicked()"><ion-icon
                      name="eye-outline"></ion-icon>View
                  </ion-label>
                  <!-- Provide a download button if a valid file URL exists -->
                  <ion-label class="semiBold-sm-text cursor-p" (click)="onDownloadClicked()"><ion-icon
                      name="download-outline"></ion-icon>Download
                  </ion-label>
                </div>
              </ng-container>
            </div>
          </ion-col>
        </ion-row>
        <!-- Show more/less button if there are more fields than the initialColumns -->
        <div *ngIf="group.fields?.length ?? 0 > initialColumns && !useExpandCollapse && !showDetailsInitially">
          <span class="semiBold-sm-text cursor-p" (click)="fieldDetailsToShow(group)">
            {{ group.showMore ? 'Show Less' : 'Show More' }}
          </span>
        </div>
      </div>
    </div>
    <!-- Ungrouped Fields (display initially and toggle visibility based on showAllDetails) -->
    <div class="ungrouped">
      <span class="expand-content" *ngIf="useExpandCollapse">
        <ion-label class="semiBold-xl-text-20px">{{viewModel.name}} </ion-label>
        <ion-icon class="expand-icon cursor-p" (click)="fieldDetailsToShow()"
          [name]="showAllDetails ? 'chevron-down' : 'chevron-forward'">
        </ion-icon></span>
      <ion-row *ngIf="!useExpandCollapse">
        <ion-col [size]="field.size ?? 3" *ngFor="let field of getInitialFields(viewModel?.fields ?? [])" class="field"
          [ngClass]="field.type === 'checkBox' ? 'position-relative' : ''">
            <!-- section Header -->
          <span class="expand-content section" >
            <ion-label class="semiBold-xl-text-20px">{{field.sectionHeader}} </ion-label>
           </span>
             <!-- section Header -->
          <ion-icon *ngIf="field.icon" [src]="field.icon" class="field-icon"></ion-icon>
          <div class="field-text" [ngClass]="{
            'checkbox-ui': field.type === 'checkBox',
            'toggle-ui': field.type === 'toggle'
        }">
            <div class="field-type" *ngIf="field.type !== 'checkBox'"><ion-label class="regular-md-text">{{
                field.displayName }} 
                <span *ngIf="field.required"
      class="red-color-text">*</span>
              </ion-label>
              <span class="medium-md-text"
                *ngIf="field.type !== 'date'  && field.type != 'dropDown' && field.type !== viewType.dateTime && field.type !== viewType.CHECKBOX_GROUP && !field.sectionHeader">{{
                field.value || '-' }}</span>
            </div>
            <ng-container [ngSwitch]="field.type">
              <span class="regular-md-text" *ngSwitchCase="viewType.dateTime">
                <lib-date-picker (dateObj)="selectedDate($event)" [inputData]="{
                                                    label: '',
                                                    placeholder: field.placeholder ?? '',
                                                    value: field.value,
                                                    mode: field.mode,
                                                    iconPosition: datePickerConfig.iconPosition,
                                    iconSize: datePickerConfig.iconSize,
                                    iconName: datePickerConfig.iconName,
                                    isBorder: datePickerConfig.isBorder,
                                    height: datePickerConfig.height
                                                  }" (dateObj)="selectedDate($event)">

                </lib-date-picker>
              </span>
              <span class="medium-md-text toggle-content" *ngSwitchCase="viewType.TOGGLE">
                <lib-toggle [data]="field.toggleDetails" [disabled]="field.isDisabled" (toggleChange)="onToggleChange($event, field)"></lib-toggle>
              </span>
              <span class="regular-md-text" *ngSwitchCase="viewType.CHECKBOX">
                <lib-checkbox class="field-icon" [label]="field.displayName" [description]="field.description ?? '' "
                  (selectedValueChange)="onFieldChange($event)"></lib-checkbox>
              </span>
              <span class="regular-md-text checkbox-group" *ngSwitchCase="viewType.CHECKBOX_GROUP">
                <ng-container *ngFor="let option of field.checkBoxOptions">
                  <lib-checkbox [data]="option" (selectedValueChange)="checkboxChangeGroup($event, field.checkBoxOptions)"></lib-checkbox>
                </ng-container>
              </span>
              <span class="medium-md-text" *ngSwitchCase="viewType.DROPDOWN">
                <lib-dropdown [data]="field.options ?? []"  
                (ngModelChange)="onDropdownChange($event)" displayBy="value"  [(ngModel)]="field.value"
                  value="id" [disabled]="field.isDisabled ?? false"  ></lib-dropdown>
              </span>
              <span class="medium-md-text" *ngSwitchCase="viewType.DATE">
                <ion-label class="medium-md-text">{{ field.value ? (field.value | formatDate: field.dateFormat) : '-' }}
                </ion-label>
              </span>
              <div *ngSwitchCase="viewType.VIEW" class="display-flex">
                <ion-label class="semiBold-sm-text cursor-p" (click)="onViewClicked()"><ion-icon
                    name="eye-outline"></ion-icon>View
                </ion-label>
                <!-- Provide a download button if a valid file URL exists -->
                <ion-label class="semiBold-sm-text cursor-p" (click)="onDownloadClicked()"><ion-icon
                    name="download-outline"></ion-icon>Download
                </ion-label>
              </div>
            </ng-container>
          </div>
        </ion-col>
      </ion-row>
      <!-- Show more/less button for ungrouped fields if there are more than initialColumns -->
      <div *ngIf="viewModel?.fields?.length ?? 0 > initialColumns && !useExpandCollapse  && !showDetailsInitially">
        <span class="semiBold-sm-text cursor-p" (click)="fieldDetailsToShow()">
          {{ showAllDetails ? 'Show Less' : 'Show More' }}
        </span>
      </div>
      <!-- Show all ungrouped fields when 'showAllDetails' is true -->
      <ion-row *ngIf="showAllDetails">
        <ion-col [size]="field.size ?? 3" *ngFor="let field of getRemainingFields(viewModel?.fields ?? [])"
          class="field" [ngClass]="field.type === 'checkBox' ? 'position-relative' : ''">
          <ion-icon [src]="field.icon" class="field-icon"></ion-icon>
          <div class="field-type" [ngClass]="{
            'checkbox-ui': field.type === 'checkBox',
            'toggle-ui': field.type === 'toggle'
        }">
            <div class="field-type"><ion-label class="regular-md-text">{{ field.displayName }}</ion-label>
              <span class="medium-md-text"
                *ngIf="field.type !== 'date'  && field.type != 'dropDown' && field.type !== viewType.dateTime && field.type !== viewType.CHECKBOX_GROUP"
                >{{
                field.value || '-' }}</span>
            </div>
            <ng-container [ngSwitch]="field.type" class="switch">
              <span class="medium-md-text toggle-content" *ngSwitchCase="viewType.TOGGLE">
                <lib-toggle [data]="toggleDetails" (toggleChange)="onToggleChange($event, field)"></lib-toggle>
              </span>
              <span class="regular-md-text checkbox-group" *ngSwitchCase="viewType.CHECKBOX_GROUP">
                <ng-container *ngFor="let option of field.checkBoxOptions">
                  <lib-checkbox [data]="option" (selectedValueChange)="checkboxChangeGroup($event, field.checkBoxOptions)"></lib-checkbox>
                </ng-container>
              </span>
              <span class="regular-md-text" *ngSwitchCase="viewType.dateTime">
                <lib-date-picker (dateObj)="selectedDate($event)" [inputData]="{
                                    label: '',
                                    placeholder: field.placeholder ?? '',
                                    value: field.value,
                                    mode: field.mode,
                                    iconPosition: datePickerConfig.iconPosition,
                                    iconSize: datePickerConfig.iconSize,
                                    iconName: datePickerConfig.iconName,
                                    isBorder: datePickerConfig.isBorder,
                                    height: datePickerConfig.height
                                  }" (dateObj)="selectedDate($event)">

                </lib-date-picker>
              </span>
              <span class="regular-md-text" *ngSwitchCase="viewType.CHECKBOX">
                <lib-checkbox [label]="field.displayName" [description]="field.value ?? '' "
                  (selectedValueChange)="onFieldChange($event)"></lib-checkbox>
              </span>
              <span class="medium-md-text" *ngSwitchCase="viewType.DROPDOWN">
                <lib-dropdown [disabled]="field.isDisabled" [data]="field.options ?? []" displayBy="value" (ngModelChange)="onDropdownChange($event)"
                  value="id"></lib-dropdown>
              </span>
              <span class="medium-md-text" *ngSwitchCase="viewType.DATE">
                <ion-label class="medium-md-text">{{ field.value ? (field.value | formatDate: field.dateFormat) : '-' }}
                </ion-label>
              </span>
              <div *ngSwitchCase="viewType.VIEW" class="display-flex">
                <ion-label class="semiBold-sm-text cursor-p" (click)="onViewClicked()"><ion-icon
                    name="eye-outline"></ion-icon>View
                </ion-label>
                <!-- Provide a download button if a valid file URL exists -->
                <ion-label class="semiBold-sm-text cursor-p" (click)="onDownloadClicked()"><ion-icon
                    name="download-outline"></ion-icon>Download
                </ion-label>
              </div>
            </ng-container>
          </div>
        </ion-col>
      </ion-row>
    </div>
  </ion-grid>
</div>