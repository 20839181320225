import { Injectable } from '@angular/core';
import { hexToRgb } from '@synergy-application/shared-ui';

@Injectable({
  providedIn: 'root'
})

export class ThemeService {
  favIconUrl!: string;

  theme: object = {};

  brandTheme: object = {};
  defaultTheme: object = {
    '--app-primary-color-scheme': '#20A6AC',
    // '--app-primary-color-rgb-scheme': hexToRgb('#20A6AC')
  }

  constructor(
  ) { }

  /**
  * Applies the selected theme.
  */
  applyTheme(theme: any) {
    Object.keys(theme).forEach((key) => {
      document.documentElement.style.setProperty(key, theme[key]);
    });
  }

  /**
   * Applies the selected brand theme.
   */
  applyBrandTheme(theme: string) {
    this.brandTheme = {
      '--app-primary-color-scheme': theme,
      // '--app-primary-color-rgb-scheme': hexToRgb(theme)
    };
    this.applyTheme(this.brandTheme);
  }

  /**
  * Applies the selected default theme.
  */
  applyDefaultTheme() {
    this.applyTheme(this.defaultTheme);
    this.theme = this.defaultTheme;
  }

  /**
   * Applies the selected favicon.
   */
  applyFavIcon(icon: string) {
    this.favIconUrl = icon;
  }
}