import { Directive, ElementRef, HostListener, Input, Renderer2 } from '@angular/core';

@Directive({
  selector: '[tooltip]'
})
export class TooltipDirective {
  @Input('tooltip') tooltipText: string = ''; // Tooltip content

  @Input() tooltipPosition: 'top' | 'bottom' | 'left' | 'right' = 'bottom'; // Position

  private tooltipElement: HTMLElement | null = null;

  constructor(private el: ElementRef, private renderer: Renderer2) { }

  @HostListener('mouseenter') onMouseEnter() {
    if (this.tooltipText) {
      this.showTooltip();
    }
  }

  @HostListener('mouseleave') onMouseLeave() {
    this.hideTooltip();
  }

  private showTooltip() {
    // Create a dummy tooltip element
    this.tooltipElement = this.renderer.createElement('div');
    this.renderer.appendChild(
      this.tooltipElement,
      this.renderer.createText(this.tooltipText) // Hardcoded text for testing
    );

    // Add a test class for visibility
    this.renderer.appendChild(document.getElementById('app'), this.tooltipElement);

    // Add fixed position for testing
    this.renderer.setStyle(this.tooltipElement, 'position', 'absolute');
    this.renderer.setStyle(this.tooltipElement, 'z-index', '1000'); // Ensure visibility

    if (this.tooltipElement) {
      const hostPos = this.el.nativeElement.getBoundingClientRect();
      const tooltipPos = this.tooltipElement.getBoundingClientRect();

      const offset = 10; // Space between tooltip and element
      let top, left;
      switch (this.tooltipPosition) {
        case 'top':
          top = hostPos.top - tooltipPos.height - offset;
          left = hostPos.left + (hostPos.width - tooltipPos.width) / 2;
          break;
        case 'bottom':
          top = hostPos.bottom + offset;
          left = hostPos.left + (hostPos.width - tooltipPos.width) / 2;
          break;
        case 'left':
          top = hostPos.top + (hostPos.height - tooltipPos.height) / 2;
          left = hostPos.left - tooltipPos.width - offset;
          break;
        case 'right':
          top = hostPos.top + (hostPos.height - tooltipPos.height) / 2;
          left = hostPos.right + offset;
          break;
      }
      this.renderer.setStyle(this.tooltipElement, 'top', `${top}px`);
      this.renderer.setStyle(this.tooltipElement, 'left', `${left}px`);
      this.renderer.addClass(this.tooltipElement, 'custom-tooltip');

    }

  }

  private hideTooltip() {
    if (this.tooltipElement) {
      this.renderer.removeChild(document.body, this.tooltipElement);
      this.tooltipElement = null;
    }
  }
}
