<div class="lead_filter_container" *ngIf="isRendered">
    <div class="header-section">
        <h2 class="h4">Filters</h2>
        <ion-icon class="close-icon" name="close-outline" (click)="closeSlider()"></ion-icon>
    </div>
    <ion-row *ngFor="let data of props; trackBy: trackByName" class="row_p">
        <!-- Main content when `data.child` has items -->
        <ion-col size-xs="12" size-sm="12" size-md="12" size-lg="12" size-xl="12"
            *ngIf="(data?.child?.length ?? 0) > 0; else noChildTemplate" class="ion-no-padding">
            <label *ngIf="data.displayName" class="medium-sm-text label-color-text">{{data.displayName}}</label>
            <ion-row class="checkbox_p">
                <ng-container *ngFor="let item of data?.child; trackBy: trackByName">
                    <!-- Use the common template for rendering item types -->
                    <ng-container *ngTemplateOutlet="itemTemplate; context: { item: item, grid: data.grid }">
                    </ng-container>
                </ng-container>
            </ion-row>
        </ion-col>

        <!-- Fallback template when `data.child` has no items -->
        <ng-template #noChildTemplate>
            <ion-col size-xs="12" size-sm="12" size-md="12" size-lg="12" size-xl="12" class="ion-no-padding">
                <!-- Use the common template for noChild items -->
                <ng-container *ngTemplateOutlet="itemTemplate; context: { item: data, grid: data.grid }">
                </ng-container>
            </ion-col>
        </ng-template>
    </ion-row>

    <!-- Common template for item types -->
    <ng-template #itemTemplate let-item="item" let-grid="grid">
        <ion-col *ngIf="item.type === 'dropDown'" [attr.size-xl]="grid | filterSize" [attr.size-lg]="grid | filterSize"
            class="ion-no-padding">
            <lib-dropdown [data]="item.value" [placeholder]="item.placeHolder ?? ''" [label]="item.displayName"
                [displayBy]="'value'" [value]="'key'" [loadOnDemand]="true" [name]="item.name"
                [(ngModel)]="item.selectedValue" [searchable]="true" [allowApiSearch]="true" 
                [searchDebounce]="2000" [showCheckbox]="true"
                (ngClick)="dropDownClick($event)" (ngScroll)="dropDownScroll($event)"
                (ngSearch)="dropDownSearch($event)" [multiSelectable]="item.isMultiple"></lib-dropdown>
        </ion-col>
        <ion-col *ngIf="item.type === 'input'" [attr.size-xl]="grid | filterSize" [attr.size-lg]="grid | filterSize"
            class="ion-no-padding">
            <lib-input [inputData]="item.value"></lib-input>
        </ion-col>
        <ion-col *ngIf="item.type === 'date'" [attr.size-xl]="grid | filterSize" [attr.size-lg]="grid | filterSize"
            class="ion-no-padding">
            <lib-date-picker *ngIf="item.value" [inputData]="item.value"></lib-date-picker>
        </ion-col>
        <ion-col *ngIf="item.type === 'checkBox'" [attr.size-xl]="grid | filterSize" [attr.size-lg]="grid | filterSize"
            class="ion-no-padding">
            <lib-checkbox [data]="item.value" />
        </ion-col>
        <ion-col *ngIf="item.type === 'multiSelect'" [attr.size-xl]="grid | filterSize"
            [attr.size-lg]="grid | filterSize" class="ion-no-padding">
            <lib-multi-select-dropdown [inputData]="item.value" [defaultDropdown]="true" [isMultiSelect]="true"
                [labelKey]="'value'" [valueKey]="'key'" [displayName]="item.displayName" [loadOnDemand]="true"
                [placeHolder]="item.placeHolder" [name]="item.name" [isSelected]="item.selectedValue"
                [allowApiSearch]="true" (ngSearch)="dropDownSearch($event)" (ngClick)="multiSelectDropdownClick($event)"
                (selectionChanged)="selectionChanged($event, item)" (ngScroll)="dropDownScroll($event)" />
        </ion-col>
    </ng-template>
</div>
<div *ngIf="isRendered">
    <ion-row class="footer_row">
        <ion-col size-xs="12" size-sm="12" size-md="12" size-lg="6" size-xl="6" class="footer_container ion-no-padding">
            <lib-button is-secondary (click)="resetFilter()">Reset</lib-button>
            <lib-button is-primary (click)="applyFilter()">Apply</lib-button></ion-col>
    </ion-row>
</div>