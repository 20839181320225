<div class="navbar" *ngIf="data">
  <a *ngIf="!data?.dropdownOptions?.length" (click)="navigateToPage(data)"><img *ngIf="data.img" [src]=""
      alt="">{{data.label}}</a>
  <div class="dropdown position-relative" *ngIf="data?.dropdownOptions?.length">
    <button class="dropbtn medium-md-text" (click)="onClick(showDropdown)">
      <img *ngIf="data.img" [src]="" alt="">{{data.label}}
      <ion-icon [ngClass]="showDropdown ? 'rotate-icon' : ''" class="nav-chevron"
        name="chevron-down-outline"></ion-icon>
    </button>
    <div class="dropdown-content" *ngIf="showDropdown">
      <a class="regular-md-text" *ngFor="let menu of data?.dropdownOptions" (click)="navigateToPage(menu)"><img
          *ngIf="menu.img" [src]="" alt="image"> {{menu.label}}</a>
    </div>
  </div>
</div>