<div id="app">
  <lib-loader></lib-loader>
  <app-header *ngIf="showHeader" (emitNav)="emitNavigation($event)" (logoutEvent)="logoutEvent($event)" [sideNavDataList]="sideBar"
    [userInfo]="userInfo" [isShowSideMenu]="isShowSideMenu" [tenantInfo]="tenantInfo"
    [showHeader]="showHeader"></app-header>
  <lib-side-nav (bottomMenuClick)="logoutEvent($event)" *ngIf="isShowSideMenu" [navBarData]="sideBar"
    (landingNavigation)="handleLandingNavigation()" (profileNavigation)="handleProfileNavigation()"> </lib-side-nav>
  <lib-alert></lib-alert>
  <lib-gallery></lib-gallery>
  <div [ngClass]="(isShowSideMenu && !isMobile) ? 'main-content' : 'content-css'">
    <router-outlet></router-outlet>
  </div>
</div>
