import { Component } from '@angular/core';
import { LanguageService } from '../../../shared/services/language.service';
import { AppLoaderService, CdnContentService, getSubDomain, LoggingService, LoginService } from '@synergy-application/shared-ui';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { environment } from '../../../../../environments/environment';
import { BlobName } from '../../../../../app/core/app-constant';
import { LogLevel } from 'shared-ui/src/lib/enum/logger';


@Component({
  selector: 'app-privacy-policy',
  templateUrl: './privacy-policy.component.html',
  styleUrl: './privacy-policy.component.scss',
})
export class PrivacyPolicyComponent {
  constructor(private loggingService:LoggingService, private loginService:LoginService,private languageService:LanguageService,private cdnContentService: CdnContentService,private loaderService: AppLoaderService,
    private domSanitizer: DomSanitizer) { }
    languageLabel : any;
    safeHTML!: SafeHtml;
  ngOnInit() {
    this.loginService.headerSub.next(false);
    this.getTermsAndServiceHTML()
  }

  /**
  * Retrieves and returns the HTML content for the travel policy section.
  */
  async getTermsAndServiceHTML() {
    this.languageLabel = this.languageService.getPolicyKey();
    this.loaderService.showLoader(true);
    // let token = await this.sharedApiService.getSasToken(BlobAccess.read);
    let domain = getSubDomain();
    await this.cdnContentService.getBlobData(`${domain}/${BlobName.privacyPolicy}`, environment.config.azure.configContainerName)
      .then((data: any) => {
        this.loaderService.showLoader(false);
        this.safeHTML = this.domSanitizer.bypassSecurityTrustHtml(data);
      })
      .catch(error => {
        this.loaderService.showLoader(false);
        this.loggingService.logException(error, LogLevel.Error)
      })
  }
}
