import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ConfigService, getSubDomain, SharedApiService, StorageService } from '@synergy-application/shared-ui';
import { BlobServiceClient, ContainerClient } from '@azure/storage-blob';
import { LoginModel } from '../models/shared-common.model';



@Injectable({
  providedIn: 'root'
})
export class CdnContentService {
  private blobServiceClient: BlobServiceClient;
  private privateContainer: ContainerClient;
  private publicContainer!: ContainerClient;
  blobServiceUrl: string;

  constructor(private sharedApiService: SharedApiService, private configService: ConfigService, private http: HttpClient, private storageService: StorageService) {
    let config = this.configService.getConfig;
    this.blobServiceUrl = `https://${config.azure.storageAccountName}.blob.core.windows.net/`;
    this.blobServiceClient = new BlobServiceClient(this.blobServiceUrl);
    this.privateContainer = this.blobServiceClient.getContainerClient(config.azure.privateContainerName);
  }

  async getBlobData(blobName: string, container: string, token?: string): Promise<string> {
    return new Promise(async (resolve, reject) => {
      this.publicContainer = this.blobServiceClient.getContainerClient(container);
      // Get a reference to the blob
      const blobClient = await this.publicContainer.getBlobClient(blobName);
      const downloadResponse = await blobClient.download(0);
      // Generate the URL (make sure to handle permissions)
      // Convert the stream to text
      const streamBody = await downloadResponse.blobBody;
      const reader = new FileReader();
      reader.onloadend = () => {
        try {
          resolve(reader.result as string);
        } catch (error) {
        }
      };
      if (streamBody) {
        reader.readAsText(streamBody);
      }
    });
  }

  /**
   *  Retrieves the travel policy for a specific client.
   * @param filePath 
   * @returns Travel policy details for the specified client.
   */
  getClientTravelerPolicy(filePath: any): Observable<string> {
    return this.http.get(filePath, { responseType: 'text' });
  }


  /**
 * 
 * @returns clients data like logo themes
 */
  getClientData(fileName: string, configContainerName: string): Promise<LoginModel> {
    return new Promise(async (resolve, reject) => {
      let result: any = await this.getBlobData(fileName, configContainerName);
      result = JSON.parse(result);
      let loginData = {
        logo: result?.login?.logo,
        img1: result.login?.img1,
        welcomeText: result?.login?.welcomeText,
        domainName: getSubDomain(),
        colorScheme: result?.login?.colorScheme
      }
      resolve(loginData);
    });
  }

  /**
  * Uploads a file to Azure Blob Storage.
  * @param file The file to upload (e.g., Blob or File object)
  * @param containerName The name of the container where the file will be stored (optional, defaults to 'client')
  * @param blobName The name of the blob (file) to store in Azure (optional, defaults to 'oracle')
  * @returns A promise with the URL of the uploaded blob
  */
  async uploadBlob(file: File, containerName = 'client', blobName = 'oracle', fileName: string): Promise<string> {
    return new Promise(async (resolve, reject) => {
      try {
        // Get the container client using the containerName parameter (or default 'client')
        const token = await this.sharedApiService.getSasToken('w');
        const blobClient = new BlobServiceClient(`${this.blobServiceUrl}?${token}`);
        const containerClient = blobClient.getContainerClient(containerName);
        const data = `${blobName}/${fileName}`
        // Get a reference to the blob client (this is where the file will be uploaded) 
        const client = containerClient.getBlockBlobClient(data);
        // Upload the file to the blob
        await client.upload(file, file.size)
        // On successful upload, return the blob's URL
        resolve(client.url);
      } catch (error) {
        // In case of error, reject the Promise with an error message
        reject(`Error uploading blob: ${error}`);
        throw error;
      }
    });
  }
}