import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ConfigService } from './config.service';
import { IMapBase, LocationItem, NearByPlaces, TravelTime } from '../models';
import { ErrorConstants } from '../constants/shared-error-constants';
import { AppConstants } from '../constants/shared-constant';

@Injectable({
  providedIn: 'root'
})
export class LocationService {

  constructor(private http: HttpClient, private configService: ConfigService) { }

  /**
   * Get Google API location predictions
   * @param query 
   * @returns 
   */
  getAutoLocationApi(query: string): Promise<LocationItem[]> {
    return new Promise((resolve, reject) => {
      const service = new google.maps.places.AutocompleteService();
      service.getPlacePredictions({ input: query }, (predictions, status) => {
        if (status === google.maps.places.PlacesServiceStatus.OK && predictions?.length) {
          const response = predictions.map(item => ({
            name: item.description,
            placeId: item.place_id
          }));
          resolve(response);
        } else {
          reject('No data');
        }
      });
    });
  }

  /**
   * Retrieves the user's current geographical location.
   * @returns A promise that resolves with the location coordinates or rejects on error.
   */
  getCurrentLocation(): Promise<{ lng: number, lat: number }> {
    return new Promise((resolve, reject) => {
      navigator.geolocation.getCurrentPosition(
        (response) => {
          if (response?.coords?.longitude && response?.coords?.latitude) {
            resolve({ lng: response.coords.longitude, lat: response.coords.latitude });
          } else {
            reject(ErrorConstants.locationServiceError.noDataError);
          }
        },
        (error) => {
          reject(error);
        }
      );
    });
  }
  /**
     * Get location based on placeId
     * @param query 
     * @returns 
     */
  getLatLongFromPlaceId(placeId: string): Promise<IMapBase> {
    return new Promise((resolve, reject) => {
      const service = new google.maps.places.PlacesService(document.createElement('div'));
      service.getDetails({ placeId: placeId }, (place: google.maps.places.PlaceResult | null, status) => {
        if (status === google.maps.places.PlacesServiceStatus.OK && place?.geometry?.location) {
          const result: IMapBase = {
            lat: place.geometry.location.lat(),
            lng: place.geometry.location.lng()
          }
          resolve(result);
        } else {
          console.error(ErrorConstants.locationServiceError.fetchingPlaceDetailsError, status);
        }
      });
    })
  }
  /**
     * Retrieves the address based on provided geographical coordinates.
     * @param latitude The latitude of the location.
     * @param longitude The longitude of the location.
     * @returns A promise that resolves with the address or rejects on error.
     */
  getAddress(latitude: number, longitude: number): Promise<any> {
    let config = this.configService.getConfig;
    const url = config.googleApi + `geocode/json?latlng=${latitude},${longitude}&result_type=street_address&key=${config.googleKey}`;
    return new Promise((resolve, reject) => {
      this.http.get<any>(url).subscribe(
        (response) => {
          resolve(response);
        },
        (error) => {
          reject(error);
        }
      );
    });
  }

  /**
   * Fetches the user's current location and retrieves the corresponding address.
   * @returns A promise that resolves with the address or rejects on error.
   */
  fetchLocationAndAddress(): Promise<{ name: string, placeId: string }> {
    return this.getCurrentLocation()
      .then(location => this.getAddress(location.lat, location.lng))
      .then(addressResponse => {
        if (addressResponse.results && addressResponse.results.length > 0) {
          return {
            name: addressResponse.results[0].formatted_address,
            placeId: addressResponse.results[0].place_id
          };
        } else {
          throw new Error(ErrorConstants.locationServiceError.noAddressFoundError);
        }
      })
      .catch(error => {
        console.error('Error:', error);
        return Promise.reject(error);
      });
  }

  /**
   * Fetches nearby places based on type.
   * @param type 
   * @returns 
   */
  fetchNearByPlaces(type: string | undefined, lat: number, lng: number, keyword?: string,): Promise<google.maps.places.PlaceResult[]> {
    return new Promise((resolve, reject) => {
      const request: google.maps.places.PlaceSearchRequest = {
        location: { lat: lat, lng: lng },
        radius: 1500,
      };
      if (type) request.type = type;
      if (keyword) request.keyword = keyword;
      const map = new google.maps.Map(document.createElement("div"), {
        center: AppConstants.lat,
        zoom: AppConstants.zoom
      });
      const service = new google.maps.places.PlacesService(map);
      service.nearbySearch(request, (place, status) => {
        if (status === google.maps.places.PlacesServiceStatus.OK && place) resolve(place)
        else reject({ message: ErrorConstants.locationServiceError.noDataError })
      })
    })
  }

  /**
   * Fetches place details based on placeId.
   * @param placeId 
   * @returns 
   */
  fetchPlaceDetails(placeId: string): Promise<google.maps.places.PlaceResult> {
    return new Promise((resolve, reject) => {
      const map = new google.maps.Map(document.createElement("div"), {
        center: AppConstants.lat,
        zoom: AppConstants.zoom
      });
      const service = new google.maps.places.PlacesService(map);
      service.getDetails({ placeId }, (place, status) => {
        if (status === google.maps.places.PlacesServiceStatus.OK && place) resolve(place);
        else reject({ message: ErrorConstants.locationServiceError.noDataError });
      });
    });
  }

  /**
  * Calculates the travel distance and time between two geographical points.
  * 
  * @param from - The starting point as an object implementing IMapBase (contains latitude and longitude).
  * @param to - The destination point as an object implementing IMapBase (contains latitude and longitude).
  * @param miles - A boolean indicating whether to return the distance in miles (true) or kilometers (false).
  * @param mode - Optional travel mode, defaults to driving. Can be 'DRIVING' or 'WALKING'.
  * 
  * @returns A Promise that resolves to a TravelTime object containing the distance and duration.
  */
  calculateDistance(from: IMapBase, to: IMapBase, miles: boolean, mode?: string): Promise<TravelTime> {
    return new Promise((resolve, reject) => {
      // Create LatLng objects for the origin and destination
      const origin = new google.maps.LatLng(from.lat, from.lng); // Starting location
      const destination = new google.maps.LatLng(to.lat, to.lng); // Ending location

      // Create a new instance of the DistanceMatrixService
      const service = new google.maps.DistanceMatrixService();
      const request: google.maps.DistanceMatrixRequest = {
        origins: [origin], // Set the origin(s)
        destinations: [destination], // Set the destination(s)
        travelMode: mode === google.maps.TravelMode.DRIVING
          ? google.maps.TravelMode.DRIVING : mode === google.maps.TravelMode.TRANSIT ? google.maps.TravelMode.TRANSIT
            : google.maps.TravelMode.WALKING, // Determine travel mode
        unitSystem: miles ? google.maps.UnitSystem.IMPERIAL : google.maps.UnitSystem.METRIC
      }
      if (mode === google.maps.TravelMode.TRANSIT) request.transitOptions = {
        modes: [google.maps.TransitMode.BUS]
      }
      // Use the DistanceMatrixService to get distance and travel time
      service.getDistanceMatrix(
        request,
        (response: any, status) => {
          // Check if the response is valid and contains the expected data
          if (response && response.rows?.length && response.rows[0]?.elements?.length && response.rows[0].elements[0] && status === google.maps.DistanceMatrixStatus.OK) {
            resolve(response.rows[0].elements[0]); // Resolve the Promise with the travel time data
          } else {
            console.error('Error:', status); // Log error status if the response is not valid
            reject(status); // Reject the Promise with the error status
          }
        }
      );
    });
  }

  /**
   * 
   * @param address - user enter the country
   * @returns 
   */
  getLocationInfo(address: string): Promise<{ country: string | undefined; state: string | undefined }> {
    return new Promise((resolve, reject) => {
      const geocoder = new google.maps.Geocoder();
      geocoder.geocode({ address }, (results, status) => {
        if (status === 'OK' && results && results.length > 0) {
          const addressComponents = results[0].address_components;

          const country = addressComponents.find(component =>
            component.types.includes('country'))?.long_name;

          const state = addressComponents.find(component =>
            component.types.includes('administrative_area_level_1'))?.long_name;

          resolve({ country, state });
        } else {
          reject('No results found or Geocoding failed');
        }
      });
    });
  }
}