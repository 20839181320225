export enum ColumType {
    AllCHECKBOX = 'allCheckBox',
    DEFAULT = 'default',
    ACTION = 'action',
    CHECKBOX = 'checkBox',
    EXPANDABLE = 'Expandable',
    CHECKBOXWITHEXPAND = 'checkboxWithExpand',
    CHIP = 'chip',
    LINK = 'link',
    DATE = 'date',
    DROPDOWN = 'dropDown',
    ICON = 'icon',
    INPUT = 'input',
    TEXTWITHICON = 'textWithIcon'
}

export enum SortDirection {
    ASC = 'asc',
    DESC = 'desc'
}

export enum LeadStatus {
    PENDING = 1,
    BOOKINGCREATED = 2,
    DENIED = 3,
    UNASSIGNED = 4,
    CANCELLED = 5,
    SUBMITTED = 8,
    PRESENTED = 9,
    MISSEDBIDDEADLINE = 14,
    CHOSEN = 16,
    DECLINEDTOBID = 21   
}



export enum SupplierStatus {
    PENDING = 1,
    ACTIVE = 2,
    SERVICEWATCH = 3,
    INACTIVE = 4,
    JUSTINTIME = 5,
 
}



export const supplierStatusLabel: Record<SupplierStatus, { label: string, class: string }> = {
    [LeadStatus.PENDING]: {
        label: 'Pending',
        class: 'chip-yellow'
    },
    [SupplierStatus.ACTIVE]: {
        label: "Active",
        class: 'chip-green'
    },
    [SupplierStatus.SERVICEWATCH]: {
        label: "Service Watch",
        class: 'chip-yellow'
    },
    [SupplierStatus.INACTIVE]: {
        label: "InActive",
        class: "chip-red"
    },
    [SupplierStatus.JUSTINTIME]: {
        label: "Just in time",
        class: 'chip-green'
    }
} 

export enum ChipStatus {
    GREEN = 1,
    YELLOW = 2,
    GREY = 3,
    RED = 4,
    BLUE = 5,

}


// Reverse mapping of status IDs to ChipStatus
export const StatusIdMap: Record<number, ChipStatus> = {
    1: ChipStatus.GREEN,
    2: ChipStatus.YELLOW,
    3: ChipStatus.GREY,
    4: ChipStatus.RED,
    5: ChipStatus.BLUE,
    6: ChipStatus.RED
};

export const statusLabel: Record<LeadStatus, { label: string, class: string }> = {
    [LeadStatus.PENDING]: {
        label: 'Pending',
        class: 'chip-yellow'
    },
    [LeadStatus.BOOKINGCREATED]: {
        label: 'New',
        class: 'chip-grey'
    },
    [LeadStatus.DENIED]: {
        label: 'Denied',
        class: 'chip-yellow'
    },
    [LeadStatus.UNASSIGNED]: {
        label: 'Unassigned',
        class: 'chip-red'
    },
    [LeadStatus.CANCELLED]: {
        label: 'Cancelled',
        class: 'chip-red'
    },
    [LeadStatus.SUBMITTED]: {
        label: 'Submitted',
        class: 'chip-yellow'
    },
    [LeadStatus.DECLINEDTOBID]: {
        label: 'Declined To Bid',
        class: 'chip-red'
    },
    [LeadStatus.PRESENTED]: {
        label: 'Presented',
        class: 'chip-green'
    },
    [LeadStatus.CHOSEN]: {
        label: 'Presented',
        class: 'chip-green'
    },
    [LeadStatus.MISSEDBIDDEADLINE]: {
        label: 'Missed Bid Deadline',
        class: 'chip-red'
    }
} 

// Color mapping for ChipStatus
export const ChipCircleColorMap: Record<ChipStatus, string> = {
    [ChipStatus.GREEN]: "circle_green_color",
    [ChipStatus.YELLOW]: "circle_yellow_color",
    [ChipStatus.GREY]: "circle_grey_color",
    [ChipStatus.RED]: "circle_red_color",
    [ChipStatus.BLUE]: "circle_blue_color",
};

export const ChipStatusColorMap: Record<ChipStatus, string> = {
    [ChipStatus.GREEN]: "chip-green",
    [ChipStatus.YELLOW]: "chip-yellow",
    [ChipStatus.GREY]: "chip-grey",
    [ChipStatus.RED]: "chip-red",
    [ChipStatus.BLUE]: "chip-blue",
};

export enum DateFormat {
    SHORT = 'short',
    MEDIUMDATE = 'mediumDate',
    MEDIUM = 'medium',
    LONG = 'long'
}

export enum ValueType {
    STRING = 'string',
    ARRAY = 'array',
    OBJECT = 'object'
}

export enum Alignment {
    LEFT = 'left',
    CENTER = 'center',
    RIGHT = 'right'
}

export enum Position {
    PREFIX = 'prefix',
    SUFFIX = 'suffix'
}
