import { InputData, UserProfile } from 'shared-ui/src/lib/models';
import { LanguageService } from '../../../shared/services/language.service';
import { CommonService } from 'shared-ui/src/lib/services/common.service';
import {
  AppLoaderService,
  GraphQLService,
  SharedApiService,
} from '@synergy-application/shared-ui';
import { SharedData } from '@synergy-application/shared-ui';
import { Component } from '@angular/core';

@Component({
  selector: 'app-profile-page',
  templateUrl: './profile-page.component.html',
  styleUrl: './profile-page.component.scss',
})
export class ProfilePageComponent {
  languageLabel: any;
  data: any[] = [];
  editData: any;
  profileData?: UserProfile;
  sharedData = SharedData;
  isEdit: boolean = false;

  constructor(
    private languageService: LanguageService,
    private commonService: CommonService,
    private sharedApiService: SharedApiService,
    private graphQLService: GraphQLService,
    private loaderService: AppLoaderService,
  ) { }

  async ngOnInit() {
    this.initializedData();
    await this.mapToResponse();
  }
  /**
   * Mapping model with the response
   */
  async mapToResponse() {
    this.loaderService.showLoader(true);
    try {
      const apiProfileResponseValue = await this.sharedApiService.getMyProfile(
        this.graphQLService
      );
      this.profileData = apiProfileResponseValue;
      this.editData = this.sharedData.profileDynamicForm[0];
      const profileFields =
        this.sharedData.profileDynamicForm[0].sections[0].fields;

      profileFields.forEach((field) => {
        const leadRef = field.leadRef;
        const result = this.findValueByKey(apiProfileResponseValue, leadRef);
        field.value = result ?? '';
      });

      this.data = profileFields;
      this.editData.sections[0].fields = profileFields;
    } catch (error) {
      this.loaderService.showLoader(false);
    } finally {
      this.loaderService.showLoader(false);
    }
  }

  /**
   * Initializes data and fetches the user profile information.
   */
  initializedData() {
    this.languageLabel = this.languageService.getProfileKey();
  }

  /**
   * Validates the specified field to ensure it is required
   * and matches the defined regex pattern.
   * @param {string} field - The field to validate.
   */
  focusoutValidation(field: InputData) {
    this.commonService.validateInputFields(field);
  }

  /**
   * Exits edit mode without saving.
   */
  onCancel() {
    this.isEdit = false;
  }

  /**
   * Saves the changes made to the profile.
   * Calls the API to update the profile data and reloads the updated information.
   */
  async onSave() {
    this.loaderService.showLoader(true); // Show loader before saving
    try {
      const updatedProfileData = this.editData.sections[0].fields.reduce(
        (acc: any, curr: any) => {
          acc[curr.leadRef] = curr.value;
          return acc;
        },
        {}
      );

      // await this.sharedApiService.updateProfile(this.graphQLService, updatedProfileData);
      this.isEdit = false; // Exit edit mode
      await this.mapToResponse(); // Reload data to reflect the changes
    } catch (error) {
      this.loaderService.showLoader(false);
    } finally {
      this.loaderService.showLoader(false); // Hide loader after saving
    }
  }

  /**
   * Enters edit mode to allow changes.
   */
  onEdit() {
    this.isEdit = true;
  }


  /**
   * Used to edit profile
   */
  imageClick() { }

  /**
   * Recursively searches for a value by its key within an object.
   * @param obj - The object to search through.
   * @param key - The key to find the corresponding value for.
   * @returns The value associated with the key, or undefined if not found.
   */
  findValueByKey(obj: any, key: string): any {
    // Base case: if the object has the key, return its value
    if (obj.hasOwnProperty(key)) {
      return obj[key];
    }

    // Iterate over each key in the object
    for (let k in obj) {
      if (typeof obj[k] === 'object' && obj[k] !== null) {
        // Recursively search in nested objects or arrays
        const result = this.findValueByKey(obj[k], key);
        if (result !== undefined) {
          return result;
        }
      }
    }

    // If the key is not found, return undefined
    return undefined;
  }
  /**
   * Method to save profile data
   */
  async saveProfileData() {
    const updatedProfileData = this.data;
    // future reference
  }
}