import { Directive, ElementRef, Input, Renderer2 } from '@angular/core';

@Directive({
  selector: '[chipStyler]'
})
export class ChipStylerDirective {
  
  constructor(private el: ElementRef, private renderer: Renderer2) {}

  @Input() set chipStyler(styles: { background?: string; radius?: string; color?: string }) {
    if (styles.background) {
      this.renderer.setStyle(this.el.nativeElement, 'background-color', styles.background);
    }
    if (styles.radius) {
      this.renderer.setStyle(this.el.nativeElement, 'border-radius', styles.radius);
    }
    if (styles.color) {
      this.renderer.setStyle(this.el.nativeElement, 'color', styles.color);
    }
  }
}
