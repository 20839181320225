import { Injectable } from '@angular/core';
import { CountriesResponseModel, DropDownInput, DropDownResponseModel, IMasterAmenities, IMasterPropertyType, IPagination, IPropertyDetail, IPropertyType, LocationItem, PaginationInput, PaginationModel, PropertyResult, RequestInput, UnitTypeModel, UserProfile } from '../models';
import { APIUrlConfig, GraphQLService } from './graphql';
import { LoginService } from '../services/login.service';
import { ACCEPT_POLICY, GET_AMENITIES_LIST, GET_AVAILABLE_PROPERTIES, GET_CLIENT_LOCATION, GET_COUNTRY, GET_CURRENCY, GET_DROPDOWN_VALUES, GET_LOGIN, GET_MY_PROFILE, GET_PROPERTY_BY_ID, GET_PROPERTY_TYPE, GET_SAS_TOKEN, GET_UNIT_TYPE, GET_USER_BY_ID, LOGIN } from './shared-query';
import { ErrorConstants } from '../constants/shared-error-constants';
import { cloneDeep } from '@apollo/client/utilities';
import { CurrencyModel, CurrencyResponse } from '../models/currency.model';
import { BlobAccess } from '../constants/shared-constant';



@Injectable({
  providedIn: 'root'
})
export class SharedApiService {

  constructor(private graphQLService: GraphQLService, private loginService: LoginService) { }

  /**
   * Retrieves property details by property ID.
   * @param id - The ID of the property to retrieve.
   * @returns A promise that resolves to the property details.
   */
  getPropertyDetailById(id: string): Promise<IPropertyDetail> {
    return new Promise((resolve, reject) => {
      const variable = {
        propertyId: id // Variable for the GraphQL query
      };
      const query = `${GET_PROPERTY_BY_ID}`;
      // Execute the GraphQL query
      this.graphQLService.query(query, variable, APIUrlConfig.SEARCH).then((res: any) => {
        resolve(res.data.getPropertyById);
      }).catch(error => {
        console.error(ErrorConstants.apiServiceError.fetchingDataError, error); // Log any errors
        reject(error); // Reject the promise
      });
    });
  }

  /**
   * Retrieves a list of available properties based on search criteria.
   * @param variable - The search parameters for the query.
   * @returns A promise that resolves to the list of available properties.
   */
  getAvailablePropertyList(variable: any): Promise<PropertyResult> {
    return new Promise((resolve, reject) => {
      const GET_DATA = `${GET_AVAILABLE_PROPERTIES}`;
      this.graphQLService.query(GET_DATA, variable, APIUrlConfig.SEARCH).then((result: any) => {
        resolve(cloneDeep(result?.data?.getAvailableProperties as PropertyResult));
      }).catch(error => {
        console.error(ErrorConstants.apiServiceError.fetchingDataError, error);
        reject(error);
      });
    });
  }

  /**
   * Retrieves a list of property types.
   * @returns A promise that resolves to the list of property types.
   */
  getPropertyType(): Promise<IMasterPropertyType> {
    return new Promise((resolve, reject) => {
      const query = `${GET_PROPERTY_TYPE}`;
      this.graphQLService.query(query).then((result: any) => {
        resolve(result.data.getPropertyType);
      }).catch(error => {
        console.error(ErrorConstants.apiServiceError.fetchingDataError, error);
        reject(error);
      });
    });
  }

  /**
   * Retrieves a list of amenities.
   * @returns A promise that resolves to the list of amenities.
   */
  getAminity(pagination: RequestInput): Promise<IMasterAmenities> {
    return new Promise((resolve, reject) => {
      const query = `${GET_AMENITIES_LIST}`;
      this.graphQLService.query(query, pagination).then((result: any) => {
        resolve(result.data.getAmenitiesList);
      }).catch(error => {
        console.error(ErrorConstants.apiServiceError.fetchingDataError, error);
        reject(error);
      });
    });
  }

  /**
  * Retrieves a list of amenities.
  * @returns A promise that resolves to the list of amenities.
  */
  getCurrency(variable: RequestInput): Promise<CurrencyResponse> {
    return new Promise((resolve, reject) => {
      const query = `${GET_CURRENCY}`;
      this.graphQLService.query(query, variable).then((result: any) => {
        if (result.data.getCurrencies.currenciesList) resolve(result.data.getCurrencies as CurrencyResponse);
        else reject(result)
      }).catch(error => {
        console.error(ErrorConstants.apiServiceError.fetchingDataError, error);
        reject(error);
      });
    });
  }

  /**
  * Retrieves a list of amenities.
  * @returns A promise that resolves to the list of amenities.
  */
  getCountries(variable: RequestInput): Promise<CountriesResponseModel> {
    return new Promise((resolve, reject) => {
      const query = `${GET_COUNTRY}`;
      this.graphQLService.query(query, variable).then((result: any) => {
        if (result.data.getCountriesList) resolve(result.data.getCountriesList as CountriesResponseModel);
        else reject(result)
      }).catch(error => {
        console.error(ErrorConstants.apiServiceError.fetchingDataError, error);
        reject(error);
      });
    });
  }

  /**
  * Retrieves a list of amenities.
  * @returns A promise that resolves to the list of amenities.
  */
  getUnitType(): Promise<UnitTypeModel[]> {
    return new Promise((resolve, reject) => {
      const query = `${GET_UNIT_TYPE}`;
      this.graphQLService.query(query).then((result: any) => {
        resolve(result.data.getUnitTypeList as UnitTypeModel[]);
      }).catch(error => {
        console.error(ErrorConstants.apiServiceError.fetchingDataError, error);
        reject(error);
      });
    });
  }

  /**
   * Logs in the user and retrieves a token.
   * @returns A promise that resolves to the login response.
   */
  userLogin(token: string) {
    var variable = {
      token: token
    }
    return new Promise((resolve, reject) => {
      const GET_DATA = `${LOGIN}`;
      this.graphQLService.mutate(GET_DATA, variable).then((result: any) => {
        this.loginService.setToken(result.data.login); // Set the token in LoginService
        resolve(result.data.login); // Resolve with login data
      }).catch(error => {
        console.error(ErrorConstants.apiServiceError.fetchingDataError, error); // Log any errors
        reject(error); // Reject the promise
      });
    });
  }
  /**
   * Logs in the user and retrieves a token.
   * @returns A promise that resolves to the login response.
   */
  createToken() {
    return new Promise((resolve, reject) => {
      const GET_DATA = `${GET_LOGIN}`;
      this.graphQLService.mutate(GET_DATA).then((result: any) => {
        this.loginService.setToken(result.data.login); // Set the token in LoginService
        resolve(result.data.login); // Resolve with login data
      }).catch(error => {
        console.error(ErrorConstants.apiServiceError.fetchingDataError, error); // Log any errors
        reject(error); // Reject the promise
      });
    });
  }

  /**
 * Logs in the user and retrieves a token.
 * @returns A promise that resolves to the login response.
 */
  uservalidation() {
    return new Promise((resolve, reject) => {
      const GET_DATA = `${GET_LOGIN}`;
      this.graphQLService.mutate(GET_DATA).then((result: any) => {
        this.loginService.setToken(result.data.login); // Set the token in LoginService
        resolve(result.data.login); // Resolve with login data
      }).catch(error => {
        console.error(ErrorConstants.apiServiceError.fetchingDataError, error); // Log any errors
        reject(error); // Reject the promise
      });
    });
  }


  /**
  * Retrieves user information by user ID using a GraphQL query.
  * @param graphqLService An instance of the GraphQLService to make GraphQL requests.
  * @returns A promise resolving to the user details or an error.
  */
  getUserById(graphqLService: GraphQLService,id: string): Promise<UserProfile>{
    return new Promise((resolve, reject) => {
      const variables = {
        id: id
      };
      const query = `${GET_USER_BY_ID}`;
      graphqLService.query(query, variables).then((result: any) => {
        resolve(result.data.getUserById);
      }).catch((error: Error) => {
        console.error(ErrorConstants.apiServiceError.fetchingDataError, error);
        reject(error);
      });
    });
  }

  /**
   * Fetches the profile details of the user.
   * @param userId - The ID of the user whose profile is to be fetched.
   * @returns A promise that resolves to the user's profile details.
   */
  getMyProfile(graphqLService: GraphQLService): Promise<UserProfile> {
    return new Promise((resolve, reject) => {
      const query = `${GET_MY_PROFILE}`;
      graphqLService.query(query,{},APIUrlConfig.LEAD)
        .then((result: any) => {
          if (result?.data?.getMyProfile) {
            const response = <UserProfile>result.data.getMyProfile;
            resolve(cloneDeep(response));
          }
        }).catch((error: Error) => {
          console.error(ErrorConstants.apiServiceError.fetchingProfileDataError, error);
          reject(error);
        });
    });
  }

  /**
  * Fetches the profile details of the user.
  * @param userId - The ID of the user whose profile is to be fetched.
  * @returns A promise that resolves to the user's profile details.
  */
  getClientLocation(graphqLService: GraphQLService, request: RequestInput): Promise<LocationItem[]> {
    return new Promise((resolve, reject) => {
      const query = `${GET_CLIENT_LOCATION}`;
      graphqLService.query(query, request, APIUrlConfig.PORTAL)
        .then((result: any) => {
          if (result?.data?.getClientLocation?.clientLocation?.length) {
            let response = result.data.getClientLocation.clientLocation.reduce((acc: Array<any>, item: any) => {
              acc.push({
                name: item.location,
                latitude: item.latitude,
                longitude: item.longitude
              })
              return acc;
            }, []);
            resolve(response);
          }
          resolve([]);
        }).catch((error: Error) => {
          console.error(ErrorConstants.apiServiceError.fetchingProfileDataError, error);
          reject(error);
        });
    });
  }
  /**
  * Fetches the Sas token for the url.
  * @returns accept policy Api when user accept the policy
  */
  acceptPrivacyPolicy() {
    return new Promise((resolve, reject) => {
      this.graphQLService.mutate(ACCEPT_POLICY)
        .then(res => {
          resolve(res);
        })
        .catch(ex => {
          reject(ex);
        })
    })
  }
  /**
  * Fetches the Sas token for the url.
  * @param blobUrl - The url of the blob is to be fetched.
  * @returns A promise that resolves to the Sas token.
  */
  getSasToken(permission: string = BlobAccess.read): Promise<string> {
    return new Promise((resolve, reject) => {
      const GET_DATA = `${GET_SAS_TOKEN}`;
      this.graphQLService.mutate(GET_DATA, { permission }).then((result: any) => {
        if (result?.data?.generateSasToken)
          resolve(result.data.generateSasToken); // Resolve with login data
      }).catch(error => {
        console.error(ErrorConstants.apiServiceError.fetchingDataError, error); // Log any errors
        reject(error); // Reject the promise
      });
    });
  }
  
  fetchDropDownValues(request: DropDownInput, apiUrl?: APIUrlConfig): Promise<DropDownResponseModel> {
    const query = GET_DROPDOWN_VALUES;
    return new Promise((resolve, reject) => {
      this.graphQLService.query(query, request, apiUrl)
        .then((res) => {
          if (res?.data && (res.data as Record<string, any>)['getDropDownValues']) resolve((res.data as Record<string, any>)['getDropDownValues']);
          else reject(res.errors)
        })
        .catch((error) => {
          reject(error);
        })
    })
  }
}
