<div class="select-parent-container" [ngClass]="disabled ? 'isDisabled' : ''">
  <ion-label class="semiBold-sm-text font-color dropdown-label">{{ label }}<span *ngIf="required"
      class="red-color-text">*</span></ion-label>

  <div (click)="uiToggleDropDown()" tabindex="0" (keyup.enter)="iToggleDropDown()" [attr.aria-label]="displayLabel"
    [ngClass]="disabled ? 'isDisabled' : ''" class="select-container ripple page-color" role="list"
    [class.readOnly]="readOnly">
    <ion-grid class="select-container-grid">
      <ion-row class="ion-align-items-center">
        <ion-col class="ion-no-padding display-flex-justify-center" *ngIf="selectedValue && displayImage">
          <ion-icon [src]="selectedValue.imgUrl"></ion-icon>
        </ion-col>
        <ion-col class="pagination-center ion-no-padding ellipsis">
          <ion-label *ngIf="!displaySecondLabel" id="displayLabel" class="display-label">{{
            displayLabel
            }}</ion-label>
          <ion-label *ngIf="displaySecondLabel" id="displayLabel" class="display-label">{{
            (selectedValue && selectedValue[displaySecondLabel]) ||  placeholder
            }}</ion-label>

        </ion-col>

        <ng-container *ngIf="!readOnly">
          <ion-col class="image-col ion-no-padding">
            <ion-label class="drop-down-img-container">
              <ion-spinner *ngIf="isLoading" name="crescent" class="loader"></ion-spinner>
              <ion-icon [ngClass]="showDropDown ? 'rotate-icon' : ''" class="nav-chevron" *ngIf="!isLoading"
                name="chevron-down-outline"></ion-icon>
            </ion-label>
          </ion-col>
        </ng-container>
      </ion-row>
    </ion-grid>
  </div>

  <div *ngIf="showDropDown" id="dropDownContainer"
  class="drop-down-container border-radius top-gap">    <ion-searchbar *ngIf="searchable" #search (ionCancel)="onCancel()" mode="md" (ionInput)="onSearch($event)"
      [debounce]="searchDebounce" (ionClear)="onCancel()" showCancelButton="never" [placeholder]="searchBarPlaceholder"
      [value]="searchValue">
    </ion-searchbar>

    <div class="drop-down-container" [ngClass]="selectionPosition === 'upWord' ? 'upWord-dropdown' : 'downWord-dropdown'" #dropdownContainer>
      <div (scroll)="loadData()" class="list" [ngClass]="isActionButton ? 'list-button-height':''">
        <ion-list id="list" tabindex="0" class="ion-no-padding" (focus)="getChildElements($event)" #dropdownList
          role="listbox">
          <div *ngFor="let item of listToShow; let i = index" lines="none">
            <ion-item *ngIf="!item.isHidden" class="list-to-show-item">
              <ion-grid tabindex="0" (keyup.enter)="onSelect(item, $event)" class="ion-no-padding"
                [id]="'dropdown_focus_' + i">
                <ion-row class="ion-align-items-center" role="option" (click)="onSelect(item)"
                  [ngClass]=" item?.isDisabled ? 'opacity' : '' ">
                  <ion-col size="2.5" *ngIf="item.imgUrl">
                    <ion-label class="drop-down-img-container">
                      <ion-icon class="filter-icon" [src]="item.imgUrl"></ion-icon>
                    </ion-label>
                  </ion-col>
                  <ng-container *ngIf="!item.imgUrl && showCheckbox">
                    <ion-col class="pl-7">
                      <ion-checkbox ngDefaultControl [(ngModel)]="item.isSelected" class="checkbox ion-no-margin"
                        mode="md">
                      </ion-checkbox>
                    </ion-col>
                  </ng-container>
                  <ion-col class="dropdown-text text-wrap">
                    <img *ngIf="item.icon" [src]="item.icon" alt="" />
                    <ion-label *ngIf="!isTwoLabels && !isLabelWithDescription"
                      class="list-item-label text-font-regular white-space" [ngClass]="
                    item.isSelected ? 'selected-label' : 'un-selected-label'
                  ">
                      {{ item[this.displayBy] }}</ion-label>
                    <ion-label *ngIf="isTwoLabels" class="list-item-label text-font-regular white-space" [ngClass]="
                    item.isSelected ? 'selected-label' : 'un-selected-label'
                  ">
                      {{ item[this.displayBy] }} -
                      {{ item[this.secondDisplayName] }}</ion-label>

                    <!-- label with description -->
                    <ion-label *ngIf="isLabelWithDescription"
                      class="list-description list-item-label text-font-regular white-space" [ngClass]="
                    item.isSelected ? 'selected-label' : 'un-selected-label'
                  ">
                      {{ item[this.displayBy] }}
                      <div class="description-container">
                        <span class="description-text">{{item[this.description]}}</span>
                        <span class="description-text">{{item[this.secondDescription]}}</span>
                      </div>
                    </ion-label>

                  </ion-col>
                  <ion-col *ngIf="!item.imgUrl && !showCheckbox" [ngClass]="
                  item.isSelected ? 'selected-tick' : 'un-selected-tick'
                " class="image-col">
                    <ion-label *ngIf="showTick" class="drop-down-img-container align-tick">
                    </ion-label>
                  </ion-col>
                </ion-row>
              </ion-grid>
            </ion-item>
          </div>
          <ion-item lines="none" class="ion-padding-bottom" *ngIf="searchable && showLoader">
            <ion-spinner tabindex="0" name="bubbles" color="primary" class="more-loader"></ion-spinner>
          </ion-item>
        </ion-list>

      </div>
      <div class="buttons" *ngIf="isActionButton">
        <lib-button is-borderless>{{buttonText}}</lib-button>
      </div>
    </div>
  </div>
  <small *ngIf="isShowErrorMessage && showErrorMessage" class="red-color-text">{{showErrorMessage }}</small>
</div>