import { DatePipe } from "@angular/common";

export function isValidName(value: string): boolean {
  return /^[A-Za-z][-A-Za-z' ]*$/.test(value)
}

/**
 * Validates an email address using a regular expression.
 * @param value - The email address to validate.
 * @returns A boolean indicating whether the email address is valid.
 */
export function isValidEmail(value: string): boolean {
  return /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(value);
}

/**
 * Retrieves the subdomain from the current window's hostname.
 * @returns The subdomain as a string. If there is no subdomain, it returns an empty string.
 */
export function getSubDomain(): string {
  return window.location.hostname.split('.')[0] == 'localhost' || window.location.hostname.split('.')[0] == 'dev-client-portal' ? 'oracle' : window.location.hostname.split('.')[0];
}

/**
 * Converts a hex color code to an RGB string.
 * @param hex - The hex color code (e.g., "#ffffff" or "ffffff").
 * @returns A string representing the RGB values in the format "r, g, b". Returns an empty string if the input is invalid.
 */
export function hexToRgb(hex: string): string {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result ? `${parseInt(result[1], 16)}, ${parseInt(result[2], 16)}, ${parseInt(result[3], 16)}` : '';
}

/**
 * This function is used to calculate the day of the week from a given date string.
 * @param dateString - The date string to convert.
 * @returns The day of the week corresponding to the provided date.
 */
export function getDayFromDate(dateString: string): string {
  const date = new Date(dateString);
  const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
  return days[date.getDay()];
}

/**
 * Calculates the difference in days between two dates.
 * @param date1 - The first date string in a format recognized by the Date object.
 * @param date2 - The second date string in a format recognized by the Date object.
 * @returns An object containing the difference in days between the two dates.
 */
export function calculateDateDifference(date1: string, date2: string) {
  // If not two dates are provided, return 0
  if (!date1 || !date2) {
    return 0;
  }
  const d1 = new Date(date1);
  const d2 = new Date(date2);
  d1.setHours(0, 0, 0, 0);
  d2.setHours(0, 0, 0, 0);
  const diffInMs = Math.abs(d2.getTime() - d1.getTime());
  const days = Math.floor(diffInMs / (1000 * 60 * 60 * 24));
  return days;
}

export function formDate(event: Array<string>, datePipe: DatePipe) {
  let date = '';
  const moveIn = event?.length && event[0] ? datePipe.transform(event[0], 'MMM dd, yyyy') : event[0];
  const moveOut = event?.length > 1 && event[1] ? datePipe.transform(event[1], 'MMM dd, yyyy') : event[1];
  if (moveIn && !moveOut) {
    date = moveIn;
  } else if (moveOut && moveIn) {
    date = moveIn + " - " + moveOut;
  }
  return date;
}

/**
 * 
 * @returns flag - true for mobile
 */
export function checkScreenSize() {
  return window.innerWidth < 992;
}

/**
 * calculate 30 days from current date
 */
export function calculateLastDate(to: number, date?: string) {
  const currentDate: Date = date ? new Date(date) : new Date();
  const lastDate: Date = date ? new Date(date) : new Date();
  return {
    currentDate: currentDate,
    lastDate: new Date(lastDate.setDate(currentDate.getDate() + to))
  }
}

/**
* 
* @returns flag - true for laptop
*/
export function checkLapSize() {
  return window.innerWidth <= 1280;
}

export function checkTab() {
  return window.innerWidth < 991;
}

export function QueryBuilder(input: any[]): string {
  const parts: string[] = input.map(item => {
    if (typeof item === 'string') {
      // If it's a string, just prefix with "$"
      return `${item}: $${item}`;
    } else if (typeof item === 'object' && !Array.isArray(item)) {
      // If it's an object, format its keys with "$" placeholders
      const entries = Object.keys(item).map(key => `${key}: $${item[key]}`);
      return `{ ${entries.join(', ')} }`;
    } else if (Array.isArray(item)) {
      // If it's an array, format each element with "$"
      const arrayItems = item.map(subItem => `$${subItem}`);
      return `[${arrayItems.join(', ')}]`;
    }
    return '';
  });

  return parts.join(', ');
}

/**
   * Recursively searches for a value by its key within an object.
   * @param obj - The object to search through.
   * @param key - The key to find the corresponding value for.
   * @returns The value associated with the key, or undefined if not found.
   */
export function findValueByKey(obj: any, key: string): any {
  // Base case: if the object has the key, return its value
  if (obj.hasOwnProperty(key)) {
    return obj[key];
  }
}

export function getErrorMessage(error: Error): string {
  if (Array.isArray(error)) {
    return error
      .map(err => {
        if (typeof err === 'string') {
          return err;
        } else if (err && typeof err === 'object' && 'message' in err) {
          return err.message;
        } else {
          return JSON.stringify(err);
        }
      })
      .join(', ');
  } else if (typeof error === 'string') {
    return error;
  } else if (error && typeof error === 'object' && 'message' in error) {
    return error.message;
  }

  return 'An unknown error occurred';
}

export function uuidv4() {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'
    .replace(/[xy]/g, function (c) {
      const r = Math.random() * 16 | 0,
        v = c == 'x' ? r : (r & 0x3 | 0x8);
      return v.toString(16);
    });
}

export function encode(value: any) {
  return btoa(JSON.stringify(value));
}

export function decode(value: any) {
  return JSON.parse(atob(value) as any);
}
