import { Component, EventEmitter, Input, OnInit, Output, OnChanges, SimpleChanges, forwardRef } from '@angular/core';
import { InputData } from '../../models';
import { AppConstants } from '../../constants/shared-constant';
import { CommonService } from '../../services/common.service';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';


@Component({
  selector: 'lib-input',
  templateUrl: './input.component.html',
  styleUrls: ['./input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: InputComponent
    }
  ]
})
export class InputComponent implements OnInit, OnChanges, ControlValueAccessor {
  // Input property for configuring the input field, including placeholder, value, label, and validation constraints.
  @Input() inputData: InputData = {
    placeholder: 'Please enter the text',
    value: '',
    label: 'Label',
    type: AppConstants.fieldType.text,
    minLength: 3,
    maxLength: 100,
    readOnly: false
  };
  appConstant = AppConstants;
  // Output event emitter for focusout events from the input field.
  @Output() focusoutEvent = new EventEmitter<InputData>();

  @Output() focusEvent = new EventEmitter<InputData>();

  @Input() showArrow: boolean = true

  private onChange: (value: any) => void = () => { };

  private onTouched: () => void = () => { };

  constructor(private commonService: CommonService) { }

  writeValue(value: any): void {
    if (value) this.inputData.value = value;
  }
  
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.inputData.isDisabled = isDisabled ?? false;
  }

  ngOnInit() {
    // Lifecycle hook for component initialization if needed.
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['inputData']?.currentValue) {
      if (changes['inputData']?.currentValue?.type == AppConstants.fieldType.currency) {
        this.inputData.type = AppConstants.fieldType.number;
      }
    }

  }

  /**
   * Updates the field value on input change and emits a focusout event.
   * @param value - The new value of the input field.
   * @param field - The InputData object representing the input field.
   */
  onInputChange(value: string, field: InputData) {
    this.commonService.validateInputFields(field);
    this.onChange(value);
    this.focusoutEvent.emit(field);
  }

  /**
   * Hides the error message when the input field is focused.
   * @param field - The InputData object representing the input field.
   */
  focusInput(field: InputData) {
    field.isShowErrorMessage = false;
    this.focusEvent.emit(field);
  }

  /**
   * Emits a focusout event when the input field loses focus.
   * @param field - The InputData object representing the input field.
   */
  focusoutInput(field: InputData) {
    this.focusoutEvent.emit(field);
    this.commonService.validateInputFields(field);
    this.onTouched();
  }

  /**
   * Increments the input value if it is within the specified length constraints.
   * @param value - The current value to increment.
   */
  increment(value: number) {
    const numericValue = typeof value === 'string' ? parseInt(value) : value;
    if (this.inputData?.minLength && numericValue && numericValue < this.inputData?.maxLength) {
      this.inputData.value = numericValue + 1;
    } else if (!numericValue) {
      this.inputData.value = 1;
    }
    this.commonService.validateInputFields(this.inputData);
    this.onChange(this.inputData.value);
    this.focusoutEvent.emit(this.inputData);
  }

  /**
   * Decrements the input value if it is above the minimum length.
   * @param value - The current value to decrement.
   */
  decrement(value: number) {
    if (this.inputData?.minLength && value && value > this.inputData?.minLength) {
      this.inputData.value = value - 1;
    } else if (!value) {
      this.inputData.value = 1
    }
    this.commonService.validateInputFields(this.inputData);
    this.onChange(this.inputData.value);
    this.focusoutEvent.emit(this.inputData);
  }

}