import { Directive, ElementRef, HostListener, Input } from '@angular/core';
import { CountriesResponseModel, CountryOptionModel } from '../models';

@Directive({
  selector: '[libAppPhoneFormat]',
})
export class AppPhoneFormatDirective {
  @Input('libAppPhoneFormat') format!: CountryOptionModel;

  private inputElement: HTMLInputElement | null = null;

  private code!: string;

  private originalInput = '';

  constructor(
    private el: ElementRef
  ) { }

  @HostListener('input', ['$event'])
  onInputChange(event: KeyboardEvent): void {
    this.inputElement = this.el.nativeElement.querySelector('input');

    if (!this.inputElement || !this.format) return;

    this.code = this.format.mobileNumber.replace(/\D+/g, '');

    this.originalInput = this.inputElement?.value.replace(this.code, '').trim().replace(/\+/g, '').trim();

    if (this.validateInput()) {

      this.inputElement.value = `${this.format.mobileNumber} ${this.originalInput.slice(0, this.format.placeHolder.length)}`

      return
    }

    this.inputElement.value = this.formatNumber(this.format, this.inputElement.value.replace(/\D+/g, ''))
  }

  validateInput() {
    const regex = new RegExp(`^${this.format.pattern}$`);

    const testResult = regex.test(this.originalInput ?? "");

    return testResult || this.format.placeHolder.length < (this.originalInput?.length ?? 0)
  }

  formatNumber(format: CountryOptionModel, trimmed: string) {

    let formatted = "";

    const phoneNumber = trimmed.replace(this.code, '').trim();
    
    let patternIndex = 0;
    for (let i = 0; i < phoneNumber.length; i++) {
      if (patternIndex < format.placeHolder.length && format.placeHolder[patternIndex] === ' ') {
        formatted += ' ';
        patternIndex++;
      }
      formatted += phoneNumber[i];
      patternIndex++;
    }
    return `${format.mobileNumber} ${formatted}`;
  }
}
