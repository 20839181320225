export const AppConstants = {
  amenitiesShowCount : 2,
  locationPageSize: 500,
  error: 'Invalid Token',
  errorMessage: "Oops, Something went wrong.",
  location: 'location',
  noOfGuest: 'occupants',
  noOfNights: 'los',
  dollar: 'USD',
  delay : 200,
  defaultCurrencyCode : 'USD',
  fieldType: {
    text: 'text',
    number: 'number',
    dropdown: 'dropdown',
    toggle: 'toggle',
    textarea: 'textarea',
    checkbox: 'checkbox',
    radioOption: 'radioOption',
    date: 'date',
    currency: 'currency',
    phoneNumber: 'phoneNumber',
    tel: "tel",
    email: 'email',
    locationDropdown: 'locationDropdown',
    timeOnly: 'timeOnly',
    dateWithTime: 'dateWithTime'
  },
  map : {
    minZoom : 10,
    maxZoom : 20
  },
  defaultPlaceholder: "Enter value",
  defaultMinLengthTextarea: 0,
  defaultMaxLengthTextarea: 500,
  mode: {
    single: 'single',
    range: 'range',
    timeOnly: 'timeOnly',
    dateWithTime: 'dateWithTime'
  },
  rangeType: {
    min: 'Min',
    max: 'Max'
  },
  numberOfMonth:{
   one:1,
   two:2
  },
  moveIn: 'moveIn',
  moveOut: 'moveOut',
  moveInPlaceHolder: "Move In",
  moveOutPlaceHolder: "Move Out",
  property: "property",
  "property-cluster": "property-cluster",
  office: "office",
  neighbor: "neighbor",
  WALKING: "WALKING",
  DRIVING: "DRIVING",
  TRANSIT: "TRANSIT",
  input: {
    min: 3,
    max: 100
  },
  textarea: {
    min: 3,
    max: 500
  },
  lat: { lat: -33.8666, lng: 151.1958 },
  position: {
    top: 'top',
    middle: 'middle',
    bottom: 'bottom'
  },
  zoom: 15,
  time: {
    moveInTime: '12:00 AM',
    moveOutTime: '11:59 PM'
  },
  source: {
    synergy: 'synergy'
  },
  number: {
    min: 3,
    max: 500
  },
  responsive: {
    screen1: "1500px",
    screen2: "1024px",
    screen3: "768px",
    screen4: "560px",
    screen5: "600px"
  },
  type: {
    button: "button",
    image: "image"
  },
  searchPlaceholder: 'search',
  placeholder: 'Select',
  pageLimit: 10,
  scrollType: 'scroll',
  selectAll: 'All',
  imageTour: 'https://my.matterport.com/show/?m=KoPPWvVPqQm',
  viewAll: "view All",
  width: 992,
  publicEndPoints: ['login'],
  defaultDistance: 0,
  moreLabel: 'more',
  leadEndPoints: ['createLead'],
  login: {
    help: 'help',
    terms: 'terms',
    policy: 'policy'
  },
  firstName: "firstName",
  currency: "currency",
  event: {
    scroll: "scroll",
    search: "search"
  },
  pickerMaxSelection: 3,
  browseLabel: "Browse or drag & drop file.",
  supportedLabel: "Supported file types .png, .pdf, word, excel & .jpeg.",
  fileLabel: "Browse Files",
  mbSize: 'MB',
  progressPoint: 0,
  distance: {
    kiloMeters : 'Kms',
    miles: 'Mi'
  },
  poweredBy: 'Powered By'
}

// Constants for input properties
export const InputConstants = {
  allTextValue: "All",  
  placeHolder: "Select ...",  
  searchPlaceHolder: "Search...", 
  setButtonText: "Set",  
  cancelButtonText: "Cancel",  
  labelKey: "name",  
  valueKey: "id",
  isSearch: true,  
  isMultiSelect: false,  
  isActionButton: false,  
  currentPage: 1,  
  disabled: false,  
  defaultDropdown: false, 
  noDataText: "No Data Found !", 
  count: 130,
  close: "close"
};

export const ImagePath = {
fileIcon: "/assets/icons/file-icon.svg",
cancelIcon: "/assets/icons/cancel-icon.svg",
uploadFileIcon: "/assets/icons/upload-file.svg",
deleteIcon: "/assets/icons/delete-with-line-icon.svg",
 downArrow: "/svg/chevron-down.svg",
 iIcon: "./assets/icon/i-icon.svg"  
}
export const dataTypes = {
  string: 'string',
  number: 'number'
}

export const source = {
  SYNERGY: "synergy",
  CLIENT_PORTAL: "client_portal",
  MANUAL: "manual",
};

export const BlobAccess = {
  read: 'r',
  write: 'w'
};
export const LeadKey = {
  apartmentType: 'apartmentType',
  currency: 'currency',
  departureDate: 'departureDate',
  arrivalDate: 'arrivalDate',
  location: 'location',
  firstName: 'firstName',
  lastName: 'lastName',
  noOfUnits: 'noOfUnits',
  country: 'country'
}

export const BlobName = {
  configJson : 'config.json'
}


export const DROP_DOWN_TYPES = {
  TRAVELERS: "travelers",
  AMENITIES: "amenities",
  STATUS: "status",
  COUNTRIES: "countries",
  CURRENCY: "currency",
  SOURCE: "source",
  DENIAL_TYPE: "denialType",
  SALES_REPRESENTATIVE: "salesRepresentative",
  SALES_MANAGER: "salesManager",
  SUPPLIER_STATUS: "supplierStatus",
  APARTMENT_TYPE: "apartmentType",
  HOUSEKEEPING_SERVICE: "housekeepingService",
  PET_NIGHTLY_RATE: "petNightlyRate",
  UNIT_TYPE: "unitType",
  SUPPLIER_PROPERTY: "supplierProperty",
  TIMEZONE: "timezone",
  PROPERTY_TYPE: "propertyType",
  LANGUAGES: "languages",
  RESIDENT_STATUS: "residentStatus",
  PAYMENT_TYPE: "paymentType",
  PROPERTY_CONTACT_TYPE: "propertyContactType",
  PERSON: "person",
  COMPANY: "company",
  BILLING_PARTY: "billingParty",
  BUSINESS_UNIT: "businessUnit",
  RATE_TYPE: "rateType",
  TRAVELER_ROLE: "travelerRole",
};


export const ENVIRONMENT = {
  DEV: 'dev',
  QA: 'qa',
  PROD: 'prod'
}; 

export const ToastConstants = {
  invalidFile: 'Invalid File',
  supportedFiles: 'Supported file types are .png, .jpeg, .pdf, xslsx & docx'
}
