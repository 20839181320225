import { AmenitiesModel, CheckBoxInput, DropdownOptions, IPagination, InputData, OptionModel, SliderModelDistance, SliderModelPrice, ToggleInput } from "./shared-common.model";

export interface IPropertyDetail {
    propertyId: string
    propertyImage: Array<any>
    propertyName: string,
    address: string,
    postalCode: String,
    fullAddress: string,
    countryCode: string
    city: string,
    country: string,
    description: string,
    noOfBeds: number,
    distance: string,
    details: string,
    amenities: Array<any>,
    latitude: number,
    longitude: number,
    cancellationPolicy: string,
    rules?: Array<Rule>
    availableUnits: Array<AvailableUnit>
}
export interface AvailableUnit {
    roomType: string;
    bedCount: string;
    bathroomCount: number;
    attachments: Attachment[];
    price: number
}

export interface Attachment {
    url: string;
}
export interface Rule {
    name: string;
    icon: string;
}


export interface PropertyResult {
    properties: Array<PropertyModel>
    pagination: Pagination
}

export interface Pagination {
    page: number
    pageSize: number
    totalCount: number
}


export interface PropertyModel {
    propertyName: string
    propertyId: string
    codeOneRefId: unknown
    noOfBedrooms: number
    latitude: number
    longitude: number
    availableDate: string
    rtbAvailable: boolean
    attachments: Array<Attachments>
    amenities: Array<AmenitiesType>
    pricePerNight: number
    ownedBy: string
    distanceInMiles: number
    clickable?: boolean
    type?: string
    icon?: string
    amenitiesArray: Array<Amenities>
    units: Array<Units>
}
export interface Units {
    noOfUnitTypes: string
    startingAtRate: number
    type: string
}
export interface AmenitiesType {
    amenities: Array<Amenities>
    amenitiesType: string
}
export interface Amenities {
    id?: string
    amenitiesCategoryId?: string
    name: string
    iconPath?: string
    svgPath: string,
    categoryName?: string
    type?: string | null
    createdDate?: string
    updatedDate?: string | null
}

export interface Attachments {
    attachmentId: string
    fileName: string
    imageUrl: string
    imageOrder: number
    description: string | null
    url?:string
}
export interface PropertyRequest {
    where: {
        amenities?: { in: string[] }
        location: { latitude: number, longitude: number, radius: number }
        date: { from: String, to: String }
        price?: { min: number, max: number }
        property?: {
            instantBookingOnly: boolean
            propertyType: { in: number[] }
            preferredProperties: boolean
        }
    }
    sort: { by: string, order: string }
    pagination: { page: number, pageSize: number }
}
export interface PropertyVariableModel {
    amenities?: string[],
    latitude: number,
    longitude: number,
    radius?: number,
    dateFrom: string,
    dateTo: string,
    priceMin?: number,
    priceMax?: number,
    instantBookingOnly?: boolean,
    propertyType?: Array<number>,
    page: number,
    pageSize: number,
    preferredProperties?: boolean,
    sortBy: string,
    sortDirection: string;
}
export interface PropertyRequestModel {
    where: {
        amenities?: { in: string[] };
        location?: {
            latitude: string
            longitude: string
        }
        date?: {
            from: string
            to: string
        }
        price?: { min?: number, max?: number }
        property?: {
            instantBookingOnly?: boolean
            propertyType?: { in: number[] };
            preferredProperties?: boolean
        }
    }
    sort?: { by: string, order: string }
    pagination?: { page: number, pageSize: number }
}
export interface PropertyFilterModel {
    amenities: AmenitiesModel[];
    isInstantBookingOnly: ToggleInput;
    isPreferredProperty: ToggleInput;
    languageLabel: any;
    sortByOptions: OptionModel[];
    checkboxData: CheckBoxInput[];
    inputParams: InputData[];
    sliderConfigurationForPrice: SliderModelPrice;
    sliderConfigurationForDistance: SliderModelDistance;
    selectedOption: OptionModel
}

export interface IPropertyAttachment {
    url: string;
    embeddedUrl?: string
}

export interface IMasterPropertyType {
    propertyList: IPropertyType[] | [];
    pagination: IPagination;
}

export interface IPropertyType {
    id: string;
    propertyType: string | null;
}

export interface IAmenities {
    id: string,
    name: string,
    type: string,
    svgPath: string,
    isSelected?: boolean,
    iconPath: string | null
}

export interface IMasterAmenities {
    pagination: IPagination,
    amenitiesList: IAmenities[] | []
}

export interface UnitTypeModel {
    unitTypeId: string;  // Assuming unitTypeId is a string
    unitType: string;    // Assuming unitType is a string
    orderBy: number;     // Assuming orderBy is a number
    createdDate: string; // Assuming createdDate is a string (could be a Date object if formatted properly)
    createdBy: string;   // Assuming createdBy is a string
}