// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,

  config: {
    name: 'dev',
    apiUrl: 'https://dev-client-api.azurewebsites.net/synergy/portal',
    searchApiUrl: 'https://dev-search-api.azurewebsites.net/synergy/search',
    leadApiUrl: 'https://dev-lead-api.azurewebsites.net/synergy/lead',
    clientApiUrl: 'https://dev-client-api.azurewebsites.net/synergy/portal',
    /** Microsoft sso we are not supporting. Application will work only auth0 sso support  **/
    microsoftTenantId: "7ab4406e-ee9c-46ae-ae77-39484c4ed93e",
    microsoftClientId: "ea8f81a8-954e-4f41-9ea2-0848ec4b8036",
    microsoftRedirectURI: "https://dev-client-portal.azurewebsites.net",
    microsoftAuthority: "https://login.microsoftonline.com/7ab4406e-ee9c-46ae-ae77-39484c4ed93e",
    /** Microsoft sso we are not supporting. Application will work only auth0 sso support  **/
    googleKey: 'AIzaSyATh-GJwlYr08ySeLymNlUCqp0NxlzrNrw',
    cdnDOmainUrl: 'http://192.168.2.131:4000/api/',
    isLogEnable: false,
    googleApi: 'https://maps.googleapis.com/maps/api/',
    googleAnalyticsId : 'G-K5HJJKVNXW',
    azure: {
      storageAccountName : 'synergywebsourcing',
      privateContainerName: 'private-container',
      publicContainerName: 'synergy-source',
      configContainerName : 'client'
    },
    auth0 : {
      clientId: '1eWAEwXSW1qtqYm3zQukDgu5oqowXE2V',  // Dynamically set the client ID
      domain: 'dev-2dnjzkd45hyeaj1d.us.auth0.com',      // Dynamically set the domain
      redirectUri: 'https://oracle.devsynergyhousing.com', 
      audience: 'https://dev-2dnjzkd45hyeaj1d.us.auth0.com/api/v2/',
      scope :  'openid profile email',
      response_type : 'code',
      connection: 'azuread'
    },   
    appInsights: '87ea29c9-cbf6-4fc0-9eeb-9044692d2c7a',
  },
  build: "20241206v1"
}
 



/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
