import { Component, Input } from '@angular/core';
import { ImagePath } from 'shared-ui/src/lib/constants/shared-constant';


@Component({
  selector: 'app-info',
  templateUrl: './info.component.html',
  styleUrl: './info.component.scss',
})
export class InfoComponent {
 @Input() icon!:string;
 @Input() infoText!: string;
 @Input() customClass!: string;
}
