import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { StorageService } from '@synergy-application/shared-ui';
import { LocalStorageKeys } from '../../modules/shared/constants/local-storage.constant';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {
  constructor(private storageService: StorageService, private router: Router) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    const requiredPermissions = next.data['permissions'];
    const userPermissions = JSON.parse(this.storageService.get(LocalStorageKeys.userInfo));   

    // Check if user has required permissions
    if (this.hasPermissions(requiredPermissions, userPermissions.roles) && this.storageService.get(LocalStorageKeys.token)) {
      return true;
    } else {
      return false;
    }
  }

  private hasPermissions(requiredPermissions: string[], userPermissions: string[]): boolean {
    return requiredPermissions.some(permission => userPermissions.includes(permission));
  }
}
