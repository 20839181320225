import { Component, HostListener, Input, OnInit } from '@angular/core';
import { CardInput } from '../../models/common.model';

@Component({
  selector: 'app-left-image-container',
  templateUrl: './left-image-container.component.html',
  styleUrls: ['./left-image-container.component.scss'],
})
export class LeftImageContainerComponent implements OnInit {
  @Input() data: any;
  isMobileView = false;

  constructor() {
  }

  ngOnInit(): void {
  }

  /**
   * Handles actions when the window or element is resized.
   * @param event 
   */

  @HostListener('window:resize', ['$event'])
  onResize(event: Event): void {
  }

}
