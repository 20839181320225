import { DatePipe } from '@angular/common';
import { Component, EventEmitter, HostListener, Input, OnInit, Output, SimpleChanges, ViewChild, viewChild } from '@angular/core';
import { LocationService } from '../../services/location.service';
import { IMapBase, LocationItem, LocationSearchData, SearchDetail } from '../../models/location.model';
import { checkScreenSize } from '../../core/helper';
import { DateInput, RequestInput,IconPositionModel,IconSizeModel,IconNameModel,DatePicketType } from '../../models';
import { ModalController } from '@ionic/angular';
import { IonModal } from '@ionic/angular/common';
import { AppConstants } from '../../constants/shared-constant';
import { SharedApiService } from '../../core/shared-api.service';
import { GraphQLService } from '../../core/graphql';


@Component({
  selector: 'lib-property-search',
  templateUrl: './property-search.component.html',
  styleUrls: ['./property-search.component.scss'],
})
export class PropertySearchComponent implements OnInit {
  @ViewChild('modal', { static: true }) modal!: IonModal;

  @Output() searchEmit: EventEmitter<SearchDetail> = new EventEmitter();
  @Input() language: any;
  isMobile = false;
  @Input() objSearchDetails: SearchDetail = {
    locationDetails: {
      name: '',
      placeId: ''
    },
    moveIn: "",
    moveOut: ""
  }
  @Input() buttonUI: boolean = false;

  appConstants = AppConstants;
  @Input() dateMoveInInput: DateInput = {
    label: '',
    showErrorMessage: '',
    isShowErrorMessage: false,
    placeholder: '',
    value: '',
    isRequired: false,
    mode: this.appConstants.mode.range,
    icon: true,
    iconPosition:IconPositionModel.prefix,
    iconSize: IconSizeModel.medium,
    iconName: IconNameModel.calendar,
    isBorder: DatePicketType.borderless
  }
  @Input() dateMoveOutInput: DateInput = {
    label: '',
    showErrorMessage: '',
    isShowErrorMessage: false,
    placeholder: '',
    value: '',
    isRequired: false,
    mode: this.appConstants.mode.range,
    icon: false
  }
  @Input() locationSearchData: LocationSearchData = {
    label: '',
    type: this.appConstants.fieldType.text,
    maxLength: this.appConstants.input.max,
    placeholder: '',
    value: '',
    isShowErrorMessage: false,
    groups: [{
      name: '',
      items: []
    }, {
      name: '',
      items: []
    }]
  }

  moveIn = AppConstants.moveIn;

  moveOut = AppConstants.moveOut;

  showBorder = false;

  constructor(
    private datePipe: DatePipe,
    private locationService: LocationService,
    private modalController: ModalController,
    private sharedApiService: SharedApiService,
    private graphQLService: GraphQLService,
  ) { }

  ngOnInit() {
    this.isMobile = checkScreenSize();
    if (this.isMobile) {
      this.dateMoveInInput.mode = this.appConstants.mode.single;
      this.dateMoveOutInput.mode = this.appConstants.mode.single;
      this.dateMoveInInput.iconPosition = IconPositionModel.suffix;
      this.dateMoveOutInput.iconPosition = IconPositionModel.suffix;
      this.dateMoveInInput.iconSize = IconSizeModel.medium;
      this.dateMoveOutInput.iconSize = IconSizeModel.medium;
    }
    // this.onLocationSearch(this.objSearchDetails.locationDetails.name);

  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['language']?.currentValue) {
      this.dateMoveInInput.placeholder = this.language.moveIn;
      this.dateMoveOutInput.placeholder = this.language.moveOut;
      this.locationSearchData.label = this.language.where;
      this.locationSearchData.placeholder = this.language.chooseLocation;
    }
    if (changes['objSearchDetails']?.currentValue) {
      this.objSearchDetails = changes['objSearchDetails']?.currentValue;
    }

  }

  /**
   *  Handles the selection of a location from a list or map.
   * @param $event 
   */
  onSelectLocation($event: LocationItem) {
    if ($event) {
      this.objSearchDetails.locationDetails = $event;
    }
  }


  /**
   * Retrieves selected dates from the date picker and formats them.
   * @param $event The selected date(s) from the date picker.
   */
  getDateFromDatepicker(event: any, target?: string) {
    if (event?.value?.length && !this.isMobile) {
      this.objSearchDetails.moveIn = event.value[0];
      this.objSearchDetails.moveOut = event.value[1];
    } else if (event?.value && this.isMobile) {
      if (target && target === this.appConstants.moveIn && event?.value) {
        this.objSearchDetails.moveIn = event.value;
      }
      if (target && target === this.appConstants.moveOut && event.value) {
        this.objSearchDetails.moveOut = event.value;
      }
    }
    this.objSearchDetails = JSON.parse(JSON.stringify(this.objSearchDetails));
  }

  /**
   * Get latitude and longitude based on place Id
   * Navigates to a specific page based on the provided search data.
   */
  onSearch() {
    if (this.objSearchDetails.locationDetails?.placeId) {
      this.locationService.getLatLongFromPlaceId(this.objSearchDetails.locationDetails.placeId)
        .then((res: IMapBase) => {
          this.objSearchDetails.locationDetails.latitude = res.lat;
          this.objSearchDetails.locationDetails.longitude = res.lng
          this.searchEmit.emit(this.objSearchDetails);
          this.modalController.dismiss(this.objSearchDetails);
        })
        .catch((error: Error) => {

        })
    } else {
      this.searchEmit.emit(this.objSearchDetails);
    }
  }

  /**
   * Handles actions when the window or element is resized.
   * @param event 
   */
  @HostListener('window:resize', ['$event'])
  onResize(event: Event): void {
    this.isMobile = checkScreenSize();
    if (this.isMobile) {
      this.dateMoveInInput.mode = this.appConstants.mode.single;
      this.dateMoveOutInput.mode = this.appConstants.mode.single;
      this.dateMoveInInput.iconPosition = IconPositionModel.suffix;
      this.dateMoveOutInput.iconPosition = IconPositionModel.suffix;
      this.dateMoveInInput.iconSize = IconSizeModel.medium;
      this.dateMoveOutInput.iconSize = IconSizeModel.medium;
    } else {
      this.dateMoveInInput.iconPosition = IconPositionModel.prefix;
      this.dateMoveOutInput.iconPosition = IconPositionModel.prefix;
    }
  }

  closePopup() {
    this.modalController.dismiss(null);
    this.dateMoveInInput.mode = this.appConstants.mode.range;
  }

  resetFilter() {
    this.dateMoveInInput.value = '';
    this.dateMoveOutInput.value = '';
    this.locationSearchData.value = '';
    this.objSearchDetails.locationDetails.name = '';
    this.objSearchDetails.moveIn = '';
    this.objSearchDetails.moveOut = '';
  }

  searchProperty() {

  }

  didDismiss(event: CustomEvent) {
    if (event?.detail?.data) this.searchEmit.emit(event.detail.data);
  }

  openFilter() {
    if (window.innerWidth < this.appConstants.width) this.modal.present();
  }
  
}
