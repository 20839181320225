import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'isArray'
})
export class IsArrayPipe implements PipeTransform {
  transform(value: unknown, ...args: unknown[]): boolean {
    return Array.isArray(value);
  }
}
