
<div [class.ion-hide-lg-down]="buttonUI" (click)="openFilter()" class="border-class ion-hide-lg-down">
  <ion-grid class="container-width">
    <ion-row class="label-color-text bold-xs-text align-items">
      <!--Location search dropdown-->
      <ion-col class="location-padding pad-0">
        <lib-location-dropdown [showBorder]="showBorder" [language]="language" [inputData]="locationSearchData"
           (selectedItem)="onSelectLocation($event)"></lib-location-dropdown>
        <div class="vertical-line"></div>
        <div class="date-flex date-css">
          <lib-date-picker [inputData]="dateMoveInInput" [showIcon]="dateMoveInInput.icon ?? false"
            (dateObj)=getDateFromDatepicker($event,moveIn)></lib-date-picker>
        </div>
      </ion-col>


      <!--Search Button-->
      <ion-col class="search-class pad-0 ion-hide-lg-down" size="12" size-sm="12" size-md="2.5">
        <lib-button
          [disabled]="!(objSearchDetails.locationDetails.placeId || objSearchDetails.locationDetails.latitude)"
          is-primary is-medium class="search-btn"
          (click)="(objSearchDetails.locationDetails.placeId || objSearchDetails.locationDetails.latitude) && onSearch()">
          <ion-icon name="search-outline"></ion-icon> {{language?.search ?? ''}}<ion-icon
            name="chevron-forward-outline"></ion-icon>
        </lib-button></ion-col>
    </ion-row>
  </ion-grid>
</div>

@if (buttonUI) {
<div [class.ion-hide-lg-up]="buttonUI" class="mobileScreen">
  <lib-button id="open-modal" is-primary is-medium class="search-btn">
    <lib-icon name="lens" [className]="'medium'"></lib-icon>
    {{language.searchProperties ?? ''}}
  </lib-button>
</div>
}


<ion-modal #modal trigger="open-modal" (didDismiss)="didDismiss($event)">
  <ng-template>
    <ion-header>
      <ion-toolbar class="toolbar">
        <div class="header">
          <ion-label class="font-color semiBold-md-text"> {{ language?.searchProperties ?? ''}}</ion-label>
          <span (click)="closePopup()">
            <ion-icon class="close" name="close-outline"></ion-icon>
          </span>
        </div>
      </ion-toolbar>
    </ion-header>
    <ion-content scroll-y="false">
      <ion-grid class="page-content mobile-search mobile-scroll">
        <ion-row>
          <ion-col class="grid-gap">
            <ion-label class="medium-md-text">
              {{language?.where ?? ''}}
            </ion-label>
            <lib-location-dropdown [language]="language" [inputData]="locationSearchData"
              (selectedItem)="onSelectLocation($event)"></lib-location-dropdown>
          </ion-col>
        </ion-row>
        <ion-row>
          <ion-col class="grid-gap mobileview-date-css">
            <ion-label class="medium-md-text">
              {{language?.moveIn ?? ''}}
            </ion-label>
            <lib-date-picker [inputData]="dateMoveInInput" [showIcon]="true"
              (dateObj)=getDateFromDatepicker($event,moveIn)></lib-date-picker>
          </ion-col>
        </ion-row>
        <ion-row>
          <ion-col class="grid-gap mobileview-date-css">
            <ion-label class="medium-md-text">
              {{language?.moveOut ?? ''}}
            </ion-label>
            <lib-date-picker [inputData]="dateMoveOutInput" [showIcon]="true"
              (dateObj)=getDateFromDatepicker($event,moveOut)></lib-date-picker>
          </ion-col>
        </ion-row>
      </ion-grid>
    </ion-content>
    <ion-footer class="page-content">
      <ion-grid class="ion-no-padding footer-grid">
        <ion-row>
          <ion-col class="pad-r-8">
            <lib-button is-secondary is-medium (click)="resetFilter()">{{language?.reset ?? ''}}</lib-button>
          </ion-col>
          <ion-col class="pad-l-8">
            <lib-button is-primary is-medium
              [disabled]="!(objSearchDetails.locationDetails.name && objSearchDetails.moveIn && objSearchDetails.moveOut)"
              (click)="(objSearchDetails.locationDetails.name && objSearchDetails.moveIn && objSearchDetails.moveOut) && onSearch()">{{language?.search
              ?? ''}}</lib-button>
          </ion-col>
        </ion-row>
      </ion-grid>
    </ion-footer>
  </ng-template>
</ion-modal>
