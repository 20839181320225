import { Injectable } from '@angular/core';
import { isValidEmail, isValidName } from '../core/helper';
import { CountryOptionModel, DropDownCommonResponseModel, DropDownInput, DropDownResponseModel, GetFilterPayload, ToastModel, ToastModelEnum } from '../models';
import { AppConstants, DROP_DOWN_TYPES } from '../constants/shared-constant';
import * as toasterConfig from "../config/toast.config.json";
import { ToastController } from '@ionic/angular';
import { GET_LEAD_FILTERS } from '../core/shared-query';
import { ApolloQueryResult } from '@apollo/client';
import { SharedApiService } from '../core/shared-api.service';
import { GraphQLService } from '../core/graphql';

@Injectable({
  providedIn: 'root'
})
export class CommonService {

  selectedCountryOption! : DropDownCommonResponseModel;

  constructor(private sharedApiService: SharedApiService, private toastController: ToastController, private graphQLService: GraphQLService) { }

  /**
   * validate the input fields
   * @param field 
   */
  validateInputFields(field: any) {
    if(field.type != AppConstants.fieldType.phoneNumber){
      field.isShowErrorMessage = false;
      field.showErrorMessage = '';
    }   
    // Future Implementation
    if ((field.type == AppConstants.fieldType.locationDropdown || field.type == AppConstants.fieldType.text || field.type == AppConstants.fieldType.textarea) && !field.isHide) {
      if (!field?.value?.trim() && field.isRequired) {
        field.isShowErrorMessage = true;
        field.showErrorMessage = field.errorMessage;
        return
      }
      if (field.isRegex && !isValidName(field.value)) {
        field.isShowErrorMessage = true;
        field.showErrorMessage = field.isRegexErrorMessage;
      }
    } else if (field.type == AppConstants.fieldType.email) {
      if (!field?.value?.trim() && field.isRequired) {
        field.isShowErrorMessage = true;
        field.showErrorMessage = field.errorMessage;
        return;
      } else if (field.isRegex && field.value && !isValidEmail(field.value)) {
        field.isShowErrorMessage = true;
        field.showErrorMessage = field.isRegexErrorMessage;
      }
    } else if (field.type == AppConstants.fieldType.number) {
      if (!field?.value && field.isRequired) {
        field.isShowErrorMessage = true;
        field.showErrorMessage = field.errorMessage;
      }
      if (field.minLength && field.maxLength && field.value > field.maxLength) {
        field.isShowErrorMessage = true;
        field.showErrorMessage = field.isRegexErrorMessage;
      }
    } else if (field.type == AppConstants.fieldType.date && field.mode == AppConstants.mode.single) {
      if (!field?.value && field.isRequired) {
        field.isShowErrorMessage = true;
        field.showErrorMessage = field.errorMessage;
      }
    } else if (field.type == AppConstants.fieldType.dropdown) {
      if (!field?.value && field.isRequired) {
        field.isShowErrorMessage = true;
        field.showErrorMessage = field.errorMessage;
      }
    }
  }

  async presentToast(type: ToastModelEnum, toaster: ToastModel) {
    const config = toasterConfig[type as keyof typeof toasterConfig];
    const toast = await this.toastController.create({
      message: toaster.message ?? '',
      header: toaster.header ?? '',
      duration: toaster.duration,
      position: toaster.position ?? 'bottom',
      cssClass: `custom-toast ${config.class}`,
      icon: toaster.icon ?? config.icon ?? '',
      buttons: [
        {
          icon: config.closeIcon,
          side: 'end',
          handler: () => {
            toast.dismiss();
          }
        }
      ],
    });
    await toast.present();
  }

  getLeadFilters(request: GetFilterPayload): Promise<any> {
    return new Promise((resolve, reject) => {
      this.graphQLService.query(GET_LEAD_FILTERS, { request }).then((result: ApolloQueryResult<any>) => {
        resolve(result.data.getLeadFilters)
      }).catch((error => reject(error)));
    });
  }

  getDropDownValue(event: any, field: any) {
    const request: DropDownInput = {
      request: {
        type: field.name,
        pagination: {
          page: (event.currentPage / AppConstants.pageLimit + 1) || 1,
          pageSize: AppConstants.pageLimit
        }
      }
    }
    if (event.searchQuery) {
      request.request.search = { where: { name: { like: event.searchQuery } } };
    }
    this.sharedApiService.fetchDropDownValues(request).then((res) => {
      if (field.name == DROP_DOWN_TYPES.COUNTRIES) {
        res.data.forEach((element: any) => {
          element.mobileNumber = `+${element.mobileNumber}`
          element.value = `${element.value} (${element.mobileNumber})`;
          element.icon = element?.svgPath;
        });
      }

      field.options = event.type === AppConstants.event.scroll ? [...field.options, ...res.data] : [...res.data];
      if (field.defaultValue) {
        field.value = field.options.find((e: any) => e.value == field.defaultValue)
      }
      field.totalCount = res.pagination.totalCount ?? 0;
    }).catch((err: Error) => {

    }).finally(() => {
      if (event.toggleLoading) event.toggleLoading(true);
    })
  }
  phoneNumberValidation(input: any, format: any, language: any) {
    input.isShowErrorMessage = false;
    input.showErrorMessage = '';

    if (!input.value && !format) {
      return;
    } else if(input.value && !format){
      input.isShowErrorMessage = true;
      input.showErrorMessage = language?.countryErrorMsg || "error";
      return;
    } else if (!input.value && format){
      input.isShowErrorMessage = true;
      input.showErrorMessage = input.errorMessage;
      return;
    }
    const originalInput = input?.value.trim();

    if (format) {
      if (this.validateInput(format,originalInput)) {
        input.value = `${originalInput?.slice(0, format.placeHolder.length)}`;
        return;
      } else {
        input.isShowErrorMessage = true;
        input.showErrorMessage = input.isRegexErrorMessage;
      }

      input.value = this.formatNumber(format, input.value.replace(/\D+/g, ''));
    }

  }

  validateInput(format: any,originalInput : string) {
    const regex = new RegExp(`^${format.pattern}$`);
    const testResult = regex.test(originalInput ?? "");
    return testResult;
  }

  formatNumber(format: CountryOptionModel, phoneNumber: string) {

    let formatted = "";
    let patternIndex = 0;
    for (let i = 0; i < phoneNumber.length; i++) {
      if (patternIndex < format.placeHolder.length && format.placeHolder[patternIndex] === ' ') {
        formatted += ' ';
        patternIndex++;
      }
      formatted += phoneNumber[i];
      patternIndex++;
    }
    return `${formatted}`;
  }
}