<div class="table-container">
  <table class="responsive-table" [ngStyle]="{ 'background-color': styleConfig.backgroundColor, 
'border-color': styleConfig.borderColor, 
'color': styleConfig.textColor, 
'border-radius': styleConfig.borderRadius, 'border-bottom': styleConfig.borderBottom }">
    <thead>
      <tr class="header-row semiBold-xs-text font-color">
        <th *ngFor="let header of headers"
          [ngStyle]="{ 'border-color': styleConfig.borderColor, 'color': styleConfig.textColor, 'border-bottom': styleConfig.borderBottom, 'width':header.width }">
          <div [ngSwitch]="header.type" [ngClass]="header.alignment === 'center' ? 'center-aligned' : 
        header.alignment === 'right' ? 'right-aligned' : 
        'left-aligned'">
            <div class="checkbox-with-label" *ngSwitchCase="'allCheckBox'">
              <!-- <input class="checkbox semiBold-xs-text font-color" type="checkbox" [(ngModel)]="allSelected"
              (change)="toggleSelectAll($event)" /> -->
              <ion-checkbox slot="start" (ngModelChange)="toggleSelectAll($event)" [(ngModel)]="allSelected" name="name"
                class="filled-checkbox medium" />
              <span (click)="sortData(header)" [ngClass]="{ 'sortable': header.sortable }" tabindex="0" role="button"
                (keydown)="keyDown()">
                <span class="sort_header_content">
                  <p class="semiBold-xs-text font-color">{{header.displayName}}</p>
                  <ion-icon name="information-circle-outline" *ngIf="header.helperText" [tooltip]="header.helperText"
                    [tooltipPosition]="'bottom'" />
                  <img src="/assets/icon/sort.svg" *ngIf="sortColumn === header.name"
                    [ngStyle]="{'transform': 'scaleX(' + (sortDirection === 'asc' ? 1 : -1) + ')'}" />
                </span>
              </span>
            </div>
            <div class="checkbox-with-label semiBold-xs-text font-color" *ngSwitchCase="'checkboxWithExpand'">
              <ion-checkbox slot="start" (ngModelChange)="toggleSelectAll($event)" [(ngModel)]="allSelected" name="name"
                class="filled-checkbox medium" />
              <span (click)="sortData(header)" [ngClass]="{ 'sortable': header.sortable }" tabindex="0" role="button"
                (click)="linkClicked(header)">
                <span class="sort_header_content">
                  <p class="semiBold-xs-text font-color">{{header.displayName}}</p>
                  <ion-icon name="information-circle-outline" *ngIf="header.helperText" [tooltip]="header.helperText"
                    [tooltipPosition]="'bottom'" />
                  <img src="/assets/icon/sort.svg" *ngIf="sortColumn === header.name"
                    [ngStyle]="{'transform': 'scaleX(' + (sortDirection === 'asc' ? 1 : -1) + ')'}" />
                </span>
              </span>
            </div>
            <div *ngSwitchDefault>
              <span class="sort_header_content">
                <p class="semiBold-xs-text font-color" (click)="sortData(header)"
                  [ngClass]="{ 'sortable': header.sortable }" tabindex="0" role="button">{{ header.displayName }}</p>
                <ion-icon name="information-circle-outline" *ngIf="header.helperText" [tooltip]="header.helperText"
                  [tooltipPosition]="'bottom'" />
                <img src="/assets/icon/sort.svg" *ngIf="sortColumn === header.name"
                  [ngStyle]="{'transform': 'scaleX(' + (sortDirection === 'asc' ? 1 : -1) + ')'}" />
              </span>
            </div>
          </div>
        </th>
      </tr>
    </thead>

    <tbody>
      <ng-container *ngFor="let row of data; let i = index">
        <tr [ngStyle]="{ 'border-bottom': styleConfig.borderBottom  }">
          <td *ngFor="let header of headers" [ngClass]="{'highlight-row': row[header.highlightedKey ?? '']}">
            <div [ngSwitch]="header.type" [ngClass]="header.alignment === 'center' ? 'center-aligned' : 
          header.alignment === 'right' ? 'right-aligned' : 
          'left-aligned'">
              <div class="regular-sm-text font-colo checkbox-with-label" *ngSwitchCase="'allCheckBox'">
                <!-- <input class="checkbox" type="checkbox" [(ngModel)]="selectedRows[getRowId(row)]"
                (change)="onRowSelectionChange(row['id'])" /> -->
                <ion-checkbox slot="start" (ngModelChange)="onRowSelectionChange(row[key])"
                  [(ngModel)]="selectedRows[getRowId(row)]" name="name"
                  [ngStyle]="{cursor : row[header.apiKey] === null ? 'not-allowed' : 'pointer'}"
                  class="filled-checkbox medium" [disabled]="row[header.apiKey] === null ? true : false" />
                {{ row[header.apiKey] }}
              </div>

              <div class="checkbox-with-label" *ngSwitchCase="'checkBox'">
                <ion-checkbox slot="start" (ngModelChange)="checkBoxChange(row, row[header.apiKey])"
                  [(ngModel)]="row[header.apiKey]" name="name"
                  [ngStyle]="{cursor : row[header.apiKey] === null ? 'not-allowed' : 'pointer'}"
                  class="filled-checkbox medium" [disabled]="row[header.checkBoxDisableKey ?? ''] ? true : false" />
              </div>

              <div *ngSwitchCase="'Expandable'"
                [ngStyle]="{'color': row[header.colorKey ?? ''] ? 'var(' + row[header.colorKey ?? ''] + ')' : 'var(--app-text-color)'}">
                <div class="checkbox-with-label">
                  <span class="arrow-container" (click)="toggleRowExpansion(i)">
                    <ion-icon [class.collapse]="isRowExpanded(i)" name="chevron-forward"></ion-icon>
                  </span>
                  <label class="hightLight-text regular-sm-text font-color" tabindex="0" role="button" for="value"
                    (click)="linkClicked(row)"> {{ row[header.apiKey] }}</label>
                </div>
              </div>

              <div *ngSwitchCase="'checkboxWithExpand'"
                [ngStyle]="{'color': row[header.colorKey ?? ''] ? 'var(' + row[header.colorKey ?? ''] + ')' : 'var(--app-text-color)'}">
                <div class="checkbox-with-label">
                  <!-- <input class="checkbox" type="checkbox" [(ngModel)]="selectedRows[getRowId(row)]"
                  (change)="onRowSelectionChange(row['id'])" /> -->
                  <ion-checkbox slot="start" (ngModelChange)="onRowSelectionChange(row[key])"
                    [(ngModel)]="selectedRows[getRowId(row)]" name="name"
                    [ngStyle]="{cursor : row[header.apiKey] === null ? 'not-allowed' : 'pointer'}"
                    class="filled-checkbox medium" [disabled]="row[header.apiKey] === null ? true : false" />
                  <span class="arrow-container" (click)="toggleRowExpansion(i)">
                    <ion-icon [class.collapse]="isRowExpanded(i)" name="chevron-forward"></ion-icon>
                  </span>
                  <label class="hightLight-text regular-sm-text font-color"
                    [ngStyle]="{'color': row[header.colorKey ?? ''] ? 'var(' + row[header.colorKey ?? ''] + ')' : 'var(--app-text-color)'}"
                    tabindex="0" role="button" for="value" (linkClicked)="linkClicked(row)"> {{ row[header.apiKey]
                    }}</label>
                </div>
              </div>

              <div *ngSwitchCase="'action'">
                <ion-icon name="ellipsis-vertical" (click)="moreActionClicked($event, header, row)"></ion-icon>
              </div>
              <div *ngSwitchCase="'chip'" class="fit_content">
                <ion-row class="gap_8">
                  <ion-col>
                    <div libChipStyle [status]="row[header.chipStatusKey ?? 1]"
                      [statusConfiguration]="header.statusConfig ?? defaultStatus">
                      <div class="circle_container status"></div>
                      <div class="label"></div>
                    </div>
                  </ion-col>
                </ion-row>
              </div>
              <div *ngSwitchCase="'link'" class="regular-sm-text font-color" [ngClass]="{'link': row['isLink']}"
                [ngStyle]="{'color': row[header.colorKey ?? ''] ? 'var(' + row[header.colorKey ?? ''] + ')' : 'var(--app-text-color)'}"
                (click)="row['isLink'] ? linkClicked(row) : ''">
                {{ row[header.apiKey] }} </div>
              <div *ngSwitchCase="'date'" class="regular-sm-text font-color"
                [ngStyle]="{'color': row[header.colorKey ?? ''] ? 'var(' + row[header.colorKey ?? ''] + ')' : 'var(--app-text-color)'}">
                {{row[header.apiKey] | formatDate: header.dateFormat}}
              </div>
              <div *ngSwitchCase="'dropDown'">
                <lib-dropdown [placeholder]="'Select'" [data]="row[header.apiKey]" [displayBy]="header.valueName ?? ''"
                  [multiSelectable]=false [value]="header.valueName ?? ''"
                  [placeholderTemplate]="(header.dropDownType ?? 0) == customTextDropDownType ? 'RFH EMAILS ({value})' : ''"
                  [type]="header.dropDownType ?? 0" />
              </div>
              <div *ngSwitchCase="'input'">
                <input class="input regular-sm-text" [(ngModel)]="row[header.apiKey]" (input)="tableInputData($event)"
                  [disabled]="row | typeChange: header.disable " />
              </div>
              <div *ngSwitchCase="'icon'">
                <div *ngIf="header.svg">
                  <ion-icon src="/assets/icon/{{header.svg}}"
                    (click)="!row[header.iconDisableKey ?? ''] ? rowClick(header, row) : null"
                    [ngClass]="row[header.iconDisableKey ?? ''] ? 'disabled' : 'enabled'" />
                </div>
                <div *ngIf="header.iconName">
                  <ion-icon [name]="header.iconName"
                    (click)="!row[header.iconDisableKey ?? ''] ? rowClick(header, row) : null"
                    [ngClass]="row[header.iconDisableKey ?? ''] ? 'disabled' : 'enabled'" />
                </div>
              </div>
              <div class="regular-sm-text font-color"
                [ngStyle]="{'color': row[header.colorKey ?? ''] ? 'var(' + row[header.colorKey ?? ''] + ')' : 'var(--app-text-color)'}"
                *ngSwitchCase="'textWithIcon'">
                <span>
                  <ng-container *ngIf="row['iconPosition'] === 'prefix'">
                    <ion-icon *ngIf="row['iconPath']" src="/assets/icon/{{row['iconPath']}}"
                      [ngStyle]="{fill: 'var(' + row['iconColor'] + ')', stroke: 'var(' + row['iconColor'] + ')'}"></ion-icon>
                    <ion-icon *ngIf="row['iconName']" name="row['iconName']"></ion-icon>
                  </ng-container>
                  {{ row[header.apiKey] ?? '-'}}
                  <ng-container *ngIf="row['iconPosition'] !== 'prefix'">
                    <ion-icon *ngIf="row['iconPath']" src="/assets/icon/{{row['iconPath']}}"
                      [ngStyle]="{fill: 'var(' + row['iconColor'] + ')', stroke: 'var(' + row['iconColor'] + ')'}"></ion-icon>
                    <ion-icon *ngIf="row['iconName']" name="row['iconName']"></ion-icon>
                  </ng-container>
                </span>
              </div>
              <div class="regular-sm-text font-color"
                [ngStyle]="{'color': row[header.colorKey ?? ''] ? 'var(' + row[header.colorKey ?? ''] + ')' : 'var(--app-text-color)'}"
                *ngSwitchDefault>
                {{ row[header.apiKey] ?? '-'}}
              </div>
            </div>
          </td>
        </tr>

        <!-- Expanded row content (if expandable) -->
        <tr *ngIf="isRowExpanded(i)">
          <td [attr.colspan]="headers.length" class="expanded-row-content">
            <ng-container *ngTemplateOutlet="expandedTemplate; context: { $implicit: row }" />
          </td>
        </tr>
      </ng-container>
    </tbody>
  </table>
</div>
<!-- Template for expanded content (if needed) -->
<ng-template #expandedTemplate let-row>
  <div class="expanded-content">
    <!-- Use a recursive approach if this expanded row contains inner data -->
    <lib-table-view *ngIf="row.supplierResponse" [headers]="innerTableHeaders" [data]="row.supplierResponse"
      [isPagination]="false"></lib-table-view>
  </div>
</ng-template>

<!-- Pagination Wrapper with 'Items per Page' on the left and Pagination on the right -->
<div class="pagination-container" *ngIf="isPagination">
  <!-- Items per Page Dropdown (Left-aligned) -->
  <div class="items-per-page">
    <label class="medium-sm-text" for="itemsPerPage">Entries per page: </label>
    <lib-dropdown class="custom_select" [data]="itemsPerPageOptions" displayBy="name" value="id" 
    [ngModel]="itemsPerPage" selectionPosition="upWord" (ngModelChange)="onItemsPerPageChange($event)"
    />
    <!-- <select [(ngModel)]="itemsPerPage" (change)="onItemsPerPageChange()" class="custom_select cursor-pointer">
      <option *ngFor="let option of itemsPerPageOptions" [value]="option" class="select_option">
        {{ option }}
      </option>
    </select> -->
    <label class="p-l-10">of {{pagination.totalCount}}</label>
  </div>

  <!-- Pagination Controls (Right-aligned) -->
  <div class="pagination-controls">
    <!-- Go to Page Input -->
    Go to
    <input type="number" [class.error]="!goToPageInput || goToPageInput > totalPages" [(ngModel)]="goToPageInput"
      [min]="1" [max]="totalPages" placeholder="Go to page" class="goto-page-input"
      (ngModelChange)="goToPage(goToPageInput)" [disabled]="!isValidPageInput()" />
    of {{ totalPages }}
    <!-- <button (click)="goToPage(goToPageInput)" [disabled]="!isValidPageInput()">
      Go
    </button> -->

    <!-- Pagination Buttons -->
    <button (click)="goToFirstPage()" [disabled]="currentPage === 1" class="pagination_control">
      <ion-icon src="/assets/icon/chevron-left-double.svg" class="pagination_img" />
    </button>
    <button (click)="previousPage()" [disabled]="currentPage === 1" class="pagination_control">
      <ion-icon src="/assets/icon/chevron-left.svg" class="pagination_img" />
    </button>

    <ng-container *ngFor="let page of getPageNumbers()">
      <button [ngClass]="{ 'active-page': page === currentPage }" (click)="goToPage(page)">
        {{ page }}
      </button>
    </ng-container>

    <button (click)="nextPage()" [disabled]="currentPage === totalPages" class="pagination_control">
      <ion-icon src="/assets/icon/chevron-right.svg" class="pagination_img" />
    </button>
    <button (click)="goToLastPage()" [disabled]="currentPage === totalPages" class="pagination_control">
      <ion-icon src="/assets/icon/chevron-right-double.svg" class="pagination_img" />
    </button>
  </div>
</div>