import { Component, OnInit } from '@angular/core';
import { LanguageService } from '../../shared/services/language.service';
import { CommonService as CommonServices } from 'shared-ui/src/lib/services/common.service';
import { AmenitiesModel, CheckBoxInput, DropDownCommonResponseModel, DropDownInput, DropdownInput, DropDownResponseModel, IAmenities, IMasterAmenities, InputData, LocationItem, RequestInput, SearchDetail, TextAreaInput, ToastModel, ToastModelEnum, ToggleInput, UnitTypeModel } from 'shared-ui/src/lib/models';
import { RequestPropertyModel } from '../../request/models/rfh.model';
import { AppLoaderService, calculateDateDifference, calculateLastDate, CdnContentService, findValueByKey, getSubDomain, GoogleAnalyticsService, GraphQLService, LoggingService, SharedApiService, StorageService } from '@synergy-application/shared-ui';
import { environment } from '../../../../environments/environment';
import configJson from '../../../../assets/json/rfh.instruction.json';
import { AppConstants, DROP_DOWN_TYPES, LeadKey } from 'shared-ui/src/lib/constants/shared-constant';
import { ActivatedRoute, Router } from '@angular/router';
import { ROUTESPATH } from '../../../core/routes-constant';
import { LocalStorageKeys } from 'shared-ui/src/lib/constants/local-storage.constant';
import { BlobName, GoogleAnalyticsKey } from '../../../core/app-constant';
import { RfhService } from '../../request/services/rfh.service';
import { Role } from '../../../enum/common';
import { CommonService } from '../../shared/services/common.service';
import { LogLevel } from 'shared-ui/src/lib/enum/logger';
import formJson from "../../../../assets/json/request.form.json";


@Component({
  selector: 'app-rfh-form',
  templateUrl: './rfh-form.component.html',
  styleUrls: ['./rfh-form.component.scss'],
})
export class RfhFormComponent implements OnInit {

  data: any; // Holds the configuration data for the form

  languageLabel: any; // Holds the language-specific labels for the form

  locationLabel: any;

  requestPropertyModel!: RequestPropertyModel; // Property model for the request

  appConstant = AppConstants;

  googleAnalyticsConstants = GoogleAnalyticsKey;

  instruction = configJson;

  specialRequest!: TextAreaInput;

  checkBoxData!: CheckBoxInput;

  travelerDropdown!: DropdownInput;

  petArea!: TextAreaInput;

  checkBoxForSubmit!: CheckBoxInput;

  sidePadding!: string;

  isTravelerDropdown = true;
  // Getter for amenities to show the first few (controlled by showMore flag)
  get visibleAmenities() {
    return this.showMore ? this.masterAmenitiesType : this.masterAmenitiesType.slice(0, this.initialAmenityCount);
  }

  location = '';
  noOfGuest = 0;
  noOfNights = 0;
  guestCount = 0;
  noOfApartment = 1;
  masterAmenitiesType: AmenitiesModel[] = []; // Holds the list of amenities
  showMore = false; // Flag to toggle showing more amenities
  initialAmenityCount = 10; // Number of amenities to show initially
  objSearchDetails: SearchDetail = {
    locationDetails: {
      name: '',
      placeId: '',
      latitude: 0,
      longitude: 0
    },
    moveIn: "",
    moveOut: ""
  }
  constructor(
    private languageService: LanguageService,
    private commonService: CommonServices,
    private cdnContentService: CdnContentService,
    private loaderService: AppLoaderService,
    private sharedApiService: SharedApiService,
    private rfhService: RfhService,
    private graphqLService:GraphQLService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private storageService: StorageService,
    private commonServiceBookingPortal: CommonService,
    private loggingService: LoggingService
  ) { }

  /**
   * 
   * @param key find the keys
   * @param array assign the array to the key values
   */
  findKey(key: string, array: any) {
    this.data.forEach((k: any) => {
      k.fields.forEach((ele: any) => {
        if (ele.name == key) {
          ele.option = array;
        }
      });
    });
  }

  /**
  *  Get the master amenities
  */
  getMasterAmenities() {
    const request: DropDownInput = {
      request: {
        type: DROP_DOWN_TYPES.AMENITIES,
        pagination: {
          page: 1,
          pageSize: 1000
        }
      }
    }
    this.sharedApiService.fetchDropDownValues(request)
      .then(async (res: DropDownResponseModel) => {
        this.masterAmenitiesType = await Promise.all(res.data.map((el: DropDownCommonResponseModel) => ({
          id: el.key,
          isSelected: false,
          name: el.value,
          svgPath: `${this.cdnContentService.blobServiceUrl}${environment.config.azure.publicContainerName}${el.svgPath}`
        })))

      })
      .catch(ex => {
        this.loggingService.logException(ex, LogLevel.Critical)
      })
      .finally(() => {
      })
  }


  async ngOnInit() {
    this.loaderService.showLoader(true);
    const domain = getSubDomain();
    try {
      // const config = await this.cdnContentService.getBlobData(`${domain}/${BlobName.requestForm}`, environment.config.azure.configContainerName);
      // this.data = JSON.parse(config).sections;
      const config = formJson.sections;
      this.data = config;
      for (const field of this.data) {
        for (const element of field.fields) {
          const userInfo = JSON.parse(this.storageService.get(LocalStorageKeys.userInfo))
          if (element.leadRef == LeadKey.firstName || element.leadRef == LeadKey.lastName) {
            element.value = findValueByKey(userInfo, element.leadRef) ?? '';
          }
          if (element.defaultValue) {
            const request: DropDownInput = {
              request: {
                type: element.name,
                pagination: {
                  page: 1,
                  pageSize: AppConstants.pageLimit
                }
              }
            }

            this.commonService.getDropDownValue(request, element);
          }
          this.isTravelerDropdown = userInfo?.roles?.includes(Role.travelerManager);
        }
      }
      this.loaderService.showLoader(false);
    } catch (error) {
      this.loaderService.showLoader(false);
      this.loggingService.logException(error as Error, LogLevel.Error)
    }
    this.activatedRoute.queryParams.subscribe(async (qParams) => {
      if (qParams['data']) {
        this.objSearchDetails = JSON.parse(atob(qParams['data']));
      }
      if (this.objSearchDetails) {
        this.data?.forEach((e: any) => {
          e.fields.forEach((k: any) => {
            if (k.leadRef == LeadKey.departureDate && this.objSearchDetails.moveOut) {
              k.value = new Date(this.objSearchDetails.moveOut);
              k.selectedDate = this.objSearchDetails.moveOut;
            }
            if (k.leadRef == LeadKey.arrivalDate && this.objSearchDetails.moveIn) {
              k.value = new Date(this.objSearchDetails.moveIn);
              k.selectedDate = this.objSearchDetails.moveIn;
              this.noOfNights = calculateDateDifference(this.objSearchDetails.moveIn, this.objSearchDetails.moveOut)
            }
            if (k.leadRef == LeadKey.location && this.objSearchDetails.locationDetails.name) {
              k.location = {
                latitude: this.objSearchDetails.locationDetails.latitude,
                longitude: this.objSearchDetails.locationDetails.longitude,
                name: this.objSearchDetails.locationDetails.name
              }
              k.value = this.objSearchDetails.locationDetails.name;
              this.location = k.value;
            }
          })
        })
      }
    })

    this.initializeData(); // Initialize form data
    this.getMasterAmenities(); // Fetch amenities
    this.showSideBar();
  }
  /***
   * Get user details by id
   */
  async getUserDetails(event: any, form: DropdownInput) {
    const userProfile = await this.sharedApiService.getUserById(this.graphqLService, event.key);
    this.rfhService.mapToProfile(this.data, userProfile);
  }
  onInputChange(event: InputData | TextAreaInput | ToggleInput) {

    if (event['leadRef'] == this.appConstant.noOfGuest) {
      this.guestCount = event.value;
    }
    if (event['leadRef'] == LeadKey.noOfUnits) {
      this.noOfApartment = event.value;
    }
    this.noOfGuest = this.guestCount * this.noOfApartment;
  }

  showSideBar() {
    this.sidePadding = this.commonServiceBookingPortal.applyLeftPadding()?.sidePadding;
    // Check if any role from userole is in the role array
  }
  selectedLocation(event: LocationItem) {
    this.location = event.name;
  }
  /**
   * Creates a housing lead request based on user input.
   */
  async requestHousing() {
    this.rfhService.requestHousing(this.data, this.languageLabel, this.travelerDropdown, this.checkBoxData, undefined, this.noOfNights, this.masterAmenitiesType)
  }
  /**
   * 
   * @param event 
   */
  onDateChange(event: any) {
    if (event.leadRef == LeadKey.arrivalDate) {
      this.data.forEach((e: any) => {
        e.fields.forEach((k: any) => {
          if (k.leadRef == LeadKey.departureDate) {
            const date = calculateLastDate(1, event.value)
            k.minDate = date.lastDate;
          }
        })
      })
    }
    let from; let to;
    this.data.forEach((e: any) => {
      e.fields.forEach((k: any) => {
        if (k.leadRef == LeadKey.arrivalDate) {
          k.value.setHours(0, 0, 0, 0);
          from = k.value;
        }
        if (k.leadRef == LeadKey.departureDate) {
          to = k.value;
        }
      })
    })
    if (from && to && new Date(from).getTime() >= new Date(to).getTime() && this.data?.length) {
      this.data.forEach((e: any) => {
        e.fields.forEach((k: any) => {
          if (k.leadRef == LeadKey.departureDate) {
            k.value = '';
          }
        })
      })
    }
    if (from && to) {
      this.noOfNights = calculateDateDifference(from, to)
    }
  }
  /***
 * Get dropdown values based on type
 */
  getDropDownValue(event: any, field: any) {
    this.commonService.getDropDownValue(event, field)
  }
  /**
  * Navigate to landing page
  */
  navigateLanding() {
    this.router.navigateByUrl(ROUTESPATH.LANDING);
  }

  /**
   * Initializes form data including language labels.
   */
  initializeData() {
    this.locationLabel = this.languageService.getLandingKey();
    this.languageLabel = this.languageService.getRequestHousing(); // Fetch language labels for the form
    this.checkBoxForSubmit = this.rfhService.getDropdownObj(this.languageLabel).checkBoxForSubmit;
    this.checkBoxData = this.rfhService.getDropdownObj(this.languageLabel).checkboxData;
    this.travelerDropdown = this.rfhService.getDropdownObj(this.languageLabel).travelerDropdown;
  }

  /**
   * Validates a field when it loses focus (focusout).
   * @param {InputData} field - The field to validate.
   */
  focusoutValidation(field: InputData) {
    this.commonService.validateInputFields(field); // Validate the field
  }

  /**
   * Handles cancellation action, returns to the previous page without saving.
   */
  onCancel() {
    // Logic for canceling the form (currently empty)
    this.router.navigateByUrl(ROUTESPATH.LANDING);
  }
}
