<!-- Date range picker with two-month view and readonly input. -->
<div class="datepicker-height" [attr.id]="inputData.leadRef"
    [ngClass]="inputData.label ? 'datepicker-height-label' :''" [class.timeOnly]="inputData.mode === constants.mode.timeOnly">
    <ion-label class="input-text semiBold-sm-text" *ngIf="inputData.label">{{ inputData.label }}
        <span *ngIf="inputData.isRequired" class="red-color-text">*</span></ion-label>
    <div class="display-flex-justify-left align-items date-class" [class.icon-text]="inputData.iconPosition === 'suffix'"
        [class.text-margin]="inputData.iconPosition === 'prefix'"
        [ngClass]="{'date-picker-container': inputData.isBorder === 'border','icon-suffix': inputData.iconPosition === 'suffix'}"
        [ngStyle]="{'height': inputData.height}"
         (click)="toggleCalendar()"
        >

        <p-calendar (onShow)="onShow()" #calendar class="calender-wrapper" *ngIf="inputData.mode === 'range'"
            (click)="$event.stopPropagation();" [numberOfMonths]="numberOfMonths" [minDate]="minDate"
            selectionMode="range" [showIcon]="showIcon" (onSelect)="onDateSelected()" [iconDisplay]="'input'"
            [(ngModel)]="inputData.value" panelStyleClass="picker-style">
            <ng-template pTemplate="inputicon">
                <lib-icon [name]="inputData.iconName ? inputData.iconName :'calendar'" (click)="calendar.toggle()"
                    [className]="inputData.iconSize ? inputData.iconSize  : 'medium picker'"></lib-icon>
            </ng-template>
        </p-calendar>

        <p-calendar (onShow)="onShow()" #calendar class="calender-wrapper" *ngIf="inputData.mode === 'dateWithTime'"
            [numberOfMonths]="1" [minDate]="inputData.minDate" selectionMode="single" [showIcon]="showIcon"
            (onSelect)="onDateSelected()" [iconDisplay]="'input'" [(ngModel)]="inputData.value"
            [showTime]="true" [hourFormat]="'12'"
            [maxDate]="inputData.maxDate ?? undefined" panelStyleClass="picker-style" (click)="$event.stopPropagation();">
            <ng-template pTemplate="inputicon">
                <lib-icon [name]="inputData.iconName ? inputData.iconName :'calendar'" (click)="calendar.toggle()"
                    [className]="inputData.iconSize ? inputData.iconSize  : 'medium picker'"></lib-icon>
            </ng-template>
        </p-calendar>

        <p-calendar (onShow)="onShow()" #calendar class="calender-wrapper" *ngIf="inputData.mode === 'single'"
            [numberOfMonths]="1" [minDate]="inputData.minDate" selectionMode="single" [showIcon]="showIcon"
            (onSelect)="onDateSelected()" [iconDisplay]="'input'" [(ngModel)]="inputData.value"
            [maxDate]="inputData.maxDate ?? undefined" panelStyleClass="picker-style" [defaultDate]="defaultDate"
            (click)="$event.stopPropagation();">
            <ng-template pTemplate="inputicon">
                <lib-icon [name]="inputData.iconName ? inputData.iconName :'calendar'" (click)="calendar.toggle()"
                    [className]="inputData.iconSize ? inputData.iconSize  : 'medium picker'"></lib-icon>
            </ng-template>
        </p-calendar>

        <p-calendar #calendar *ngIf="inputData.mode === constants.mode.timeOnly" class="calender-wrapper"
            [(ngModel)]="inputData.value" [iconDisplay]="'input'" [showIcon]="true" [timeOnly]="true"
            (onSelect)="onDateSelected()" [hourFormat]="'12'">
            <ng-template pTemplate="inputicon">
                <lib-icon [name]="inputData.iconName ? inputData.iconName :'calendar'"
                    [className]="inputData.iconSize ? inputData.iconSize  : 'medium picker'"></lib-icon>
            </ng-template>
        </p-calendar>


        @if (inputData.mode === constants.mode.range) {
        <div class="range-selector">
            <div class="start-date">
                @if(inputData.mode === constants.mode.range && !inputData.value[0]) {
                <label class="label-color-text medium-md-text">{{ constants.moveInPlaceHolder }}</label>
                } @else {
                <ion-text class="label-color-text medium-md-text">{{ inputData.value[0] | date:'mediumDate'
                    }}</ion-text>
                }
            </div>

            <div class="separator"></div>
            <div class="divider">-</div>

            <div class="end-date">
                @if(inputData.mode === constants.mode.range && !inputData.value[1]) {
                <label class="label-color-text medium-md-text">{{ constants.moveOutPlaceHolder }}</label>
                } @else {
                <ion-text class="label-color-text medium-md-text">{{ inputData.value[1] | date:'mediumDate'}}
                </ion-text>
                }
            </div>

        </div>
        }
        @if (inputData.mode !== constants.mode.range && inputData.mode !== constants.mode.timeOnly && 
            inputData.mode !== constants.mode.dateWithTime
        ) {
        <div class="end-date">
            @if(inputData.mode !== constants.mode.range && !inputData.value) {
            <label class="label-color-text medium-md-text">{{ inputData.placeholder }}</label>
            } @else {
            <ion-text class="label-color-text medium-md-text">{{ inputData.value | date:'mediumDate'}} </ion-text>
            }
        </div>
        }

        @if (inputData.mode === constants.mode.dateWithTime) {
        <div class="range-selector">
            @if(inputData.mode === constants.mode.dateWithTime && inputData.value) {
        
            <label class="label-color-text medium-md-text">{{ inputData.value | date: 'fullDate' }} {{ inputData.value | date: 'shortTime' }}</label>
            }
            @else {
            <ion-text class="label-color-text medium-md-text">{{ inputData.placeholder }} </ion-text>
            }
        </div>
        }

        @if (inputData.mode === constants.mode.timeOnly) {
        <div class="range-selector">
            @if(inputData.mode === constants.mode.timeOnly && inputData.value) {

            <label class="label-color-text medium-md-text">{{ inputData.value }} </label>
            }
            @else {
            <ion-text class="label-color-text medium-md-text">{{ inputData.placeholder }} </ion-text>
            }
        </div>
        }
    </div>
    <!--Shows Error message-->
    <small *ngIf="inputData?.isShowErrorMessage && inputData?.showErrorMessage"
        class="red-color-text">{{inputData.showErrorMessage }}</small>
</div>