<div class="left-col">
    <div>
        <img class="login-img" [src]="data.img" alt="" />
    </div>
    <!-- Title Description -->
    <div class="container">
        <h1 class="semiBold-xl-text-22px label">
            {{data.propertyName}}
        </h1>
        <p class="regular-md-text">
            {{data.address}}
        </p>
    </div>


    <ion-grid class="container-grid padding-0px">
        <!-- Date Info-->
        <ion-row class="padding-0px" *ngFor="let info of data.additionInfo">
            <ion-col class="padding-0px" size="6">
                <ion-label class="semiBold-md-text label">{{info.label}}</ion-label>
            </ion-col>
            <ion-col size="6" class="date-container-value padding-0px">
                <ion-icon class="icon" [src]="info.icon">
                </ion-icon>
                <p class="medium-sm-text value">{{info.value}}</p>
            </ion-col>
        </ion-row>

    </ion-grid>
</div>