
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CheckBoxInput } from '../../models';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import {CheckboxSizeModel } from '../../models';
@Component({
  selector: 'lib-checkbox',
  templateUrl: './checkbox.component.html',
  styleUrls: ['./checkbox.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: CheckboxComponent
    }
  ]
})
export class CheckboxComponent implements OnInit, ControlValueAccessor {

  @Input() data: CheckBoxInput = {
    isChecked: false, // Initial state of the checkbox (unchecked).
    label: 'label',
    description: 'description', // Label displayed next to the checkbox.
    size:CheckboxSizeModel.medium //size of the checkbox
  };

  @Input() isDisable:boolean = false;

  @Input() label: string = '';

  @Input() description: string = '';

  // Output event emitter to notify parent components of changes to the checkbox state.
  @Output() selectedValueChange = new EventEmitter<CheckBoxInput>();

  onChange!: (_: any) => void;

  onTouched!: () => void;

  constructor() { }
  writeValue(value: boolean): void {
      this.data.isChecked = value;  // Update the internal data object if the value is provided
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }
  
  registerOnChange(fn: (value: any) => void): void {
    this.onChange = fn;
  }
  
  setDisabledState(isDisabled: boolean): void {
    if (this.data.isDisabled) this.data.isDisabled = isDisabled;
  }

  ngOnInit() {
  }

  /**
   * Handles changes to the checkbox input model.
   * 
   * This method is triggered when the checkbox input's state changes. 
   * It emits the updated value to notify any subscribers of the change.
   *
   * @param data - The current value of the checkbox input, encapsulated in a CheckBoxInput object.
   */
  onModelChange(data: CheckBoxInput) {
    // this.onChange(data);
    this.selectedValueChange.emit(data); // Emit the updated checkbox data.
  }

  onCheckboxBlur(): void {
    this.onTouched();
  }
}

