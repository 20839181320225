import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { BrowserUtils } from '@azure/msal-browser';
import { CorporateLoginComponent } from './modules/login/pages/corporate-login/corporate-login.component';
import { LandingComponent } from './modules/home/components/landing/landing.component';
import { PropertyResultComponent } from './modules/property/pages/property-result/property-result.component';
import { RequestPageComponent } from './modules/request/pages/request-page/request-page.component';
import { FrequentQuestionComponent } from './modules/faq/components/frequent-question/frequent-question.component';
import { PropertyDetailPageComponent } from './modules/property/pages/property-detail-page/property-detail-page.component';
import { PolicyComponent } from './modules/traveler-policy/components/policy/policy.component';
import { ROUTES, ROUTESPATH } from './core/routes-constant';
import { MyHousingRequestComponent } from './modules/booking-history/pages/my-housing-request/my-housing-request.component';
import { ProfilePageComponent } from './modules/profile/pages/profile-page/profile-page.component';
import { RfhFormComponent } from './modules/rfh-form/rfh-form/rfh-form.component';
import { TermsAndServiceComponent } from './modules/traveler-policy/components/terms-and-service/terms-and-service.component';
import { PrivacyPolicyComponent } from './modules/traveler-policy/components/privacy-policy/privacy-policy.component';
import { AuthGuard } from './core/guard/auth.guard';
import { UserManagementComponent } from './modules/user-management/pages/user-management/user-management.component';
import { Role } from './enum/common';

const routes: Routes = [
  { path: ROUTES.LOGIN, component: CorporateLoginComponent},
  { path: ROUTES.LANDING, component: LandingComponent,canActivate: [AuthGuard],data: { permissions: [Role.traveler, Role.admin,Role.superAdmin] }},
  { path: ROUTES.PROPERTYLIST, component: PropertyResultComponent,canActivate: [AuthGuard],data: { permissions: [Role.traveler, Role.admin,Role.superAdmin] }},
  { path: ROUTES.REQUESTFORM, component: RequestPageComponent,canActivate: [AuthGuard] ,data: { permissions: [Role.traveler, Role.admin,Role.superAdmin] }},
  { path: ROUTES.PROPERTYDETAIL, component: PropertyDetailPageComponent,canActivate: [AuthGuard],data: { permissions: [Role.traveler, Role.admin,Role.superAdmin] } },
  { path: ROUTES.FAQ, component: FrequentQuestionComponent,canActivate: [AuthGuard] ,data: { permissions: [Role.traveler, Role.admin,Role.superAdmin] }},
  { path: ROUTES.MYREQUEST, component: MyHousingRequestComponent ,canActivate: [AuthGuard],data: { permissions: [Role.traveler, Role.admin,Role.superAdmin] }},
  { path: ROUTES.PROFILE, component: ProfilePageComponent ,canActivate: [AuthGuard] ,data: { permissions: [Role.traveler, Role.admin,Role.superAdmin] }},
  { path: ROUTES.RFHREQUEST, component: RfhFormComponent,canActivate: [AuthGuard] ,data: { permissions: [Role.traveler, Role.admin,Role.superAdmin] }},
  { path: ROUTES.POLICY, component: PolicyComponent,canActivate: [AuthGuard] ,data: { permissions: [Role.traveler, Role.admin,Role.superAdmin] }},
  { path: ROUTES.TERMSANDSERVICE, component: TermsAndServiceComponent },
  { path: ROUTES.PRIVACYPOLICY, component: PrivacyPolicyComponent },
  { path: ROUTES.USERMANAGEMENT, component: UserManagementComponent ,
    canActivate: [AuthGuard],data: { permissions: [Role.traveler, Role.admin,Role.superAdmin] }},
  // { path: ROUTES.MYRESERVATION, component: MyReservationComponent }, UI TEMPLATE DEISIGN IN PROGRESS - will be used in upcoming release
  { path: '', redirectTo: ROUTESPATH.LOGIN, pathMatch: 'full' },
  { path: '**', component: CorporateLoginComponent }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules }), RouterModule.forRoot(routes, {
      // Don't perform initial navigation in iframe or popups
      initialNavigation:
        !BrowserUtils.isInIframe() && !BrowserUtils.isInPopup()
          ? "enabledNonBlocking"
          : "disabled", // Set to enabledBlocking to use Angular Universal
    }),
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
