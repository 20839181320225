<ion-list class="menu-list" lines="none">
  @for (menu of data; track menu.id) {
  <ion-item class="item" (click)="clickedMenu(menu)">
    @if (menu.icon) {
    <ion-icon class="menu-icon" slot="start" [src]="menu.icon"></ion-icon>
    }
    <ion-label class="label-text" [ngClass]="menu?.color">
      {{ menu.name }}
    </ion-label>

  </ion-item>
  }
</ion-list>