import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ChipStyle } from '../../models/filter.model';

@Component({
  selector: 'lib-chip',
  templateUrl: './chip.component.html',
  styleUrl: './chip.component.scss',
})
export class ChipComponent {
  @Input() value: string = '';

  @Input() closeIcon: boolean = true;

  @Input() styles: ChipStyle = {
    background: 'var(--app-background-chip-color)',
    color: 'var(--app-text-color)',
    radius: '4px'
  }

  @Output() close = new EventEmitter();

  chipClose() { this.close.emit(); }
}
