<ion-grid class="header-bottom" *ngIf="showHeader">
  <ion-row class="header-col">
    <!--Tenant Logo-->
    <ion-col size-sm="8" size-md="4" size-xl="4" size-lg="3" class="logo-img p-0">
      <img *ngIf="!isShowSideMenu" (click)="goToLanding()" [src]="tenantInfo && tenantInfo.logo ? tenantInfo.logo : ''"
        alt="''" class="header-logo cursor-pointer" />
      <div class="bold-xl-text-24px side-header" *ngIf="isShowSideMenu">{{subHeaderText}}</div>
    </ion-col>

    <!--Top nav container-->
    <ion-col size-sm="4" size-md="8" size-xl="8" size-lg="9" class="header-right p-0">
      <div class="medium-md-text ion-hide-lg-down" *ngFor="let data of headerOptions">
        <lib-top-nav *ngIf="!isShowSideMenu" [data]="data"></lib-top-nav>
      </div>
      <div class="v-align-center spacer profile">

        <!-- <div *ngIf="subHeaderText == 'User Management'" class="button-align">
            <lib-button is-secondary class="regular-sm-text"><img class="icon-spacing" [src]=iconPath.bulk alt="">{{userManagementLabel.bulkLabel}}</lib-button>
            <lib-button (click)="addNewUser()" is-primary class="regular-sm-text"><img class="icon-spacing" [src]=iconPath.plus
                alt="">{{userManagementLabel.addNewUserLabel}}</lib-button>
          </div> -->



        <div class="language-container cursor-p  ion-hide-lg-down">
          <img src="/assets/image-card/flag.jpg" alt="">
          <ion-text class="medium-md-text font-color">{{appConstants.enLabel}}</ion-text>
        </div>

        <lib-button class="profile-class dropdown" *ngIf="userInfo && !isShowSideMenu" (click)="onClick(showDropdown)"
          is-secondary>
          <lib-icon class="reorder" [name]="'reorder'"></lib-icon>
          @if (userInfo.firstName) {
          <div class="profile-css">
            <ion-text class="medium-md-text">{{userInfo.firstName.slice(0,!userInfo.lastName ? 2 : 1) |
              uppercase}}{{userInfo.lastName?.slice(0,1) | uppercase}}</ion-text>
          </div>
          } @else {
          <div class="profile-css">
            <ion-text class="medium-md-text">{{userInfo.name.slice(0,2) | uppercase}}</ion-text>
          </div>
          }

        </lib-button>
      </div>

      <div class="dropdown-content profile-content" *ngIf="showDropdown">
        <div class="profile-name" *ngIf="userInfo">
          @if (userInfo.firstName) {
          <div class="profile-css">
            <ion-text class="medium-md-text">{{userInfo.firstName.slice(0,!userInfo.lastName ? 2 : 1) |
              uppercase}}{{userInfo.lastName?.slice(0,1) | uppercase}}</ion-text>
          </div>
          } @else {
          <div class="profile-css">
            <ion-text class="medium-md-text">{{userInfo.name.slice(0,2) | uppercase}}</ion-text>
          </div>
          }
          {{userInfo.name}}
        </div>
        <div *ngFor="let data of profileOption">
          <a (click)="navigateToPage(data)"> <ion-icon class="profile-icon-margin"
              [src]="data.img"></ion-icon>{{data.label}}</a>
        </div>
      </div>
    </ion-col>
  </ion-row>
</ion-grid>