import { Injectable } from '@angular/core';
import { AppLoaderService, CommonService, findValueByKey, GoogleAnalyticsService, LoggingService } from '@synergy-application/shared-ui';
import { AppConstants, DROP_DOWN_TYPES, LeadKey } from 'shared-ui/src/lib/constants/shared-constant';
import { AppConstants as app } from '../../../../app/core/app-constant';
import { GoogleAnalyticsKey } from '../../../core/app-constant';
import { ClientApiService } from '../../../core/client-api.service';
import { ErrorModel, LeadErrorResponse } from '../../../core/api-response-model/lead.model';
import { AlertType, AmenitiesModel, CheckBoxInput, CheckboxSizeModel, DropdownInput, ToastModel, ToastModelEnum, UserProfile } from 'shared-ui/src/lib/models';
import { Router } from '@angular/router';
import { ROUTESPATH } from '../../../core/routes-constant';
import { PropertyDetailRequest } from '../models/rfh.model';
import { LogLevel } from 'shared-ui/src/lib/enum/logger';
import { AlertService } from 'shared-ui/src/lib/services/alert.service';

@Injectable({
  providedIn: 'root'
})
export class RfhService {
  googleAnalyticsConstants = GoogleAnalyticsKey;
  constructor(private alertService: AlertService,private loggingService: LoggingService, private router: Router, private clientApiService: ClientApiService, private commonService: CommonService, private loaderService: AppLoaderService, private googleAnalytics: GoogleAnalyticsService) { }
  /**
  * Creates a housing lead request based on user input.
  */
  async requestHousing(data: any, languageLabel: any, travelerDropdown: DropdownInput, checkBoxData: CheckBoxInput, requestPropertyInfo?: PropertyDetailRequest, noOfNights?: number, amenities?: AmenitiesModel[]) {
    let error = false;
    const request1: any = {};
    data.forEach((k: any) => {
      k.fields.forEach((ele: any) => {
        this.commonService.validateInputFields(ele);
        if (ele.leadRef) {
          if (ele.type == AppConstants.fieldType.toggle) {
            request1[ele.leadRef] = ele.isChecked;
          } if (ele.type == AppConstants.fieldType.checkbox) {
            request1[ele.leadRef] = ele.isChecked;
          } if (ele.type == AppConstants.fieldType.radioOption) {
            request1[ele.leadRef] = ele.selectedOption.name == app.yes;
          } else if (ele.type == AppConstants.fieldType.dropdown) {
            request1[ele.leadRef] = ele.value[ele.uniqueKey];
          } else if (ele.type == AppConstants.fieldType.number && ele.value) {
            request1[ele.leadRef] = Number(ele.value);
          } else if (ele.type == AppConstants.fieldType.locationDropdown && ele.location) {
            request1['latitude'] = ele.location.latitude;
            request1['longitude'] = ele.location.longitude;
            request1['location'] = ele.location.name;
          } else if (ele.value) {
            request1[ele.leadRef] = ele.value;
          }
        }
        if (ele.showErrorMessage && ele.isShowErrorMessage && !error) {
          setTimeout(() => {
            const element = document.getElementById(ele.leadRef);
            if (element) {
              element.scrollIntoView({ behavior: 'smooth', block: 'start' });
            }
          }, 200);
          error = true;
        }
      });
    });
    if (error) return;

    if(request1?.noOfUnits && request1.occupants){
      request1.occupants = request1.occupants * request1?.noOfUnits
    }
    const request: any = {
      leadInfo: {
        los: noOfNights,
        moveInTime: AppConstants.time.moveInTime,
        moveOutTime: AppConstants.time.moveOutTime,
        ...request1 // Spread the user input into the request
      }
    };
    if (requestPropertyInfo?.propertyId) {
      request.leadInfo.propertyId = requestPropertyInfo.propertyId;
    }
    if (requestPropertyInfo?.moveIn) {
      request.leadInfo.arrivalDate = requestPropertyInfo.moveIn;
    }
    if (requestPropertyInfo?.moveOut) {
      request.leadInfo.departureDate = requestPropertyInfo?.moveOut;
    }
    if (requestPropertyInfo?.selectedUnits?.length) {
      request.leadInfo.costPerNight = requestPropertyInfo?.selectedUnits[0].price;
      request.leadInfo.currencyCode = app.dollarSymbol;
    }
    if (checkBoxData?.isChecked) {
      request.leadInfo.bookingFor = travelerDropdown.value.key;
    }
    if (amenities?.length && amenities?.filter(e => e.isSelected)?.length) {
      request.leadInfo.amenities = amenities?.filter(e => e.isSelected).map(e => e.id);
    }
    this.alertService.warning({
      description: languageLabel.confirmationText,
      title: languageLabel.confirmRequest,
      type: AlertType.warning,
      buttons: [
        {
          label: languageLabel.cancel,
          attributes: ['is-secondary', 'is-small'],
        },
        {
          label: languageLabel.ok,
          attributes: ['is-primary', 'is-small'],
          handler: () => {
           this.createLead(request,languageLabel);
          }
        }
      ],
    })
  }
  createLead(request: any,languageLabel: any){
    this.loaderService.showLoader(true);
    this.googleAnalytics.trackEvent(this.googleAnalyticsConstants.requestRateFormSubmission, request.leadInfo) //tracking Events using Google Analytics service

     // Create a lead by calling the API
     this.clientApiService.createLead(request)
     .then((res: LeadErrorResponse) => {
       const toaster: ToastModel = {
         message: '',
         header: languageLabel.toastHeader,
         position: 'top',
         duration: 5000
       };
       if (!res?.errors?.length) {
         // eslint-disable-next-line @typescript-eslint/no-unused-expressions
         toaster.message = `${languageLabel.toastMessage} ${res.data.createLead?.leadId ?? languageLabel.toastMessageSuccess}`,
           this.commonService.presentToast(ToastModelEnum.Success, toaster);
         this.loaderService.showLoader(false);
         this.router.navigate([ROUTESPATH.MYREQUEST]);
       } else {
         toaster.header = languageLabel.errorOccurred;
         toaster.message = res?.errors?.map((e: ErrorModel) => e.message).join(',');
         this.loaderService.showLoader(false);
         this.commonService.presentToast(ToastModelEnum.Error, toaster);
       }
     })
     .catch(error => {
       this.loaderService.showLoader(false);
       this.loggingService.logException(error, LogLevel.Critical)
     });
  }
  getDropdownObj(languageLabel: any) {
    return {
      checkboxData: {
        isChecked: false, // Initial state of the checkbox (unchecked).
        label: languageLabel.bookOnBehalf
      },
      travelerDropdown: {
        placeholder: languageLabel.selectTraveler,
        name: DROP_DOWN_TYPES.TRAVELERS,
        value: '',
        label: languageLabel.selectTravelerPlaceHolder,
        showErrorMessage: languageLabel.travelerErrorMsg,
        isShowErrorMessage: false,
        type: AppConstants.fieldType.dropdown,
        isSearch: true,
        pageLimit: AppConstants.pageLimit,
        allowApiSearch: true,
        multiSelectable: false,
        totalCount: 0,
        leadRef: 'bookingFor',
        options: []
      },
      checkBoxForSubmit: {
        isChecked: false,
        label: `${languageLabel.checkboxOption} <a class="link-css" href="${ROUTESPATH.PRIVACYPOLICY}" target="_blank">${languageLabel.privacyPolicy}</a>,
            <a class="link-css" href="${ROUTESPATH.TERMSANDSERVICE}" target="_blank">${languageLabel.termsAndCondition}</a> ${languageLabel.and}
           <a class="link-css" href="${ROUTESPATH.TERMSANDSERVICE}" target="_blank">${languageLabel.cookiePolicy}</a> .`,
        description: '',
        size: CheckboxSizeModel.medium
      }
    }
  }
  mapToProfile(data:any,mapValue:UserProfile){
    for (const field of data) {
      for (const element of field.fields) {       
        if (element.leadRef == LeadKey.firstName || element.leadRef == LeadKey.lastName) {
        element.value = findValueByKey(mapValue, element.leadRef) ?? '';        
        }
      }
    }
  }
}
