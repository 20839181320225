import { Component, OnInit } from '@angular/core';
import { PropertyDetailRequest, RequestPropertyModel } from '../../models/rfh.model';
import { AppLoaderService, CdnContentService, CommonService, findValueByKey, getSubDomain, GraphQLService, LoggingService, SharedApiService, StorageService } from '@synergy-application/shared-ui';
import { LanguageService } from '../../../shared/services/language.service';
import { LocalStorageKeys } from '../../../shared/constants/local-storage.constant';
import { Router } from '@angular/router';
import { CheckBoxInput, DropdownInput, InputData } from 'shared-ui/src/lib/models';
import { ClientApiService } from '../../../../core/client-api.service';
import { ROUTESPATH } from '../../../../core/routes-constant';
import { BlobName, GoogleAnalyticsKey } from '../../../../core/app-constant';
import { RfhService } from '../../services/rfh.service';
import formJson from "../../../../../assets/json/request.rate.json";
import { DROP_DOWN_TYPES, LeadKey } from 'shared-ui/src/lib/constants/shared-constant';
import { Role } from 'apps/booking-portal/src/app/enum/common';
import { LogLevel } from 'shared-ui/src/lib/enum/logger';
import { environment } from 'apps/booking-portal/src/environments/environment';
import configJson from '../../../../../../../booking-portal/src/assets/json/request.rate.instruction.json';

@Component({
  selector: 'app-request-page',
  templateUrl: './request-page.component.html',
  styleUrl: './request-page.component.scss',
})
export class RequestPageComponent implements OnInit {

  data: any = [];

  checkBoxData!: CheckBoxInput;

  travelerDropdown!: DropdownInput;

  checkBoxForSubmit!: CheckBoxInput;

  requestPropertyInfo!: PropertyDetailRequest;

  requestPropertyModel!: RequestPropertyModel;

  languageLabel: any;

  locationLabel: any;

  googleAnalyticsConstants = GoogleAnalyticsKey;

  instruction = configJson;
  isTravelerDropdown = false;

  constructor
    (private languageService: LanguageService,
      private commonService: CommonService,
      private storageService: StorageService,
      private loaderService: AppLoaderService,
      private cdnContentService: CdnContentService,
      private sharedApiService: SharedApiService,
      private graphqLService: GraphQLService,
      private router: Router,
      private rfhService: RfhService,
      private loggingService: LoggingService
    ) { }
  async ngOnInit() {
    this.initializeData();
    try {
      this.loaderService.showLoader(true);
      const domain = getSubDomain();
      // const config = await this.cdnContentService.getBlobData(`${domain}/${BlobName.requestRate}`, environment.config.azure.configContainerName);
      // this.data = JSON.parse(config).sections;
      const config = formJson.sections;
      this.data = config;
      this.data?.forEach((e: any) => {
        e.fields.forEach((k: any) => {
          if (k.leadRef == LeadKey.location) {
            k.location = {
              latitude: this.requestPropertyInfo.objRequestDetails.lat,
              longitude: this.requestPropertyInfo.objRequestDetails.long,
              name: this.requestPropertyInfo.objRequestDetails.name
            }
          }
        })
      })
      this.loaderService.showLoader(false);
    } catch (error) {
      this.loaderService.showLoader(false);
      this.loggingService.logException(error as Error, LogLevel.Critical)

    }
    const userInfo = JSON.parse(this.storageService.get(LocalStorageKeys.userInfo));
    this.isTravelerDropdown = userInfo?.roles?.includes(Role.travelerManager);
    this.rfhService.mapToProfile(this.data, userInfo)
  }
  /***
    * Get user details by id
    */
  async getUserDetails(event: any, form: DropdownInput) {
    const userProfile = await this.sharedApiService.getUserById(this.graphqLService, event.key);
    this.rfhService.mapToProfile(this.data, userProfile);
  }
  /***
  * Get dropdown values based on type
  */
  getDropDownValue(event: any, field: any) {
    this.commonService.getDropDownValue(event, field)
  }
  /**
   * Create a form Field
   */
  initializeData() {
    if (this.storageService.checkValueExists(LocalStorageKeys.requestProperty)) {
      this.languageLabel = this.languageService.getRequestHousing();
      this.locationLabel = this.languageService.getLandingKey();
      this.checkBoxData = this.rfhService.getDropdownObj(this.languageLabel).checkboxData;
      this.travelerDropdown = this.rfhService.getDropdownObj(this.languageLabel).travelerDropdown;
      this.checkBoxForSubmit = this.rfhService.getDropdownObj(this.languageLabel).checkBoxForSubmit;
      this.requestPropertyInfo = JSON.parse(this.storageService.get(LocalStorageKeys.requestProperty));
    } else {
      this.navigateLanding();
    }
  }

  /**
   * Validates the specified field to ensure it is required 
   * and matches the defined regex pattern.
   * @param {string} field - The field to validate.
   */
  focusoutValidation(field: InputData) {
    this.commonService.validateInputFields(field);
  }

  /**
   * Create a lead based on user input
   */
  async requestRate() {
    this.rfhService.requestHousing(this.data, this.languageLabel, this.travelerDropdown, this.checkBoxData, this.requestPropertyInfo, this.requestPropertyInfo.los);
  }
  /**
   * Navigate to landing page
   */
  navigateLanding() {
    this.router.navigate([ROUTESPATH.LANDING])
  }
  /**
    * Handles cancellation action, returns to the previous page without saving.
    */
  onCancel() {
    this.navigateLanding();
  }
}
