<div class="container">
  <!-- Displays the image for the card using the URL from cardData -->
  @if (cardData.imageUrl) {
  <div>
    <img [src]="cardData.imageUrl" alt="" />
  </div>
  }

  <!-- Grid layout for the card overlay, adjusting width based on provided width property -->
  <div class="custom-overlay-image font-color"
    [ngStyle]="{ 'width': width ? width : '100%','word-wrap' : width ? ' break-word' : '' }">

    <!-- Displays the title of the card -->
    <div *ngIf="cardData.title"><ion-text class="bold-xl-text-20px">{{cardData.title}}</ion-text>
    </div>

    <!-- Displays the heading text of the card -->
    <div *ngIf="cardData.headingText">
      <ion-text class="semiBold-xl-text-36px">{{cardData.headingText}}</ion-text>
    </div>

    <!-- Displays the description text of the card -->
    <div *ngIf="cardData.descriptionText" class="regular-lg-text"><ion-text
        class="regular-lg-text">{{cardData.descriptionText}}</ion-text>
    </div>
    <div *ngIf="cardData.details" class="regular-lg-text">
      <ion-text class="regular-lg-text">{{cardData.details}}</ion-text>
    </div>

    <!-- Displays a button if buttonText is provided in cardData -->
    <div *ngIf="cardData.buttonText">
      <ion-button ion-button class="primary-button semiBold-md-text" (click)="onButtonClick(cardData.url)">
        {{cardData.buttonText}}
        <!-- <ion-icon name="arrow-forward-outline"></ion-icon> -->
      </ion-button>
    </div>
  </div>
</div>