<!-- My-housing-Request ->  -->

<ion-content scroll-y="false" [fullscreen]="true" class="housing-class">
    <div class="housing-scroll housing-card pad-bottom-10 {{sidePadding}}">
        <ion-label class="semiBold-xl-text-24px plr-40 ">{{housingLabel.housingLabel ?? ''}}</ion-label>
        <lib-tab class="plr-40 pad-top-24" [tabInput]="tabLabel" [count]="pagination.totalCount"
            (dataToParent)="onTabChange($event)"></lib-tab>

        <!-- search , toggle and dropdown   -->
        <ion-row *ngIf="selectedTab" class="plr-40 pad-top-24">
            <ion-col class="pad-0">

                <!-- Search Component -->
                <div class="search-width">
                    <lib-search (searchChange)=onSearch($event)
                        [searchQuery]="selectedTab.id === 1 ? activeSearchQuery : pastSearchQuery"
                        [placeholder]="housingLabel.requestPlaceholder"></lib-search>
                </div>
                <!-- Search Component -->
            </ion-col>
            <ion-col class="flex-end pad-0">

                <!-- Toggle Component  -->
                <div *ngIf="selfReservationOnly">
                    <lib-toggle class="medium-sm-text" (toggleChange)=onToggleChange($event)
                        [data]="toggleDetails"></lib-toggle>
                </div>
                <!-- Toggle Component  -->
                <!-- Dropdown Component  -->
                <div class="dropdown-class" *ngIf="selectedTab && selectedTab.key">
                    <lib-dropdown [(ngModel)]="optionValue[selectedTab.key]" [data]="options" displayBy="name"
                        value="id" (ngModelChange)="dropdownBy($event)" ngDefaultControl>
                    </lib-dropdown>
                </div>
                <!-- Dropdown Component  -->
            </ion-col>
        </ion-row>
        <!-- Request details Card -->
        <div class="lazy-scroll plr-40 pad-top-24 pad-bottom-10 spacer" (scroll)="onScroll($event)">
            <div *ngFor="let housing of myBookingList">
                <ion-card class="property-card-align" *ngIf="housing.requestNumber">
                    <ion-col size="3" *ngIf="housing.propertyImage" class="ion-no-padding">
                        <div class="image-content">
                            <img class='image-class ' [src]="housing.propertyImage" alt="">
                        </div>
                    </ion-col>
                    <ion-col class="ion-no-padding col-align">
                        <ion-row [ngClass]="isMobile ? 'chip-mobile-align' : 'row-align'">
                            <div *ngIf="housing.requestNumber">
                                <ion-label class="semiBold-xl-text-20px">{{housingLabel.requestNumberLabel ?? ""}}:
                                    {{housing.requestNumber}}</ion-label>
                            </div>

                            <!-- Status Chip (Pending/Accepted/Denied/Cancelled) -->
                            <div class="flex-end">
                                <ion-chip class="medium-sm-text"
                                    [ngClass]="{'pending-class': housing.status === status.Pending || housing.status === status.Unassigned, 'accepted-class': housing.status === status.Accepted, 'denied-class': housing.status === status.Denied, 'cancel-class': housing.status === status.Cancelled}">
                                    <div
                                        [ngClass]="{'pending-dot': housing.status === status.Pending || housing.status === status.Unassigned, 'accepted-dot': housing.status === status.Accepted, 'denied-dot': housing.status === status.Denied,'cancel-dot': housing.status === status.Cancelled}">
                                    </div>
                                    {{ getStatusMessage(housing.status) }}
                                </ion-chip>
                            </div>
                            <!-- Status Chip (Pending/Accepted/Denied/Cancelled) -->
                        </ion-row>
                        <ion-row class="row-align">

                            <!-- Address and Units/Guests Information -->
                            <div class="right-col-align">
                                <div *ngIf="!housing.propertyImage">
                                    <ion-label class="semiBold-xl-text-20px gap-align"><img class="image-align"
                                            src="/assets/icon/location.svg" alt="">
                                        {{housing.country}}</ion-label>
                                    <div class="gap-align top_10">
                                        <ion-label class="medium-md-text">{{housingLabel.unitLabel ?? '' }}:
                                            {{housing.units}}</ion-label>
                                        <div class="grey-dot"></div>
                                        <ion-label class="medium-md-text">{{housingLabel.guestLabel ?? '' }}:
                                            {{housing.guests}}</ion-label>
                                    </div>
                                </div>

                                <div class="right-col-align" *ngIf="housing.propertyImage">
                                    <div class="property-img">
                                        <ion-label class="medium-sm-text">{{housingLabel.guestName ?? ''
                                            }}: {{housing.guestName}}</ion-label>
                                        <ion-label class="semiBold-md-text">{{housing.propertyName}}</ion-label>
                                        <ion-label class="medium-md-text">{{housing.propertyAddress}}</ion-label>
                                    </div>
                                </div>


                                <!-- Date Details Component -->
                                <div class="align-class" class="align-class">
                                    <app-days-and-date *ngIf="housing && housing.dateDetails"
                                        [dateDetails]="housing.dateDetails"></app-days-and-date>

                                </div>
                                <!-- Date Details Component -->
                            </div>
                            <!-- Address and Units/Guests Information -->
                            <div class="left-col-align">
                                <div *ngIf="housing.budget">
                                    <ion-label class="regular-md-text">{{housingLabel.amountLabel}}</ion-label>
                                </div>
                                <div *ngIf="housing.budget">
                                    <ion-label
                                        class="semiBold-xl-text-24px">{{housing.currencyCode}}{{housing.budget}}</ion-label>
                                </div>
                                <div *ngIf="housing.status === status.Accepted">
                                    <lib-button is-primary is-medium>{{housingLabel.buttonLabel ?? ''}}</lib-button>
                                </div>
                                <a *ngIf="housing.status === status.Accepted || housing.status === status.Pending"
                                    (click)="cancelRequest()"
                                    class="anchor-class semiBold-md-text">{{housingLabel.cancelLabel}}</a>
                            </div>
                            <!-- <div *ngIf="housing.propertyImage && housing.status !== status.Cancelled">
                                <a class="anchor-class semiBold-md-text">{{housingLabel.modifyLabel}}</a>
                                <a class="anchor-class semiBold-md-text">{{housingLabel.cancelLabel}}</a>
                            </div> -->
                            <ion-col class="right-col-align ion-no-padding" *ngIf="isMobile">
                                <div class="mobile-view-align"
                                    *ngIf="housing.status === status.Accepted || housing.status === status.Pending">

                                    <!-- Cancel Request Link -->
                                    <a class="anchor-class semiBold-md-text">{{housingLabel.cancelLabel ?? '' }}</a>
                                </div>
                                <div class="space-between-align">
                                    <div class="right-col-align" *ngIf="housing.budget != null && housing.budget !== ''">
                                        <ion-label class="regular-md-text">{{housingLabel.amountLabel ?? ''}}</ion-label>
                                        <ion-label class="semiBold-xl-text-24px">{{housing.budget}}</ion-label>
                                    </div>
                                    <div *ngIf="housing.viewHousingOption" class="mobile-view-flex-end">
                                        <lib-button is-primary is-medium>{{housingLabel.buttonLabel}}</lib-button>
                                    </div>
                                </div>
                            </ion-col>
                        </ion-row>
                        <ion-row class="denial-class" *ngIf="housing.isRequestDenied">
                            <div>
                                <ion-label class="bold-sm-text">{{housingLabel.DenialLabel}}</ion-label>
                            </div>
                            <div>
                                <span class="regular-md-text">{{housing.requestDeniedReason}}</span>
                            </div>
                        </ion-row>
                    </ion-col>

                </ion-card>
            </div>
            @if (lazyLoader) {
            <div class="bubble-loader">
                <ion-spinner class="spinner" name="bubbles"></ion-spinner>
            </div>
            }
        </div>
    </div>
</ion-content>