<ion-row class="align-items">
    <ion-col size-xs="12" size-sm="12" size-md="12" size-lg="4" size-xl="4" *ngIf="search">
        <ion-row>
            <ion-col size-xs="12" size-sm="12" size-md="12" size-lg="10" size-xl="10">
                <lib-search class="search_container" [placeholder]="language.leadList.searchPlaceHolder" [style]="searchStyle" (searchChange)="searchModelChange($event)" (cancel)="searchModelChange('')"/>
            </ion-col>
            <ion-col size-lg="2" size-xl="2"></ion-col>
        </ion-row>
    </ion-col>
    
    <ion-col size-xs="12" size-sm="12" size-md="12" [attr.size-lg]="(size | addMoreFilter:search:data.length) | dynamicColumn" 
    [attr.size-xl]="(size | addMoreFilter:search:data.length) | dynamicColumn">
        <ion-row class="align-items-end p_t_b_10">
        <ng-container *ngFor="let item of slicedData; trackBy: trackByName">
            <ion-col size-xs="12" size-sm="12" size-md="12" 
            [attr.size-lg]="size | filterSize" 
            [attr.size-xl]="size | filterSize"  
            *ngIf="item.type === 'dropDown'"
            >
                <lib-dropdown [label]="item.displayName ?? ''" [placeholder]="item.placeHolder ?? ''" />
            </ion-col>
            <ion-col size-xs="12" size-sm="12" size-md="12" [attr.size-lg]="size | filterSize" 
            [attr.size-xl]="size | filterSize" class="m-t-15" *ngIf="item.type === 'toggle'">
            <ion-row class="ion-no-padding">
                <ion-col size-lg="3" size-xl="3" />
                <ion-col size-xs="12" size-sm="12" size-md="12" size-lg="8.5" size-xl="8.5">
                    <lib-toggle class="medium-sm-text" [data]="item.value"
                (toggleChange)="onToggleChange($event)" />
            </ion-col>
            <ion-col size-lg="0.5" size-xl="0.5" />
            </ion-row>
            </ion-col>
        </ng-container>
        </ion-row>
    </ion-col>
    <ion-col size-xs="12" size-sm="12" size-md="12" size-lg="1.3" size-xl="1.3" class="m-t-15" *ngIf="!(size | addMoreFilter:search:data.length)">
        <lib-button is-borderOnly is-medium [icon]="'/assets/icon/filter-icon.svg'" [image]="false" [isSelected]="false" (click)="moreFilterClicked()">Filters</lib-button>
    </ion-col>
</ion-row>