import { Injectable } from '@angular/core';
import { AppLoaderService, findValueByKey, GraphQLService, SharedApiService } from '@synergy-application/shared-ui';
import * as profile from "../../../../assets/json/user.form.json";
import { FieldModel, UserProfile } from 'shared-ui/src/lib/models';
@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(private graphqLService: GraphQLService, private sharedApiService: SharedApiService, private loaderService: AppLoaderService) { }

  async mapToResponse(data?: any) {
    this.loaderService.showLoader(true);
    try {
      const editData = (profile as any).default;
      if (data) {
        const apiProfileResponseValue:UserProfile = await this.sharedApiService.getUserById(this.graphqLService, data.id);  
        editData.userDynamicForm[0].sections[0].fields.forEach((field: any) => {
          const leadRef = field.leadRef;
          const result = findValueByKey(apiProfileResponseValue, leadRef);
          field.value = result ?? '';
        });
        return {
          ...editData.userDynamicForm[0],
          apiProfileResponseValue
        };
      }
      return {
        ...editData.userDynamicForm[0]
      };
      
    } catch (error) {
      this.loaderService.showLoader(false);
    } finally {
      this.loaderService.showLoader(false);
    }
    return ''
  }
  
}
