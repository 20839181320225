import { Injectable } from '@angular/core';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { LogLevel } from '../enum/logger';
import { ConfigService } from './config.service';

@Injectable({
  providedIn: 'root'
})
export class LoggingService {

  private appInsights!: ApplicationInsights;

  public static instance: LoggingService | null = null;

  constructor(private configService: ConfigService) {

    let config = this.configService.getConfig;

    if (LoggingService.instance) return LoggingService.instance;

    this.appInsights = new ApplicationInsights({
      config: {
        instrumentationKey: config.appInsights,
        enableAutoRouteTracking: true // option to log all route changes
      }
    });
    this.appInsights.loadAppInsights();
    this.trackLoadEvent();

    LoggingService.instance = this;
  }

  private trackLoadEvent() {
    let config = this.configService.getConfig;
    if (config.isLogEnable) {
      this.appInsights.trackEvent({ name: 'Application Loaded' });
    }
  }

  /**
   * Logs a page view.
   * @param name - Page name.
   * @param url - URL of the page.
   */
  logPageView(name?: string, url?: string) {
    let config = this.configService.getConfig;
    if (config.isLogEnable) {
      this.appInsights.trackPageView({
        name: name || 'Unnamed Page',
        uri: url || ''
      });
    }
  }

  /**
   * Logs an event.
   * @param name - Event name.
   * @param properties - Additional data related to the event.
   */
  logEvent(name: string, properties?: Record<string, unknown>) {
    let config = this.configService.getConfig;
    if (config.isLogEnable) {
      this.appInsights.trackEvent({ name }, properties);
    }
  }

  /**
   * Logs a metric.
   * @param name - Metric name.
   * @param average - Average value of the metric.
   * @param properties - Additional data related to the metric.
   */
  logMetric(name: string, average: number, properties?: Record<string, unknown>) {
    let config = this.configService.getConfig;
    if (config.isLogEnable) {
      this.appInsights.trackMetric({ name, average }, properties);
    }
  }

  /**
   * Logs an exception.
   * @param exception - The captured exception.
   * @param severityLevel - Severity level (optional).
   */
  logException(exception: Error, severityLevel: number = LogLevel.Error) {
    let config = this.configService.getConfig;
    if (config.isLogEnable) {
      this.appInsights.trackException({ exception, severityLevel });
    }
  }

  /**
   * Logs a trace message.
   * @param message - Trace message.
   * @param properties - Additional data related to the trace.
   */
  logTrace(message: string, properties?: Record<string, unknown>) {
    let config = this.configService.getConfig;
    if (config.isLogEnable) {
      this.appInsights.trackTrace({ message }, properties);
    }
  }
}
