<ion-grid class="nav-bar">
    <div class="logo">
        <ion-icon class="logo-img" [src]="navBarData.client.icon"></ion-icon>
        <div class="floater">
            <ion-icon class="arrow" name="chevron-forward-outline"></ion-icon>
        </div>
    </div>
    @for(item of navBarData.navItem; track item.label) {
        <div [class.active]="item.isSelected" class="nav-item">
            <ion-icon class="menu-icon" [src]="item.icon"></ion-icon>
        </div>
    }
</ion-grid>