import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { FilterFieldModel } from '../models';
import { Observable } from '@apollo/client';

@Injectable({
  providedIn: 'root'
})
export class FilterService {

  private filterDataSubject = new Subject<FilterFieldModel[]>();

  setFilter(data: FilterFieldModel[]) {
    this.filterDataSubject.next(data);
  }

  getFilter() {
    return this.filterDataSubject.asObservable();
  }
}
