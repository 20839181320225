import { Component, Input, OnInit } from '@angular/core';
import { CommonService } from '@synergy-application/shared-ui';
import { AppConstants } from 'apps/booking-portal/src/app/core/app-constant';
import { ImagePath } from 'shared-ui/src/lib/constants/shared-constant';
import { IPropertyDetail, ToastModel, ToastModelEnum } from 'shared-ui/src/lib/models';
import { SharePropertyComponent } from '../../../shared/components/share-property/share-property.component';
import { ModalController } from '@ionic/angular';


@Component({
  selector: 'app-info-detail',
  templateUrl: './property-info.component.html',
  styleUrl: './property-info.component.scss',
})
export class PropertyInfoComponent implements OnInit {

  @Input() propertyData!: IPropertyDetail;
  @Input() propertyLabel: any;
  copiedMessage = '';
  appConstants = AppConstants;
  iconPath = ImagePath;
  
  constructor(private commonService: CommonService, private modalController: ModalController) { }
  ngOnInit(): void {

  }

  /**
 * Copy the URL
 */
  async copyToClipboard() {
    try {
      const urlToCopy = window.location.href;
      const modal = await this.modalController.create({
        component: SharePropertyComponent,
        cssClass: 'share-property-popup',
        componentProps: {
          url: urlToCopy
        }
      });
      modal.onDidDismiss().then(async (data) => {
        if (data.data) await navigator.clipboard.writeText(urlToCopy);
      });
  
      await modal.present();
    } catch (err) {
    }
  }
  

}