<!--Popover details-->
<div class="custom-popover">
  <ion-grid>
    <ion-row *ngFor="let data of popoverData">
      <ion-col class="regular-md-text">
        {{data.key}}
      </ion-col>
      <ion-col *ngIf="data.value" class="regular-md-text" [innerHTML]="data.value">
      </ion-col>
    </ion-row>
  </ion-grid>
</div>