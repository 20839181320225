import { Component } from '@angular/core';
import { LanguageService } from '../../../shared/services/language.service';
import { CdnContentService } from '../../../../../../../../shared-ui/src/lib/services/cdn-content.service';
import { AppLoaderService, getSubDomain, LoginService, SharedApiService } from '@synergy-application/shared-ui';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { environment } from 'apps/booking-portal/src/environments/environment';
import { BlobName } from 'apps/booking-portal/src/app/core/app-constant';

@Component({
  selector: 'app-terms-and-service',
  templateUrl: './terms-and-service.component.html',
  styleUrl: './terms-and-service.component.scss',
})
export class TermsAndServiceComponent {
  constructor(private loginService:LoginService,private languageService:LanguageService,private cdnContentService: CdnContentService,private loaderService: AppLoaderService,
    private domSanitizer: DomSanitizer) { }
    languageLabel : any;
    safeHTML!: SafeHtml;
  ngOnInit() {
    this.loginService.headerSub.next(false);
    this.getTermsAndServiceHTML()
  }

  /**
  * Retrieves and returns the HTML content for the travel policy section.
  */
  async getTermsAndServiceHTML() {
    this.languageLabel = this.languageService.getPolicyKey();
    this.loaderService.showLoader(true);
    // let token = await this.sharedApiService.getSasToken(BlobAccess.read);
    let domain = getSubDomain();
    await this.cdnContentService.getBlobData(`${domain}/${BlobName.termsAndCondition}`, environment.config.azure.configContainerName)
      .then((data: any) => {
        this.loaderService.showLoader(false);
        this.safeHTML = this.domSanitizer.bypassSecurityTrustHtml(data);
      })
      .catch(error => {
        this.loaderService.showLoader(false);
      })
  }
}
