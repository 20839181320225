<ng-container *ngIf="data">
  <ng-container *ngFor="let group of object.keys(data)" [formGroup]="formGroup">
    <ion-grid class="pad-0 top-grid" [formGroupName]="group">
      <div (click)="openAccordian(group)" class="label-color-text container">
        <ion-label class="label-color-text semiBold-xl-text-20px">{{ data[group].header }}</ion-label>
        <ion-icon class="accordian" [class.rotate-icon]="data[group]?.close ?? false" [src]="imagePath.downArrow"></ion-icon>
      </div>
      <ng-container *ngIf="group === 'companyDetails'" [ngTemplateOutlet]="companyTemplateForm"></ng-container>
      <div [class.close-accordian]="data[group]?.close ?? false" class="grid-template" *ngIf="group !== 'companyDetails'">
        <ng-container *ngFor="let field of data[group].fieldType">
          <div size="4" class="pad-0">
            <ng-container [ngSwitch]="field.type">
              <ng-container *ngSwitchCase="constants.fieldType.dropdown">
                <lib-dropdown [label]="field.label" [data]="field.options" [value]="field.value"
                  [required]="field.isRequired" (ngClick)="loadDropDownValues($event, field)"
                  [loadOnDemand]="field.loadOnDemand ?? false" [displayBy]="field.displayBy" [value]="field.uniqueId"
                  [pageLimit]="10" [name]="field.name" [searchable]="field.searchable"
                  [allowApiSearch]="field.allowApiSearch" [ngModel]="getControl(group, field.name)?.value ?? null"
                  (ngScroll)="loadDropDownValues($event, field)" (ngSearch)="loadDropDownValues($event, field)"
                  [totalCount]="field.totalCount" [formControlName]="field.name"></lib-dropdown>
              </ng-container>
              <ng-container *ngSwitchCase="constants.fieldType.number">
                <lib-input [inputData]="field" [showArrow]="field.arrow" [formControlName]="field.name"></lib-input>
              </ng-container>
              <ng-container *ngSwitchCase="constants.fieldType.text">
                <lib-input [inputData]="field" [formControlName]="field.name"></lib-input>
              </ng-container>
              <ng-container *ngSwitchCase="constants.fieldType.date">
                <lib-date-picker [inputData]="field" [defaultDate]="field.minDate" [formControlName]="field.name"></lib-date-picker>
              </ng-container>
              <ng-container *ngSwitchCase="constants.fieldType.locationDropdown">
                <lib-location-dropdown [language]="language" [label]="field.label" [inputData]="field"
                  (locationSearch)="locationSearch($event, field)" 
                  [useClientLocation]="false"
                  [formControlName]="field.name"></lib-location-dropdown>
              </ng-container>
              <ng-container *ngSwitchCase="constants.fieldType.textarea">
                <lib-text-area [inputData]="field" [formControlName]="field.name"></lib-text-area>
              </ng-container>
              <ng-container *ngSwitchCase="constants.fieldType.radioOption">
                <lib-radio-button [label]="field.label" [data]="field.options" [formControlName]="field.name"
                  [isDefaultSelect]="field.value !== null && field.value !== undefined"
                  [selectedOption]="field.value !== null && field.value !== undefined ? field.value ? field.options[0] : field.options[1] : undefined"
                  [isDisabled]="field.disabled ?? false"
                  ></lib-radio-button>
              </ng-container>
              <ng-container *ngSwitchCase="constants.fieldType.timeOnly">
                <lib-date-picker [inputData]="field" [formControlName]="field.name"></lib-date-picker>
              </ng-container>
            </ng-container>
          </div>
        </ng-container>
      </div>
    </ion-grid>
  </ng-container>
</ng-container>
