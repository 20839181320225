import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterSize',
  pure: true
})
export class FilterSizePipe implements PipeTransform {
  transform(value: number = 12): number {
    if (value == 1) return 4;  // If the size count is 1 then remove the empty space
    return (12 / value);
  }
}
