import { Alignment, ChipStatus, ColumType, LeadStatus, Position, ValueType } from "../enum/table"
import { DropDownType } from "./common.model"

export type TTableColumn = {
    name: string,

    displayName: string,

    apiKey: string,

    sortable?: boolean,

    type: ColumType,

    dateFormat?: string,

    valueType?: ValueType,

    valueName?: string,

    svg?: string,

    iconName?: string,

    helperText?: string | null,

    textColor?: string,

    highlightText?: string | null,

    coloredPart?: string | null,

    appendText?: string | null,

    appendColor?: string | null,

    width?: string,

    disable?: boolean,

    placeHolder?:string,

    value?: unknown,

    highlightedKey?: string,

    alignment?: Alignment,

    chipStatusKey?: string,

    chipTextKey?: string,

    chipCountKey?: string,

    colorKey?: string,

    defaultSelect?:string,

    secondDisplayValue?:string,

    isTwoLabels?:boolean | string,

    iconDisableKey?: string,

    checkBoxDisableKey?: string,

    dropDownType?: DropDownType,

    statusConfig?:  Record<string | number, { label: string, class: string }>
}

export type TSort = {
    data: TTableColumn,

    sortDirection: string
}

export type TCheckBoxChangeEvent = {
    data: unknown,

    state: boolean
}

export interface TableStyleConfig {
    backgroundColor?: string;

    borderColor?: string;

    hoverColor?: string;

    textColor?: string;

    borderRadius?: string;

    borderBottom?: string;
  }
  
export const LeadStatusDescription: Record<LeadStatus, string> = {
    [LeadStatus.PENDING]: 'Pending',
    [LeadStatus.DENIED]: 'Denied',
    [LeadStatus.CANCELLED]: 'Cancelled',
    [LeadStatus.UNASSIGNED]: "Unassigned",
    [LeadStatus.BOOKINGCREATED]: 'Booking Created',
    [LeadStatus.SUBMITTED]: 'Submitted',
    [LeadStatus.DECLINEDTOBID]: 'Declined To Bid',
    [LeadStatus.PRESENTED]: 'Presented',
    [LeadStatus.MISSEDBIDDEADLINE]:'Missed Bid Deadline',
    [LeadStatus.CHOSEN]: 'Chosen'
};

export interface TableModel {
    color?: string;

    iconPath?: string;

    iconName?: string;

    iconColor?: string;

    iconPosition?: Position;

    chipStatus?: ChipStatus;

    chipText?: string;

    isLink?: boolean;

    chipCountKey?: number;
}

export type GetFilterPayload = {
    pagination: {
        page: number,
        pageSize: number
    },
    type: string,

    search?: string
}
