import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CookieService {

  // Set a cookie
  setCookie(name: string, value: string, secure = true, sameSite: 'Strict' | 'Lax' | 'None' = 'Strict', hours?: number, days?: number,): void {
    let expires = '';
    if (days) {
      const date = new Date();
      if(days) date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000)); // Set expiration date for days
      if (hours) date.setTime(date.getTime() + (hours * 60 * 60 * 1000)); // Set expiration date for hours
      expires = `; expires=${date.toUTCString()}`; // Format expiration date
    }
    
    // Construct the cookie string
    const cookieString = `${name}=${value};${expires}; path=/; SameSite=${sameSite}${secure ? '; Secure' : ''}`;
    document.cookie = cookieString; // Set the cookie
  }

   // Get a cookie by name
   getCookie(name: string): string | null {
    const value = `; ${document.cookie}`; // Prepare the cookie string for searching
    const parts = value.split(`; ${name}=`); // Split on the target cookie name

    // Check if the parts array is valid
    if (parts.length === 2) {
      // Return the cookie value, ensuring it's defined before accessing
      const cookieValue = parts.pop()?.split(';').shift(); // Optional chaining to safely access the value
      return cookieValue ?? null; // Return null if the cookie value is undefined
    }
    
    return null; // Return null if the cookie doesn't exist
  }

  // Delete a cookie
  deleteCookie(name: string): void {
    // this.setCookie(name, '', -1); // Set cookie with a past expiration date to delete it
  }
}
