import { Component, OnInit, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LanguageService } from '../../services/language.service';
import { IconPath } from 'apps/booking-portal/src/app/core/app-constant';
import { ImagePath } from 'shared-ui/src/lib/constants/shared-constant';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-share-property',
  templateUrl: './share-property.component.html',
  styleUrl: './share-property.component.scss',
})
export class SharePropertyComponent implements OnInit {
  languageLabel: any;

  iconPath = IconPath;
  iconPicture = ImagePath;
  @Input() url!: string;
  constructor(private languageService: LanguageService, private modalCtrl : ModalController) {

  }

  ngOnInit() {
    this.languageLabel = this.languageService.getShareProperty();
  }

  onCancel() {
    this.modalCtrl.dismiss(false);
  }

  onCopy(){
    this.modalCtrl.dismiss(true);
  }
}
