<div class="toggle" [ngClass]="disabled ? 'isDisabled' : ''" *ngIf="data">
  <div class="toggle-label-container">
    <ion-label class="medium-sm-text label" *ngIf="data.label">{{ data.label }}</ion-label>

    <!-- Icon with a tooltip -->
    @if (data.infoMsg) {
    <div class="icon-container">
      <ion-icon name="information-circle" class="info-icon"></ion-icon>
      <div class="tooltip">{{data.infoMsg}}</div>
    </div>
    }

  </div>
<span *ngIf="data.leftInfo">{{data.leftInfo}}</span>
  <ion-toggle [(ngModel)]="data.isChecked" (ngModelChange)="onToggleChange(data)" [disabled]="disabled"></ion-toggle>
  <span *ngIf="data.rightInfo">{{data.rightInfo}}</span>
</div>