import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'addMoreFilter',
  pure: true,
})
export class AddMoreFilterPipe implements PipeTransform {
  transform(value: number, search: boolean, dataSize: number): boolean {
    return (value >= (search ? dataSize - 1 : dataSize));
  }
}
