import { Component, HostListener, Input, OnInit } from '@angular/core';
import { environment } from '../environments/environment';
import { ThemeService } from './modules/shared/services/brand.service';
import { BlobName } from './core/app-constant';
import { ROUTESPATH } from './core/routes-constant';
import { CdnContentService, getSubDomain, LoggingService, LoginService, StorageService } from '@synergy-application/shared-ui';
import { AlertType, CorporateLogin, UserProfile } from 'shared-ui/src/lib/models';
import { LocalStorageKeys } from './modules/shared/constants/local-storage.constant';
import sideNavDataList from '../../../booking-portal/src/assets/mock/side-nav.mock.json';
import { Router } from '@angular/router';
import { AlertService } from 'shared-ui/src/lib/services/alert.service';
import { checkTab } from 'shared-ui/src/lib/core/helper';
import { LogLevel } from 'shared-ui/src/lib/enum/logger';
import { CommonService } from './modules/shared/services/common.service';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
})
export class AppComponent implements OnInit {
  @Input() tenantInfo!: CorporateLogin;
  @Input() userInfo!: UserProfile;
  sideBar = sideNavDataList;
  showHeader = false;
  isShowSideMenu = false;
  isMobile = false;

  constructor(private loggingService: LoggingService,private alertService: AlertService, private router: Router, private themeService: ThemeService, private loginService: LoginService, private storageService: StorageService, private cdnContentService: CdnContentService, private commonService: CommonService) {
  }

  // On initialization of the page, display the page elements based on the user state
  ngOnInit(): void {
    this.isMobile = checkTab();
    if ((window.location.pathname == ROUTESPATH.LOGIN) || window.location.pathname == '/' || !window.location.pathname) {
      this.showHeader = false;
      this.isShowSideMenu = false;
    } else {
      this.showHeader = true;
      this.showSideBar();
    }
    this.getProfile();
    this.loginService.showHeader().subscribe((options) => {
      this.showHeader = options;
      this.getProfile();
    });
    this.themeService.applyDefaultTheme();
    this.loadClientTheme();
  }
  getProfile() {
    if (this.storageService.checkValueExists(LocalStorageKeys.userInfo)) {
      this.userInfo = JSON.parse(this.storageService.get(LocalStorageKeys.userInfo));
      this.sideBar.profileDetails.name = `${this.userInfo.firstName} ${this.userInfo.lastName || ''}`;
      this.sideBar.profileDetails.role = this.userInfo.roles.join(',');
      this.tenantInfo = JSON.parse(this.storageService.get(LocalStorageKeys.tenantInfo));
      this.showSideBar();
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: Event): void {
    this.isMobile = checkTab();
  }
  
  /**
   * Show the side nave based on role
   */
  showSideBar() {
    this.isShowSideMenu = this.commonService.sideMenuShow();
  }

  /**
   * Get the client data and load the theme based on given
   */
  loadClientTheme() {
    const domain = getSubDomain();
    this.cdnContentService.getClientData(`${domain}/${BlobName.configJson}`, environment.config.azure.configContainerName)
      .then((resultData: CorporateLogin) => {
        this.themeService.applyBrandTheme(resultData.colorScheme);
      })
      .catch((error: Error) => {
        this.loggingService.logException(error, LogLevel.Error)
      })
  }
  emitNavigation(event: string) {
    if ((event == ROUTESPATH.LOGIN)) {
      this.showHeader = false;
      this.isShowSideMenu = false;
    } else {
      this.showHeader = true;
      this.showSideBar();
    }
  }
  logoutEvent(data?: any) {
    this.alertService.warning({
      description: data.content[0].description,
      title: data.content[0].title,
      type: AlertType.warning,
      buttons: [
        {
          label: 'Cancel',
          attributes: ['is-secondary', 'is-small'],
          handler(data) {

          },
        },
        {
          label: 'Ok',
          attributes: ['is-primary', 'is-small'],
          handler: () => {
            sessionStorage.clear();
            localStorage.clear();
            this.showHeader = false;
            this.router.navigateByUrl(ROUTESPATH.LOGIN);
          }
        }
      ],
    })
  }

handleLandingNavigation() {
      // Perform actions or navigate to the landing page
      this.router.navigateByUrl('/landing');
    }

handleProfileNavigation() {
      // Perform actions or navigate to the profile page
      this.router.navigateByUrl('/profile');
    }
  }

