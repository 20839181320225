import { Injectable } from '@angular/core';
import { ApolloClient, InMemoryCache, gql } from '@apollo/client/core';
import { HttpLink } from '@apollo/client';

import { onError } from '@apollo/client/link/error';
import { ApolloLink } from '@apollo/client/core';
import { ConfigService, LoginService } from '@synergy-application/shared-ui';
import { Router } from '@angular/router';

export enum APIUrlConfig {
    LEAD = 'lead',
    PORTAL = 'portal',
    SEARCH = 'search'
}

@Injectable({
    providedIn: 'root',
})
export class GraphQLService {
    private client: ApolloClient<any>;
    constructor(private loginService: LoginService, private route: Router, public configService: ConfigService) {
        const errorLink = onError(({ graphQLErrors, networkError }) => {
            if (graphQLErrors) {
                graphQLErrors.forEach(({ message, locations, path }) => {
                    console.error(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`);
                });
            }
            if (networkError && networkError.message && networkError.message.toLowerCase().includes('401')) {
                sessionStorage.clear();
                localStorage.clear();
                this.route.navigateByUrl('/login');
                console.error(`[Network error]: ${networkError}`);
            }
        });

        // Create a middleware link
        const authLink = new ApolloLink((operation, forward) => {
            // Retrieve the token from local storage or wherever you store it
            const token = this.loginService.getToken();
            let url = configService.getApiUrl; // Fallback URL

            const { uri } = operation.getContext();

            // Set the headers for the operation
            operation.setContext({
                headers: {
                    Authorization: `Bearer ${token}`,
                    Accept: 'application/json',
                },
                uri: uri ?? url, // Set the URI dynamically
            });

            return forward(operation); // Proceed with the request
        });

        // Initialize the HttpLink with a default URI
        const httpLink = new HttpLink({ uri: configService.getApiUrl });

        // Create the Apollo Client with the dynamic link
        this.client = new ApolloClient({
            link: ApolloLink.from([errorLink, authLink, httpLink]),
            cache: new InMemoryCache(),
            defaultOptions: {
                watchQuery: {
                    fetchPolicy: 'no-cache',
                    errorPolicy: 'ignore'
                },
                query: {
                    fetchPolicy: 'no-cache',
                    errorPolicy: 'all'
                },
                mutate: {
                    fetchPolicy: 'no-cache',
                    errorPolicy: 'all'
                }
            }
        });
    }

    query<T>(query: string, variables?: Record<string, any>, apiUrlConfig?: APIUrlConfig) {
        const uri = apiUrlConfig ? this.getUrl(apiUrlConfig) : this.configService.getApiUrl;
        return this.client.query<T>({ query: gql`${query}`, variables, context: { uri } });
    }

    mutate<T>(mutation: string, variables?: Record<string, any>, apiUrlConfig?: APIUrlConfig) {
        const uri = apiUrlConfig ? this.getUrl(apiUrlConfig) : this.configService.getApiUrl;
        return this.client.mutate<T>({ mutation: gql`${mutation}`, variables, context: { uri } });
    }

    private getUrl(uri?: string): string {
        const leadApiBaseUrls = {
            dev: this.configService.getConfig.leadApiUrl,
            qa: this.configService.getConfig.leadApiUrl,
            prod: this.configService.getConfig.leadApiUrl
        };
        const clientApiBaseUrls = {
            dev: this.configService.getConfig.clientApiUrl,
            qa: this.configService.getConfig.clientApiUrl,
            prod: this.configService.getConfig.clientApiUrl
        };
        const searchApiBaseUrls = {
            dev: this.configService.getConfig.searchApiUrl,
            qa: this.configService.getConfig.searchApiUrl,
            prod: this.configService.getConfig.searchApiUrl
        };

        const envName = this.configService.getName as keyof typeof leadApiBaseUrls;
        const leadApiUrl = `${leadApiBaseUrls[envName] || leadApiBaseUrls.dev}`;
        const bookingApiUrl = `${clientApiBaseUrls[envName] || clientApiBaseUrls.dev}`;
        const searchApiUrl = `${searchApiBaseUrls[envName] || searchApiBaseUrls.dev}`;

        switch (uri) {
            case APIUrlConfig.LEAD: return leadApiUrl;
            case APIUrlConfig.PORTAL: return bookingApiUrl
            default: return searchApiUrl;
        }
    }
}
