<ion-card class="content-card flex-align-center ion-no-padding">
    <div class="image-container" *ngIf="image">
        <img class="image" [src]="image" alt="no-image">
    </div>
    <div class="grid-container ion-no-padding">
        <div class="grid-items">
            @for (item of cardConfiguration; track $index) {
                <div [ngClass]="item.classList" class="item-info ion-no-padding">
                    <ion-label class="label regular-sm-text">{{ item.label }}</ion-label>
                    <ng-container 
                        *ngTemplateOutlet="renderCardItem;
                        context: {
                            item
                        }"
                    ></ng-container>
                </div>
            }
        </div>
    </div>
</ion-card>

<ng-template let-item="item" #renderCardItem>
    <ng-container *ngIf="cardData | pickValueFromObject: (mapValueFromObject ? item.apiKey : 'value') as pickedValue; else noValue">
        @switch (item.type) {
            @case (contentType.Text) {
                <ng-container *ngTemplateOutlet="replaceTemplatedString;
                    context: {
                        item,
                        value: pickedValue
                    }">
                </ng-container>
            }
            @case (contentType.Date) {
                <ion-text class="value semiBold-sm-text">
                    {{ pickedValue | formatDate: item.dateFormat }}
                </ion-text>
            }
            @case (contentType.Boolean) {
                <ion-text class="value semiBold-sm-text">
                    {{ pickedValue ? 'Yes' : 'No' }}
                </ion-text>
            }
            @case (contentType.MultiText) {
                <div class="flex-ai-c-jc-s flex-gap-4">
                    <ion-text class="value semiBold-sm-text">
                        @if(pickedValue.length > 0) {
                            {{ pickedValue[0][item.readDisplayValueFrom] }}
                        }
                    </ion-text>
                    @if(pickedValue?.length > 1) {
                        <ion-text (click)="displayRemainingValue($event, cardData, item)" class="semiBold-sm-text cursor-pointer multi-text-display">
                            +{{ pickedValue?.length }} more
                        </ion-text>
                    }
                </div>
            }
        }
    </ng-container>
    <ng-template #noValue>
        <span class=" value semiBold-sm-text">{{ item.defaultNoDataText ?? '-' }}</span>
      </ng-template>
</ng-template>

<ng-template let-item="item" #replaceTemplatedString let-value="value">
    <ion-text class="value semiBold-sm-text">
        <ng-container *ngIf="item.templatedString; else plainValue">
            {{ item.templatedString.replace("{value}", value) }}
        </ng-container>
        <ng-template #plainValue>
            {{ value }}
        </ng-template>
    </ion-text>
</ng-template>



