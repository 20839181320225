const GET_FAQ = `query GetFAQ {
  getFAQ {
    id
    question
    answer
    sortOrder
    clientId
    isActive
    createdAt
    updatedAt
  }
}`

const GET_HOUSING_REQUEST_LIST_BY_STATUS = `query GetHousingRequestListByStatus($status: String!, $pageSize: Int!, $page: Int!, $selfReservationsOnly: Boolean, $durationFilter: String, $search: CommonSearch) {
  getHousingRequestListByStatus(
      housingRequest: {
          status: $status
          pagination: { pageSize: $pageSize, page: $page }
          selfReservationsOnly: $selfReservationsOnly
          durationFilter: $durationFilter
          search: $search
      }
  ) {
    lead {
            leadId
            currencyCode
            propertyId
            source
            salesManagerId
            businessUnit
            los
            bedroom
            budget
            bathroom
            occupants
            paymentTypeId
            counselor
            firstName
            lastName
            arrivalDate
            departureDate
            moveInTime
            moveOutTime
            noOfUnits
            unitType
            denialInfo
            createdAt
            updatedAt
            isDeleted
            incidentals
            state
            country
            city
            costPerNight
            parkingSpacesRequested
            crossMarketCSC
            housekeepingFrequency
            bringingPet
            petDetails
            approvedNights
            mtm
            ntvDaysRequired
            ntvReceived
            residentStatus
            statusId
            location
            customerName
            emailId
            phoneNumber
            radius
            cancellationReason
            notes
            deadLine
            response
            parkingNeeded
            synergyReferral
            zipCode
            status {
                id
                name
            }
            property {
                propertyName
                  address {
                    id
                    name
                    createdDate
                    updatedDate
                    address1
                    address2
                    city
                    postalCode
                    countyCode
                    country
                    fullAddress
                    phone
                    email
                    latitude
                    longitude
                    description
                }
                attachments {
                    attachmentId
                    fileName
                    description
                    imageUrl
                    imageOrder
                }
            }
        }
        pagination {
            page
            pageSize
            totalCount
        }
  }
}`


const TRAVELER_LIST = `query GetTravelerList(
  $search: CommonSearch, 
  $pagination: PaginationInput!, 
  $order: SortingInput
) {
  getTravelerList(
    travelerListFilter: {
      search: $search,
      pagination: $pagination,
      order: $order
    }
  ) {
    travelerLists {
      id
      firstName
      lastName
      email
      costCenter
      employeeId
    }
    pagination {
      page
      pageSize
      totalCount
    }
  }
}


`

const CREATE_LEAD = `mutation createLead($leadInfo: LeadInput!) {
  createLead(leadInfo: $leadInfo) {
    leadId
    propertyId
    source
    salesManagerId
    businessUnit
    los
    bedroom
    budget
    bathroom
    occupants
    paymentTypeId
    counselor
    customerName
    noOfUnits
    unitType
    denialInfo
    createdAt
    updatedAt
    isDeleted
    incidentals
    state
    country
    city
    parkingSpacesRequested
    crossMarketCSC
    housekeepingFrequency
    bringingPet
    petDetails
    approvedNights
    residentStatus
    arrivalDate
    cancellationReason
    costPerNight
    departureDate
    emailId
    firstName
    location
    lastName
    statusId
    radius
    phoneNumber
    moveOutTime
    moveInTime,
    currencyCode
  }
}`;



export {
  GET_FAQ, CREATE_LEAD,
  GET_HOUSING_REQUEST_LIST_BY_STATUS, TRAVELER_LIST
}