import { Pipe, PipeTransform } from '@angular/core';
import { DateFormat } from '../enum/table';
import { DatePipe } from '@angular/common';

@Pipe({
  name: 'formatDate'
})
export class FormatDatePipe implements PipeTransform {
  constructor(private datePipe: DatePipe) {}
  
  transform(value: unknown, dateFormat: string = DateFormat.SHORT): string {
    if (typeof value === 'string') return this.datePipe.transform(value, dateFormat ?? DateFormat.SHORT) ?? '';
    return '-';
  }
}
