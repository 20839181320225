import { Component, Input, OnInit } from '@angular/core';
import { CardContentType, ContentCardInfoModel } from '../../models';
import { PopoverController } from '@ionic/angular';
import { PopoverComponent } from '../popover/popover.component';

/**
  * Component for the displaying the detailed information in the card
*/
@Component({
  selector: 'lib-content-card',
  templateUrl: './content-card.component.html',
  styleUrl: './content-card.component.scss',
})
export class ContentCardComponent {
  /**
   * Card configuration need to be passed as an array
  */
  @Input() cardConfiguration: ContentCardInfoModel[] = [];

  /**
   * IMAGE URI
  */
  @Input() image?: string;

  /**
   * Holds the card value
  */
  @Input() cardData: {
    [key: string]: any;
  } = {};

  contentType = CardContentType;

  /**
   * Indicate from which source data need to be rendered
  */
  @Input() mapValueFromObject = true;

  constructor(
    private popoverController: PopoverController
  ) {}

  /**
   * DISPPLAY THE REMAING LIST 
  */
  async displayRemainingValue(event: Event, list: { [key: string]: any }, config: ContentCardInfoModel) {
    try {
      const key: string = config.apiKey ?? '';
      const clickedItem = this.mapValueFromObject ? list[key] : list['value'];
      const popover = await this.popoverController.create({
        component: PopoverComponent,
        event,
        componentProps: {
          popoverData: clickedItem.map((data: Record<string, any>) => ({
            key: data[config.readDisplayValueFrom]
          }))
        }
      });
      await popover.present();
    } catch(error) {
      throw error;
    }
  }
}
