<ion-content scroll-y="false" [fullscreen]="true">
  <div class="page-content pt-0 property-list {{sidePadding}}">
    <ion-grid class="p-0 fullscreen flex-col o-hidden">

      <ion-row class="align-items property-search-list">
        <ion-col size-xs="8.5" size-sm="8.5" size-md="10" size-lg="8" size-xl="8" class="pad-0 left-col detail-search">
          <!--Property search section-->
          <lib-property-search class="search-align" [language]=landingLabel [dateMoveInInput]=dateMoveInInput
            [dateMoveOutInput]=dateMoveOutInput [objSearchDetails]="objSearchDetails" (searchEmit)="onSearch($event)"
            [locationSearchData]="locationSearchData"></lib-property-search>
        </ion-col>

        <!-- Amenities Section -->
        <ion-col class="aminities-class pad-0 grow-1">
          <!-- <lib-button is-secondary [isSelected]="amenity.isSelected || false" *ngFor="let amenity of AmenitiesType"
            (click)="onAmenityToggle(amenity)">
          <img [src]="amenity.iconPath"> {{ amenity.name }}
          </lib-button> -->
          <div class="none max-width-600">
            <lib-carousel [image]="true" [value]="amenitiesType" render="button" [circular]="false" [numVisible]="2"
              [numScroll]="1" (clickEmitter)="onSelectAmenities($event)"></lib-carousel>
          </div>

        </ion-col>

        <!-- Toggle Section -->
        <ion-col class="display-flex-justify-right align-items pad-0 right-col filter-col">
          <!-- <lib-toggle class="medium-sm-text ion-hide-lg-down" [data]="isInstantBookingOnly"
            (toggleChange)="onToggleChange(isInstantBookingOnly)">
          </lib-toggle>
          <div class="vertical-line ion-hide-lg-down"></div> -->
          <div class="filter-section align-items cursor-pointer" (click)="openAdvancedFilter()">
            <ion-icon [src]="icon.filter" class="medium-icon"></ion-icon>
            <ion-label class="medium-sm-text ion-hide-lg-down no-wrap">{{languageData?.advanceFilter}}
            </ion-label>
            <ion-label class="medium-sm-text ion-hide-lg-up no-wrap">{{languageData?.filterLabel}}
            </ion-label>
          </div>
        </ion-col>
      </ion-row>

      <ion-row class="content-row" *ngIf="propertyList?.length">
        <!--List the property based on search-->
        <ion-col [class.hide]="showMapView && isTabOrMobile" class="pad-0 left-col h-100" size="12" size-sm="12"
          size-md="12" size-lg="7" size-xl="7">
          <ion-grid class="card-bg-class main-grid p-0 h-100">
            <ion-row class="info-row">
              <ion-col size="12" size-sm="12" size-md="12" size-lg="12" size-xl="7"
                class="semiBold-md-text font-color p-0"> {{languageData.weFound}} {{ pagination.totalCount}}
                {{languageData.propertiesFor}} {{daysDifference}}
                <ion-label>{{languageData.nights}}{{appConstants.dotLabel}}</ion-label>
              </ion-col>
              <ion-col size="12" size-sm="12" size-md="12" size-lg="12" size-xl="5"
                class="display-flex-justify-right filter regular-md-text font-color p-0 ion-hide-lg-down sort-col">
                {{languageData.sortByLabel}} :
                <lib-dropdown [(ngModel)]="sortedValue" [data]="options" displayBy="name"
                  (ngModelChange)="sortBy($event)" ngDefaultControl class="drop-down-align">
                </lib-dropdown>
              </ion-col>
            </ion-row>
            <ion-row class="props-row  items-container" (scroll)="onScroll($event)">
              <ion-col class="props-col" size-xs="12" size-sm="12" size-md="6" size-lg="6" size-xl="6"
                *ngFor="let propertyList of propertyList">
                <lib-property-list [languageData]=languageData (navigateTo)="navigateToPropertyDetail($event)"
                  [data]="propertyList"></lib-property-list>
              </ion-col>
              @if (lazyLoader) {
              <div class="bubble-loader">
                <ion-spinner class="spinner" name="bubbles"></ion-spinner>
              </div>
              }
            </ion-row>
          </ion-grid>
        </ion-col>

        <!--Map view-->
        <ion-col class="p-0 map-view" size="12" size-sm="12" size-md="12" [attr.size-lg]="showMapView ? 12 : 5"
          [attr.size-xl]="showMapView ? 12 : 5">
          <lib-google-map-view (navigateTo)="navigateToPropertyDetail($event)" [propertyList]="propertyList"
            [places]="groupedProperty" [searchPlace]="searchedPlace" [languageData]=languageData></lib-google-map-view>
          <div class="expand-view">
            <lib-button [icon]="showMapView ? svgPath.zoomIn : svgPath.zoomOut" is-secondary is-medium
              (click)="switchView()">
              {{showMapView ? 'Collapse Map View' : 'Expand Map View'}}
            </lib-button>
          </div>
        </ion-col>
      </ion-row>
      <ion-row *ngIf="!propertyList?.length && isRender && apiCompleted" class="on-result-data">
        <app-text-card [cardData]="noResultData" (navigate)="onNavigate()"></app-text-card>
      </ion-row>
    </ion-grid>
    <ion-footer class="ion-hide-lg-up footer-view">
      <lib-button is-medium is-primary (click)="switchView()" *ngIf="propertyList?.length">
        @if(showMapView) {
        <lib-icon name="list" [className]="'medium'"></lib-icon> {{languageData?.listView ?? ''}}
        } @else {
        <ion-icon class="mapIcon" name="map-outline"></ion-icon>
        {{languageData?.mapView ?? ''}}
        }
      </lib-button>
    </ion-footer>
  </div>
</ion-content>