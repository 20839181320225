<div class="property-info-class">
    <!--Property details-->
    <app-info [icon] ="iconPath.iIcon"  [infoText] = "propertyLabel.info"></app-info>
       
    <div class="address-class">
      
        <div class="property-description">
            <ion-label class="bold-xl-text-20px label-color-text">{{propertyData.propertyName}}</ion-label>
            <div>
                <ion-label class="medium-md-text">{{ propertyData.fullAddress }}</ion-label>
            </div>
            <!--Rooms availability-->

            <div class="amenities-class">
                <!-- Due to property unit changes -->
                <div class="img-description" *ngIf="propertyData?.availableUnits?.length">
                    <lib-icon [name]="'lock'" [className]="'about-property'"></lib-icon>
                    <ion-label class="medium-md-text">{{propertyData.availableUnits[0].roomType}}</ion-label>
                </div>

                <!--Property distance-->
                <div class="img-description">
                    <lib-icon [name]="'eye'" [className]="'about-property'"></lib-icon>
                    <ion-label class="medium-md-text">{{propertyData.distance}}
                    </ion-label>
                </div>
            </div>
        </div>
        <!-- share button -->
        <div class="share-class" (click)="copyToClipboard()">
            <lib-icon [name]="'share'" [className]="'share-icon'"></lib-icon>
            <ion-label class="medium-md-text">{{propertyLabel?.shareText}}</ion-label>
        </div>
    </div>
    <div class="details-props">
        <!-- property-overview -->
        <div class="property-overview" *ngIf="propertyData.description">
            <ion-label class="semiBold-xl-text-20px label-color-text">{{propertyLabel?.overviewTitle}}</ion-label>
            <ion-label class="regular-md-text">{{propertyData.description}}</ion-label>
        </div>
        <!-- Property Specific Details -->
        <div class="property-overview" *ngIf="propertyData?.details">
            <ion-label class="semiBold-xl-text-20px label-color-text">{{propertyLabel?.details}}</ion-label>
            <ion-label class="regular-md-text">{{propertyData.details}}</ion-label>
        </div>
        <!-- Available units -->
        <div class="property-overview availableUnits label-color-text">
            <ion-label class="semiBold-xl-text-20px">{{propertyLabel?.availableUnitsLabel}}</ion-label>
            <ion-row>
                @for(item of propertyData.availableUnits; track item.roomType) {
                <ion-col class="availableUnits-col" size-xs="12" size-sm="12" size-md="6" size-lg="6" size-xl="6">
                    <!-- <img class="units-img" alt="image"
                        [src]="item.attachments[0].url || 'assets/icon/default-room.svg'" /> -->
                    <div class="availability">
                        <ion-label class="semiBold-xl-text-20px label-color-text">{{ item.roomType }}</ion-label>
                        <div>
                            <div class="unit">
                                <lib-icon *ngIf="item.roomType != appConstants.studio" [name]="'lock'" [className]="'about-property'"></lib-icon>
                                <ion-text class="regular-md-text label-color-text">
                                    {{item.bedCount}}</ion-text>
                            </div>

                            <ion-label class="regular-sm-text">
                                <span class="from-align regular-md-text">{{propertyLabel?.from ?? ''}}</span>
                                <span class="medium-md-text">${{item.price}}</span>{{propertyLabel?.night ?? ''}}
                            </ion-label>
                        </div>
                    </div>
                </ion-col>
                }
            </ion-row>
        </div>
        <!-- Amenities Section -->
        <div class="amenities-section">
            <ion-label class="semiBold-xl-text-20px label-color-text">{{propertyLabel?.amenitiesTitle}}</ion-label>
            <ion-row *ngFor="let data of propertyData.amenities" class="section-class">
                <ion-col size="12">
                    <ion-label class="semiBold-md-text">{{ data.amenitiesType }}</ion-label>
                </ion-col>
                <ion-col *ngFor="let value of data.amenities" class="amenitiesname-class flex-gap-10" size-xs="12"
                    size-sm="12" size-md="4" size-lg="3" size-xl="3">
                    <img class="large-icon-amenities font-color" [src]="value.svgPath" />
                    <ion-label class="regular-md-text">{{ value.name }}</ion-label>
                </ion-col>
            </ion-row>
        </div>
        <!-- Things To Know -->
        <!-- <div class="property-overview" *ngIf="propertyData.rules.length">
            <ion-label class="semiBold-lg-text">{{propertyLabel.thingsToKnowLabel}}</ion-label>
            <div class="rules">
                <ion-label class="semiBold-md-text">{{ propertyLabel?.apartmentsRulesLabel ?? ''}}</ion-label>
                <div class="content">
                    @for(item of propertyData.rules; track item) {
                    <div class="rules-list">
                         <img [src] = "item.icon"> 
                        <ion-text class="regular-md-text">{{item?.name}}</ion-text>
                    </div>
                    }
                </div>

            </div>
        </div> -->
        <!-- NeighborhoodLabel Section -->
        <div class="property-overview">
            <ion-label class="semiBold-xl-text-20px label-color-text">{{propertyLabel?.neighborhoodLabel}}</ion-label>
            <ion-label class="regular-md-text">{{propertyLabel?.neighborhoodDescription}}</ion-label>
        </div>
    </div>

</div>