<!--Web view-->
<ion-grid *ngIf="!isMobileView">
  <ion-row [ngClass]="data.flexDirection">

    <!--Image card section-->
    <ion-col size="6">
      <app-image-card [data]="data?.imageData">
      </app-image-card>
    </ion-col>
    <!--Text card section-->
    <ion-col class="left-container">
      <app-text-card [cardData]=data></app-text-card>
    </ion-col>
  </ion-row>
</ion-grid>

<!--Mobile view-->
<ion-grid *ngIf="isMobileView">
  <ion-row>
    <ion-col class="content-card-mobile">

      <!--Displays Title-->
      <ion-row size="12" size-lg="1">
        <ion-text class="header bold-xl-text">{{data.headingText}}</ion-text>
      </ion-row>

      <!--Image details-->
      <ion-row size="12" size-lg="1" class="img-block">
        <app-image-card [data]="data?.imageData">
        </app-image-card>
      </ion-row>

      <!--Displays description-->
      <ion-row size="12" size-lg="1">
        <ion-text class="sub-header semiBold-md-text">{{data.descriptionText}}</ion-text>
      </ion-row>

      <!--Action button-->
      <lib-button is-primary is-medium>{{data.buttonText}}<ion-icon
          name="arrow-forward-outline"></ion-icon></lib-button>
    </ion-col>
  </ion-row>
</ion-grid>