<ion-content scroll-y="false" [fullscreen]="true" *ngIf="data">
    <div class="scroll-css booking-request">
        <h1 class="semiBold-xl-text-24px title pad-left-0">{{languageLabel?.requestRateTitle ?? ''}}</h1>
        <ion-grid class="pad-0">
            <ion-row class="col-gap">
                <ion-col class="position-relative pad-0" size="12" size-sm="12" size-md="6" size-xl="4" size-lg="4"
                    *ngIf="requestPropertyInfo">
                    <div class="sticky card-align">
                        <app-rfh-instruction [instruction]="instruction"></app-rfh-instruction>
                        <app-request-property [data]="requestPropertyInfo"></app-request-property>
                    </div>
                </ion-col>
                <ion-col class="pad-0">
                    <ion-grid class="pad-0">
                        <ion-row *ngIf="isTravelerDropdown">
                            <ion-col class="checkbox-align">
                                <lib-checkbox [data]="checkBoxData"></lib-checkbox>
                            </ion-col>
                        </ion-row>
                        <ion-row>
                            <ion-col size="12" *ngIf="checkBoxData.isChecked" size-sm="12" size-md="6" size-xl="6" class="pl-10"
                                size-lg="4">
                                <lib-dropdown   (ngModelChange)="getUserDetails($event,travelerDropdown)" [multiSelectable]="travelerDropdown.multiSelectable"
                                    [(ngModel)]="travelerDropdown.value" [data]="travelerDropdown.options"
                                    [label]="travelerDropdown.label" displayBy="value" value="key"
                                    [searchable]="travelerDropdown.isSearch" ngDefaultControl
                                    (ngClick)="getDropDownValue($event,travelerDropdown)" [totalCount]="travelerDropdown?.totalCount || 0"
                                    [allowApiSearch]="travelerDropdown.allowApiSearch" [pageLimit]="10"
                                    [loadOnDemand]="true" (ngSearch)="getDropDownValue($event,travelerDropdown)"
                                    (ngScroll)="getDropDownValue($event,travelerDropdown)">
                                </lib-dropdown>
                            </ion-col>
                        </ion-row>
                        <ion-row>
                            <ion-col size="12" size-sm="12" size-md="12" size-xl="12" size-lg="12">
                                <div *ngFor="let value of data" class="dynamic-class">
                                    <lib-dynamic-form   [requestLabel] = "languageLabel" [languageLabel]="locationLabel" [value]="value"></lib-dynamic-form>
                                </div>
                            </ion-col>
                            
                        </ion-row>
                        <ion-row>
                            <ion-col class="check-box-align">
                                <lib-checkbox [data]="checkBoxForSubmit"></lib-checkbox>
                            </ion-col>
                        </ion-row>
                    </ion-grid>
                </ion-col>
            </ion-row>
           
            <ion-row>
                <ion-col class="button-class pad-0 pr-5px">
                    <lib-button is-secondary (click)="onCancel()" is-medium class="semiBold-md-text">{{
                        languageLabel?.cancel ??
                        ''}}</lib-button>
                    <lib-button is-primary is-medium (click)="requestRate()" class="semiBold-md-text">{{
                        languageLabel?.requestRate ?? ''}}</lib-button>
                </ion-col>
            </ion-row>
        </ion-grid>
    </div>
</ion-content>