import { Component, EventEmitter, forwardRef, Input, Output, SimpleChanges } from '@angular/core';
import { OptionModel } from '../../models';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { AppConstants, ImagePath } from '../../constants/shared-constant';

@Component({
  selector: 'lib-radio-button',
  templateUrl: './radio-button.component.html',
  styleUrl: './radio-button.component.scss',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => RadioButtonComponent)
    }
  ]
})
export class RadioButtonComponent implements ControlValueAccessor {
  @Input() data: OptionModel[] = [
    {
      id: 1,
      name: 'label'
    }
  ]
  @Output() selectedValueChange = new EventEmitter<OptionModel>();

  @Input() selectedOption!: OptionModel;

  @Input() label?: string;

  @Input() isDefaultSelect:boolean = true;

  private onChange: (value: OptionModel) => void = () => { };

  @Input({
    required: false
  }) isDisabled: boolean = false;

  @Input() infoMsg:string = ''

  iconPath = ImagePath;

  private onTouched: () => void = () => { };

  constructor() { }

  writeValue(obj: OptionModel): void {
    if (obj) this.selectedOption = obj;
  }

  registerOnChange(fn: (value: OptionModel) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.isDisabled = isDisabled ?? false;
  }
  /**
  * Emits the selected option when the radio button selection changes.
  * @param event - The selected radio option that triggered the change.
  */
  onRadioChange(event: OptionModel) {
    this.selectedOption = event;
    this.onChange(this.selectedOption);
    this.selectedValueChange.emit(this.selectedOption);
  }

  ngOnChanges(changes: SimpleChanges) {
    // this.selectedOption = changes['selectedOption'].currentValue;
    if (changes['data'].currentValue && this.isDefaultSelect) {
      this.data = changes['data'].currentValue;
      this.selectedOption = changes['data'].currentValue.filter((e: OptionModel) => e.id == this.selectedOption?.id)?.length ? changes['data'].currentValue.filter((e: OptionModel) => e.id == this.selectedOption?.id)[0] : changes['data'].currentValue[0];
      this.onChange(this.selectedOption);
    }
  }
  /**
   * Selects the radio button when the corresponding label is clicked.
   * Emits the selected option to notify the change.
   * @param event - The radio option associated with the clicked label.
   */
  onLabelClick(event: OptionModel) {
    this.selectedOption = event;
    this.onChange(this.selectedOption);
    this.selectedValueChange.emit(this.selectedOption);
  }
}