import { Component, ElementRef, EventEmitter, HostListener, Input, Output, OnInit, forwardRef } from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';
import { LocationService } from '../../services/location.service';
import { LocationItem, LocationSearchData, RequestInput } from '../../models';
import { SharedApiService } from '../../core/shared-api.service';
import { GraphQLService } from '../../core/graphql';
import { CommonService } from '../../services/common.service';

@Component({
  selector: 'lib-location-dropdown',
  templateUrl: './location-dropdown.component.html',
  styleUrls: ['./location-dropdown.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => LocationDropdownComponent),
      multi: true
    }
  ]
})
export class LocationDropdownComponent implements OnInit, ControlValueAccessor {

  @Input() language: any;

  @Input() styleCss = '';

  @Input() label?: string;

  @Input() inputData: LocationSearchData = {
    label: 'Where To?',
    type: 'text',
    maxLength: 10,
    placeholder: 'Choose Location',
    value: '',
    isShowErrorMessage: false,
    groups: [],
    isRequired: false,
    showErrorMessage : '',
    leadRef : ''
  };

  @Input() useClientLocation:boolean = true;

  @Input() showBorder = false;

  @Output() locationSearch: EventEmitter<string> = new EventEmitter();

  @Output() selectedItem: EventEmitter<LocationItem> = new EventEmitter();

  searchTerm = '';

  showDropdown = false;

  private onChange = (value: any) => value;
  private onTouched: () => void = () => { };

  constructor(
    private elementRef: ElementRef,
    private locationService: LocationService,
    private sharedApiService:SharedApiService,
    private graphQLService: GraphQLService,
    private commonService: CommonService
  ) { }

  ngOnInit() { 
    if(this.useClientLocation) this.onLocationSearch();
  }

  /**
   * Called whenever the user types in the search input.
   * @param searchValue The current input value from the user.
   */
  onSearchChange(searchValue: string) {
    this.showDropdown = true;
    this.searchTerm = searchValue?.trim().toLowerCase() || '';
    if (this.useClientLocation) this.onLocationSearch(this.searchTerm);
    else this.locationSearch.emit(this.searchTerm);
    this.onChange(this.searchTerm); // Notify the form control of the change
  }

   /**
   * Hides the error message when the input field is focused.
   * @param field - The InputData object representing the input field.
   */
   focusInput(field: LocationSearchData) {
    field.isShowErrorMessage = false;
  }

  /**
   * Emits a focusout event when the input field loses focus.
   * @param field - The InputData object representing the input field.
   */
  focusoutInput(field: LocationSearchData) {
    this.commonService.validateInputFields(field);
  }

  /**
   * Shows the dropdown when the input is clicked.
   */
  onClick() {
    this.showDropdown = true;
  }

  /**
   * Handles the selection of a dropdown item.
   * @param item The selected item from the dropdown.
   */
  selectItem(item: LocationItem) {
    this.inputData.value = item.name;
    this.showDropdown = false;
    this.onChange(item); // Notify the form control of the selected value
    this.selectedItem.emit(item);
  }

  /**
   * Closes the dropdown if a click occurs outside the component.
   * @param target - The target element that was clicked.
   */
  @HostListener('document:click', ['$event.target'])
  onDocumentClick(target: HTMLElement) {
    const clickedInside = this.elementRef.nativeElement.contains(target);
    if (!clickedInside) {
      this.showDropdown = false;
      this.showBorder = false;
      this.onTouched(); // Mark as touched when clicked outside
    }
  }

  /**
   * Fetches the user's current location and sets the input value to the location's name.
   */
  async goToCurrentLocation() {
    try {
      this.locationService.fetchLocationAndAddress()
        .then((response: LocationItem) => {
          if (response?.name) {
            this.showDropdown = false;
            this.inputData.value = response?.name;
            this.selectedItem.emit(response);
            this.onChange(response); // Notify the form control of the new value
          }
        }).catch((error: Error) => {
          console.error(error);
        });
    } catch (error) {
      console.error(error);
    }
  }
  /**
   *  Searches for locations based on the search term. 
   * @param searchTerm The query used to search for locations.
   */
  async onLocationSearch(searchTerm?: string) {
    try {
      if (searchTerm) {
        this.getClientLocation(searchTerm);
        this.locationService.getAutoLocationApi(searchTerm)
          .then((response: LocationItem[]) => {
            if (response?.length) {
              this.showBorder = true;
              this.inputData.groups[1].name = this.language.googleLocation;
              this.inputData.groups[1].items = response;
            }
          }).catch((error: Error) => {

          });

      } else {
        this.inputData.groups[1].items = [];
        // this.objSearchDetails = {
        //   ...this.objSearchDetails,
        //   locationDetails: {
        //     name: "",
        //     placeId: ""
        //   }
        // }
        this.getClientLocation();
      }

    } catch (error) {
    }
  }


  getClientLocation(searchTerm?: string) {
    this.inputData.groups[0].items = [];
    let pagination: RequestInput;
    if (searchTerm) {
      pagination = {
        pagination: {
          page: 1, pageSize: 500
        },
        search: {
          where: {
            name: {
              like: searchTerm || ''
            }
          }
        }
      }

    } else {
      pagination = {
        pagination: {
          page: 1, pageSize: 500
        }
      }
    }

    this.sharedApiService.getClientLocation(this.graphQLService, pagination)
      .then((response: LocationItem[]) => {
        this.inputData.groups[0].items = [];
        if (response?.length) {
          this.showBorder = true;
          this.inputData.groups[0].name = this.language.officeLocation;
          this.inputData.groups[0].items = response;
        }
      }).catch((error: Error) => {

      });
  }
  // ControlValueAccessor methods
  writeValue(value: any): void {
    this.inputData.value = value?.name;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.showBorder = !isDisabled;
  }
}
