import { Injectable } from '@angular/core';

interface AppConfig {
  name: string;

  apiUrl: string;

  searchApiUrl: string;

  clientApiUrl?: string;

  leadApiUrl?: string;

  microsoftTenantId: string;

  microsoftClientId: string;

  microsoftRedirectURI: string;

  microsoftAuthority: string;

  googleKey: string;

  isLogEnable: boolean;

  googleApi: string;

  azure: AzureConfig;

  appInsights : string,
  
  googleAnalyticsId?:string,
  buildNo?:string
}
interface AzureConfig {
  storageAccountName: string,
  privateContainerName: string,
  publicContainerName: string,
  configContainerName: string,
  blobName?: string
}
@Injectable({
  providedIn: 'root'
})
export class ConfigService {
  private config: AppConfig;

  constructor() {
    this.config = {
      name: '',
      apiUrl: '',
      searchApiUrl: '',
      leadApiUrl: '',
      clientApiUrl: '',
      microsoftTenantId: '',
      microsoftClientId: '',
      microsoftRedirectURI: '',
      microsoftAuthority: '',
      googleKey: '',
      isLogEnable: true,
      googleApi: '',
      azure: {
        storageAccountName: '',
        privateContainerName: '',
        publicContainerName: '',
        configContainerName: ''
      },
      appInsights : '',
      googleAnalyticsId : '',
      buildNo:''
    };
  }

  setConfig(config: AppConfig) {
    this.config = config;
  }

  get getApiUrl(): string {
    return this.config.apiUrl;
  }

  get getName(): string {
    return this.config.name;
  }

  get getConfig(): AppConfig {
    return this.config;
  }

  getBaseUriBlob() {
     return `https://${this.getConfig.azure.storageAccountName}.blob.core.windows.net/${this.getConfig.azure.publicContainerName}/`
  }
}
