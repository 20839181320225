import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AppConstants, ImagePath } from '../../constants/shared-constant';
import { progressDataModel } from '../../models/shared-common.model';

@Component({
  selector: 'lib-upload-file-progress',
  templateUrl: './upload-file-progress.component.html',
  styleUrl: './upload-file-progress.component.scss',
})
export class UploadFileProgressComponent {
  appConstants = AppConstants;
  imagePath = ImagePath;
  
  @Input() progressData: progressDataModel = {
    isCompleted: false,
    fileName: '',
    progress: this.appConstants.progressPoint,
    total: this.appConstants.progressPoint,
  };

  @Output() cancelClicked = new EventEmitter();

  showProgressBar: boolean = true;
  /*** 
  * Method to emit the cancel button in progress bar while uploading the file
  * 
  */
  onCancel() {
    this.cancelClicked.emit();
  }

}