import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'flattenJson'
})
export class FlattenJsonPipe implements PipeTransform {

  transform(input: any[]): any[] {
    const result: any[] = [];

    // Helper function to process value (handling arrays, objects with nested 'value', etc.)
    const processValue = (value: any, type: string) => {
      if (type === 'date' && value.value && value.hasOwnProperty('value')) {
        // If the type is 'date', return the 'minDate' (convert to string if it's a Date object)
        const date = value.value;
        return date instanceof Date ? date.toDateString() : date;
      }
      if (Array.isArray(value)) {
        // If it's an array, check for isSelected or isChecked flags, and return only those items
        const selectedValues = value.filter((item: any) => item.isSelected || item.isChecked)
          .map((item: any) => { return { displayName: item.value, value: item.key } });
        return selectedValues.length > 0 ? selectedValues : null;
      } else if (value.value && typeof value === 'object' && value.hasOwnProperty('value')) {
        // If it's an object with a 'value' field, extract that 'value'
        return value.value;
      }
      return value === null || value === undefined || !value.value ? null : value;
    };

    // Function to check if a value is considered "empty" (null, empty array, or empty object)
    const isEmpty = (value: any) => {
      if (Array.isArray(value)) {
        return value.length === 0;
      }
      if (typeof value === 'object' && value !== null) {
        return Object.keys(value).length === 0;
      }
      return false;
    };

    // Function to process each item in the input recursively
    const traverse = (obj: any, parentName: string = '') => {
      if (obj && typeof obj === 'object') {
        // If it's an array, process each item recursively
        if (Array.isArray(obj)) {
          obj.forEach(item => traverse(item, parentName));
        } else {
          // If it has 'child', process each child recursively
          if (obj.child && Array.isArray(obj.child)) {
            if (obj.type === 'checkBox') {
              const checkBoxValues = obj.child?.filter((child: any) => child.value.isChecked === true).map((el: any) => { return { displayName: el.displayName, value: el.id } });

              // Only include the 'name' and 'value' if there are checked items
              if (checkBoxValues.length > 0) {
                const item = {
                  name: obj.name,
                  value: checkBoxValues
                };
                result.push(item);
              }
            } else {
              obj.child.forEach((child: any) => traverse(child, `${parentName ? parentName + '.' : ''}${obj.name}`));
            }
          } else {
            // If it's a parent element with 'name' and 'value'
            if (obj.hasOwnProperty('name') && obj.hasOwnProperty('value')) {
              if ((obj.type === 'multiSelect' || obj.isMultiple) && obj.hasOwnProperty('selectedValue') && obj.selectedValue.length) {
                const values = obj.selectedValue.map((el: any) => { return { displayName: el.value, value: el.key } });
                const item = {
                  name: obj.name,
                  value: values
                };
                result.push(item);
              } else {
                const value = processValue(obj.value, obj.type);  // Process the value, considering the type
                // Only include it if the value is not null or empty
                if (value !== null && !isEmpty(value)) {
                  const item = {
                    name: `${parentName ? parentName + '.' : ''}${obj.name}`,
                    type: obj.type || '',  // Include type if available
                    value: value
                  };
                  result.push(item);
                }
              }
            }
          }
        }
      }
    };

    // Traverse the input to extract and flatten all items, including children
    input.forEach(item => traverse(item));

    return result;
  }
}
