@if (options) {
<ion-grid class="alert-container">
  <ion-grid class="alert-box" [ngClass]="{
    'center-orientation': options.position === orientation.Center,
    'top-orientation': options.position === orientation.Top,
    'bottom-orientation': options.position === orientation.Bottom
  }">
    <header class="header">
      <ion-label class="semiBold-xl-text-20px label-color-text">{{ options.title }}</ion-label>
      <span (click)="dismiss()">
        <ion-icon class="icon-close" name="close"></ion-icon>
      </span>
    </header>

    <main class="main-content">
      @if (options.description) {
      <ion-text class="alert-color font-color regular-sm-text">{{ options.description }}</ion-text>
      }

      @if (options.subText) {
        <ion-text class="alert-color font-color regular-sm-text">{{ options.subText }}</ion-text>
        }

      @if (options.textarea) {
      <lib-text-area [inputData]="textAreaOption"></lib-text-area>
      }
    </main>

    @if (options.buttons ?? '') {
    <footer class="alert-footer">
      @for(button of options.buttons; track button.label) {
      <lib-button is-primary is-medium *ngIf="findAttribute('is-primary', button)" (click)="clickListener(button)">{{
        button.label }}</lib-button>
      <lib-button is-secondary is-medium *ngIf="findAttribute('is-secondary', button)"
        (click)="clickListener(button)">{{ button.label }}</lib-button>
      }
    </footer>
    }
  </ion-grid>
</ion-grid>
}