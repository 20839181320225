export const sourceIcon = {
  shopping: "../../../../../assets/icon/shopping.svg",
  transport: "../../../../../assets/icon/transport.svg",
  default: "../../../../../assets/icon/property.svg",
  property: "../../../../../assets/icon/property.svg",
  "property-cluster": "../../../../../assets/icon/property-cluster.svg",
  gym: "../../../../../assets/icon/gym.svg",
  bag: "../../../../../assets/icon/bag.svg",
  mic: "../../../../../assets/icon/mic.svg",
  beach: "../../../../../assets/icon/beach.svg",
  bus: "../../../../../assets/icon/bus.svg",
  dumbell: "../../../../../assets/icon/dumbell.svg",
  car: "../../../../../assets/icon/car.svg",
  entertainment: "../../../../../assets/icon/mic.svg"
}