export const Constant = {
    searchPlaceHolderText: "Search by Lead ID, Location, Company, Sales Representative ",
    removeFileConfirmation: {
        title: "Remove",
        content: "Are you sure do you want to delete the attachment?",
        removeText: "Remove"
    }
}

export const IconPath = {
    upload: 'assets/icons/shared-spirit.svg#upload-cloud'
}