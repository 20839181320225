import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { ToggleInput } from '../../models';

@Component({
  selector: 'lib-toggle',
  templateUrl: './toggle.component.html',
  styleUrls: ['./toggle.component.scss'],
})
export class ToggleComponent implements OnChanges {

  @Input() data: any = {
    label: 'label',
    isChecked: false,
    type : 'toggle',
    icon: ''
  };
  @Input() disabled:any = false;
  @Output() toggleChange = new EventEmitter<ToggleInput>();

  constructor() { }

  ngOnChanges(changes: SimpleChanges): void {
    if(changes) {
      if(changes['data']) this.data = changes['data'].currentValue;
    }
  }

  /**
    * emit the recent value
    * @param data 
    */
  onToggleChange(data: ToggleInput) {
    this.toggleChange.emit(data);
  }

}