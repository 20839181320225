export interface ISideNavBar {
  client: NavItemModal;
  navItem: NavItemModal[];
}

export interface NavItemModal {
  icon: string;
  label?: string;
  logo?: string;
  isSelected?: boolean;
  href?: string;
  expandIcon?: string;

}
export interface SideNavModel {
  clientDetails: {[key: string]: string };
  profileDetails: {[key: string]: string };
  menuItem: ParentMenu[];
  bottomMenu:BottomMenuModel[];
  bottomDetail?:BottomDetailModel;
}

export interface BottomDetailModel{
  bottomText:string,
  expandBottomText:string
}
export interface Menu {
  menuId: string;
  menuName: string;
  svgPath: string;
  createdAt: string;
  createdBy: string;
  updatedAt: string | null;
  updatedBy: string | null;
  root: string;
  children: Menu[]; 
}

export interface ParentMenu {
  parentId: string;
  parentName: string;
  parentSvgPath: string;
  parentCreatedAt: string;
  parentCreatedBy: string;
  parentUpdatedAt: string | null;
  parentUpdatedBy: string | null;
  root: string;
  children: Menu[]; 
}
//bottom 
export interface Content {
  title: string;
  description: string;
  type: string; 
  buttons: string;
  AlertPosition: string;
}

export interface BottomMenuModel {
  parentId: string | number;
  parentName: string;
  parentSvgPath: string;
  content: Content[]; 
  children: Menu[]; 
  openType: string;
  root: string;
}