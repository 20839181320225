import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { FilterFieldModel, SearchStyle, ToggleInput } from '../../models';
import { Constant } from 'shared-ui/src/core/constant';

@Component({
  selector: 'lib-filter',
  templateUrl: './filter.component.html',
  styleUrl: './filter.component.scss',
})
export class FilterComponent implements OnInit, OnChanges {
  @Input() data: FilterFieldModel[] = [];

  @Input() language: any;

  @Input() search: boolean = true;

  @Input() size: number = 4;

  @Input() moreFilter: boolean = true;

  @Output() searchChange = new EventEmitter<string>();

  @Output() showMoreFilters = new EventEmitter();

  @Output() toggleChange = new EventEmitter<ToggleInput>();

  slicedData: FilterFieldModel[] = [];

  constructor() {}

  ngOnInit(): void {
    const size = this.search ? this.size + 1 : this.size;
    const startIndex = this.search ? 1 : 0;
    this.slicedData = this.data.slice(startIndex, size);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes) {
      if(changes['data']) this.data = changes['data'].currentValue
      this.slicedData = this.data.slice(0, this.size);
    }
  }

  toggleDetails!: ToggleInput;

  toggleData: ToggleInput[] | [] = [];

  searchPlaceHolderText = Constant.searchPlaceHolderText;

  toggleInput: ToggleInput = {
    label: 'Unassigned Only',
    isChecked: false,
    type: 'toggle',
    icon: '',
  };

  searchStyle: SearchStyle = {
    fontSize: '12px',
  };

  filterArray: FilterFieldModel[] = [];

  /**
   *  Updates value on toggle change
   * @param toggle
   */
  onToggleChange(event: ToggleInput) {
    this.toggleChange.emit(event);
  }

  /**
   *  Updates value on toggle change
   * @param event
   */
  searchModelChange(event: string) {
    this.searchChange.emit(event);
  }

  openAdvancedFilter() {
    // code comes here
  }

  moreFilterClicked(): void {
    this.showMoreFilters.emit();
  }

  trackByName(index: number, item: any): string {
    return item.name;
  }
}
