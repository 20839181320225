<ion-grid class="main-grid" *ngIf="loginData">
    <ion-row class="content">
        <!-- Left side of the login page containing an image and header description for welcoming users. -->
        <ion-col size-xs="12" size-sm="12" size-md="12" size-lg="6" size-xl="6" class="pad-0 center-align align-img ion-hide-lg-down">
            <div class="h-100">
                <div class="left-col">
                    <div class="logo-background"></div>
                    <img class="login-img blend" [src]="loginData.img1" alt="" />
                    <ion-label class="semiBold-xl-text-24px max-size">{{languageLabel?.titleText ?? ''}}</ion-label>
                    <ion-label class="regular-md-text max-size">{{languageLabel?.descriptionText ?? ''}}</ion-label>
                </div>
            </div>
        </ion-col>

        <!-- Welcome Text -->
        <ion-col class="pad-0 right-gap center-align " size-xs="12" size-sm="12" size-md="12" size-lg="6" size-xl="6">
            <div class="main-login">
                <div class="login-grid logo-img text-align-center right-grid">
                    <img [src]="loginData.logo" alt="" />
                    <ion-label class="medium-xl-text-20px">{{loginData.welcomeText}}</ion-label>
                </div>
                <!-- Email login form -->
                <div class="login-grid highlight-border right-grid">
                    <ion-label class="semiBold-xl-text-20px">{{languageLabel.moduleName}}</ion-label>
                    <lib-button is-secondary (click)="oktaLogin()" type="submit" class="sso-login regular-sm-text">
                        <ion-icon src="/assets/icon/key-sso.svg" alt=""></ion-icon>{{languageLabel?.LoginWithMicrosoft
                        ??
                        ''}}</lib-button>
                </div>

                <!--Powered by section-->
                <div class="login-grid text-align-center powered-by regular-sm-text right-grid gap-10">
                    <ion-label class="regular-sm-text">{{languageLabel?.poweredBy ?? 'Powered by'}}</ion-label>
                    <img class="synergy-logo" src="/assets/icon/synergy-header.svg" alt="" />
                </div>
            </div>


            <!-- Help, Terms of Service, and Privacy Policy links. -->
            <div class="footer-text text-align-center semiBold-sm-text align-horizontal right-grid">
                <!-- <a class="cursor-p" (click) = "goToDetail('help')">{{languageLabel?.helpText ?? ''}}</a> -->
                <!-- <div class="vertical-line"></div> -->
                <a class="cursor-p" (click) = "goToDetail('terms')">{{languageLabel.termsAndServices ?? ''}}</a>
                <div class="vertical-line"></div>
                <a class="cursor-p" (click) = "goToDetail('policy')">{{languageLabel.privacyPolicy ?? ''}}</a>
            </div>
        </ion-col>
    </ion-row>
</ion-grid>