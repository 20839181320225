/* eslint-disable consistent-return */
/* eslint-disable array-callback-return */
/* eslint-disable max-lines */
import {
  Component,
  OnInit,
  Input,
  EventEmitter,
  Output,
  HostListener,
  ElementRef,
  ViewChild,
  OnChanges,
  SimpleChanges,
  forwardRef,
} from '@angular/core';
import {
  ControlValueAccessor,
  FormControl,
  NG_VALUE_ACCESSOR,
  Validators,
} from '@angular/forms';
import { AppConstants } from '../../constants/shared-constant';
import { ClientUserListModel, DropdownOptions, DropDownType } from '../../models';
import { OptionModel } from '../../models';
import { CurrencyModel } from '../../models/currency.model';
// import { setFocusEle } from 'src/app/_core';

@Component({
  selector: 'lib-dropdown',
  templateUrl: './dropdown.component.html',
  styleUrls: ['./dropdown.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DropdownComponent),
      multi: true
    }
  ]
})
export class DropdownComponent implements OnInit, OnChanges, ControlValueAccessor {
  @Input() data: Array<OptionModel | ClientUserListModel | DropdownOptions | CurrencyModel> = [];
  @Input() name?: string;
  @Input() multiSelectable? = false;
  @Input() displaySecondLabel:string = '';
  @Input() showTick = true;
  @Input() showCheckbox? = false;
  @Input() placeholder = AppConstants.placeholder;
  @Input() searchable? = false;
  @Input() disabled? = false;
  @Input() resetPeriod? = false;
  @Input() value!: string;
  @Input() displayBy!: string;
  @Input() ngModel: any;
  @Input() allowApiSearch = false;
  @Input() pageLimit = AppConstants.pageLimit;

  @Input() totalCount!: number;

  @Input() currentPage = 1;
  @Input() showLoader = false;
  @Input() label = '';
  @Input() isTwoLabels = false;
  @Input() isLabelWithDescription = false;
  @Input() isActionButton = false;
  @Input() buttonText !: string;
  @Input() description !: string;
  @Input() secondDescription !: string;
  @Input() secondDisplayName = '';
  @Input() required = false;
  @Input() upWordDropdown = false;
  @Output() ngModelChange: EventEmitter<any> = new EventEmitter<any>();
  @Output() ngSearch: EventEmitter<any> = new EventEmitter<any>();
  @Output() ngScroll: EventEmitter<any> = new EventEmitter<any>();
  @Output() ngClick: EventEmitter<any> = new EventEmitter<any>();
  @Input() loadOnDemand = false;
  @Input() size: {
    labelSize: number[];
    iconSize: number[];
  } = {
      labelSize: [10, 10.5, 11],
      iconSize: [2, 1.5, 1],
    };

  @Input() placeholderTemplate?: string;
  @Input() readOnly = false;
  @Input() searchBarPlaceholder = AppConstants.searchPlaceholder;
  @Input() isShowErrorMessage = false;
  @Input() showErrorMessage = '';
  @Input() displayImage: boolean = false;
  @Input() searchDebounce = 0;
  @Input() type: DropDownType = DropDownType.default;
  @Input() selectionPosition: 'upWord' | 'downWord' = 'downWord';

  selectedValue: any | Array<any>;
  listToShow: any[] = [];
  showDropDown = false;
  searchQuery = '';
  childElements: any;
  dropdownCountVar = 0;

  id = 0;
  displayLabel!: string;
  searchValue = '';
  propChanges: any;
  isLoading = false;

  private onChange = (value: any) => value;
  private onTouched: () => void = () => { };

  @ViewChild('search') searchElement!: ElementRef;

  @ViewChild('dropdownContainer', { read: ElementRef })
  dropdownContainerElementRef!: ElementRef;

  @ViewChild('dropdownList', { read: ElementRef }) dropdownList!: ElementRef;
  @HostListener('document:click', ['$event.target'])
  async click(targetElement: any) {
    if (
      (!this.elementRef.nativeElement.contains(targetElement) ||
        !this.elementRef.nativeElement.contains(
          targetElement?.parentElement
        )) &&
      this.showDropDown
    ) {
      this.showDropDown = false;
      this.resetDropDown();
    }
  }

  @HostListener('keydown', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    if (event.keyCode === 27) {
      setTimeout(() => {
        this.iToggleDropDown();
      }, 500);
    }
    if (event.keyCode === 40) {
      if (this.dropdownCountVar >= 0) {
        this.dropdownCountVar += 1;
        this.id = this.childElements[this.dropdownCountVar].children[0].id;
      } else {
        this.dropdownCountVar = 0;
      }
    }
    if (event.keyCode === 38) {
      if (this.dropdownCountVar >= 0) {
        this.dropdownCountVar -= 1;
        this.id = this.childElements[this.dropdownCountVar].children[0].id;
      } else {
        this.dropdownCountVar = 0;
      }
    }
  }

  iToggleLoading(dataLoaded: any) {
    this.isLoading = !this.isLoading;
    if (dataLoaded) {
      this.iToggleDropDown();
    }
  }

  constructor(private elementRef: ElementRef) { }

  writeValue(value: any): void {
    this.selectedValue = value;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;        // Save the function to handle future value changes
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  ngOnInit(): void {
    if (this.data && Array.isArray(this.data))
      this.data = JSON.parse(JSON.stringify(this.data)); // DEEP COPYING THE DATA
    this.listToShow = this.data;
    this.displayLabel = this.placeholder;
    if (this.multiSelectable) {
      this.selectedValue = this.ngModel;
      this.onChange(this.selectedValue);
    }
    this.setDisplayLabel();
  }

  ngOnChanges(changes: SimpleChanges) {
    this.propChanges = changes;
    this.listToShow = this.data;
    this.selectedValue = this.ngModel;
    // this.onChange(this.selectedValue);
    this.setDisplayLabel();
    this.rememberSelectedValue();
    this.showLoader = false;
    if (
      changes &&
      changes['data'] &&
      changes['data'].currentValue?.length < changes &&
      changes['data'] &&
      changes['data']?.previousValue?.length
    ) {
      this.currentPage = 1;
    }
    if (changes['data']) {
      this.listToShow = changes['data'].currentValue;
    }
  }
  validate(control: FormControl) {
    if (!this.required) {
      return null;
    }
    let error: any;
    if (!control.value) {
      this.placeholder = AppConstants.placeholder;
    }
    if (!control.value) {
      error = error || {};
      error.isRequired = true;
      error.valid = false;
      return error;
    }
    return null;
  }

  rememberSelectedValue() {
    if (this.listToShow && this.listToShow.length) {
      this.listToShow.map((item) => {
        if (this.multiSelectable && this.selectedValue) {
          const cpy = this.selectedValue;
          item.isSelected = cpy.some((el: any) => item[this.value] === el[this.value])
        } else {
          item.isSelected =
            item[this.value] ===
            (this.selectedValue instanceof Object &&
              this.selectedValue &&
              this.value
              ? this.selectedValue[this.value]
              : this.selectedValue);
        }
        return item;
      });
    }
  }

  onSelect(param: any, event?: any): void {
    this.onTouched();
    this.dropdownCountVar = 0;
    const item = param;
    if (item.isDisabled) {
      return;
    }
    this.searchValue = '';
    if (this.multiSelectable) {
      if (!this.selectedValue) {
        this.selectedValue = [];
      }
      const foundItemIndex = this.selectedValue.findIndex(
        (value: { [x: string]: any }) => value[this.value] === item[this.value]
      );

      if (foundItemIndex > -1) {
        item.isSelected = false;
        this.selectedValue.splice(foundItemIndex, 1);
      } else {
        item.isSelected = true;
        this.selectedValue.push(item);
      }
      if (!event) {
        this.ngModelChange.emit(this.selectedValue);
      }
    } else {
      this.listToShow.map((iterator) => {
        const list = iterator;
        list.isSelected = item[this.value] === list[this.value];
        return list;
      });
      this.selectedValue = item;
      this.iToggleDropDown();
      this.ngModelChange.emit(this.selectedValue);
    }
    this.onChange(this.selectedValue);
    this.setDisplayLabel();
    // this.ngModelChange.emit(this.selectedValue);
  }

  onSearch($event: any) {
    const searchQuery = $event?.target?.value || '';

    this.searchQuery = searchQuery;

    this.currentPage = 0;

    if (this.allowApiSearch) {
      this.ngSearch.emit({
        currentPage: this.currentPage,
        type: AppConstants.searchPlaceholder,
        searchQuery,
        name: this.name
        // event: this.searchEventCallback.bind(this)
      });
    } else if (searchQuery === '') {
      this.listToShow = this.data;
    } else {
      this.listToShow = this.data.filter((item: any) => {
        if (
          item[this.displayBy].toUpperCase().includes(searchQuery.toUpperCase())
        ) {
          return item;
        }
      });
    }

    this.currentPage = 1;
  }

  onCancel(): void {
    this.searchValue = '';
    this.listToShow = this.data;
  }

  uiToggleDropDown(): void {
    if (!this.disabled) {
      if (this.showDropDown) {
        this.iToggleDropDown();
        return;
      }

      if (this.loadOnDemand) {
        this.isLoading = true;
        this.ngClick.emit({
          toggleLoading: this.iToggleLoading.bind(this),
          name: this.name
        });
      } else {
        this.iToggleDropDown();
      }
    }
  }

  iToggleDropDown() {
    this.showDropDown = !this.showDropDown;
    if (!this.showDropDown) {
      this.resetDropDown();
    }
  }

  private resetDropDown() {
    this.searchQuery = '';

    this.searchValue = '';

    this.onSearch('');

    this.currentPage = 1;
  }

  setDisplayLabel() {
    this.displayLabel = this.placeholder;
    if (this.isTwoLabels) {
      if (
        this.selectedValue &&
        this.selectedValue instanceof Array &&
        this.selectedValue.length
      ) {
        const values = this.selectedValue.map(
          (value) =>
            `${value[this.displayBy]} - ${value[this.secondDisplayName]}`
        );
        this.displayLabel = values.join(', ');
      } else if (
        this.selectedValue &&
        typeof this.selectedValue === 'object' &&
        Object.keys(this.selectedValue).length
      ) {
        this.displayLabel = `${this.selectedValue[this.displayBy]} - ${this.selectedValue[this.secondDisplayName]
          }`;
      }
    } else if (
      this.selectedValue &&
      this.selectedValue instanceof Array &&
      this.selectedValue.length
    ) {
      const values = this.selectedValue.map((value) => value[this.displayBy]);
      this.displayLabel =
        values?.length === this.totalCount
          ? AppConstants.selectAll
          : values.join(', ');
    } else if (this.selectedValue && Object.keys(this.selectedValue).length) {
      this.displayLabel = this.selectedValue[this.displayBy];
    } else {
      const foundItem =
        this.listToShow instanceof Array
          ? this.listToShow?.find(
            (list) => list[this.value] === this.selectedValue
          )
          : undefined;
      this.displayLabel = foundItem
        ? foundItem[this.displayBy]
        : this.placeholder;
    }

    if (this.resetPeriod) {
      this.displayLabel = this.placeholder;
    }

    this.displayLabel = this.displayLabel ?? this.placeholder;

    // SET THE SELECTED VALUE TO THE PLACEHOLDER TEMPLATE
    if (this.placeholderTemplate)
      this.displayLabel = this.placeholderTemplate.replace(
        '{value}',
        ((this.type == DropDownType.customText) ? this.selectedCount : this.displayLabel)
      );

  }

  get selectedCount(): string {
    if (this.type == DropDownType.customText) {
      if (this.selectedValue) return !this.multiSelectable ? '1' : this.selectedValue.length;
      else return '0';
    }
    return this.displayLabel;
  }

  loadData() {
    if (!this.allowApiSearch) {
      return;
    }
    if (this.showLoader) {
      return;
    }
    const skip = this.currentPage * this.pageLimit;
    if (skip < this.totalCount) {
      this.showLoader = true;
      setTimeout(() => {
        this.currentPage += 1;
        this.ngScroll.emit({
          type: AppConstants.scrollType,
          currentPage: skip,
          searchQuery: this.searchQuery,
          name: this.name
          // event: this.afterEventFinished.bind(this)
        });
        const element = document.getElementById('list');
        this.getChildElements(element);
      }, 500);
    }
  }

  getChildElements(event: any) {
    if (event?.target) {
      this.childElements = event.target.children;
    }
  }
}
