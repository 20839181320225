import { Component, HostListener, OnInit } from '@angular/core';
import { LanguageService } from '../../../shared/services/language.service';
import { DaysInput, HousingRequestListModel, ReservationDetailsModel, ReservationDetailsResult } from '../../models/reservation.models';
import { DropdownOptions, OptionModel, PaginationModel, TabInput, ToggleInput } from 'shared-ui/src/lib/models';
import { checkTab } from 'shared-ui/src/lib/core/helper';
import { IAction, StatusDescription } from '../../../shared/models/common.model';
import { AppConstants } from '../../../../../app/core/app-constant';
import { AppLoaderService, LoggingService, SharedData, StorageService } from '@synergy-application/shared-ui';
import { ClientApiService } from '../../../../../app/core/client-api.service';
import { RequestStatus, RequestStatusDescription, Role } from '../../../../../app/enum/common';
import { LocalStorageKeys } from '../../../shared/constants/local-storage.constant';
import { CommonService } from '../../../shared/services/common.service';
import { LogLevel } from 'shared-ui/src/lib/enum/logger';

@Component({
  selector: 'app-my-housing-request',
  templateUrl: './my-housing-request.component.html',
  styleUrl: './my-housing-request.component.scss',
})
export class MyHousingRequestComponent implements OnInit {
  
  status = RequestStatus;

  requestStatus = RequestStatusDescription;

  housingLabel: any; // Stores the housing request labels from the language service

  dateDetails?: DaysInput;

  isMobile = false;

  appConstants = AppConstants;

  options: DropdownOptions[] = SharedData.options;

  tabLabel: TabInput[] = SharedData.tabLabel;

  selectedTab: TabInput | undefined;

  activeSearchQuery = '';

  pastSearchQuery = '';

  activeFilter = SharedData.options[0];

  pastFilter = SharedData.options[0];

  optionValue!: { [key: string]: DropdownOptions | unknown };

  activeToggle = false;

  pastToggle = false;

  data!: ReservationDetailsResult;

  toggleDetails: ToggleInput = { label: '', isChecked: false, icon: '', infoMsg: '' };

  selfReservationOnly = false;

  pagination: PaginationModel = {
    page: 1,
    pageSize: AppConstants.propertyPageSize,
    totalCount: 0
  };

  apiCompleted = true;

  myBookingList: ReservationDetailsModel[] = [];

  lazyLoader = false;
  sidePadding: any;

  constructor(private loggingService: LoggingService,private commonServiceBookingPortal: CommonService,private loaderService: AppLoaderService, private languageService: LanguageService, private clientApiService: ClientApiService, private storageService: StorageService) { }

  ngOnInit(): void {
    if (this.storageService.checkValueExists(LocalStorageKeys.requestProperty)) {
      this.storageService.remove(LocalStorageKeys.requestProperty);
    }
    this.selectedTab = this.tabLabel ? this.tabLabel[0] : undefined;
    if (this.tabLabel && this.tabLabel[0]?.key && this.tabLabel[1]?.key && this.selectedTab?.key) {
      this.optionValue = {
        [this.tabLabel[0].key]: this.options[0] ?? {},
        [this.tabLabel[1].key]: this.options[0] ?? {}
      }
    }
    this.isMobile = checkTab();
    this.housingLabel = this.languageService.getHousingRequest();
    this.toggleDetails = {
      label: this.housingLabel.toggleLabel,
      isChecked: false,
      icon: '',
      infoMsg: ''
    }
    this.showToggle();
    this.getRequest();
    this.showSideBar();
  }
  showToggle() {
    const userInfo = JSON.parse(this.storageService.get(LocalStorageKeys.userInfo));
    this.selfReservationOnly = userInfo?.roles?.includes(Role.travelerManager);
    // Check if any role from userole is in the role array
  }
  /**
   * Handles actions when the window or element is resized.
   * @param event 
   */

  @HostListener('window:resize', ['$event'])
  onResize(event: Event): void {
    this.isMobile = checkTab();
    this.showSideBar();

  }
  showSideBar() {
    this.sidePadding = this.commonServiceBookingPortal.applyLeftPadding()?.sidePadding;
    // Check if any role from userole is in the role array
  }
  /**
   * description - get the event from search and get the response from bookingHistoryService 
   *  * @param tabId
   */
  async getRequest(lazy = false) {
    try {
      if (lazy) this.lazyLoader = true;
      this.apiCompleted = false;
      if (!lazy) this.loaderService.showLoader(true);
      const variable: HousingRequestListModel = {
        status: this.selectedTab?.id == IAction.Active ? StatusDescription[IAction.Active] : StatusDescription[IAction.Past],
        // search : request.search,
        selfReservationsOnly: this.selectedTab?.id == IAction.Active ? this.activeToggle : this.pastToggle,
        page: this.appConstants.page,
        pageSize: AppConstants.propertyPageSize,
        durationFilter: this.selectedTab?.id == IAction.Active ? this.activeFilter.name : this.pastFilter.name,
      }
      if (this.selectedTab?.id === IAction.Active && this.activeSearchQuery) {
        variable.search = {
          where: {
            name: {
              like: this.activeSearchQuery
            }
          }
        }
      }
      if (this.selectedTab?.id === IAction.Past && this.pastSearchQuery) {
        variable.search = {
          where: {
            name: {
              like: this.pastSearchQuery
            }
          }
        }
      }
      const request = { ...variable };
      this.data = await this.clientApiService.getHousingRequestListByStatus(request);
      if (lazy) this.myBookingList = [...this.myBookingList, ...this.data.lead];
      else this.myBookingList = [...this.data.lead];
      this.pagination = { ...this.data.pagination };
    } catch (err) {
      this.loggingService.logException(err as Error, LogLevel.Error)
    }
    finally {
      if (lazy) this.lazyLoader = false;
      this.apiCompleted = true;
      if (!lazy) this.loaderService.showLoader(false);
    }
  }

  /**
   * 
   * @param event - emit the search query
   */
  onSearch(event: string) {
    this.appConstants.page = 1;
  
    if (this.selectedTab?.id === IAction.Active) this.activeSearchQuery = event; else this.pastSearchQuery = event
    if (
      this.selectedTab?.id === IAction.Active && (this.activeSearchQuery?.length > this.appConstants.searchLength || !this.activeSearchQuery)
    ) {
      this.myBookingList = [];
      this.getRequest();
    }

  }

  /**
   * description - Update the selectedTab variable with the received tab ID
   * @param tabId 
   */
  onTabChange(tab: TabInput): void {
    this.selectedTab = tab;
    this.pagination.totalCount = 0;
    this.appConstants.page = 1;
    this.toggleDetails.isChecked = this.selectedTab.id == 1 ? this.activeToggle : this.pastToggle;
    this.myBookingList = [];
    this.getRequest();
  }

  /**
   * 
   * @param toggle - capture toggle event when changed
   */
  onToggleChange(toggle: ToggleInput) {
    if (this.selectedTab?.id == 1) this.activeToggle = toggle.isChecked; else this.pastToggle = toggle.isChecked
    this.getRequest();
  }

  /**
   * 
   * @param status - to get the status of housing request
   */
  getStatusMessage(status: number): string {
    return this.requestStatus[status as RequestStatus];
  }

  /**
   * 
   * @param event - to get the event of page
   */
  onScroll(event: Event) {
    const target = event.target as HTMLElement;
    const scrollTop = target.scrollTop;
    const clientHeight = target.clientHeight;
    const scrollHeight = target.scrollHeight;
    if (scrollTop + clientHeight + 50 >= scrollHeight
      && this.apiCompleted && this.pagination.totalCount > this.myBookingList.length) {
      this.appConstants.page += 1;
      this.getRequest(true);
    }
  }

  /**
   * 
   * @param event - to get the event of dropdown in housing request
   */
  dropdownBy(event: OptionModel) {
    if (event) {
      event.isSelected = false;
      this.optionValue[this.selectedTab?.key ?? ""] = event;
      const filterOption = {
        id: typeof event.id === 'string' ? parseInt(event.id, 10) : event.id,
        name: event.name,
      };
      if (this.selectedTab?.id === 1) {
        this.activeFilter = filterOption;
      } else {
        this.pastFilter = filterOption;
      }
      this.getRequest();
    }
  }

  /**
   * Displays a confirmation modal to the user for canceling a request. 
   * @returns 
   */
  async cancelRequest() {

  }
}
