import { sourceIcon } from "../constants/source-icon";

export interface LocationItem {
  name: string
  placeId?: string
  latitude?: number
  longitude?: number
}


export interface LocationDetail {
  locationDetails: LocationItem
  moveIn: string
  moveOut: string
}

export interface GeographyModel {
  latitude: number;
  longitude: number
}

export interface IDistanceMatrix {
  origin: IMapBase,
  destination: string,
  mode: string
}
export interface IMapBase {
  lat: number;
  lng: number
}
export interface LocationGroup {
  name: string;
  items: LocationItem[];
}
export interface SearchDetail {
  locationDetails: LocationItem,
  moveIn: string,
  moveOut: string
}
export interface LocationSearchData {
  label: string;
  type: string;
  maxLength?: number;
  placeholder: string;
  value: string;
  isShowErrorMessage: boolean;
  isRequired?: boolean;
  groups: LocationGroup[] | [];
  showErrorMessage?: string;
  leadRef?:string
}

export interface NearByPlaces {
  business_status?: string;
  geometry: {
    location: {
      lat: number,
      lng: number
    }
  };
  icon: string;
  icon_background_color: string;
  icon_mask_base_uri: string;
  name: string;
  place_id: string;
  reference: string;
  types: string[];
  vicinity: string;
}

export interface MapModel {
  latitude: number;
  longitude: number;
  name: string;
  icon?: string;
  clickable?: boolean;
  type: string;
  id?: string | number;
}

export interface IMapCollectionView {
  [key: string]: MapModel[]
}
export interface IPlace {
  geometry: {
      location: ILocation;
  };
}

export interface ILocation {
  lat: () => number;
  lng: () => number;
}

export interface IDistanceMatrixConfig {
  id: number
  icon: string,
  apiKey: string,
  distance: string,
  duration: string
}
export interface Distance {
  text: string; // e.g., "4.2 km"
  value: number; // e.g., 4233 (in meters)
}

export interface Duration {
  text: string; // e.g., "1 hour 0 mins"
  value: number; // e.g., 3575 (in seconds)
}

export interface TravelTime {
  distance: Distance;
  duration: Duration;
  status: string; // Status of the response (e.g., "OK", "NOT_FOUND")
}

export class MapFactory {
  latitude: number;
  longitude: number;
  name: string | undefined;
  type: string;
  clickable: boolean;
  icon: string;
  id: number;

  constructor(data: any, clickable?: boolean, type?: string, icon?: string) {

    this.id = data.id ?? 0;
    this.latitude = data.latitude ?? 0;
    this.longitude = data.longitude ?? 0;
    this.name = data?.name ?? undefined;
    this.type = type ?? "property";
    this.clickable = clickable ?? false;
    this.icon = icon ?? sourceIcon[type as keyof typeof sourceIcon] ?? sourceIcon.property
  }
}
