import { Component, HostListener, OnInit } from '@angular/core';
import { LanguageService } from '../../../shared/services/language.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Amenities, AmenitiesType, Attachments, AvailableUnit, CarouselResponsive, GalleriaResponsive, IMapBase, IMapCollectionView, InputData, IPropertyAttachment, IPropertyDetail, LocationItem, LocationSearchData, MapFactory, MapModel, OptionModel, TCarousel, TextAreaInput, ToggleInput, UserProfile } from 'shared-ui/src/lib/models';
import { LocationService } from 'shared-ui/src/lib/services/location.service';
import * as featureConfig from "../../config/nearby-places.config.json";
import { AppLoaderService, calculateDateDifference, calculateLastDate, CdnContentService, checkScreenSize, formDate, GoogleAnalyticsService, LoggingService, SharedApiService, StorageService } from '@synergy-application/shared-ui';
import { IAmenitiesFilter } from 'shared-ui/src/lib/models/amenities-filter.model';
import { AppConstants, GoogleAnalyticsKey, IconPath } from '../../../../core/app-constant';
import { DatePipe } from '@angular/common';
import { LocalStorageKeys } from '../../../shared/constants/local-storage.constant';
import { PropertyDetailRequest } from '../../../request/models/rfh.model';
import { cloneDeep } from '@apollo/client/utilities';
import { environment } from '../../../../../../src/environments/environment';
import { GalleryService } from 'shared-ui/src/lib/services/gallery.service';
import { Subscription } from 'rxjs';
import { RequestHousingModel } from '../../models/request-housing.model';
import { ROUTESPATH } from '../../../../../app/core/routes-constant';
import { Role } from 'apps/booking-portal/src/app/enum/common';
import { CommonService } from '../../../shared/services/common.service';
import { LogLevel } from 'shared-ui/src/lib/enum/logger';


@Component({
  selector: 'app-property-detail-page',
  templateUrl: './property-detail-page.component.html',
  styleUrl: './property-detail-page.component.scss',
})
export class PropertyDetailPageComponent implements OnInit {

  propertyData!: IPropertyDetail;
  numVisible = 2;

  numScroll = 2;

  propertyLabel: any;

  locationSearchData: LocationSearchData = {
    label: AppConstants.searchNeighPlaceHolder,
    type: AppConstants.fieldType.text,
    maxLength: AppConstants.input.max,
    placeholder: '',
    value: '',
    isShowErrorMessage: false,
    groups: []
  }

  searchNeighbor!: string

  imageTour: IPropertyAttachment[] | [] = [];

  features: IAmenitiesFilter[] | [] = [];

  appConstants = AppConstants;

  responsiveOptions: CarouselResponsive[] = [
    {
      breakpoint: "992px",
      numVisible: 1,
      numScroll: 1
    }
  ]

  amenitiesResponsiveOptions: CarouselResponsive[] = [
    {
      breakpoint: '1500px',
      numVisible: 4,
      numScroll: 1
    },
    {
      breakpoint: '1300px',
      numVisible: 3,
      numScroll: 1
    },
    {
      breakpoint: '1150px',
      numVisible: 2,
      numScroll: 1
    },
    {
      breakpoint: '768px',
      numVisible: 3,
      numScroll: 1
    },
    {
      breakpoint: '560px',
      numVisible: 2,
      numScroll: 1
    }
  ]

  displayBasic = false;

  fullScreen = true;

  galleriaCircular = true;

  showItemNavigators = false;

  galleriaResponsive: GalleriaResponsive[] = ([
    {
      breakpoint: this.appConstants.responsive.screen1,
      numVisible: 5
    },
    {
      breakpoint: this.appConstants.responsive.screen2,
      numVisible: 3
    },
    {
      breakpoint: this.appConstants.responsive.screen3,
      numVisible: 2
    },
    {
      breakpoint: this.appConstants.responsive.screen4,
      numVisible: 1
    }
  ])

  showCount = true;

  nearByPlaces: IMapCollectionView = {};

  mapSource!: MapModel;


  objRequestDetails: RequestHousingModel = {
    propertyId: '',
    moveIn: '',
    moveOut: '',
    lat: 0,
    long: 0,
    noOfGuest: this.appConstants.defaultGuest,
    noOfBedrooms: ''
  };

  playTour = false;

  formDetail!: any;

  searchPlace!: MapModel;

  gallerySubscriber!: Subscription;

  googleAnalyticsConstants = GoogleAnalyticsKey;

  isMobile = false;

  userInfo!: UserProfile;

  sidePadding!: string;

  isMediumSize = false;
  isShowSideMenu: boolean;

  constructor(
    private languageService: LanguageService,
    private sharedApiService: SharedApiService,
    private router: Router,
    private datePipe: DatePipe,
    private cdnContentService: CdnContentService,
    private storageService: StorageService,
    private activatedRoute: ActivatedRoute,
    private locationService: LocationService,
    private loaderService: AppLoaderService,
    private galleryService: GalleryService,
    private googleAnalytics: GoogleAnalyticsService,
    private commonService: CommonService,
    private loggingService: LoggingService
  ) { }

  async ngOnInit() {
    this.activatedRoute.queryParams.subscribe(async (qParams) => {
      const query: any = qParams ? JSON.parse(atob(qParams['query'])) : undefined;
      if (query) {
        this.objRequestDetails = {
          propertyId: query.id,
          moveIn: query.moveIn,
          moveOut: query.moveOut,
          name: query.locationDetails.name,
          lat: parseFloat(query.locationDetails.latitude),
          long: parseFloat(query.locationDetails.longitude),
          noOfGuest: this.appConstants.defaultGuest,
          noOfBedrooms: ''
        }
        this.features = (featureConfig as any).default;
        this.features = this.features.map((current: IAmenitiesFilter) => ({
          ...current, url: IconPath[current.url as keyof typeof IconPath]
        }))
      }
      this.propertyLabel = await this.languageService.getPropertyKey();
      await this.fetchPropertyDetail();
      this.isMobile = checkScreenSize();
      this.isMediumSize = this.checkLapMediumSize();
      this.showSideBar();
    })
    this.gallerySubscriber = this.galleryService.getGallery().subscribe((res) => {
      this.displayBasic = false
    })
    this.isMobile = checkScreenSize();
    this.isMediumSize = this.checkLapMediumSize();
    this.showSideBar();
  }

  /**
   * description - To Get the date from datepicker
   * @param $event 
   */
  getDateFromDatepicker(event: any) {
    if (event?.value?.length && event.mode == this.appConstants.mode.range) {
      this.objRequestDetails.moveIn = event.value[0];
      this.objRequestDetails.moveOut = event.value[1];
    }
    
    if (this.formDetail) {
      this.formDetail.sections[0].fields[1].value = calculateDateDifference(this.objRequestDetails.moveIn, this.objRequestDetails.moveOut)
      this.objRequestDetails.noOfGuest = calculateDateDifference(this.objRequestDetails.moveIn, this.objRequestDetails.moveOut)
    }
  }

  checkLapMediumSize() {
    return window.innerWidth > 992 && window.innerWidth <= 1280;
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: Event): void {
    this.isMobile = checkScreenSize();
    this.isMediumSize = this.checkLapMediumSize();
    this.showSideBar();
  }

  showSideBar() {
    this.isShowSideMenu = this.commonService.sideMenuShow();
    this.sidePadding = this.commonService.applyLeftPadding()?.sidePadding;
    // Check if any role from userole is in the role array
  }

  getGuestCount(event: InputData | ToggleInput | TextAreaInput) {
    if (event.value) {
      this.objRequestDetails.noOfGuest = Number(event.value);
      if (this.formDetail && this.objRequestDetails.moveIn && this.objRequestDetails.moveOut) {
        const date = calculateLastDate(this.objRequestDetails.noOfGuest, this.objRequestDetails.moveIn);
        this.objRequestDetails.moveIn = this.datePipe.transform(date.currentDate, 'MMM dd, yyyy') || this.appConstants.invalidDate;
        this.objRequestDetails.moveOut = this.datePipe.transform(date.lastDate, 'MMM dd, yyyy') || this.appConstants.invalidDate;
        this.formDetail.sections[0].fields[0].value = [this.objRequestDetails.moveIn, this.objRequestDetails.moveOut];
      }
    } else {
      this.objRequestDetails.noOfGuest = 0;
    }
  }
  /** 
  * description - To navigate to the request Housing Form 
  */
  requestHousingMethod() {
    const requestObj: PropertyDetailRequest = {
      img: this.propertyData.propertyImage[0].url,
      propertyId: this.propertyData.propertyId,
      propertyName: this.propertyData.propertyName,
      address: this.propertyData.address,
      objRequestDetails: this.objRequestDetails,
      propertyData: this.propertyData,
      moveIn: this.objRequestDetails.moveIn,
      moveOut: this.objRequestDetails.moveOut,
      selectedUnits: this.propertyData.availableUnits.filter(e => e.roomType == this.formDetail?.sections[0].fields[2]?.value?.value),
      los: calculateDateDifference(this.objRequestDetails.moveIn, this.objRequestDetails.moveOut),
      additionInfo: [{
        label: this.propertyLabel.date,
        value: formDate([this.objRequestDetails.moveIn, this.objRequestDetails.moveOut], this.datePipe),
        icon: '/assets/icon/date_range.svg',
      },

      {
        label: this.propertyLabel.totalNights,
        value: calculateDateDifference(this.objRequestDetails.moveIn, this.objRequestDetails.moveOut),
        icon: '/assets/icon/nights_stay.svg'
      },

      {
        label: this.propertyLabel.apartmentType,
        value: this.formDetail.sections[0].fields[2].value?.value,
        icon: '/assets/icon/apartment.svg'
      }]
    }
    this.storageService.set(LocalStorageKeys.requestProperty, JSON.stringify(requestObj))
    //tracking Events using Google Analytics
    const selectPropertyType = {
      propertyName: requestObj.propertyName,
      propertyType: requestObj.selectedUnits,
      moveIn: requestObj.moveIn,
      moveOut: requestObj.moveOut,
      address: requestObj.address,
    };
    this.googleAnalytics.trackEvent(this.googleAnalyticsConstants.selectUnitType, selectPropertyType);
    this.router.navigateByUrl(ROUTESPATH.REQUESTFORM);
  }
  /**
    * 
    * @param event - TO show the all image 
    */
  showAllImage(event: Event, playTour: boolean) {
    this.playTour = playTour;
    this.displayBasic = true;
    this.galleryService.showGallery({
      attachments: playTour ? this.imageTour : this.propertyData.propertyImage,
      fullScreen: this.fullScreen,
      visible: this.displayBasic,
      responsiveOptions: this.galleriaResponsive,
      containerClass: "collection",
      showCount: true,
      numVisible: 6,
      circular: this.galleriaCircular,
      apiKey: "url",
      imageTour: playTour,
      showThumbnails: !playTour,
      showItemNavigators: playTour ? false : this.showItemNavigators,
    })
  }
  /**
   * 
   * @param event - TO show all the image 
   */
  visibilityChange(event: boolean) {
    this.displayBasic = event;
  }
  /**
   * 
   * @param attachment Get the place based on Lat and long when selecting the option from UI
   */
  clickListener(attachment: TCarousel) {
    if (attachment.isSelected) this.fetchPlacesByLatLong(attachment);
    else {
      const { type } = attachment;
      if (type) {
        const { [type]: removedProperty, ...rest } = this.nearByPlaces;
        this.nearByPlaces = { ...rest };
      }
    }
  }
  /**
   * 
   * @param attachment Get the place based on Lat and long
   */
  async fetchPlacesByLatLong(attachment: TCarousel) {
    const { type, url } = attachment;
    const places: IMapCollectionView = {};
    this.locationService.fetchNearByPlaces(type ?? '', this.objRequestDetails.lat, this.objRequestDetails.long).then(res => {
      if (res) {
        const response = res
        const mapModel: MapModel[] = response.map((place: google.maps.places.PlaceResult) => {
          return new MapFactory({
            ...place,
            latitude: place.geometry?.location?.lat() ?? 0, longitude: place.geometry?.location?.lng() ?? 0,
            id: place.place_id,
          }, true, type, url) as MapModel
        });
        if (!this.nearByPlaces[type as keyof typeof this.nearByPlaces]) places[type ?? ''] = mapModel;
        this.nearByPlaces = { ...this.nearByPlaces, ...places };
      }
    })
      .catch(error => {
        this.loggingService.logException(error, LogLevel.Error)
      })

  }
  async mapToModel(data: any) {
    if (data) {
      const amenitiesArray: AmenitiesType[] = [];
      let aminity: Amenities[] = [];
      const units: AvailableUnit[] = [];
      const images: any = [];
      data?.amenities?.forEach((element: AmenitiesType) => {
        aminity = [];
        element.amenities.forEach((k: Amenities) => {
          aminity.push({
            // icon:k.iconPath,
            svgPath: `${this.cdnContentService.blobServiceUrl}${environment.config.azure.publicContainerName}${k.svgPath}`,
            name: k.name
          })
        });
        amenitiesArray.push({
          amenitiesType: element.amenitiesType,
          amenities: aminity
        })
      });
      data?.units?.forEach((e: any) => {
        const [value, unit] = e?.type?.split(' ');
        units.push({
          roomType: e.type,
          bedCount: e.type == AppConstants.studio ? e.noOfUnitTypes.concat(AppConstants.studio) : value == 1 ? value.concat(this.propertyLabel.bed) : value.concat(this.propertyLabel.beds),
          bathroomCount: e.bathroomCount,
          attachments: [{
            url: e.attachments
          }],
          price: e.startingAtRate
        })
      })
      data?.attachments?.forEach((attach: Attachments) => {
        images.push({
          url: `${this.cdnContentService.blobServiceUrl}${environment.config.azure.publicContainerName}/${attach.imageUrl}`
        })
      })
      const from: IMapBase = {
        lat: this.objRequestDetails.lat,
        lng: this.objRequestDetails.long
      }
      const to: IMapBase = {
        lat: data?.address?.latitude,
        lng: data?.address?.longitude
      }
      const result = await this.locationService.calculateDistance(from, to, true)
      const miles = result?.distance ? result?.distance?.text?.split(' ') : [];
      const distance: number = miles?.length ? Math.round(miles[0] ? parseFloat(miles[0]) * 0.621371 : 0) : 0
      const resultData: IPropertyDetail = {
        propertyId: data?.propertyId,
        propertyImage: images,
        propertyName: data.propertyName,
        address: data?.address?.address1,
        city: data?.address?.city,
        country: data?.address?.country,
        postalCode: data?.address?.postalCode,
        countryCode: data?.address?.countryCode,
        fullAddress: data?.address?.fullAddress,
        description: data?.address?.description,
        noOfBeds: data?.propertyType,
        distance: distance + this.propertyLabel.distanceLabel,
        details: data?.details,
        amenities: amenitiesArray,
        latitude: parseFloat(data?.address?.latitude),
        longitude: parseFloat(data?.address?.longitude),
        cancellationPolicy: data?.bookingInfo?.cancellationPolicy,
        // rules: [],
        availableUnits: units
      }
      this.propertyData = resultData;
      const eventTracking = {
        propertyName: this.propertyData.propertyName,
        latitude: this.objRequestDetails.lat,
        longitude: this.objRequestDetails.long
      }
      this.googleAnalytics.trackEvent(this.googleAnalyticsConstants.viewPropertyDetails, eventTracking);
    }

  }

  /**
   * 
   * @param propertyId - Get the property detail based on PropertyID
   */
  async fetchPropertyDetail(propertyId?: number) {
    this.loaderService.showLoader(true);
    //tracking Events using Google Analytics service

    this.sharedApiService.getPropertyDetailById(this.objRequestDetails.propertyId || '')
      .then(async (res: IPropertyDetail) => {
        if (res) {
          res = cloneDeep(res);
          await this.mapToModel(res);
          this.formInit();
          this.imageTour = this.propertyData.propertyImage.filter((el: any) => el.embeddedUrl)?.length ? this.propertyData.propertyImage[0].embeddedUrl : AppConstants.imageTour;
          this.mapSource = new MapFactory({ ...this.propertyData, name: this.propertyData.propertyName, id: propertyId }, false, "property") as MapModel
          this.loaderService.showLoader(false);
        }
      })
      .catch((error: Error) => {
        this.loaderService.showLoader(false);
        this.loggingService.logException(error, LogLevel.Critical)
      })
  }
  /**
   * create a form field for raising a housing request
   */
  async formInit() {
    const dropdownOption = this.propertyData?.availableUnits?.reduce((acc: Array<any>, item: AvailableUnit, index) => {
      acc.push({
        value: item.roomType,
        id: index
      })
      return acc;
    }, []);
    if (this.storageService.checkValueExists(LocalStorageKeys.requestProperty)) {
      this.storageService.remove(LocalStorageKeys.requestProperty);
    }

    this.locationSearchData.placeholder = this.propertyLabel.search;
    this.formDetail =
    {
      sections: [
        {
          fields: [{
            label: this.propertyLabel.requestLabel1,
            placeholder: this.propertyLabel.requestLabel2,
            type: this.appConstants.fieldType.date,
            value: [new Date(this.objRequestDetails.moveIn), new Date(this.objRequestDetails.moveOut)],
            mode: this.appConstants.mode.range,
            size: 12,
            iconPosition: 'suffix',
            iconSize: 'medium',
            isBorder: 'border',
            iconName: 'calendar'
          },
          {
            label: this.propertyLabel.requestLabel2,
            placeholder: this.propertyLabel.requestLabel2,
            type: this.appConstants.fieldType.number,
            value: calculateDateDifference(this.objRequestDetails.moveIn, this.objRequestDetails.moveOut) || 1,
            size: 12,
            minLength: this.appConstants.number.min,
            maxLength: this.appConstants.number.max,
          },
          {
            label: this.propertyLabel.requestLabel3,
            type: this.appConstants.fieldType.dropdown,
            value: dropdownOption?.length ? dropdownOption[0] : '',
            options: dropdownOption,
            isRegex: false,
            size: 12,
            isSearch: false,
          }]
        }
      ]
    }
  }

  onLocationSearch(searchTerm?: string) {
    try {
      if (searchTerm) {
        this.locationService.fetchNearByPlaces(undefined, this.objRequestDetails.lat, this.objRequestDetails.long, searchTerm)
          .then((response) => {
            if (response?.length) {
              const result: LocationItem[] = response?.map(ele => ({
                name: ele && ele.name ? ele.name : '',
                placeId: ele && ele.place_id ? ele.place_id : ''
              }))
              this.locationSearchData.groups = [{
                name: this.propertyLabel.officeLocation,
                items: result
              }];
            }
          }).catch((error: Error) => {
            error
          });
      }

    } catch (error) {
      this.locationSearchData.isShowErrorMessage = true;
      this.loggingService.logException(error as Error, LogLevel.Error)
    }
  }

  onSelectLocation(event: LocationItem) {
    if (event) {
      if (event.placeId) {
        this.searchNeighbor = event.placeId;
        this.locationService.fetchPlaceDetails(event.placeId)
          .then(res => {
            if (res) {
              this.searchPlace = new MapFactory({
                ...res,
                latitude: res.geometry?.location?.lat() ?? 0, longitude: res.geometry?.location?.lng() ?? 0,
                id: res.place_id,
              }, true, this.appConstants.neighbor, IconPath.pin) as MapModel
            }
          })
          .catch(err => {
            this.loggingService.logException(err, LogLevel.Critical)
          })
      }
    }

  }
}

