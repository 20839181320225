import { ViewType } from "../enum/lead-info";

export interface GalleriaResponsive {
  breakpoint: string;
  numVisible: number;
}

export interface UnknownKeyValuePairModel {
  [key: string]: string
}

export interface GalleryOptionsModel {
  attachments?: UnknownKeyValuePairModel[] | string[];
  showItemNavigators?: boolean;
  showThumbnails?: boolean;
  showIndicators?: boolean;
  showIndicatorsOnItem?: boolean;
  apiKey?: keyof UnknownKeyValuePairModel;
  imageTour?: boolean;
  showCount?: boolean;
  responsiveOptions?: GalleriaResponsive[] | [];
  containerStyle?: { [key: string]: string };
  containerClass?: string | undefined;
  visible?: boolean;
  numVisible?: number;
  circular?: boolean;
  fullScreen?: boolean;
  close?: boolean
}

export interface PaginationModel {
  page: number;
  pageSize: number;
  totalCount: number;
}
export interface TCarousel {
  isSelected?: boolean;
  label?: string;
  url: string;
  type?: string;
  id?: string | number
}

export interface CarouselResponsive {
  breakpoint: string;
  numVisible: number;
  numScroll: number;
}

export interface TabInput {
  id: number;
  label: string;
  key?: string
}

export interface TopNavInput {
  img?: string;
  label: string;
  url?: string;
  dropdownOptions?: Array<TopNavOption>;
}

export interface TopNavOption {
  img?: string;
  label: string;
  url: string;
}

export interface OptionModel {
  id: number | string;
  name: string;
  value?: string | boolean
  isSelected?: boolean
  code?: string;
  dialCode?: string;
  flag?: string;
  isDisabled?: boolean;
}
export interface DropdownInput {
  placeholder?: string;
  value: any;
  label: string;
  showErrorMessage: string;
  isShowErrorMessage: boolean;
  type: string;
  options: Array<OptionModel | ClientUserListModel>;
  isSearch: boolean;
  pageLimit: number;
  allowApiSearch: boolean;
  multiSelectable?:boolean;
  name?:string;
  totalCount?:number;
}

export interface ClientUserListModel {
  address: string | null;
  branch: string | null;
  clientName: string | null;
  companyName: string;
  costCenter: string | null;
  designation: string | null;
  email: string;
  id: string;
  manager: string | null;
  mobileNo: string;
  name: string;
  region: string | null;
  roles: string | null;
  imgUrl?: string;
}
export interface ToggleInput {
  infoMsg?: string;
  label?: string;
  isChecked: boolean;
  icon?: string;
  value?: string;
  type?: string;
  leadRef?: string;
  id?: number;
  leftInfo?:string;
  rightInfo?:string;
}

export interface DateInput {
  label: string;
  showErrorMessage?: string;
  isShowErrorMessage?: boolean;
  errorMessage?: string;
  placeholder: string;
  value: any;
  isRequired?: boolean;
  type?: string;
  mode?: string;
  icon?: boolean;
  minDate?: Date;
  maxDate?: Date;
  leadRef?: string;
  iconPosition?: string;
  iconSize?: string;
  iconName?: string;
  isBorder?: string;
  height?: string;
}
export interface CheckBoxInput {
  isChecked: boolean;
  label: string;
  id?: number;
  isDisabled?: boolean
  size?:string;
  isFilled?:boolean;
  description?:string;
 
}

export enum CheckboxSizeModel {
  small = 'small',
  medium = 'medium ',
  large = 'large'
}

export enum DatePicketType {
  border = 'border',
  borderless = 'borderless'
}

export enum IconPositionModel {
  prefix = 'prefix',
  suffix = 'suffix'
}

export enum IconSizeModel {
  small = 'small',
  medium = 'medium ',
  large = 'large'
}
export enum IconNameModel {
  clock = 'clock',
  calendar = 'calendar'
}

export interface DropdownOptions {
  id: number | string;
  name: string;
  code?: string;
  dialCode?: string;
  flag?: string;
}

export interface InputData {
  placeholder: string;
  value?: any;
  type: string;
  minLength: number;
  maxLength: number;
  label?: string;
  showErrorMessage?: string;
  isShowErrorMessage?: boolean;
  isRequired?: boolean;
  isDisabled?: boolean;
  isRegex?: boolean;
  errorMessage?: string;
  isRegexErrorMessage?: string;
  leadRef?: string;
  readOnly?: boolean;
};
export interface LoginModel {
  logo: string,
  img1: string,
  welcomeText: string,
  domainName: string,
  colorScheme: string
};

export type ICountryModel = {
  placeholder: string;
  value?: any;
  label: string;
  apiKey?: string;
  type: string;
  isRegex: boolean;
  isSearch: boolean;
  pageLimit: number;
  allowApiSearch: boolean;
  option: any;
};
export interface CountryOptionModel {
  name: string;
  code: string;
  imgUrl: string;
  phoneCode: string;
  mobileNumber: string;
  isSelected: boolean;
  pattern: string;
  placeHolder: string;
}
export interface AmenitiesModel {
  name: string; // The name of the amenity item
  svgPath: string; // The path to the icon image
  isSelected: boolean; // Indicates if the item is selected,
  id: string;
}

export interface SlideRange {
  lower: number;
  upper: number;
}
export interface SliderModel {
  minValue: number;
  maxValue: number;
  step?: number;
  isRange: boolean;
}

export interface SliderModelDistance extends SliderModel {
  value: number;
}
export interface PaginationInput {
  page: number;
  pageSize: number;
}

export interface TabModel { 
  id: number;
  label: string;
  key: string;
}

export interface SearchInput {
  where: {
    name: {
      like: string
    }
  }
}

export interface RequestInput {
  pagination: PaginationInput;
  search?: SearchInput;
}
export interface IPagination {
  page: number;
  pageSize: number;
  totalCount?: number;
}

export interface DropdownEvent {
  type: string,
  currentPage: number,
  searchQuery: string | null
}

export interface SliderModelPrice extends SliderModel {
  value: SlideRange;
}

export interface TextAreaInput {
  name?: string,
  placeholder: string;
  value: string;
  type: string;
  showErrorMessage?: string;
  isShowErrorMessage?: boolean;
  isRequired?: boolean;
  isDisabled?: boolean;
  minLength: number;
  maxLength: number;
  isRegex?: boolean;
  label?: string;
  errorMessage?: string;
  isRegexErrorMessage?: string;
  leadRef?: string;
}

export type SearchStyle = {
  fontSize?: string
}
export interface FieldSections {
  name: string;
  labelName: string;
  fields: Array<InputData | CheckBoxInput | TextAreaInput>;
}

export interface CountriesModel {
  id: string;
  countryName: string;
  countryCode: string;
  mobileNumber: string;
  imageUrl: string;
  pattern: string;
  placeHolder: string;
}

export interface CountriesResponseModel {
  countries: CountriesModel[];
  pagination: IPagination
}

export interface DetailViewModel {
    name?: string;  
    group: GroupModel[]; 
    fields: FieldModel[];
  }
  export interface GroupModel {
    name: string;
    fields: FieldModel[];
    showDetails?: boolean;
    showMore?: boolean;
  }
  export interface FieldModel {
    name: string;
    isDisabled?:boolean;
    displayName: string; 
    value?: string; 
    icon?: string;
    size?: number;
    showDetails?: boolean; 
    showMore?: boolean;
    type?: ViewType;
    isChecked?: boolean;
    isToggle?: boolean;
    options?: OptionModel[];
    label?: string;
    description?: string;
    dateFormat?: string;
    placeholder?: string,
    toggleDetails?: ToggleInput[],
    mode?: string,
    checkBoxOptions?: CheckBoxInput[],
    sectionHeader?:string,
    required?:boolean
  }
/**
  * Card Info configuration
*/
export interface ContentCardInfoModel {
  label: string;
  value?: string | string[];
  type?: CardContentType;
  classList?: string;
  apiKey?: string;
  dateFormat?: string;
  templatedString?: string;
  readDisplayValueFrom?: any;
  defaultNoDataText?: string;
}
export interface progressDataModel {
  isCompleted: boolean;
  fileName: string;
  progress: number;
  total: number;
}

export interface FileDetailsModel {
  id?: string | number;
  imageUrl: string;
  name: string;
  size: number;
  lastModifiedDate?: Date;
  uploadedBy: string,
  isDeleted?: boolean,
  isFileNew?: boolean,
  file?: File,
  fileType?: string
}

export enum AllowedFileTypes {
  PNG = 'image/png',
  JPEG = 'image/jpeg',
  PDF = 'application/pdf',
  DOCX = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  XLSX = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
};

export enum CardContentType {
  Text = 'text',
  Date = 'date',
  Boolean = 'boolean',
  MultiText = 'multiText'
}

export interface DropDownInput {
  request: {
    type: string;
    search?: SearchInput;
    pagination?: PaginationInput
  }
}

export interface DropDownResponseModel {
  data: DropDownCommonResponseModel[];
  pagination: IPagination;
}
 
export interface DropDownCommonResponseModel {
  value: string;
  key: string;
  iconPath?: string;
  svgPath?: string;
  countryCode?: string;
  imageUrl?: string;
  placeHolder?: string;
  pattern?: string;
  currencyId?: string;
  currencySymbol?: string;
  firstName?: string;
  lastName?: string;
  email?: string;
  languageCode?: string;
  region?: string;
}
