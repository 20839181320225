import { Component, OnInit } from '@angular/core';
import { LanguageService } from '../../../shared/services/language.service';
import { IFaqModel } from '../../models/faq.model';
import { ClientApiService } from 'apps/booking-portal/src/app/core/client-api.service';
import { cloneDeep } from '@apollo/client/utilities';
import { AppConstants } from 'apps/booking-portal/src/app/core/app-constant';
import { AppLoaderService } from '@synergy-application/shared-ui';
import { CommonService } from '../../../shared/services/common.service';

@Component({
  selector: 'app-frequent-question',
  templateUrl: './frequent-question.component.html',
  styleUrl: './frequent-question.component.scss',
})
export class FrequentQuestionComponent implements OnInit {
  languageLabel: any;
  appConstants = AppConstants;
  faQArray!: IFaqModel[];
  sidePadding: any;
  constructor(private commonServiceBookingPortal: CommonService,private languageService: LanguageService, private loaderService: AppLoaderService, private clientApiService: ClientApiService) { }
  async ngOnInit() {
    this.languageLabel = this.languageService.getCommonKey();
    this.loaderService.showLoader(true);
    this.faQArray = cloneDeep(await this.clientApiService.getFaq());
    this.loaderService.showLoader(false);
    this.faQArray.map((el: IFaqModel) => {
      el.isExpanded = false;
    })
    this.showSideBar();
  }
  expand(section: IFaqModel) {
    section.isExpanded = !section.isExpanded;
  }
  showSideBar() {
    this.sidePadding = this.commonServiceBookingPortal.applyLeftPadding()?.sidePadding;
    // Check if any role from userole is in the role array
  }
}
