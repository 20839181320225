<ion-grid class="grid-col">
    <ion-row class="semiBold-xl-text-22px ">
        {{languageLabel?.requestInfoLabel}}
    </ion-row>
    <ion-row *ngFor="let data of instruction">
        <ion-col class="instruction-css regular-sm-text">
            <img alt="" [src]="data.icon">
            <div class="border-css medium-sm-text">{{data.text}}</div>
        </ion-col>
    </ion-row>
</ion-grid>