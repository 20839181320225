import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { GalleryOptionsModel } from '../models';

@Injectable({
  providedIn: 'root'
})
export class GalleryService {

  constructor() { }

  private subject = new Subject<any>();

  getGallery() { 
    return this.subject.asObservable();
  }

  showGallery(input: GalleryOptionsModel) { 
    return this.subject.next(input);
  }

  dismissGallery() { 
    return this.subject.next({ close: true });
  }
}
