import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { AppConstants, ImagePath, ToastConstants } from '../../constants/shared-constant';
import { AllowedFileTypes, FileDetailsModel, progressDataModel } from '../../models/shared-common.model';
import { interval, Subscription } from 'rxjs';
import { AlertService } from '../../services/alert.service';
import { AlertType, ToastModel, ToastModelEnum } from '../../models';
import { uuidv4 } from '../../core/helper';
import { Constant, IconPath } from 'shared-ui/src/core/constant';
import { CommonService } from '../../services/common.service';


@Component({
  selector: 'lib-browse-file',
  templateUrl: './browse-file.component.html',
  styleUrl: './browse-file.component.scss',
})
export class BrowseFileComponent {
  @Input() fileList: FileDetailsModel[] = [];

  @ViewChild('fileInput') fileInput!: ElementRef<HTMLInputElement>;

  @Output() fileListChange = new EventEmitter<FileDetailsModel[]>();

  appConstants = AppConstants;

  imagePath = ImagePath;

  isDragging = false;

  AllowedFileTypes = AllowedFileTypes;

  progressData: progressDataModel | null = null;

  uploadProgressSub?: Subscription;

  isUploadComplete = false;

  icon: Record<string, string> = IconPath;

  constants = ToastConstants

  constructor(
    private alertService: AlertService,
    private commonService: CommonService,
  ) {}

  /*** 
 * Method to handle drag over event
 * @event - Triggered when a file is dragged over the div tag
 */
  onDragOver(event: DragEvent) {
    event.preventDefault();
    this.isDragging = true;
  }

  /*** 
 * Method to handle drag leave event
 * @event - Triggered when a file is dragged out of the div tag
 */
  onDragLeave(event: DragEvent) {
    event.preventDefault();
    this.isDragging = false;
  }

  /*** 
 * Method to handle the drop event when a file is dropped
 * @event - Triggered when a file is dropped onto the div tag
 */
  onDrop(event: DragEvent) {
    event.preventDefault();
    this.isDragging = false;
    const files = event.dataTransfer?.files;
    if (files && files.length > 0) {
      this.handleFile(files);
    }
  }

  /*** 
 * Method to handle file selection through input
 * @event - Triggered when a file is selected using the file input field
 */
  onFileSelected(event: Event) {
    const input = event.target as HTMLInputElement;
    if (input.files && input.files.length > 0) {
      this.handleFile(input.files);
    }
  }

  /*** 
 * Method to handle the selected or dropped file
 * @file - The file object to be processed
 */
  handleFile(fileList: FileList) {
    const allowedTypes = Object.values(AllowedFileTypes);
    const acceptedFiles: File[] = [];
    // Convert FileList to an array and iterate
    Array.from(fileList).forEach((file: File) => {
      if (allowedTypes.includes(file.type as AllowedFileTypes)) {
        const path = URL.createObjectURL(file);
        const fileType = this.getFileType(file);
        this.fileList.push({
          imageUrl: path,
          name: file.name,
          size: file.size,
          lastModifiedDate: new Date(file.lastModified),
          isFileNew: true,
          uploadedBy: '',
          id: uuidv4(),
          file,
          fileType
        });
        acceptedFiles.push(file);
      } else {
        // Show a custom toast for unsupported file type
        const toaster: ToastModel = {
          message: this.appConstants.supportedLabel,
          header: this.constants.invalidFile,
          duration: 1500,
          position: 'top',
        };
       this.commonService.presentToast(ToastModelEnum.Error, toaster);
      }
    });
  }

  /*** 
   * Method to calculate the file size
   * @param size to get the value of size
   */
  formatFileSize(size: number): string {
    return (size / (1024 * 1024)).toFixed(2) + this.appConstants.mbSize;
  }

  /*** 
   * Starts a mock upload process for the file with a progress bar.
   * @param file The file being uploaded.
   */
  startUploadProgress(file: File) {
    const fileSizeMB = file.size / (1024 * 1024);
    this.progressData = {
      fileName: file.name,
      progress: this.appConstants.progressPoint,
      total: Math.round(fileSizeMB * 100) / 100,
      isCompleted: false
    };
    this.isUploadComplete = false;
  }

  
  askForConfirmation(removedFile: FileDetailsModel) {
    try {
      this.alertService.confirmation({
        title: Constant.removeFileConfirmation.title,
        description: Constant.removeFileConfirmation.content,
        type: AlertType.confirmation,
        buttons: [{
          label: 'Cancel',
          attributes: ['is-secondary'],
          handler: () => {}
        }, {
          label: Constant.removeFileConfirmation.removeText,
          attributes: ['is-primary'],
          handler: () => this.onFileRemoved(removedFile)
        }]
      });
    } catch(error) {
      throw error;
    }
  }

  /*** 
   * CTA for deleting the file
   */
  onFileRemoved(removedFile: FileDetailsModel) {
    if(removedFile.isFileNew) this.fileList = this.fileList.filter((file: FileDetailsModel) => file.id !== removedFile.id)
    else removedFile.isDeleted = true;
    this.fileListChange.emit(this.fileList)
  }

  // Helper function to determine the file type
 getFileType(file: File): string {
  switch (file.type) {
    case AllowedFileTypes.PDF:
      return 'pdf';
    case AllowedFileTypes.JPEG:
      return 'jpeg'
    case AllowedFileTypes.DOCX:
      return 'document';
    case AllowedFileTypes.PNG:
      return 'png';
    case AllowedFileTypes.XLSX:
      return 'excel'
    default:
      return 'unknown';
  }
}
}