import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { SearchStyle } from '../../models';

@Component({
  selector: 'lib-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss'],
})
export class SearchComponent implements OnInit {
  @Input() searchQuery = '';

  @Input() placeholder!: string;

  @Input() style: SearchStyle = {}

  @Output() searchChange = new EventEmitter<string>();

  @Input() mode: string = "md";
  @Output() clear = new EventEmitter<string>();
  @Output() cancel = new EventEmitter<string>();

  constructor() { }

  ngOnInit() { }

  /**
   * Handle the search input change event.
   * @param event 
   */

  onSearch(event: any) {
    const query = event.detail.value || '';
    this.searchChange.emit(query);
  }
  onCancel(){
    this.cancel.emit();
  }

  onClear(){
    this.cancel.emit();
  }
}