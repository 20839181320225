import { Injectable } from '@angular/core';
import { Role } from '../../../enum/common';
import { checkScreenSize, StorageService } from '@synergy-application/shared-ui';
import { LocalStorageKeys } from '../constants/local-storage.constant';

@Injectable({
  providedIn: 'root'
})
export class CommonService {

  constructor(private storageService: StorageService) { }

  sideMenuShow() {
    const userInfo = JSON.parse(this.storageService.get(LocalStorageKeys.userInfo));
    const isShowSideMenu =  userInfo?.roles?.includes(Role.travelerManager);
    return isShowSideMenu;
    // Check if any role from userole is in the role array
  }

  applyLeftPadding() {
    const isShowSideMenu = this.sideMenuShow();
    const isMobile = checkScreenSize();
    const isMediumSize = this.checkLapMediumSize();
    const sidePadding = isShowSideMenu && !isMobile ? isMediumSize ? 'pad-left-20' : 'pad-left-20' : 'mobile-pad-left';
    const sideNavApplied = isShowSideMenu && !isMobile ? 'inner-pad-left-20' : 'inner-mobile-pad-left';
    const paddingClass = {
      sidePadding,
      sideNavApplied
    }
    return paddingClass;
  }

  checkLapMediumSize() {
    return window.innerWidth > 992 && window.innerWidth <= 1280;
  }

}
