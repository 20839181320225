<ion-header class="modify-reservation-header ion-header-horizontal">
    <ion-toolbar>
      <div class="header-section">
        <ion-label class="semiBold-xl-text-20px">Modify Reservation</ion-label>
        <ion-icon class="close-icon" name="close-outline" (click)="closeModal()"></ion-icon>
      </div>
    </ion-toolbar>
</ion-header>

<ion-content class="modify-reservation-content">
    <ion-grid class="modify-reservation-grid">
        <ion-row>

        </ion-row>
        <ion-row>
            
        </ion-row>
        <ion-row>
            
        </ion-row>
        <ion-row>
            
        </ion-row>
    </ion-grid>
</ion-content>

<ion-footer class="modify-reservation-footer flex-ai-s-jc-e p-20">
    <ion-toolbar>
        <div class="btn-container flex-ai-e-jc-e">
          <lib-button (click)="closeModal()" is-primary is-medium>Submit Request</lib-button>
        </div>
    </ion-toolbar>
</ion-footer>
