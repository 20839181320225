import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FilterDetailsModel } from '../../models/filter.model';

@Component({
  selector: 'lib-filter-details',
  templateUrl: './filter-details.component.html',
  styleUrl: './filter-details.component.scss',
})
export class FilterDetailsComponent {
  @Input() padding = '0px';

  @Input() data: FilterDetailsModel[] = [];

  @Output() remove = new EventEmitter<{ index: number, childIndex?: number }>();

  @Output() removeFilter = new EventEmitter<number>();

  @Output() removeAllFilter = new EventEmitter();

  /**
   * Emit events when chip close clicked
   * @param index 
   * @param childIndex optional
   */
  removeChip(index: number, childIndex?: number): void {
    this.remove.emit({ index, childIndex });
  }

  /**
   * Emit event when single filter elements remove clicked
   * @param index 
   */
  removeFilterDetails(index: number): void {
    this.removeFilter.emit(index);
  }

  /**
   * Emit when remove all filter clicked
   * @param event 
   */
  removeAll(): void {
    this.removeAllFilter.emit();
  }

  /**
   * To optimize performance of iterations
   * @param index 
   */
  trackByFn(index: number): number {
    return index;
  }
}
