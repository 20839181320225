<div class="multi-select">
  <label class="secondary_header">{{displayName}}</label>
  <div class="dropdown" [ngClass]="disabled ? 'isDisabled' : ''" (click)="toggleDropdown()">
    <!-- Multi selection -->
    <div class="selected-items" *ngIf="isMultiSelect">
      <!-- Display selected items dynamically using labelKey -->
      <span class="item" *ngFor="let item of filteredItems">
        {{ item[labelKey] }}
        <!-- Remove selected item -->
        <span class="remove" (click)="removeItemFromSelection(item); $event.stopPropagation()">
          <ion-icon class="close-icon" name="close-outline"></ion-icon>
        </span>
      </span>
      <span class="label medium-sm-text" *ngIf="!filteredItems.length">{{placeHolder}}</span>
    </div>
    <!-- single selection -->
    <div *ngIf="!isMultiSelect">
      <!-- Display selected items dynamically using labelKey -->
      <span class="single-item" *ngFor="let item of filteredItems">
        {{ item[labelKey] }}

      </span>
      <span class="label medium-sm-text" *ngIf="!filteredItems.length">{{placeHolder}}</span>
    </div>
    <!-- Dropdown arrow -->
    <ion-spinner *ngIf="loading" name="crescent" class="loader"></ion-spinner>
    <ion-icon *ngIf="!loading" [ngClass]="dropdownOpen ? 'rotate-icon' : ''" class="dropdown-arrow"
      [class.single-arrow]="!isMultiSelect || defaultDropdown" name="chevron-down-outline"></ion-icon>
  </div>

  <!-- Modal Popup -->
  <div class="overlay" [class.default-dropdown]="defaultDropdown" *ngIf="dropdownOpen" (click)="closePopup()">
    <div class="popup" (click)="$event.stopPropagation();">
      <div class="dropdown-list" [class.option-padding]="isActionButton">
        <!-- Search Field -->
        <div *ngIf="isSearch" [class.option-padding]="isSearch && !isActionButton ">
          <ion-searchbar mode="md"  #search (ionInput)="onSearch($event)"  [debounce]="searchDebounce"
            showCancelButton="never" [placeholder]="searchPlaceHolder" [value]="searchQuery"
            (ionCancel)="onCancel()" (ionClear)="onCancel()">
          </ion-searchbar>
          <ion-label class="drop-down-img-container align-tick"></ion-label>


          <!-- Select All Checkbox -->
          <div class="option" *ngIf="isMultiSelect && filteredData?.length && isAllSelect" (click)="toggleItemSelection(allTextValue)">
            <ion-checkbox [checked]="areAllItemsSelected()" class="filled-checkbox" mode="md">
            </ion-checkbox>
            <span class="label medium-sm-text">{{allTextValue}}</span>
          </div>

          <!-- Options with Scroll -->
          <div class="option-list" (scroll)="onScroll($event)" *ngIf="isMultiSelect">
            <div class="option" *ngFor="let item of filteredData" (click)="toggleItemSelection(item)">
              <ion-checkbox [checked]="selectedIds.has(item[valueKey])" class="filled-checkbox" mode="md">
              </ion-checkbox>
              <span class="label medium-sm-text">{{ item[labelKey]}} </span>
            </div>
          </div>

          <!-- single selection -->

          <div class="option-list" *ngIf="!isMultiSelect" (scroll)="onScroll($event)">
            <div class="option" *ngFor="let item of filteredData" (click)="toggleItemSingleSelection(item)">
              <span class="label medium-sm-text">{{ item[labelKey]}} </span>
            </div>
          </div>

          <!-- No Data Found -->
          <div class="option-list" *ngIf="filteredData?.length ==0 ">
            <span class="label medium-sm-text nodata-text">{{noDataText}}</span>
          </div>
          <!-- Loading Spinner -->
          <div *ngIf="loading" class="loading-spinner">
            <ion-spinner name="dots"></ion-spinner>
          </div>

          <!-- Action Buttons -->
          <div class="buttons" *ngIf="isActionButton && isMultiSelect">
            <lib-button is-primary (click)="setSelection()">{{ setButtonText }}</lib-button>
            <lib-button is-small is-secondary (click)="cancelSelection()">{{ cancelButtonText }}</lib-button>
          </div>
        </div>
      </div>
    </div>
  </div>