import { Component, HostListener, Input, OnInit } from '@angular/core';
import { checkScreenSize } from '@synergy-application/shared-ui';
import { CardInput } from '../../models/common.model';


@Component({
  selector: 'app-parallax-content',
  templateUrl: './parallax-content.component.html',
  styleUrls: ['./parallax-content.component.scss'],
})
export class ParallaxContentComponent implements OnInit {
  @Input() list!: CardInput[];
  @Input() header!: string;
  @Input() sliceStartFirst = 0;
  @Input() sliceEndFirst = 3;
  @Input() sliceStartSecond = 3;

  isMobile = false;

  constructor() { }

  ngOnInit() {
    this.isMobile = checkScreenSize();
  }

  /**
   * Retrieves the first row of data from a dataset or table.
   */
  get firstRow() {
    return this.list.slice(this.sliceStartFirst, this.sliceEndFirst); // First 3 values for the first row
  }

  /**
   * Retrieves the remaining data from a dataset or table.
   */
  get secondRow() {
    return this.list.slice(this.sliceStartSecond); // Remaining values for the second row
  }

  /**
  * Handles actions when the window or element is resized.
  * @param event 
  */
  @HostListener('window:resize', ['$event'])
  onResize(event: Event): void {
    this.isMobile = checkScreenSize();
  }
}
