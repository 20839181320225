<div *ngIf="data" class="position-relative propertyList">

    <!-- Displays ownership information if available -->
    <div class="place-at" *ngIf="data.ownedBy">
        <div class="chip-class">
            <img class="img-icon-class" src="/assets/icon/synergy-icon.jpg" alt="">
            <span class="medium-xs-text">{{data.ownedBy}}</span>
        </div>
        <!-- Displays a flash icon if this is a request -->
        <div class="round-class" *ngIf="data.rtbAvailable" [ngClass]="{'corner-position': !data.ownedBy}">
            <img class="img-flash-class" src="/assets/icon/flash-icon.svg" alt="">
        </div>
    </div>

    <!-- Carousel for displaying property images -->
    <lib-gallery [apiKey]="'imageUrl'" [attachments]="data.attachments" [showItemNavigators]="true"
        [showThumbnails]="false" [showIndicators]="true" [showIndicatorsOnItem]="true"
        [containerStyle]="{ 'width':'100%' }" (imageClick)="navigateToPropertyDetail(data.propertyId)"></lib-gallery>

    <div class="card-class">
        <!-- Displays the property name -->
        <div class="grid-8">
            <ion-label (click)="navigateToPropertyDetail(data.propertyId)"
                class="semiBold-xl-text-20px cursor-p ellipsis">{{data.propertyName}}</ion-label>
            @if (!cardUI) {
            <ion-row>
                <ion-col size="auto" class="ion-no-padding amenities-col">
                    <!-- Displays the first two amenities -->
                    <div *ngFor="let amenity of data.amenitiesArray?.slice(0,appConstant.amenitiesShowCount)" class="amenity-class">
                        <img class="aminity-icon-class" [src]="amenity.svgPath || amenity.iconPath" />
                        <ion-label class="regular-xs-text">{{ amenity.name }}</ion-label>
                    </div>
                    <!-- Shows more amenities option if there are more than 2 -->
                    <div  (click)="navigateToPropertyDetail(data.propertyId)" *ngIf="data.amenitiesArray && data.amenitiesArray.length > appConstant.amenitiesShowCount && !showMore"
                        class="amenity-more-class">
                        <ion-label class="semiBold-xs-text">+{{ data.amenitiesArray.length - appConstant.amenitiesShowCount }}</ion-label>
                        <ion-grid class="amenities-hide-show">
                            <ion-row>
                                <div class="align-amenities-css" *ngFor="let amenity of data.amenitiesArray | slice:appConstant.amenitiesShowCount">
                                    <img class="aminity-icon-class" [src]="amenity.svgPath" />
                                    <ion-label class="regular-xs-text">{{ amenity.name }}</ion-label>
                                </div>
                            </ion-row>
                        </ion-grid>
                    </div>
                </ion-col>
            </ion-row>
            }
        </div>

        <ion-row class="icon-class-row">
            <ion-col size="auto" class="icon-class ion-no-padding" *ngFor="let unit of data.units?.slice(0,1)">
                <!-- Displays the number of beds -->
                <!-- <ion-icon class="aminities-icon-class" src="/assets/icon/bed.svg"></ion-icon> -->
                <ion-label class="regular-md-text ellipsis">{{unit.type}}</ion-label>
            </ion-col>
            <div *ngIf="data.units && data.units.length > 1 && !showMore && !cardUI" class="units-more-class">
                <ion-label class="medium-xs-text cursor-p" (click)="openPopover($event)">+{{ data.units.length - 1 }}{{languageData.more}}</ion-label>
            </div>
        </ion-row>

        <ion-col size="auto" class="icon-class ion-no-padding">
            <ion-icon class="aminities-icon-class" src="/assets/icon/location.svg"></ion-icon>
            <ion-label class="regular-md-text ">{{data.distanceInMiles}}{{languageData?.miles
                ?? ''}}</ion-label>
        </ion-col>

        @if (!cardUI) {
        <ion-row>
            <ion-col class="property-rate ion-no-padding" *ngIf="data?.units?.length">
                <!-- Displays property rate information -->
                <ion-label class="regular-sm-text">
                    <span class="from-align regular-md-text">{{languageData?.from ?? ''}}</span>
                    <span class="medium-md-text">${{data.units[0].startingAtRate}}</span>{{languageData?.night ?? ''}}
                </ion-label>
            </ion-col>
            <!-- Displays the available date for the property -->
            <ion-col class="ion-no-padding ion-text-end" *ngIf="data.rtbAvailable">
                <ion-label class="regular-sm-text">{{languageData?.available ?? ''}}{{data.availableDate | date: 'MMM
                    dd, yyyy'}}</ion-label>
            </ion-col>
        </ion-row>
        }
    </div>
</div>