import { Component, HostListener, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { IMapCollectionView, LocationSearchData, MapModel, SearchDetail } from 'shared-ui/src/lib/models/location.model';
import { LanguageService } from '../../../shared/services/language.service';
import { ModalController } from '@ionic/angular';
import { checkLapSize, checkScreenSize, calculateDateDifference, SharedApiService, AppLoaderService, sourceIcon, calculateLastDate, CommonService, GoogleAnalyticsService, StorageService, LoggingService, CdnContentService } from '@synergy-application/shared-ui';
import { FilterComponent } from '../../../shared/components/filter/filter.component';
import { Amenities, AmenitiesModel, Attachments, CheckBoxInput, DateInput, DropdownOptions,InputData, OptionModel, PaginationModel, PropertyFilterModel, PropertyModel, PropertyRequestModel, PropertyResult, PropertyVariableModel, SliderModelDistance, SliderModelPrice, ToggleInput, AmenitiesType, PaginationInput, RequestInput, TCarousel, CarouselResponsive, DropDownInput, DropDownResponseModel, DropDownCommonResponseModel, UserProfile } from 'shared-ui/src/lib/models';
import { CardInput } from '../../../shared/models/common.model';
import { AppConstants, GoogleAnalyticsKey, IconPath } from '../../../../core/app-constant';
import { ROUTESPATH } from '../../../../core/routes-constant';
import { cloneDeep } from '@apollo/client/utilities';
import { environment } from '../../../../../environments/environment';
import { DatePipe } from '@angular/common';
import { DROP_DOWN_TYPES } from 'shared-ui/src/lib/constants/shared-constant';
import { LocalStorageKeys } from '../../../shared/constants/local-storage.constant';
import { Role } from '../../../../enum/common';
import { CommonService as CommonServicebooking} from '../../../shared/services/common.service';
import { LogLevel } from 'shared-ui/src/lib/enum/logger';

@Component({
  selector: 'app-property-result',
  templateUrl: './property-result.component.html',
  styleUrl: './property-result.component.scss',
})
export class PropertyResultComponent implements OnInit {
  noResultData!: CardInput;

  options!: OptionModel[];

  locationSearchData: LocationSearchData = {
    label: '',
    type: AppConstants.fieldType.text,
    maxLength: AppConstants.input.max,
    placeholder: '',
    value: '',
    isShowErrorMessage: false,
    groups: []
  }

  amenitiesResponsiveOptions: CarouselResponsive[] = [
    {
      breakpoint: '1600',
      numVisible: 1,
      numScroll: 1
    }
  ]

  isLap = false;

  isMobile = false;

  appConstants = AppConstants;

  isInstantBookingOnly!: ToggleInput;

  isPreferredProperty!: ToggleInput;

  toggleData: ToggleInput[] | [] = [];


  languageData: any;

  sortByOptions!: OptionModel;

  checkboxData!: CheckBoxInput;

  propertyList!: PropertyModel[];

  dateMoveInInput: DateInput = {
    label: '',
    showErrorMessage: '',
    isShowErrorMessage: false,
    placeholder: '',
    value: '',
    isRequired: false,
    mode: AppConstants.mode.range,
    icon: false
  }

  dateMoveOutInput: DateInput = {
    label: '',
    showErrorMessage: '',
    isShowErrorMessage: false,
    placeholder: '',
    value: '',
    isRequired: false,
    mode: AppConstants.mode.range
  }

  svgPath = IconPath;

  objSearchDetails: SearchDetail = {
    locationDetails: {
      name: '',
      placeId: '',
      latitude: 0,
      longitude: 0
    },
    moveIn: "",
    moveOut: ""
  }

  sortedValue: OptionModel = {
    id: 1,
    name: ''
  };

  landingLabel: any;

  groupedProperty!: IMapCollectionView

  showMapView = false;

  isTabOrMobile = true;

  icon = IconPath;

  daysDifference = 0;

  filters!: PropertyRequestModel;

  masterPropertyType: CheckBoxInput[] | [] = [];

  masterAmenitiesType: AmenitiesModel[] | [] = [];

  isRender = false;

  // isInstantBooking: boolean = false;

  searchedPlace!: MapModel;

  distanceRange = 0;

  minPrice = 0;

  maxPrice = 0;

  sliderConfigurationForPrice: SliderModelPrice = {
    minValue: this.appConstants.range.minValue,
    maxValue: this.appConstants.range.maxValue,
    step: this.appConstants.range.step,
    value: { lower: this.appConstants.range.defaultPriceValue.lower, upper: this.appConstants.range.defaultPriceValue.upper },
    isRange: true,
  };

  inputParams: InputData[] = [
    {
      placeholder: this.appConstants.rangeType.min,
      value:
        this.sliderConfigurationForPrice &&
          this.sliderConfigurationForPrice.value
          ? this.sliderConfigurationForPrice.value.lower
          : this.appConstants.range.minValue,
      label: this.appConstants.rangeType.min,
      isShowErrorMessage: true,
      isRequired: true,
      type: this.appConstants.fieldType.text,
      minLength: this.appConstants.range.minValue,
      maxLength: this.appConstants.range.maxValue,
    },
    {
      placeholder: this.appConstants.rangeType.max,
      value:
        this.sliderConfigurationForPrice &&
          this.sliderConfigurationForPrice.value
          ? this.sliderConfigurationForPrice.value.upper
          : this.appConstants.range.maxValue,
      label: this.appConstants.rangeType.max,
      isShowErrorMessage: true,
      isRequired: true,
      type: this.appConstants.fieldType.text,
      minLength: this.appConstants.range.minValue,
      maxLength: this.appConstants.range.maxValue,
    },
  ];

  sliderConfigurationForDistance: SliderModelDistance = {
    minValue: this.appConstants.distanceRange.minValue,
    maxValue: this.appConstants.distanceRange.maxValue,
    step: this.appConstants.range.step,
    value: this.appConstants.range.defaultDistanceValue,
    isRange: false,
  };

  pagination: PaginationModel = {
    page: 1,
    pageSize: AppConstants.propertyPageSize,
    totalCount: 0
  };

  apiCompleted = true;

  amenitiesType: TCarousel[] = [];

  isExtend = false;

  lazyLoader = false;

  userInfo!: UserProfile;

  sidePadding!: string;

  isMediumSize = false;

  masterProperties!: PropertyFilterModel;
  copyOfMasterProperties!: PropertyFilterModel;
  googleAnalyticsConstants = GoogleAnalyticsKey;

  constructor(
    private activatedRoute: ActivatedRoute,
    private languageService: LanguageService,
    private modalController: ModalController,
    private cdnContentService: CdnContentService,
    private sharedApiService: SharedApiService,
    private loaderService: AppLoaderService,
    private datePipe: DatePipe,
    private router: Router,
    private googleAnalytics: GoogleAnalyticsService,
    private storageService: StorageService,
    private commonServicebooking: CommonServicebooking,
    private loggingService: LoggingService) {

  }
  ngOnInit() {
    this.activatedRoute.queryParams.subscribe(async (qParams) => {
      this.objSearchDetails = JSON.parse(atob(qParams['searchQuery']));
      if (!this.objSearchDetails.moveOut) {
        const date = calculateLastDate(this.appConstants.defaultDays, this.objSearchDetails.moveIn);
        this.objSearchDetails.moveIn = date.currentDate.toISOString();
        this.objSearchDetails.moveOut = date.lastDate.toISOString();
      }
      this.getDays();
      this.initializedData();
      this.locateSearchedPlace();
      this.getPropertyList();
      this.masterProperties = {
        amenities: [],
        isInstantBookingOnly: this.isInstantBookingOnly,
        isPreferredProperty: this.isPreferredProperty,
        languageLabel: this.languageData,
        sortByOptions: this.options,
        selectedOption: this.sortedValue,
        checkboxData: [],
        inputParams: this.inputParams,
        sliderConfigurationForPrice: this.sliderConfigurationForPrice,
        sliderConfigurationForDistance: this.sliderConfigurationForDistance,
      };
      this.copyOfMasterProperties = cloneDeep(this.masterProperties);
    })
    this.isMobile = checkScreenSize();
    this.isMediumSize = this.checkLapMediumSize();
    this.showSideBar();
  }

  checkLapMediumSize() {
    return window.innerWidth > 992 && window.innerWidth <= 1280;
  }

  /**
  * To list the property based on the search
  * @param event 
  */
  onSearch(event: SearchDetail) {
    this.objSearchDetails = event;
    this.clearResultData();
    this.locateSearchedPlace();
    this.pagination.page = 1;
    this.getPropertyList();
  }
  /**
   * clear the List when sorting and applying the filter
   */
  clearResultData() {
    this.propertyList = [];
    this.groupedProperty = {};
  }
  /**
   * Initialized data for property list
   */
  initializedData() {
    this.isLap = checkLapSize();
    this.isMobile = checkScreenSize();
    this.isMediumSize = this.checkLapMediumSize();
    this.showSideBar();
    this.landingLabel = this.languageService.getLandingKey();
    this.languageData = this.languageService.getPropertyListKey();

    this.isInstantBookingOnly =
    {
      label: this.languageData.toggleLabel,
      isChecked: false,
      id: 1,
    }
      ;
    this.isPreferredProperty =
    {
      label: this.languageData.preferredToggleLabel,
      isChecked: false,
      id: 2,
    }

    this.locationSearchData = {
      label: this.landingLabel.where,
      type: this.appConstants.fieldType.text,
      maxLength: this.appConstants.input.max,
      placeholder: this.landingLabel.chooseLocation,
      value: this.objSearchDetails.locationDetails.name,
      isShowErrorMessage: false,
      groups: [{
        name: this.landingLabel.officeLocation,
        items: []
      },
      {
        name: this.landingLabel.googleLocation,
        items: []
      }]
    }
    this.dateMoveInInput = {
      label: '',
      showErrorMessage: '',
      isShowErrorMessage: false,
      placeholder: this.landingLabel.moveIn,
      value: [new Date(this.objSearchDetails.moveIn), new Date(this.objSearchDetails.moveOut)],
      isRequired: false,
      mode: this.appConstants.mode.range
    }
    this.dateMoveOutInput = {
      label: '',
      showErrorMessage: '',
      isShowErrorMessage: false,
      placeholder: this.landingLabel.moveOut,
      value: [new Date(this.objSearchDetails.moveIn), new Date(this.objSearchDetails.moveOut)],
      isRequired: false,
      mode: this.appConstants.mode.range
    }

    this.options = [
      {
        id: 1, name: this.languageData.distanceLabel, isSelected: false
      },
      {
        id: 2, name: this.languageData.priceLabel, isSelected: false
      },
      {
        id: 3, name: this.languageData.priceLabel2, isSelected: false
      }
    ]
    this.sortedValue = this.options[0];

    this.noResultData = {
      imageUrl: '/assets/icon/undraw_best_place.svg',
      title: this.languageData.title,
      headingText: '',
      descriptionText: this.languageData.descriptionText,
      buttonText: this.languageData.buttonText,
      url: ''
    };
    this.getMasterPropertyType();
    this.getMasterAmenities();
  }

  /**
   *  Updates value on toggle change 
   * @param toggle - Capture the event when changing the toggle event
   */
  onToggleChange(toggle: ToggleInput) {
    this.getPropertyList();
  }
  /**
   *  Updates value on toggle change 
   * @param event - selecting sorting option 
   */
  sortBy(event: OptionModel) {
    if (event) {
      event.isSelected = false;
      this.sortedValue = event;
      this.clearResultData();
      this.pagination.page = 1;
      this.getPropertyList();
    }
  }
  /**
   * Handles actions when the window or element is resized.
   * @param event 
   */
  @HostListener('window:resize', ['$event'])
  onResize(event: Event): void {
    this.isLap = checkLapSize();
    this.isMobile = checkScreenSize();
    this.isMediumSize = this.checkLapMediumSize();
    this.showSideBar();
  }
  /**
   * Amenity selection handler
   * @param amenity 
   */
  onAmenityToggle(amenity: AmenitiesModel) {
    amenity.isSelected = !amenity.isSelected;
    var find = this.masterAmenitiesType?.find(e => e.id == amenity.id)
    if (find) {
      find.isSelected = !find.isSelected;
    }
    this.pagination.page = 1;
    this.getPropertyList();
  }

  showSideBar() {
    this.sidePadding = this.commonServicebooking.applyLeftPadding()?.sidePadding;
    // Check if any role from userole is in the role array
  }

  /*
   *
   * When click advanced filter button this method will get triggered.
   */
  async openAdvancedFilter() {
    const modal = await this.modalController.create({
      component: FilterComponent,
      cssClass: 'filter-content-popup',
      componentProps: {
        masterProperties: this.masterProperties,
        defaultProperties: this.copyOfMasterProperties
      },
    });

    modal.onDidDismiss().then(propertyFilter => {
      const { data } = propertyFilter;
      if (data) {
        const propertyFilterResponse: PropertyFilterModel = data;
        if (propertyFilterResponse) {
          this.masterProperties = data;
          this.masterAmenitiesType = propertyFilterResponse.amenities;
          this.masterPropertyType = propertyFilterResponse.checkboxData;
          this.sliderConfigurationForDistance = propertyFilterResponse.sliderConfigurationForDistance;
          this.sliderConfigurationForPrice = propertyFilterResponse.sliderConfigurationForPrice;
          this.isInstantBookingOnly.isChecked = propertyFilterResponse.isInstantBookingOnly.isChecked;
          this.isPreferredProperty.isChecked = propertyFilterResponse.isPreferredProperty.isChecked;
          this.sortedValue = propertyFilterResponse.sortByOptions[0] as DropdownOptions;
          this.inputParams = [...propertyFilterResponse.inputParams];
          this.clearResultData();
          this.pagination.page = 1;
          this.getPropertyList();
        }
      }
    });

    modal.present();
  }
  /**
   * 
   * @returns days based on selected dates
   */
  getDays() {
    this.daysDifference = calculateDateDifference(this.objSearchDetails.moveIn, this.objSearchDetails.moveOut)
  }

  /**
   * 
   * @param request Create a dynamic search query based on input
   * @returns 
   */
  buildSearchRequest(request: PropertyVariableModel) {
    let query: any = {
      where: {
      }
    }

    // Add amenities if present
    if (request.amenities && request.amenities.length > 0) {
      query.where.amenities = { in: request.amenities };
    }

    // Add location if latitude and longitude are present
    if (request.latitude && request.longitude && request.radius) {
      query.where.location = {
        latitude: request.latitude,
        longitude: request.longitude,
        radius: request.radius
      };
    }

    // Add date range if both dates are present
    if (request.dateFrom && request.dateTo) {
      query.where.date = {
        from: request.dateFrom,
        to: request.dateTo
      };
    }

    // Add price range if both min and max are present
    if (request.priceMin && request.priceMax) {
      query.where.price = {
        min: request.priceMin,
        max: request.priceMax
      };
    }

    // Add property filters
    query.where.property = {};
    if (request.instantBookingOnly) {
      query.where.property.instantBookingOnly = request.instantBookingOnly;
    }

    // Add propertyType if present
    if (request.propertyType && request.propertyType.length > 0) {
      query.where.property.propertyType = { in: request.propertyType };
    }

    // Add preferredProperties if present
    if (request.preferredProperties) {
      query.where.property.preferredProperties = request.preferredProperties;
    }

    // Add sorting
    if (request.sortBy) {
      query.sort = { by: request.sortBy };
    }

    // Add pagination
    if (request.page !== undefined && request.pageSize !== undefined) {
      query.pagination = { page: request.page, pageSize: request.pageSize };
    }

    return query;
  }

  /**
  * Navigates to a specific page based on the provided search data.
  */
  getPropertyList(flag?: boolean) {
    this.apiCompleted = false;
    if (flag) this.lazyLoader = true;
    if (!flag) this.loaderService.showLoader(true);
    const variable: PropertyVariableModel = {
      amenities: this.masterAmenitiesType?.reduce((acc: string[] | [], currentValue: AmenitiesModel) => {
        if (currentValue.isSelected) acc = [...acc, currentValue.id];
        return acc;
      }, []),
      latitude: this.objSearchDetails.locationDetails.latitude || 0,
      longitude: this.objSearchDetails.locationDetails.longitude || 0,
      radius: this.sliderConfigurationForDistance.value || this.appConstants.range.defaultDistanceValue,
      dateFrom: new Date(this.objSearchDetails.moveIn).toISOString(),
      dateTo: new Date(this.objSearchDetails.moveOut).toISOString(),
      priceMin: this.sliderConfigurationForPrice.value.lower ?? 0,
      priceMax: this.sliderConfigurationForPrice.value.upper ?? 0,
      instantBookingOnly: this.isInstantBookingOnly.isChecked,
      propertyType: this.masterPropertyType.reduce((acc: number[] | [], currentValue: CheckBoxInput) => {
        if (currentValue.isChecked && currentValue.id) acc = [...acc, currentValue.id];
        return acc;
      }, []),
      page: this.pagination.page,
      pageSize: this.appConstants.propertyPageSize,
      preferredProperties: this.isPreferredProperty.isChecked,
      sortDirection: this.appConstants.sortDirection.ASC,
      sortBy: this.appConstants.sortByOption[this.sortedValue.id as keyof typeof this.appConstants.sortByOption]
    }
    const request = {
      searchReq: this.buildSearchRequest(variable)
    }
    this.googleAnalytics.trackEvent(this.googleAnalyticsConstants.search, variable); //tracking Events using Google Analytics service

    this.sharedApiService.getAvailablePropertyList(request)
      .then(async (res: PropertyResult) => {
        if (res) {
          const result = await this.appendBlobUrls(res)
          res.properties = result ? result : [];
          this.propertyList = flag ? [...this.propertyList, ...res.properties] : res.properties
          this.pagination = cloneDeep(res.pagination);
          const eventTracking = {
            totalCount: this.pagination.totalCount,
            latitude: this.objSearchDetails.locationDetails.latitude,
            longitude: this.objSearchDetails.locationDetails.longitude
          }
          this.googleAnalytics.trackEvent(this.googleAnalyticsConstants.propertyListingView, eventTracking);
          this.getDays();
          this.groupedProperty = {
            'property-cluster': this.propertyList.map((el: PropertyModel) => ({
              ...el,
              latitude: Number(el.latitude.toFixed(5)),
              longitude: Number(el.longitude.toFixed(5)),
              icon: sourceIcon.property,
              clickable: true,
              type: 'property',
              name: el.propertyName,
              id: el.propertyId
            }))
          }
        } else {
          this.propertyList = [];
          this.groupedProperty = {};
        }
        this.apiCompleted = true;
        this.loaderService.showLoader(false);
      })
      .catch((error: Error) => {
        this.apiCompleted = true;
        this.loaderService.showLoader(false);
        this.loggingService.logException(error, LogLevel.Critical)
      })
      .finally(() => {
        if (flag) this.lazyLoader = false;
        if (!flag) this.loaderService.showLoader(false);
        this.isRender = true;
      })
  }
  /**
   * 
   * @param res Append the blob url
   */
  async appendBlobUrls(res: PropertyResult) {
    try {
      const results = await Promise.all(
        res?.properties?.map(async (el: PropertyModel) => {
          // Process attachments
          if (el?.attachments) {
            el.attachments = await Promise.all(
              el?.attachments?.map(async (attach: Attachments) => {
                attach.imageUrl = `${this.cdnContentService.blobServiceUrl}${environment.config.azure.publicContainerName}/${attach.imageUrl}`;
                return attach;
              })
            );
          }

          el.distanceInMiles = Math.round(el.distanceInMiles)
          // Initialize amenitiesArray
          el.amenitiesArray = [];

          // Process amenities
          if (el?.amenities) {
            await Promise.all(
              el?.amenities?.map(async (attach: AmenitiesType) => {
                await Promise.all(
                  attach?.amenities?.map(async (k: Amenities) => {
                    if (k.svgPath && !k.svgPath.startsWith(this.cdnContentService.blobServiceUrl)) {
                      k.svgPath = `${this.cdnContentService.blobServiceUrl}${environment.config.azure.publicContainerName}${k.svgPath}`;
                      el.amenitiesArray.push(k);
                    }
                  })
                );
              })
            );
          }


          return el; // Return the modified element
        })
      );

      // If you need to update res with the modified properties
      return results;
    }
    catch (error) {
      this.loggingService.logException(error as Error, LogLevel.Error)
    }
    return [];
  };


  /**
  * Navigate to another page
  */
  onNavigate() {
    this.router.navigate([ROUTESPATH.RFHREQUEST], { queryParams: { data: btoa(JSON.stringify(this.objSearchDetails)) } });
  }
  /**
   * Switch to the map view and list view for mobile screen
   */
  switchView() {
    this.showMapView = !this.showMapView;
  }
  /**
  * Navigates the user to the property details page for the selected property.
  * @param {string} propertyId - The ID of the property to display.
  */
  navigateToPropertyDetail(propertyId: string) {

    const request: any = {
      id: propertyId,
      ...this.objSearchDetails
    }

    const baseUrl = window.location.origin;


    // Create a query string from the queryParams object
    const queryString = new URLSearchParams({ query: btoa(JSON.stringify(request)) }).toString();

    // Construct the full URL with query parameters
    const fullUrl = `${baseUrl}${ROUTESPATH.PROPERTYDETAIL}?${queryString}`;

    // Open the URL in a new window
    window.open(fullUrl, '_blank');
  }
  /**
   * 
   * @param event - Emit the event when selecting the Amenities
   */
  onSelectAmenities(event: TCarousel) {
    const selectedAmenity: AmenitiesModel = {
      name: event.label ?? '',
      svgPath: event.url ?? '',
      isSelected: event.isSelected ?? false,
      id: typeof event.id === "string" ? event.id : ''
    }
    this.onAmenityToggle(selectedAmenity)
  }
  /**
   *  Get the master property type
   */
  getMasterPropertyType() {
    const request: DropDownInput = {
      request: {
        type: DROP_DOWN_TYPES.PROPERTY_TYPE,
        pagination: {
          page: 1,
          pageSize: 100
        }
      }
    }
    this.sharedApiService.fetchDropDownValues(request)
      .then((res: DropDownResponseModel) => {
        this.masterPropertyType = res.data.map((el: DropDownCommonResponseModel) => ({
          isChecked: false,
          label: el && el.value ? el.value : '',
          id: parseInt(el.key, 10)
        }))
        this.masterProperties.checkboxData = this.masterPropertyType;
        this.copyOfMasterProperties = cloneDeep(this.masterProperties);
      })
      .catch(ex => {
        this.loggingService.logException(ex, LogLevel.Critical)
      })
      .finally(() => {
      })
  }
  /**
    *  Get the master amenities
    */
  getMasterAmenities() {
    const request: DropDownInput = {
      request: {
        type: DROP_DOWN_TYPES.AMENITIES,
        pagination: {
          page: 1,
          pageSize: 100
        }
      }
    }
    this.sharedApiService.fetchDropDownValues(request)
      .then(async (res: DropDownResponseModel) => {
        this.masterAmenitiesType = await Promise.all(res.data.map((el: DropDownCommonResponseModel) => ({
          id: el.key,
          isSelected: false,
          name: el.value,
          svgPath: `${this.cdnContentService.blobServiceUrl}${environment.config.azure.publicContainerName}${el.svgPath}`
        })))
        this.masterProperties.amenities = this.masterAmenitiesType;
        this.copyOfMasterProperties = cloneDeep(this.masterProperties);
        this.amenitiesType = this.masterAmenitiesType.filter(item1 =>
          this.appConstants.amenitiesId.some((item2) => item2 === item1.id)
        ).map(el => ({
          isSelected: false,
          label: el.name,
          url: el.svgPath,
          id: el.id
        }));
      })
      .catch(ex => {
        this.loggingService.logException(ex, LogLevel.Error)
      })
      .finally(() => {
      })
  }
  /**
   * create a marker based on searched location
   */
  locateSearchedPlace() {
    this.searchedPlace = {
      latitude: this.objSearchDetails.locationDetails.latitude ?? 0,
      longitude: this.objSearchDetails.locationDetails.longitude ?? 0,
      name: "",
      clickable: false,
      icon: IconPath.office,
      type: this.appConstants.office
    }
  }
  /**
   * 
   * @param event Lazy loading based on scroll
   */
  onScroll(event: Event) {
    const target = event.target as HTMLElement; // Correctly assert the type
    const scrollTop = target.scrollTop;
    const clientHeight = target.clientHeight;
    const scrollHeight = target.scrollHeight;

    if (scrollTop + clientHeight + 50 >= scrollHeight && this.apiCompleted && this.pagination.totalCount > this.propertyList?.length) {
      this.pagination.page += 1;
      this.getPropertyList(true);
    }
  }
  /**
   * expand the map for full view
   */
  extendMap() {
    this.isExtend = !this.isExtend;
  }
}