<ion-label [attr.id]="inputData.leadRef" class="semiBold-sm-text label-color-text location-dropdown-label"
  *ngIf="label">{{label}}<span *ngIf="inputData.isRequired" class="red-color-text">*</span></ion-label>

<div [ngClass]="styleCss ? 'custom-css dropdown' : 'dropdown'">
  <!-- Search Icon -->
  <lib-icon name="lens" class="lens-icon" [className]="'medium lens ion-hide-lg-down'"></lib-icon>

  <!-- Input Field for Search -->
  <input type="text" (focusout)="focusoutInput(inputData)" (focus)="focusInput(inputData)" [(ngModel)]="inputData.value"
    (input)="onSearchChange(inputData.value)" (click)="onClick()" [placeholder]=inputData.placeholder
    class="search-input medium-md-text" (focus)="showDropdown = true" />

  <div>
    <!-- Dropdown List -->
    <ul class="dropdown-list" *ngIf="showDropdown">
      <!-- Current Location Option -->
      <div [ngClass]="showBorder ? 'current-loc border-class' : 'current-loc'">
        <ion-icon src="/assets/icon/location.svg" alt=""></ion-icon>
        <ion-label class="regular-md-text" (click)="goToCurrentLocation()">{{ language?.useCurrentLocation ??
          ''}}</ion-label>
      </div>

      <!-- Check if Group has Items -->
      <div class="div-height" *ngFor="let group of inputData.groups">
        <li *ngIf="group.items?.length" class="list-style">
          <strong class="semiBold-sm-text" *ngIf="group.items?.length && group.name">{{ group.name }}</strong>
          <ul class="pl-0 list-style-ul regular-sm-text" *ngIf="group.items?.length">
            <li *ngFor="let item of group.items" (keyup.enter)="selectItem(item)" (click)="selectItem(item)"
              class="dropdown-item medium-sm-text">
              {{ item.name }}
            </li>
          </ul>
        </li>
      </div>

    </ul>
  </div>
</div>

<small *ngIf="inputData?.isShowErrorMessage && inputData?.showErrorMessage"
  class="red-color-text">{{inputData.showErrorMessage }}</small>