import { Component, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';
import { checkScreenSize } from '@synergy-application/shared-ui';
import { CardInput } from '../../models/common.model';
import { SearchDetail } from 'shared-ui/src/lib/models';

@Component({
  selector: 'app-image-card',
  templateUrl: './image-card.component.html',
  styleUrls: ['./image-card.component.scss'],
})
export class ImageCardComponent  implements OnInit {

  @Input() data : CardInput = {
    imageUrl : '',
    title : '',
    headingText : '',
    descriptionText: '',
    details : '',
    buttonText : '',
    url : ''
  };
  @Input() width : string | undefined;

  @Input() buttonUI = false;

  @Input() isMobile = false;

  @Input() language: any;

  @Output() searchEmit: EventEmitter<any> = new EventEmitter();

  constructor() { }

  ngOnInit() {
    this.isMobile = checkScreenSize();
  }

  
    /**
   * Handles actions when the window or element is resized.
   * @param event 
   */

    @HostListener('window:resize', ['$event'])
    onResize(event: Event): void {
      this.isMobile = checkScreenSize();
    }

  onSearchEvent(event: SearchDetail) { 
    this.searchEmit.emit(event);
  }
}
