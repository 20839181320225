<ion-grid>
  <ion-row class="container-row">
    <ion-col class="container-column">
      <div class="move">
        <div class="dates-width">
          <ion-label class="day flex-align">
            <span class="semiBold-xl-text-20px">{{ dateDetails.fromDates | date: 'MMM' }}</span>
            <span class="semiBold-md-text ">{{ dateDetails.fromDates | date: 'dd' }}</span>
          </ion-label>
        </div>
        <div class="move-container">
          <ion-label class="semiBold-md-text day">
            {{ dateDetails.fromDays }}
          </ion-label>
          <ion-label class="move-label regular-sm-text">
            {{ dateLabel.moveInLabel }}
          </ion-label>
        </div>
      </div>
    </ion-col>
    <ion-col>
      <div class="container-days">
        <ion-label class="medium-sm-text day">
          {{dateDetails.numberOfNights}}
          <span class="regular-xs-text day">
            {{dateLabel.nights}}
          </span>
        </ion-label>
        <img class="image-class" src="/assets/icon/line-with-dots.svg" alt="" />
        <ion-label class="medium-sm-text day" *ngIf="dateDetails?.price"> 
          {{dateDetails.currencyCode || appConstants.dollarSymbol}} {{dateDetails.price}}
          <span class="regular-xs-text day">
            {{dateLabel.nightLabel}}
          </span>
        </ion-label>
      </div>
    </ion-col>
    <ion-col>
      <div class="move-out-class">
        <div class="dates-width">
          <ion-label class="day flex-align">
            <span class="semiBold-xl-text-20px">{{ dateDetails.toDates | date: 'MMM' }}</span>
            <span class="semiBold-md-text ">{{ dateDetails.toDates | date: 'dd' }}</span>
          </ion-label>
        </div>
        <div class="move-container">
          <ion-label class="semiBold-md-text day">
            {{dateDetails.toDays }}
          </ion-label>
          <ion-label class="move-label regular-sm-text">
            {{ dateLabel.moveOutLabel }}
          </ion-label>
        </div>
      </div>

    </ion-col>
  </ion-row>
</ion-grid>