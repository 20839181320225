import { ROUTESPATH } from "../core/routes-constant";

export enum RequestStatus {
  Pending = 1,
  Accepted = 2,
  Denied = 3,
  Unassigned = 4,
  Cancelled = 5
}

export const RequestStatusDescription: Record<RequestStatus, string> = {
  [RequestStatus.Pending]: 'Request Pending',
  [RequestStatus.Accepted]: 'Request Accepted',
  [RequestStatus.Denied]: 'Request Denied',
  [RequestStatus.Cancelled]: 'Cancelled',
  [RequestStatus.Unassigned]: "Request Pending"
};

export const RequestStatusClasses: Record<RequestStatus, string> = {
  [RequestStatus.Pending]: 'pending-class',
  [RequestStatus.Accepted]: 'accepted-class',
  [RequestStatus.Denied]: 'denied-class',
  [RequestStatus.Cancelled]: 'denied-dot',
  [RequestStatus.Unassigned]: "cancel-dot"
};

export enum Role {
  admin = 'Admin',
  supplier = 'Supplier',
  traveler = 'Traveler',
  guest = 'Guest',
  salesRepresentative = 'Sales Representative',
  superAdmin= 'Super Admin',
  salesManager = 'Sales Manager',
  user = 'User',
  travelerManager = 'Traveler Manager'	
}

export const ROUTESLABEL = {
  [ROUTESPATH.LOGIN]: 'Home',
  [ROUTESPATH.LANDING]: 'Home',
  [ROUTESPATH.RFHREQUEST]: 'Request an Accommodation',
  [ROUTESPATH.USERMANAGEMENT]: 'User Management',
  [ROUTESPATH.MYREQUEST]: 'Housing Request',
  [ROUTESPATH.POLICY]: 'Travel Policy',
  [ROUTESPATH.FAQ]: 'FAQ',
  [ROUTESPATH.PROPERTYLIST]: 'Property Listing'
}