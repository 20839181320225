<ion-grid class="view-content">
    <div class="space-align">
        <div><ion-label class="bold-xl-text-20px">{{ isAdd ? languageLabel.addLabel : (isEdit ? languageLabel.editDetail
                : languageLabel.viewLabel) }}</ion-label></div>
        <div>
            <!-- <img  class='image-align' (click)="onCancel()" [src]=iconPath.cancel alt="" > -->
            <ion-icon class="close-icon" name="close-outline" (click)="onCancel()"></ion-icon>
        </div>
    </div>
    <div class="margin-align"><ion-label class="bold-sm-text">{{languageLabel.profilePicLabel}}</ion-label></div>
    <div class="avatar align-items display-flex-justify-center">
        <span *ngIf="!isAdd" class="align-items semiBold-xl-text-36px display-flex-justify-center"
            [ngClass]="!isAdd ? 'initials' : ''">
            {{ profileData?.apiProfileResponseValue?.firstName?.slice(0, !profileData?.apiProfileResponseValue?.lastName ? 2 : 1) | uppercase }}
            {{ profileData?.apiProfileResponseValue?.lastName?.slice(0, 1) | uppercase }}
            <span class="initials" *ngIf="!profileData?.apiProfileResponseValue?.firstName && profileData?.apiProfileResponseValue?.name">
                {{ profileData?.apiProfileResponseValue?.name?.slice(0, 2) | uppercase }}
            </span>
        </span>


        <span *ngIf="isAdd" [ngClass]="{
            'profile-image': profileImage,
            'add-image': !profileImage
          }" class="align-items display-flex-justify-center">
            <ion-icon class="add-icon" [src]="profileImage || iconPath.addImage" (click)="addImage()"></ion-icon>
        </span>
        <ion-icon *ngIf="isEdit || isAdd" class="edit-pencil-icon" [src]="iconPath.editPencil"
            (click)="fileInput.click()"></ion-icon>

        <input type="file" #fileInput accept="image/*" hidden (change)="onFileSelected($event)" />

    </div>

    <div *ngFor="let data of profileData.sections" [ngClass]="(isEdit || isAdd) ? '' : 'pointer-event'">
        <lib-dynamic-form [value]="data"></lib-dynamic-form>
        <div class="action-buttons" *ngIf="isEdit || isAdd">
            <div class="button-pointer">
                <lib-button is-medium is-secondary class="semiBold-md-text"
                    (click)="onCancel()">{{languageLabel.cancelLabel}}</lib-button>
            </div>
            <div class="button-pointer">
                <lib-button [disabled]="isEdit" is-medium is-primary class="semiBold-md-text"
                    (click)="onSave()">{{languageLabel.saveLabel}}</lib-button>
            </div>
        </div>

    </div>
</ion-grid>