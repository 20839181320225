<ion-header class="ion-header-horizontal">
  <ion-toolbar>
    <div class="header-section">
      <ion-label class="semiBold-xl-text-20px">{{
        masterProperties.languageLabel.filterLabel ?? ''}}</ion-label>
      <ion-icon class="close-icon" name="close-outline" (click)="closeSlider()"></ion-icon>
    </div>
  </ion-toolbar>
</ion-header>
<ion-content>
  <div class="scroll-filter-css">
    <ion-row class="filter-padding">
      <ion-col class="pad-0 toggle">
        <div>
          <lib-toggle class="medium-sm-text" [data]="masterProperties.isPreferredProperty"></lib-toggle>
        </div>
      </ion-col>
    </ion-row>
    <ion-row class="filter-padding">
      <div>
        <ion-label class="semiBold-md-text label-align">{{
          masterProperties.languageLabel.sortByLabel ?? '' }}</ion-label>
        <lib-radio-button id="filter" [data]="masterProperties.sortByOptions" [selectedOption]="selectedOption"
          (selectedValueChange)="selectedValueChange($event)"></lib-radio-button>
      </div>
    </ion-row>

    <div class="filter-padding">
      <div>
        <ion-label class="semiBold-md-text label-align">{{ masterProperties.languageLabel.propertyLabel ?? ''
          }}</ion-label>
      </div>

      <ion-row>
        <ion-col size="12" size-sm="12" size-md="4" size-xl="4" size-lg="6"
          *ngFor="let data of masterProperties.checkboxData">
          <div>
            <lib-checkbox [data]="data"></lib-checkbox>
          </div>
        </ion-col>
      </ion-row>
    </div>
    <div class="filter-padding">
      <ion-label class="semiBold-md-text">{{ masterProperties.languageLabel.distanceLabel ?? '' }}</ion-label>
      <ion-range mode="md" aria-label="distanceLabel"  [value]="5"
      [pin]="true"
        [(ngModel)]="masterProperties.sliderConfigurationForDistance.value" [pin]="true" [pinFormatter]="pinFormatter">
        <div class="medium-sm-text" slot="start" >{{masterProperties.sliderConfigurationForDistance.minValue}}</div>
        <div class="medium-sm-text" slot="end" >{{masterProperties.sliderConfigurationForDistance.maxValue}}</div>
      </ion-range>
    </div>
    <div class="filter-padding">
      <ion-label class="semiBold-md-text">{{
        masterProperties.languageLabel.priceRange ?? 'Price Range'
        }}</ion-label>
      <ion-range [pin]="true" aria-label="Dual Knobs Range" [dualKnobs]="true"
        [(ngModel)]="masterProperties.sliderConfigurationForPrice.value" (ionChange)="onPriceRangeChange()"
        min="{{ masterProperties.sliderConfigurationForPrice.minValue }}"
        max="{{ masterProperties.sliderConfigurationForPrice.maxValue }}">
        <div class="medium-sm-text" slot="start" >{{masterProperties.sliderConfigurationForPrice.minValue}}</div>
        <div class="medium-sm-text" slot="end" >{{masterProperties.sliderConfigurationForPrice.maxValue}}</div></ion-range>
    </div>

    <div class="filter-padding">
      <ion-row>
        <ion-col size="12" size-sm="12" size-md="6" size-xl="6" size-lg="6"
          *ngFor="let range of masterProperties.inputParams">
          <lib-input [inputData]="range" (focusoutEvent)="onInputChange(range.label!, range.value)"></lib-input>
        </ion-col>
      </ion-row>
    </div>
    <div class="filter-padding">
      <div class="label-align">
        <ion-label class="semiBold-md-text">{{ masterProperties.languageLabel.amenitiesPreferencesLabel ?? '' }}</ion-label>
      </div>
      <ion-row class="amenities-row">
        <ion-chip *ngFor="let amenity of masterProperties.amenities; let i = index"
          [class.selected]="amenity.isSelected" class="amenity-class"
          (click)="amenity.isSelected = !amenity.isSelected">
          <ion-icon  [src]="amenity.svgPath"></ion-icon>
          <ion-label class="regular-sm-text amenity-icon-class">{{
            amenity.name || 'Name'
            }}</ion-label>
        </ion-chip>
      </ion-row>
    </div>
  </div>
</ion-content>
<ion-footer class="footer-grid">
  <ion-toolbar>
    <ion-row class="cta-row">
      <ion-col class="cta-buttons" size-xs="6" size-sm="6" size-md="3" size-xl="3">
        <div>
          <lib-button is-medium is-secondary class="semiBold-md-text" (click)="clearFilters()">{{
            masterProperties.languageLabel.reset ?? 'Reset' }}</lib-button>
        </div>
      </ion-col>
      <ion-col class="cta-buttons" size-xs="6" size-sm="6" size-md="3" size-xl="3">
        <div>
          <lib-button is-medium is-primary class="semiBold-md-text" (click)="applyFilter()">{{
            masterProperties.languageLabel.apply ?? 'Apply' }}</lib-button>
        </div>
      </ion-col>
    </ion-row>
  </ion-toolbar>
</ion-footer>