import { Component, Input, Output, EventEmitter, OnChanges, SimpleChanges, OnInit, ViewChild, ElementRef, ChangeDetectorRef, HostListener } from '@angular/core';
import mockDataList from '../../mock/multi-select-dropdown.mock.json';
import { InputConstants } from '../../constants/shared-constant';
import { ItemModel } from '../../models/multi-select-dropdown.model';

@Component({
  selector: 'lib-multi-select-dropdown',
  templateUrl: './multi-select-dropdown.component.html',
  styleUrls: ['./multi-select-dropdown.component.scss'],
})
export class MultiSelectDropdownComponent implements OnInit, OnChanges {


  constructor(private elementRef: ElementRef) { }

  @ViewChild('search') searchElement!: ElementRef;
  @Input() allTextValue = InputConstants.allTextValue;  // Text for 'All' selection
  @Input() inputData !: ItemModel[];  // Input data for the dropdown (leave as empty array for now)
  @Input() placeHolder: string = InputConstants.placeHolder;  // Placeholder text for the search input
  @Input() searchPlaceHolder: string = InputConstants.searchPlaceHolder;  // Placeholder text for the search input
  @Input() setButtonText: string = InputConstants.setButtonText;  // Text for the 'Set' button
  @Input() cancelButtonText: string = InputConstants.cancelButtonText;  // Text for the 'Cancel' button
  @Input() labelKey: string = InputConstants.labelKey;  // The key for the label displayed in the dropdown
  @Input() valueKey: string = InputConstants.valueKey;  // The key for the value of the selected items
  @Input() isSearch: boolean = InputConstants.isSearch;  // Flag to determine if search should be enabled
  @Input() isMultiSelect: boolean = InputConstants.isMultiSelect;  // Flag to determine if multiple items can be selected
  @Input() isActionButton: boolean = InputConstants.isActionButton;  // Flag to determine if there are action buttons
  @Input() currentPage = InputConstants.currentPage;  // Current page for pagination
  @Input() disabled: boolean = InputConstants.disabled;  // Flag to disable the dropdown
  @Input() defaultDropdown: boolean = InputConstants.defaultDropdown;  // Default dropdown state
  @Input() noDataText: string = InputConstants.noDataText;  // Text to display when no data is found
  @Input() count: number = InputConstants.count;  // Data's Total count
  @Input() displayName: string = '';
  @Input() loadOnDemand = false;
  @Input() name? = '';
  @Output() selectionChanged = new EventEmitter<ItemModel[]>();  // Emit event when selection changes
  @Output() ngClick: EventEmitter<any> = new EventEmitter<any>();
  @Output() ngScroll: EventEmitter<any> = new EventEmitter<any>();
  @Output() ngSearch: EventEmitter<any> = new EventEmitter<any>();
  @Input() isAllSelect:boolean = false;
  @Input() searchDebounce = 0;
  @Input() isSelected = [];
  searchValue = '';
  selectedIds: Set<any> = new Set();  // Track selected item IDs
  filteredData: ItemModel[] = [];  // Filtered data based on search input
  searchQuery: string = '';  // Search text for filtering the items
  dropdownOpen: boolean = false;  // Flag to toggle dropdown visibility
  savedSelection: ItemModel[] = [];  // Store the previously selected items
  displayedItems: ItemModel[] = [];  // List of items currently displayed in the dropdown
  @Input() pageSize !: number;  // Number of items to show per page
  loading: boolean = false;  // Track if data is being loaded for pagination
  @Input() totalCount !: number;
  @Input() allowApiSearch = false;

  get filteredItems() {
    // Return the currently displayed filtered items
    return this.displayedItems;
  }

  ngOnInit(): void {
    this.totalCount = this.count
    // Initialize the component by loading more items.
    this.loadMoreItems();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['inputData']) this.filteredData = changes['inputData'].currentValue;
    // if (changes['isSelected']) if(this.isSelected && Array.isArray(this.isSelected) && !this.isSelected.length) this.selectedIds.clear();
    // this.loadMoreItems();
  }

  /**
   * Toggle the dropdown open/close state
   */
  toggleDropdown() {
    this.loading = true;
    if (!this.disabled) {
      if (this.loadOnDemand) {
        this.ngClick.emit({
          toggleLoading: this.iToggleLoading.bind(this),
          name: this.name
        })
      } else this.dropdownOpen = !this.dropdownOpen;


    }

  }

  iToggleLoading(dataLoaded: boolean) {
    if (dataLoaded) {
      this.dropdownOpen = !this.dropdownOpen;
      this.loading = false;
    }
  }

  /**
   * Filter the input data based on the search text.
   * Resets the pagination when the filter changes.
   */
  filterData() {
    this.filteredData = this.inputData.filter(item =>
      item[this.labelKey].toString().toLowerCase().includes(this.searchQuery.toLowerCase())
    );
    this.resetPagination();  // Reset pagination when the filter changes
  }


  onSearch(event: any) {
    const searchQuery = event?.target?.value || '';

    this.searchQuery = searchQuery;

    this.currentPage = 0;

    if (this.allowApiSearch) {
      this.ngSearch.emit({
        currentPage: this.currentPage,
        type:this.searchPlaceHolder,
        searchQuery,
        name: this.name
     
      });
    } else if (searchQuery === '') {
      this.filteredData = this.inputData;
    } else {
      this.filteredData = this.inputData.filter(item =>
        item[this.labelKey].toString().toLowerCase().includes(this.searchQuery.toLowerCase())
      );
    }

    this.currentPage = 1;
  }

  /**
   * Toggle the selection of a single item or 'All' items.
   * If 'All' is selected, all items are selected or deselected.
   */
  toggleItemSelection(item: ItemModel | string) {
    if (item === this.allTextValue) {
      
      this.toggleAllItems();
    } else {
      if (typeof item === 'object' && item !== null && this.valueKey != null) {
        const itemKeyValue = item[this.valueKey];
        
        if (this.selectedIds.has(itemKeyValue)) {
          this.selectedIds.delete(itemKeyValue);
        } else {
          this.selectedIds.add(itemKeyValue);
        }

        if (!this.isActionButton) {
          this.setSelection();  // Immediately update selection if no action button
        }
      }
    }
  }

  /**
   * Handle the selection of a single item and close the dropdown.
   * This is typically used in single selection mode.
   */
  toggleItemSingleSelection(item: {} | string) {
    this.dropdownOpen = false;
    let data = [];
    data.push(item)
    this.savedSelection = data
    this.displayedItems = this.savedSelection;
    this.selectionChanged.emit(this.savedSelection);
    this.searchQuery = '';  // Clear search text after selection
  }

  /**
   * Select or deselect all items based on the current selection state.
   * This is triggered when the user clicks the 'All' selection option.
   */
  toggleAllItems() {
    const allSelected = this.areAllItemsSelected();
    let dataToUpdate;

    if (this.searchQuery) {
      dataToUpdate = this.filteredData;
    } else {
      dataToUpdate = this.inputData;
    }
    dataToUpdate.forEach(item => {
      if (allSelected) {
        this.selectedIds.delete(item[this.valueKey]);
      } else {
        this.selectedIds.add(item[this.valueKey]);
      }
    });

    if (!this.isActionButton) {
      this.setSelection();  // Immediately update selection if no action button
    }
  }

  /**
   * Update the selected items based on the current selected IDs
   * and emit the selectionChanged event.
   */
  setSelection() {
    // this.savedSelection = this.inputData.filter(item => this.selectedIds.has(item[this.valueKey]));
    this.displayedItems = this.inputData.filter(item => this.selectedIds.has(item[this.valueKey]));
    this.selectionChanged.emit(this.displayedItems);
    if (this.isActionButton) {
      this.clearSearch();  // Clear the search input if using action buttons
    }
  }

  /**
   * Clear the search input and reset the dropdown state.
   * This is triggered when the user cancels the search.
   */
  clearSearch() {
    this.dropdownOpen = false;
    this.currentPage = 0;
    this.filteredData = this.inputData;
    this.searchQuery='';
    this.ngSearch.emit({
      currentPage: this.currentPage,
      type:this.searchPlaceHolder,
      searchQuery:this.searchQuery ,
      name: this.name
   
    });
  }

  /**
   * Cancel the current selection and reset the selected items 
   * to the previous state before selection.
   */
  cancelSelection() {
    this.selectedIds.clear();
    this.savedSelection.forEach(item => this.selectedIds.add(item[this.valueKey]));
    this.filteredData = this.savedSelection
    this.dropdownOpen = false;
    this.clearSearch();
  }

  /**
   * Remove a single item from the selection and update the displayed items.
   */
  removeItemFromSelection(item: ItemModel) {
    this.selectedIds.delete(item[this.valueKey]);
    this.displayedItems = this.displayedItems.filter(i => i[this.valueKey] !== item[this.valueKey]);
    this.updateSelectedItems();

  }

  /**
   * Emit the updated selected items after a change.
   */
  updateSelectedItems() {
    const selectedItems = this.inputData.filter(item => this.selectedIds.has(item[this.valueKey]));
    this.selectionChanged.emit(selectedItems);
  }

  /**
   * Check if all items are currently selected, 
   * either from the search filter or all input data.
   */
  areAllItemsSelected(): boolean {
    const dataToCheck = this.searchQuery ? this.filteredData : this.inputData;
    
    return dataToCheck.every(item => this.selectedIds.has(item[this.valueKey]));
  }

  /**
   * Close the dropdown and reset the filtered data and search text.
   */
  closePopup() {
    // if (this.isActionButton && this.savedSelection.length) {
    // this.cancelSelection()
    // }
    // else {
    //   this.filteredData = this.inputData;

    // }
    this.searchQuery = '';

    this.dropdownOpen = false;
  }

  // HostListener listens for the 'click' event on the document
  @HostListener('document:click', ['$event.target'])
  onDocumentClick(target: HTMLElement) {
    // Check if the click happened inside the element that this directive/component is attached to
    const clickedInside = this.elementRef.nativeElement.contains(target);

    // If the click was outside of the element, close the popup
    if (!clickedInside) {
      this.closePopup();
    }
  }

  /**
   * Load more items when the user scrolls to the bottom of the dropdown.
   * This is used for lazy loading and pagination.
   */
  loadMoreItems() {

    // Reset the loading state after items are loaded

    //default selection changes
    if (this.isSelected) {
      this.isSelected.forEach(async (item: ItemModel) => {
        this.toggleItemSelection(item)
      })
      this.displayedItems = this.isSelected
      this.loading = false;
    }
    else {
      this.loading = false;
    }
  }

  /**
   * Detect when the user has scrolled to the bottom of the dropdown.
   * If they have, load more items from the next page.
   */
  onScroll(event: Event) {
    const target = event.target as HTMLElement;
    const bottom = target?.scrollHeight === target?.scrollTop + target?.clientHeight;
    if (bottom) {

      if (!this.allowApiSearch) {
        return;
      }
      if (this.loading) {
        return;
      }
  
      const currentData = this.searchQuery ? this.filteredData : this.inputData; // Use filteredData if searching, otherwise use inputData
      const start = this.pageSize * (this.currentPage - 1);
      const end = start + this.pageSize;
  
      this.ngScroll.emit({
        currentPage: start,
        searchQuery: this.searchQuery,
        name: this.name ?? undefined
      })
    }

  }

  /**
   * Reset pagination state when search or filter changes.
   * This ensures that the pagination always starts from the first page.
   */
  resetPagination() {
    this.currentPage = 1;
    this.loadMoreItems();  // Load items for the first page
  }

  /**
   * Cancel the search and reset the filtered data.
   * This is typically used when clicking the cancel button after searching.
   */
  onCancel() {
    this.setSelection();
    this.searchQuery = '';
    this.currentPage = 0;
    this.ngSearch.emit({
      currentPage: this.currentPage,
      type:this.searchPlaceHolder,
      searchQuery:this.searchQuery ,
      name: this.name
   
    });
  }
}