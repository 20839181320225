import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'typeChange',
  pure: true,
})
export class TypeChangePipe implements PipeTransform {
  transform(row:any,type:unknown): boolean {
    const typeStr = type as string;
    if( typeStr ) return row[typeStr];
    return  true;
  }

}
