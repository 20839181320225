export interface UserProfile {
    branch?: string | null;
    clientName: string;
    companyName: string;
    costCenter: number;
    designation?: string | null;
    email: string;
    id: string;
    manager?: string | null;
    mobileNo: string;
    firstName: string;
    lastName: string | null;
    name : string;
    region?: string | null;
    address: Address;
    roles: string[];
    privacyPolicyAccepted?: boolean,
    client: ClientModel | null
}
  
export interface ClientModel { 
  clientId: string | null;
  clientDisplayName: string | null;
  clientName: string | null
}
  
  export interface Address {
    address1?: string | null;
    address2?: string | null;
    city: string;
    country: string;
    countyCode?: string | null;
    createdDate: Date;
    email?: string | null;
    fullAddress: string;
    id: string;
    latitude?: number | null;
    longitude?: number | null;
    name?: string | null;
    phone?: string | null;
    postalCode: string;
    updatedDate: Date;
  }
  