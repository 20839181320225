import { ChangeDetectorRef, Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { FilterFieldModel, GetFilterPayload } from '../../models';
import { ModalController } from '@ionic/angular';
import { CommonService } from '../../services/common.service';
import { AppLoaderService, decode, FlattenJsonPipe } from '@synergy-application/shared-ui';
import { KeyValueMapperPipe } from '../../pipe/common/key-value-mapper.pipe';
import { ActivatedRoute } from '@angular/router';
import { FilterService } from '../../services/filter.service';
import { Observable, Subscription } from 'rxjs';

@Component({
  selector: 'lib-more-filter',
  templateUrl: './more-filter.component.html',
  styleUrl: './more-filter.component.scss',
})
export class MoreFilterComponent implements OnInit, OnDestroy {
  @Input() props: FilterFieldModel[] = [];

  @Input() resetCallback!: () => void;

  filterSubscription?: Subscription;

  isRendered = false;

  constructor(
    private modelController: ModalController,
    private commonService: CommonService,
    private appLoaderService: AppLoaderService,
    private filterService: FilterService
  ) { }

  ngOnInit(): void {
    this.getLeadStatus();

    this.filterSubscription = this.filterService.getFilter().subscribe((res: FilterFieldModel[]) => {
      if (res) this.props = res; this.getLeadStatus();
    });
  }

  ngOnDestroy(): void {
    this.filterSubscription?.unsubscribe()
  }

  getLeadStatus() {
    this.isRendered = false;
    this.appLoaderService.showLoader(true);
    const payload = {
      pagination: {
        page: 1,
        pageSize: 10
      },
      type: 'status'
    };
    this.commonService.getLeadFilters(payload).then((res) => {
      const status = res.filtersList.map((el: any) => Number(el.key));
      const index = this.props.findIndex((el) => el['name'] == 'leadStatus');
      this.props[index].child = this.props[index].child?.filter((el) => status.includes(el.id));
    }).catch((error) => { console.log(error) }).finally(() => {
      this.appLoaderService.showLoader(false);
    }).finally(() => this.isRendered = true);
  }

  selectionChanged(event: any, item: any) {
    item.selectedValue = [...event];
  }

  /**
   * Custom trackBy function for Angular's ngFor directive.
   *
   * This function is used to track items in a list by a unique identifier, in this case, the 'name' property of each item.
   * It's typically used in situations where a list of items is dynamically updated and Angular needs to keep track of
   * which items have changed, been added, or removed to optimize the rendering process.
   *
   * @param index - The index of the current item in the iteration.
   * @param item - The current item in the iteration, expected to be an object containing a 'name' property.
   * @returns A string representing the unique identifier of the item (in this case, the 'name' property).
   */
  trackByName(index: number, item: any): string {
    return item.name;
  }

  /**
 * Applies the current filter and dismisses the modal with the applied filter data.
 * 
 * @remarks
 * This method sends the `props` object back to the parent component through the modal dismissal.
 * It is typically used to pass selected filter criteria to the parent.
 * 
 * @example
 * // Assuming `props` contains filter settings:
 * this.applyFilter();
 * // The parent component will receive these settings via the modal's onDidDismiss event.
 */
  applyFilter(): void {
    this.modelController.dismiss([...this.props]);
  }

  /**
 * Resets the filter selections and dismisses the modal with an empty array.
 * 
 * @remarks
 * This method clears all applied filters by passing an empty array to the parent component.
 * Useful when the user chooses to remove all filters and return to the default state.
 * 
 * @example
 * this.resetFilter();
 * // The parent component will receive an empty array, indicating no filters are applied.
 */
  resetFilter(): void {
    // this.modelController.dismiss([]);
    this.resetCallback();
  }

  getFilterDetails(payload: GetFilterPayload) {
    this.appLoaderService.showLoader(true)
    this.commonService.getLeadFilters(payload).then((res) => {

      const index = this.props.findIndex((el) => el.name = payload.type);
      this.props[index].value = res.filtersList;
    }).catch((error) => { console.log(error) }).finally(() => this.appLoaderService.showLoader(false));
  }

  dropDownClick(event: any) {
    const payload: GetFilterPayload = {
      pagination: {
        page: 1,
        pageSize: 10
      },
      type: event.name
    };
    const findIndex = this.props.findIndex((el) => el.name == event.name);
    this.commonService.getLeadFilters(payload).then((res) => {
      if (this.props[findIndex]) this.props[findIndex].value = res.filtersList;
    }).catch((error) => { console.log(error) }).finally(() => {
      this.appLoaderService.showLoader(false);
      event.toggleLoading(true);
    });
  }

  dropDownScroll({ currentPage, searchQuery, name }: any) {
    let payload: GetFilterPayload = {
      pagination: {
        page: currentPage + 1,
        pageSize: 10
      },
      type: name
    };
    if (searchQuery) payload['search'] = searchQuery;

    const findIndex = this.props.findIndex((el) => el.name == name);
    this.commonService.getLeadFilters(payload).then((res) => {
      if (this.props[findIndex]) this.props[findIndex].value.push(res.filtersList);
    }).catch((error) => { console.log(error) }).finally(() => {
      this.appLoaderService.showLoader(false);
    });
  }

  dropDownSearch({ searchQuery, name }: any) {
    let payload: GetFilterPayload = {
      pagination: {
        page: 1,
        pageSize: 10
      },
      type: name
    };
    if (searchQuery) payload['search'] = searchQuery;
    const findIndex = this.props.findIndex((el) => el.name == name);
    this.commonService.getLeadFilters(payload).then((res) => {
      if (this.props[findIndex]) this.props[findIndex].value = res.filtersList;
    }).catch((error) => { console.log(error) }).finally(() => {
      this.appLoaderService.showLoader(false);
    });
  }

  multiSelectDropdownClick(event: any) {
    const payload = {
      pagination: {
        page: 1,
        pageSize: 10
      },
      type: event.name
    };
    const findIndex = this.props.findIndex((el) => el.name == event.name);
    this.commonService.getLeadFilters(payload).then((res) => {
      if (this.props[findIndex]) this.props[findIndex].value = res.filtersList;
    }).catch((error) => { console.log(error) }).finally(() => {
      this.appLoaderService.showLoader(false);
      event.toggleLoading(true);
    });
  }

  /**
* close the slider and close the popup
*/
  closeSlider() {
    this.modelController.dismiss();
  }
}

