import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { CardInput } from '../../models/common.model';

@Component({
  selector: 'app-text-card',
  templateUrl: './text-card.component.html',
  styleUrls: ['./text-card.component.scss'],
})
export class TextCardComponent implements OnInit {
  @Input() cardData: CardInput = {
    imageUrl: '',
    title: '',
    headingText: '',
    descriptionText: '',
    details: '',
    buttonText: '',
    url: ''
  };
  @Input() width = '';
  @Output() navigate = new EventEmitter<any>();
  constructor(private router: Router) { }

  ngOnInit() { }


  /**
   * 
   * @param url navigate the page
   */
  onButtonClick(url: any) {
    if (url) {
      this.router.navigate([url]);
    } else {
      this.navigate.emit(this.cardData);
    }
  }
}
