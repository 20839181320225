import { Component, Input, ViewChild } from '@angular/core';
import { Sidebar } from 'primeng/sidebar';
import { ISideNavBar } from '../../models/side-nav-bar.model';


@Component({
  selector: 'app-side-bar',
  templateUrl: './side-bar.component.html',
  styleUrl: './side-bar.component.scss',
})
export class SideBarComponent {
  @Input({ required: false }) navBarData: ISideNavBar = {
    client: {
      icon: "/assets/svg/lead.svg",
    },
    navItem: [
      {
        icon: "/assets/svg/grid.svg",
        label: "Dashboard"
      }
    ]
  }


  /**
   * Toggles the sidebar visibility 
   */
  // toggleSidebar() {
  //   this.sidebarExpanded = !this.sidebarExpanded;
  // }

  /**
   * Toggles the menu visibility
   */
  toggleMenu() { }
}