import { Component, Input, OnInit, Output, EventEmitter, forwardRef } from '@angular/core';
import { TextAreaInput } from '../../models';
import { AppConstants, ImagePath } from '../../constants/shared-constant';
import { CommonService } from '../../services/common.service';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'lib-text-area',
  templateUrl: './text-area.component.html',
  styleUrls: ['./text-area.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => TextAreaComponent)
    }
  ]
})
export class TextAreaComponent implements OnInit, ControlValueAccessor {
  @Input() inputData: TextAreaInput = {
    placeholder: 'Please enter the text',
    value: '',
    label: 'Comments',
    type: AppConstants.fieldType.textarea,
    minLength: 10,
    maxLength: 200
  };
  @Input() infoMsg!: string;
  iconPath = ImagePath;

  @Output() focusoutEvent = new EventEmitter<TextAreaInput>();

  onChange: (value: string) => void = () => { };

  onTouched: () => void = () => { };

  constructor(private commonService: CommonService) { }

  writeValue(value: any): void {
    this.inputData.value = value || '';
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.inputData.isDisabled = isDisabled ?? false;
  }

  ngOnInit() {}

  /**
   * Updates field value on input change and emits focusout event.
   * @param value 
   * @param field 
   */
  onInputChange(value: string, field: TextAreaInput) {
    field.value = value;
    this.onChange(value);
    this.focusoutEvent.emit(field)
  }

  /**
   * Hides error message when the input field is focused.
   * @param field 
   */
  focusInput(field: TextAreaInput) {
    if (this.inputData.isDisabled) return;
    field.isShowErrorMessage = false;
    this.onTouched();
  }

  /**
   * Emits focusout event when the input field loses focus.
   * @param field 
   */
  focusoutInput(field: TextAreaInput) {
    if (this.inputData.isDisabled) return;
    this.commonService.validateInputFields(field)
    this.focusoutEvent.emit(field)
  }
}