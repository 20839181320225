<ion-grid class="nav-bar" [class.expanded]="isExpand" *ngIf="!isMobile">
  <div class="logo">
    <ion-icon class="logo-img logo-cursor" [class.expand-logo]="isExpand"
      [src]="isExpand ? navBarData.clientDetails['expandIcon'] : navBarData.clientDetails['icon']" (click)="navigateTo()"></ion-icon>
    <div class="floater" (click)="toggleExpand()">
      <ion-icon class="arrow" [class.left-arrow]="isExpand" [name]="expandedIcon"></ion-icon>
    </div>
  </div>
  <!-- profile details -->
  <div [class.user-info]="isExpand" (click)="navigateToProfile()">
    <span class="user" *ngIf="navBarData.profileDetails['name']">{{navBarData.profileDetails['name'].slice(0,2) | uppercase}}</span>
    <div [class.user-details]="isExpand" class="none">
      <span [class.user-name]="isExpand"
        *ngIf="navBarData.profileDetails['name']">{{navBarData.profileDetails['name']}}</span>
      <span [class.user-role]="isExpand"
        *ngIf="navBarData.profileDetails['role']">{{navBarData.profileDetails['role']}}</span>
    </div>
    <ion-icon src="/assets/svg/vertical-dot.svg" [ngClass]="{'show': isExpand, 'none': !isExpand}" />
  </div>

  <div class="menu-scroll">
    <ng-container *ngFor="let item of navBarData.menuItem; let i = index; trackBy: trackByLabel">
      <div [class.active]="item.parentId == selectedParent" class="nav-item" (click)="selectedMenu(item,item.parentId)">
        <ion-icon class="menu-icon" [src]="item.parentSvgPath"></ion-icon>
        <span class="label expand-label">{{ item.parentName }}</span>

        <!-- Expand/Collapse Icon -->
        <span *ngIf="item.children && item.children.length > 0 && isExpand" class="menu-expand">
          <ion-icon class="down-arrow" [class.up-arrow]="item.parentId == selectedParent"
            [name]="expandedIcon"></ion-icon>
        </span>
      </div>

      <!-- Show/Hide children based on the 'expandedItems' state -->
      <ng-container *ngIf="(item.parentId == selectedParent) && item.children && item.children.length > 0 && isExpand">
        <div class="submenu">
          <ng-container *ngFor="let child of item.children; trackBy: trackByLabel">
            <div class="nav-item" [class.active]="item.parentId == selectedParent" (click)="selectedNav(child)">
              <ion-icon class="menu-icon" [src]="child.svgPath"></ion-icon>
              <span class="label">{{ child.menuName }}</span>
            </div>
          </ng-container>
        </div>
      </ng-container>
    </ng-container>
  </div>


  <!-- bottom menu container -->

  <div class="bottom-container bottom-border">
    <ng-container *ngFor="let item of  navBarData?.bottomMenu; let i = index; trackBy: trackByLabel">
      <div [class.active]="item.parentId == selectedParent" class="nav-item" (click)="selectedMenu(item,item.parentId)">
        <ion-icon class="menu-icon" [src]="item.parentSvgPath"></ion-icon>
        <span *ngIf="isExpand" class="label">{{ item.parentName }}</span>
      </div>
    </ng-container>
  </div>

  <!-- bottom logo -->
  <div  *ngIf="!isExpand">
    <ion-label *ngIf="!isExpand && navBarData.bottomDetail?.bottomText" class="powered-by-css">{{navBarData.bottomDetail?.bottomText}}</ion-label>
    <!-- <ion-icon class="logo-img" [class.expand-logo]="isExpand"
      [src]="isExpand ? navBarData?.clientDetails?.['expandLogoImage'] : navBarData?.clientDetails?.['logoIcon']"></ion-icon> -->
  </div>
  

  <div  [class.logo]="navBarData.clientDetails['expandLogoImage'] || navBarData.clientDetails['logoIcon']"
  [class.expand-label-logo]="isExpand">
    <ion-label *ngIf="isExpand && navBarData.bottomDetail?.expandBottomText" class="powered-by-css">{{navBarData.bottomDetail?.expandBottomText}}</ion-label>
    <ion-icon class="logo-img" [class.expand-logo]="isExpand"
    [src]="isExpand ? navBarData.clientDetails['expandLogoImage'] : navBarData.clientDetails['logoIcon']"></ion-icon>
      

  </div>
</ion-grid>