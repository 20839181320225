import { Component, OnInit, SimpleChanges } from '@angular/core';
import { cloneDeep } from '@apollo/client/utilities';
import { ModalController } from '@ionic/angular';
import { AppConstants } from 'apps/booking-portal/src/app/core/app-constant';
import { OptionModel, PropertyFilterModel } from 'shared-ui/src/lib/models';


@Component({
  selector: 'app-filter',
  templateUrl: './filter.component.html',
  styleUrls: ['./filter.component.scss'],
})
export class FilterComponent implements OnInit {
  masterProperties!: PropertyFilterModel;
  selectedOption!: OptionModel;
  appConstants = AppConstants;
  defaultProperties!: PropertyFilterModel;

  constructor(private modalCtrl: ModalController) { }

  ngOnInit() {
    this.defaultProperties = JSON.parse(JSON.stringify(this.defaultProperties));
    if (this.masterProperties.selectedOption) {
      this.selectedOption = this.masterProperties.selectedOption;
    }
  }
  ngOnChanges(changes: SimpleChanges) {
    // if (changes['masterProperties'].currentValue) {
    //   this.defaultProperties = changes['masterProperties'].currentValue;
    //   if (this.defaultProperties.selectedOption) {
    //     this.selectedOption = this.defaultProperties.selectedOption;
    //   }
    // }
  }

  /**
   * clear the filter
   */

  clearFilters() {
    this.selectedOption = this.defaultProperties.selectedOption;
    this.masterProperties = JSON.parse(JSON.stringify(this.defaultProperties));
  }

  /**
   * close the slider and close the popup
   */
  closeSlider() {
    this.modalCtrl.dismiss();
  }

  /* Method to handle slider value changes */
  onInputChange(rangeType: string, rangeValue: number) {
    if (
      rangeType &&
      rangeType == this.appConstants.rangeType.max &&
      rangeValue
    ) {
      this.masterProperties.sliderConfigurationForPrice.value.upper =
        rangeValue;
    } else {
      this.masterProperties.sliderConfigurationForPrice.value.lower =
        rangeValue;
    }
    this.masterProperties.sliderConfigurationForPrice.value = cloneDeep(
      this.masterProperties.sliderConfigurationForPrice.value
    );
  }

  /**
   * update the tooltip format
   * @param pinValue
   * @returns {string} -- return the format as string
   */
  pinFormatter(pinValue: number) {
    return `${pinValue} miles`;
  }

  /**
   * Update the price range when user change
   */
  onPriceRangeChange() {
    this.masterProperties.inputParams = [
      {
        placeholder: this.appConstants.rangeType.min,
        value:
          this.masterProperties?.sliderConfigurationForPrice &&
            this.masterProperties?.sliderConfigurationForPrice.value
            ? this.masterProperties?.sliderConfigurationForPrice.value.lower
            : this.appConstants.range.minValue,
        label: this.appConstants.rangeType.min,
        isShowErrorMessage: true,
        isRequired: true,
        type: this.appConstants.fieldType.number,
        minLength: this.appConstants.range.maxValue,
        maxLength: this.appConstants.range.maxValue,
      },
      {
        placeholder: this.appConstants.rangeType.max,
        value:
          this.masterProperties?.sliderConfigurationForPrice &&
            this.masterProperties?.sliderConfigurationForPrice.value
            ? this.masterProperties?.sliderConfigurationForPrice.value.upper
            : this.appConstants.range.maxValue,
        label: this.appConstants.rangeType.max,
        isShowErrorMessage: true,
        isRequired: true,
        type: this.appConstants.fieldType.number,
        minLength: this.appConstants.range.minValue,
        maxLength: this.appConstants.range.maxValue,
      },
    ];
  }

  /**
   * apply filter and close the popup
   */
  applyFilter() {
    if (this.selectedOption) {
      this.masterProperties.selectedOption = this.selectedOption;
    }
    this.modalCtrl.dismiss(this.masterProperties);
  }

  /**
   * Get the value of selected radio option
   * @param $event
   * @returns {AmenitiesModel}  -- return the amenity object
   */

  selectedValueChange($event: OptionModel) {
    if ($event) {
      this.selectedOption = $event;
    }
  }
}