import { Component, EventEmitter, forwardRef, Input, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { DateInput, DatePicketType, IconPositionModel, IconSizeModel, IconNameModel } from '../../models';
import { AppConstants } from '../../constants/shared-constant';
import { CommonService } from '../../services/common.service';
import { Calendar } from 'primeng/calendar';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'lib-date-picker',
  templateUrl: './date-picker.component.html',
  styleUrls: ['./date-picker.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => DatePickerComponent)
    }
  ]
})
export class DatePickerComponent implements ControlValueAccessor, OnInit {
  @Input() inputData: DateInput = {
    label: '',
    showErrorMessage: '',
    placeholder: '',
    isShowErrorMessage: false,
    isRequired: false,
    value: '',
    mode: AppConstants.mode.range,
    iconPosition: IconPositionModel.suffix,
    iconSize: IconSizeModel.medium,
    iconName: IconNameModel.clock,
    isBorder: DatePicketType.borderless,
    height: '36px'
  };

  @Input() showIcon = true;

  @Input() numberOfMonths = AppConstants.numberOfMonth.two;

  minDate: Date;

  @Output() dateObj = new EventEmitter<DateInput>();

  @Input({
    required: false
  }) defaultDate: Date = new Date();

  constants = AppConstants

  @ViewChild("calendar") calendar!: Calendar;

  private onChange = (value: any) => {
  };

  private onTouched = () => { };

  constructor(
    private commonService: CommonService,
    private datePipe: DatePipe
  ) {
    this.minDate = new Date();
  }
  writeValue(value: any): void {
    if (value) {
      this.inputData.value = value;
    }
  }
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  ngOnInit() {
  }
  ngOnChanges(changes: SimpleChanges) {
    if (changes['inputData']?.currentValue) {
      this.inputData = changes['inputData'].currentValue;
      this.inputData.minDate = changes['inputData'].currentValue.minDate || this.minDate;
    }
  }

  /**
   * Method triggered when a date or date range is selected from the calendar.
   * Emits the selected date data to the parent component.
   */
  onDateSelected() {
    if (this.inputData.mode === AppConstants.mode.timeOnly) {
      this.inputData.value = this.datePipe.transform(this.inputData.value, 'hh:mm a')
    }
    this.dateObj.emit(this.inputData); // Emit the inputData containing the selected date(s).
    this.onChange(this.inputData.value); // Notify changes
    this.onTouched();
    if (this.inputData.value?.length == 2 && this.inputData.mode == this.constants.mode.range && this.inputData.value[0]?.getTime() == this.inputData.value[1]?.getTime()) {
      this.inputData.value[1] = null;
      return;
    }
    this.commonService.validateInputFields(this.inputData)
  }

  toggleCalendar() {
    if (this.inputData.mode === 'timeOnly') return;
    this.calendar.toggle();
  }
  onShow() {
    setTimeout(() => {
      this.calendar?.unbindScrollListener();
    }, this.constants.delay);
  }

}
