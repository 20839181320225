import { Attribute, Component, HostBinding, Input, OnInit } from '@angular/core';
import { AppConstants } from '../../constants/shared-constant';

@Component({
  selector: 'lib-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss'],
})
export class ButtonComponent {

  @Input() class? = '';
  @Input() disabled = false;
  @Input() icon = '';
  @Input() isSelected = false;

  @Input() image: boolean = false;

  @Input() type?: string = AppConstants.type.button

  constructor(
    @Attribute('is-primary') public isPrimary: boolean,
    @Attribute('is-secondary') public isSecondary: boolean,
    @Attribute('is-danger') public isDanger: boolean,
    @Attribute('is-tertiary') public isTertiary: boolean,
    @Attribute('is-borderOnly') public isBorderOnly: boolean,
    @Attribute('is-small') public isSmall: boolean,
    @Attribute('is-medium') public isMedium: boolean,
    @Attribute('is-large') public isLarge: boolean,
    @Attribute('is-border-less') public isBorderLess: boolean,
    @Attribute('expand') public expand: boolean
  ) {

    /**
     * Determine button type; defaults to true if not null
     */
    this.isPrimary = this.isPrimary != null;
    this.isSecondary = this.isSecondary != null;
    this.isDanger = this.isDanger != null;
    this.isBorderOnly = this.isBorderOnly != null;
    this.isTertiary = isTertiary != null;
    /**
     * Determine button size; defaults to true if not null
     */
    this.isSmall = this.isSmall != null;
    this.isMedium = this.isMedium != null;
    this.isLarge = this.isLarge != null;


    /**
     * Determine if button style is border less; defaults to true if not null
     */
    this.isBorderLess = this.isBorderLess != null;

    if (!this.isBorderLess) {
      this.class += 'button';
    }
  }
}