<div class="radio-container">
  <ion-label class="medium-md-text label-color-text" *ngIf="label">{{ label }} @if (infoMsg) {
    <div class="icon-container">
      <ion-icon class="info-icon" [src]=iconPath.iIcon alt=""></ion-icon>
      <div class="tooltip">{{infoMsg}}</div>
    </div>
    }</ion-label>
  
  <ion-radio-group class="radio-group" [(ngModel)]="selectedOption" (ngModelChange)="onRadioChange($event)">
    <ng-container >
      <ion-row>
        <ion-col  class="ion-radio-alignment">
        <div class="radio-css" *ngFor="let option of data" [class.disabled]="isDisabled">
          <ion-radio class="table-radio" [value]="option" [disabled]="isDisabled"></ion-radio>
          <ion-label class="medium-sm-text label-color-text" (click)=" onLabelClick(option)">{{ option?.name ?? ''
            }}</ion-label>
        </div>
        </ion-col>
      </ion-row>
    </ng-container>
  </ion-radio-group>
</div>