import { Injectable, Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'pickValueFromObject'
})
@Injectable({
  providedIn: 'root'
})
export class PickValueFromObjectPipe implements PipeTransform {
  transform(value: Record<string, unknown>, key?: string): any {
    if(key && value && value[key]) return value[key];
    return null;
  }
}
