<ion-content scroll-y="false" [fullscreen]="true" *ngIf="data">
    <div class="scroll-css rfh-form {{sidePadding}}">
        <h1 class="semiBold-xl-text-24px title">{{languageLabel?.requestHousingTitle ?? ''}}</h1>
        <ion-grid class="pad-0">
            <ion-row class="col-gap pad-0">
                <ion-col size="12" size-sm="12" size-md="6" size-xl="4" size-lg="4">
                    <app-rfh-instruction [instruction]="instruction"></app-rfh-instruction>
                </ion-col>
                <ion-col>
                    <ion-grid class="pt-0">
                        <ion-row *ngIf="isTravelerDropdown">
                            <ion-col class="checkbox-align">
                                <lib-checkbox [data]="checkBoxData"></lib-checkbox>
                            </ion-col>
                        </ion-row>
                        <ion-row>
                            <ion-col size="12" *ngIf="checkBoxData.isChecked" size-sm="12" size-md="6" size-xl="6"
                                class="pl-10" size-lg="4">
                                <lib-dropdown  (ngModelChange)="getUserDetails($event,travelerDropdown)" [showCheckbox]=true [multiSelectable]="travelerDropdown.multiSelectable"
                                    [(ngModel)]="travelerDropdown.value" [data]="travelerDropdown.options"
                                    [label]="travelerDropdown.label" displayBy="value" value="key"
                                    [searchable]="travelerDropdown.isSearch" ngDefaultControl
                                    (ngClick)="getDropDownValue($event,travelerDropdown)"
                                    [totalCount]="travelerDropdown?.totalCount || 0"
                                    [allowApiSearch]="travelerDropdown.allowApiSearch" [pageLimit]="10"
                                    [loadOnDemand]="true" (ngSearch)="getDropDownValue($event,travelerDropdown)"
                                    (ngScroll)="getDropDownValue($event,travelerDropdown)">
                                </lib-dropdown>
                            </ion-col>
                        </ion-row>
                        <ion-row>
                            <ion-col size="12" class="pl-0">
                                <div *ngFor="let value of data" class="dynamic-class">
                                    <lib-dynamic-form (selectedLocation)="selectedLocation($event)"
                                        [languageLabel]="locationLabel" (dateChange)="onDateChange($event)" [requestLabel] = "languageLabel"
                                        [value]="value" (inputChange)=onInputChange($event)></lib-dynamic-form>
                                </div>
                            </ion-col>

                        </ion-row>
                    </ion-grid>
                    <ion-grid class="pl-10 pt-0">
                        <ion-row>
                            <ion-col>
                                <div>
                                    <ion-row class="detail-grid">
                                        <ion-col class="grid-css">
                                            <img alt="" class="location-css" src="/assets/icon/location.svg">
                                            <div>
                                                <div class="regular-md-text font-color">{{languageLabel?.locationLabel
                                                    }}</div>
                                                <div class="semiBold-md-text font-color word-break-align">{{location ?
                                                    location : '-'}}</div>
                                            </div>
                                        </ion-col>
                                        <ion-col class="grid-css">
                                            <img alt="" src="/assets/svg/no-of-guest.svg">
                                            <div>
                                                <div class="regular-md-text font-color">
                                                    {{languageLabel?.noOfGuestFrame}}</div>
                                                <div class="semiBold-md-text font-color">{{noOfGuest ? noOfGuest : '-'}}
                                                </div>
                                            </div>
                                        </ion-col>
                                        <ion-col class="grid-css"> <img alt="" src="/assets/svg/no-of-nights.svg">
                                            <div>
                                                <div class="regular-md-text font-color">{{languageLabel?.noOfNights}}
                                                </div>
                                                <div class="semiBold-md-text font-color">{{noOfNights ? noOfNights :
                                                    '-'}}</div>
                                            </div>
                                        </ion-col>
                                    </ion-row>
                                </div>
                                
                                <!-- <div>
                                    <ion-col> <ion-label
                                            class="input-text semiBold-sm-text">{{languageLabel?.amenitiesLabel}}</ion-label></ion-col>
                                </div> -->
                                <!-- <div class="bottom-align">
                                    <ion-chip *ngFor="let amenity of visibleAmenities"
                                        [class.selected]="amenity.isSelected" class="amenity-class"
                                        (click)="amenity.isSelected = !amenity.isSelected">
                                        <img alt="" [src]="amenity.svgPath">
                                        <ion-label class="medium-sm-text amenity-text-color">{{
                                            amenity.name || 'Name'
                                            }}</ion-label>
                                    </ion-chip>
                                    <lib-button class="load-class regular-sm-text" is-secondary *ngIf="!showMore"
                                        (click)="showMore = true">{{languageLabel?.loadMore}}</lib-button>

                                    <lib-button is-secondary class="regular-sm-text load-class" *ngIf="showMore"
                                        (click)="showMore = false">{{languageLabel?.loadLess}}</lib-button>
                                </div>   -->
                                                             
                            </ion-col>
                        </ion-row>
                        <ion-row>
                            <ion-col class="check-box-align">
                                <lib-checkbox [data]="checkBoxForSubmit"></lib-checkbox>
                            </ion-col>
                        </ion-row>
                    </ion-grid>
                </ion-col>
            </ion-row>
            <ion-row>
                <ion-col class="button-class">
                    <lib-button (click)="onCancel()" is-secondary is-medium class="semiBold-md-text">{{
                        languageLabel?.cancel ??
                        ''}}</lib-button>
                    <lib-button is-primary is-medium (click)="requestHousing()" class="semiBold-md-text">{{
                        languageLabel?.requestHousing ?? ''}}</lib-button>
                </ion-col>
            </ion-row>
        </ion-grid>
    </div>
</ion-content>