import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { CheckBoxInput, DateInput, DatePicketType, DetailViewModel, FieldModel, GroupModel, IconNameModel, IconPositionModel, IconSizeModel, InputData, OptionModel, ToggleInput } from '../../models/shared-common.model';
import { ViewType } from '../../enum/lead-info';
import { AppConstants } from '../../constants/shared-constant';

@Component({
  selector: 'lib-detail-view',
  templateUrl: './detail-view.component.html',
  styleUrl: './detail-view.component.scss',
})

export class DetailViewComponent implements OnInit, OnChanges {

  @Input() apiResponse: any;
  @Input() keys: FieldModel[] = [];
  @Input() showDetailsInitially: boolean = false;
  @Input() useExpandCollapse: boolean = false;
  @Input() initialColumns!: number;
  @Input() enableShowMore: boolean = false;
  @Input() size: number = 4;
  @Input() language: any;
  @Output() toggleChange = new EventEmitter<ToggleInput>();
  @Output() fieldChange = new EventEmitter<CheckBoxInput>();
  @Output() ngModelChange = new EventEmitter<OptionModel>();
  @Output() viewClicked = new EventEmitter();
  @Output() downloadClicked = new EventEmitter();
  @Output() checkboxChangeGroupEmit: EventEmitter<CheckBoxInput[]> = new EventEmitter<CheckBoxInput[]>();
  @Output() selectedDateObj = new EventEmitter();
  showAllDetails: boolean = false;
  viewType = ViewType;
  toggleDetails !: ToggleInput;
  checkBoxDetails: CheckBoxInput = { isChecked: false, label: '' };
  dropdownData: OptionModel[] = []
  selectedValue: string = '';
  viewModel: DetailViewModel = { name: '', group: [], fields: [] };
  datePickerConfig = {
    iconPosition: IconPositionModel.suffix,
    iconSize: IconSizeModel.medium,
    iconName: IconNameModel.calendar,
    isBorder: DatePicketType.border,
    height: '34px'
  }


  ngOnInit() {
    this.mapFields()
    if (this.viewModel) {
      this.showAllDetails = false;
      // Initialize each group's showDetails based on parent config
      this.viewModel.group?.forEach(group => {
        group.showDetails = this.showDetailsInitially;
        group.showMore = this.showDetailsInitially;
      });
    }
  }

  ngOnChanges() {
    this.mapFields()
  }

  /**
   * Mapping keys with the api response
   */
  mapFields() {
    // Handle grouped data (array of objects in API response)
    if (Array.isArray(this.apiResponse)) {
      this.viewModel.group = this.apiResponse.map((item: any) => ({
        name: item.name,
        fields: this.keys.map((keyObj) => ({
          name: keyObj.name,
          displayName: keyObj.displayName,
          value: item[keyObj.name],
          icon: keyObj.icon,
          type: keyObj.type ?? this.viewType.TEXT,
          options: keyObj.options ?? [],
          label: keyObj.label,
          isChecked: keyObj.isChecked,
          dateFormat: keyObj.dateFormat,
          size: keyObj.size ?? 3,
          placeholder: keyObj.placeholder ?? '',
          mode: keyObj.mode ?? '',
          checkBoxOptions: keyObj.checkBoxOptions ?? [],
          toggleDetails: keyObj.toggleDetails ??  [],
          isDisabled:keyObj.isDisabled ?? false,
        }))
      }));
    }
    else if (this.apiResponse && typeof this.apiResponse === 'object') {
      // Handle ungrouped data (single object in API response)
      this.viewModel.fields = this.keys.map((keyObj) => ({
        name: keyObj.name,
        displayName: keyObj.displayName,
        value: this.apiResponse[keyObj.name] ? this.apiResponse[keyObj.name] :keyObj.value,
        description: keyObj.description,
        icon: keyObj.icon,
        type: keyObj.type ?? this.viewType.TEXT,
        options: keyObj.options ?? [],
        label: keyObj.label,
        isChecked: keyObj.isChecked,
        dateFormat: keyObj.dateFormat,
        size: keyObj.size ?? 3,
        placeholder: keyObj.placeholder ?? '',
        mode: keyObj.mode ?? '',
        checkBoxOptions: keyObj.checkBoxOptions ?? [],
        isDisabled:keyObj.isDisabled ?? false,
        toggleDetails: keyObj.toggleDetails ??  [],
        sectionHeader:keyObj.sectionHeader,
        required:keyObj.required
       
      }));
      
    }

  }

  // Generic showing details for both grouped and ungrouped fields
  fieldDetailsToShow(group: GroupModel | null = null): void {

    if (group) {
      // If group is provided, toggle its showDetails (expand/collapse for groups)
      if (this.useExpandCollapse) {
        group.showDetails = !group.showDetails;
      } else if (this.enableShowMore) {
        group.showMore = !group.showMore;
      }
    } else {
      // If no group, toggle visibility for ungrouped fields
      this.showAllDetails = !this.showAllDetails;
    }
  }

  // Get fields to show for grouped fields based on showMore or initialColumns
  getFieldsToShow(group: GroupModel): FieldModel[] {
    return group.showMore ? group.fields.slice(this.initialColumns) : group.fields.slice(0, this.initialColumns);
  }

  // Get initial fields for ungrouped fields
  getInitialFields(fields: FieldModel[]): FieldModel[] {
    return fields?.slice(0, this.initialColumns);
  }

  // Get remaining fields for ungrouped fields when 'Show More' is active
  getRemainingFields(fields: FieldModel[]): FieldModel[] {
    return fields?.slice(this.initialColumns) || [];
  }

  /**
  * Emits the event when the toggle input changes.
  * @param event The ToggleInput event containing the new state.
  */
  onToggleChange(event: ToggleInput, field: FieldModel): void {
    // Ensure the field.value is not undefined before attempting to replace
    if (field.value) {
      if (event.isChecked) {
        field.value = field.value.replace(AppConstants.distance.kiloMeters, AppConstants.distance.miles);
      } else {
        field.value = field.value.replace(AppConstants.distance.miles, AppConstants.distance.kiloMeters);
      }
    } else {
      // If value is undefined, set a default value (e.g., "Miles")
      field.value = event.isChecked ? AppConstants.distance.miles : AppConstants.distance.kiloMeters;
    }
    this.toggleChange.emit(event);
  }

  checkboxChangeGroup(event: CheckBoxInput, options: CheckBoxInput[] | undefined) {
    if (options) this.checkboxChangeGroupEmit.emit(options)
  }

  selectedDate(event: DateInput) {
    this.selectedDateObj.emit(event)
  }
  /**
  * Emits the event when the checkbox input changes.
  * @param event The CheckboxInput event containing the new state.
  */
  onFieldChange(event: CheckBoxInput) {
    this.fieldChange.emit(event);
  }

  /**
   * Emits the event when the dropdown value selected
   * @param event The OptionModel 
   */
  onDropdownChange(event: OptionModel) {
    this.ngModelChange.emit(event);
  }

  /**
   * Emits the event when the view is clicked
   */
  onViewClicked() {
    this.viewClicked.emit();
  }

  /**
   * Emits the event when download is clicked
   */
  onDownloadClicked() {
    this.downloadClicked.emit();
  }
}