<!-- Customizable Ion Button with dynamic ID, disabled state, fill style, size, expansion, and content projection. -->
<ion-button [disabled]="disabled" [fill]="isPrimary ? 'solid' : isSecondary ? 'outline' : isBorderLess ? 'clear' : ''"
  [ngStyle]="{ border : isSelected ? '1px solid var(--app-primary-color)' : '',
               color : isSelected ? 'var(--app-primary-color)' : '' } "
  [size]="isSmall ? 'small' : isMedium ? 'default' : isLarge ? 'large' : ''" [expand]="expand" [ngClass]="{
    'is-primary': isPrimary,
    'is-secondary': isSecondary,
    'is-danger': isDanger,
    'is-tertiary': isTertiary,
    'is-borderOnly': isBorderOnly,
    'is-medium': isMedium,
    'is-small': isSmall,
    'is-large': isLarge,
    'is-borderless': isBorderLess
  }" class="{{ class }}"
  type="type">
  <!-- Display image if icon is provided -->
  <ion-icon class="image icon-color" *ngIf="icon && !image" [src]="icon" alt=""
    [class.isSelected]="isSelected"
    />
    <img class="image icon-color" *ngIf="icon && image" [src]="icon" alt=""
    [class.isSelected]="isSelected"/>
  <ng-content></ng-content>
</ion-button>