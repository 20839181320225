<!-- upload-file-progress component -> To display the progress bar of the uploaded file -->
<div class="progress-bar-container" *ngIf="showProgressBar">
  <div class="align">
    <div class="space-align">
      <img src="imagePath.fileIcon" alt="">
      <ion-label class="regular-md-text">{{ progressData.fileName }}</ion-label>
    </div>
    <div>
      <img src="imagePath.cancelIcon" alt="Cancel" (click)="onCancel()" class="cancel-icon">
    </div>
  </div>
  <div class="margin-align">
    <ion-label class="regular-xs-text">{{ progressData.progress }}{{appConstants.mbSize}} / {{ progressData.total }}
      {{appConstants.mbSize}}</ion-label>
  </div>
  <div class="progress-bar">
    <div class="progress-bar-line" [style.width.%]="progressData.progress"></div>
  </div>
</div>
