export const AppConstants = {
  propertyPageSize: 10,
  defaultDays: 30,
  error: 'Invalid Token',
  studio: 'Studio',
  fieldType: {
    text: 'text',
    number: 'number',
    dropdown: 'dropdown',
    toggle: 'toggle',
    textarea: 'textarea',
    checkbox: 'checkbox',
    radioOption: 'radioOption',
    date: 'date',
    currency: 'currency',
    phoneNumber: 'phoneNumber'
  },
  copiedMsg: 'Property Copied successfully!',
  amenitiesId: ['9DE74F3B-E5AB-4B28-AF97-4B27C3FE36C0', '59B3E3A8-E568-477B-B491-7C7FD2D289E4', '20408A06-EFA4-4216-AF9C-A47F514D8DCF'],
  petsId: '59B3E3A8-E568-477B-B491-7C7FD2D289E4',
  mode: {
    single: 'single',
    range: 'range',
      timeOnly:'timeOnly'
  },
  localhost: 'localhost',
  rangeType: {
    min: 'Min',
    max: 'Max'
  },
  responsive: {
    screen1: "1500px",
    screen2: "1024px",
    screen3: "768px",
    screen4: "560px"
  },
  input: {
    min: 3,
    max: 100
  },
  textarea: {
    min: 3,
    max: 500
  },
  number: {
    min: 1,
    max: 500
  },
  sortDirection: {
    ASC: 'asc',
    DESC: 'desc'
  },
  sortByOption: {
    1: 'location',
    2: 'priceHigh',
    3: 'priceLow'
  },
    range: {
    label: "range",
    minValue: 1,
    maxValue: 999,
    defaultDistanceValue: 50,
    defaultPriceValue: {
      lower: 1,
      upper: 999
    },
    step: 1
  },
  distanceRange : {
    minValue: 0,
    maxValue: 100,
  },
  defaultGuest: 1,
  enLabel: 'EN - $',
  profileSymbol: 'IM',
  property: 'property',
  neighbor: 'neighbor',
  office: "office",
  expand: {
    remove: 'remove',
    add: 'add'
  },
  yes: 'Yes',
  dollarSymbol: '$',
  invalidDate: 'Invalid Date',
  imageTour: 'https://my.matterport.com/show/?m=KoPPWvVPqQm',
  searchNeighPlaceHolder: "Search Neighborhood",
  page: 1,
  pageSize: 100,
  searchLength: 2,
  dotLabel: '.',
  editMode: 'edit',
  addMode: 'add',
  viewMode: 'view'
}

export const BlobName = {
  termsAndCondition: 'terms-and-conditions.html',
  privacyPolicy: 'privacy-policy.html',
  travelerPolicy: 'traveller-policy.html',
  requestRate: 'request.rate.json',
  requestForm: 'request.form.json',
  configJson : 'config.json'
}

export const GoogleAnalyticsKey =  {
  search: 'search',
  propertyListingView: 'property_listing_view',
  viewPropertyDetails: 'view_property_details',
  selectUnitType: 'select_unit_type',
  requestRateFormSubmission: 'request_rate_form_submission',
  propertyFormSubmission: 'property_form_submission',
  loggedIn: 'logged_in'
}
export const IconPath = {
  bell: "/assets/svg/bell.svg",
  star: "/assets/svg/star.svg",
  settleStay: "/assets/svg/settle-stay.svg",
  care: "/assets/svg/care.svg",
  earthSearch: "/assets/svg/earth-search.svg",
  fileEdit: "/assets/svg/file-edit.svg",
  earthLens: "/assets/svg/earth-with-lens.svg",
  smileys: "/assets/svg/smileys.svg",
  medal: "/assets/svg/medal-star.svg",
  filter: "/assets/svg/filter-icon.svg",
  wifi: "/assets/icon/wifi.svg",
  pet: "/assets/icon/Pet.svg",
  dishwasher: "/assets/icon/dishwasher.svg",
  elevator: "/assets/icon/elevator.svg",
  towel: "/assets/icon/towel.svg",
  dryer: "/assets/icon/hairdryer.svg",
  microwave: "/assets/icon/microwave-oven.svg",
  tourBus: "./assets/svg/tour-bus.svg",
  walk: "./assets/svg/walk.svg",
  car: "./assets/svg/car.svg",
  shopping: "/assets/svg/shopping.svg",
  entertainment: "./assets/svg/Entertainment.svg",
  beaches: "./assets/svg/beaches.svg",
  parkingCar: "./assets/svg/Parking-car.svg",
  gym: "./assets/svg/Gym.svg",
  restaurant: "./assets/svg/restaurant.svg",
  grocery: "./assets/svg/grocery.svg",
  school: "./assets/icon/school.svg",
  hospital: "./assets/icon/hospital.svg",
  office: "./assets/svg/office-pin.svg",
  zoomIn: "./assets/svg/zoom_in_map.svg",
  zoomOut: "./assets/svg/zoom_out_map.svg",
  pin: "./assets/svg/location-pin.svg",
  bulk: "./assets/svg/bulk-people.svg",
  plus: "./assets/svg/plus-icon.svg",
  cancel: "./assets/icon/cancel.svg",
  addImage: "./assets/svg/add-image.svg",
  editPencil: "./assets/svg/edit-pencil.svg",
  mail: "./assets/icon/mail-icon.svg",
  copy: "./assets/icon/copy-symbol.svg"
}
