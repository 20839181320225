import { HostListener } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { checkScreenSize, getSubDomain } from 'shared-ui/src/lib/core/helper';
import { Router } from '@angular/router';
import { LanguageService } from '../../../shared/services/language.service';

import { SearchDetail, UserProfile } from 'shared-ui/src/lib/models';
import { AppLoaderService, CdnContentService, LoggingService, LoginService, StorageService } from '@synergy-application/shared-ui';
import { LocalStorageKeys } from 'shared-ui/src/lib/constants/local-storage.constant';

import { ModalController } from '@ionic/angular';
import { PolicyComponent } from '../../../traveler-policy/components/policy/policy.component';
import { environment } from 'apps/booking-portal/src/environments/environment';
import { BlobName, IconPath } from 'apps/booking-portal/src/app/core/app-constant';
import { LogLevel } from 'shared-ui/src/lib/enum/logger';
import { Role } from '../../../../enum/common';
import { ROUTESPATH } from '../../../../core/routes-constant';
import { CommonService } from '../../../shared/services/common.service';
@Component({
  selector: 'app-landing',
  templateUrl: './landing.component.html',
  styleUrl: './landing.component.scss',
})
export class LandingComponent implements OnInit {
  section3Heading = 'Key Benefits';

  isMobile = false;

  section4Heading = 'How To Book';

  languageLabel: any;

  star: string = IconPath.star;

  buttonUI = true;
  // imageTour = AppConstants.imageTour;

  imageTour!: string

  imageTourLabel = {
    label: 'Experience Synergy For Yourself With a 360 Virtual Tour',
  };

  section2 = {
    headingText: 'Welcome to Oracle’s Intern Program!',
    descriptionText:
      'Congratulations on your internship offer. Oracle has partnered with Synergy for those who are interested in furnished housing options. More than just a furnished accommodation, Synergy Global Housing provides a worry-free end-to-end housing experience to make your next move as smooth as possible. Each stay need is supported by a designated counselor dedicated to delivering the highest level of personalized service.',
  };

  section3: any;

  section4: any;
  content: any;
  propertyHeader = 'Featured Properties';
  propertyLists: any;

  imageData: any;

  anotherImageData = {
    imageUrl: '/assets/image-card/synergy-home-image.jpg',
    title: 'Welcome to Synergy',
    headingText: 'Apartments for anyone, anywhere',
    descriptionText:
      'Wherever your journey takes you, you’ll find a beautiful place to stay with a furnished apartment from Synergy. And with local support on hand whenever you need it you can relax, knowing that we will be with you every step of the way.',
    buttonText: 'Discover More',
  };

  propertyImage: any;

  userInfo!: UserProfile;

  environment: any;

  sidePadding!: string;

  isMediumSize = false;

  sideNavApplied!: string;

  constructor(private loggingService: LoggingService,private loaderService:AppLoaderService,private cdnContentService: CdnContentService,private modalController: ModalController, private loginService: LoginService, private storageService: StorageService, private router: Router, private languageService: LanguageService, private commonService: CommonService
  ) { }

  async ngOnInit() {
    this.environment = environment;
    if (!this.storageService.checkValueExists(LocalStorageKeys.token)) {
      this.router.navigateByUrl(ROUTESPATH.LOGIN);
      return
    }
    if (this.storageService.checkValueExists(LocalStorageKeys.userInfo)) {
      this.userInfo = JSON.parse(this.storageService.get(LocalStorageKeys.userInfo));
    }
    this.languageLabel = this.languageService.getLandingKey();
    this.isMobile = checkScreenSize();
    if (!this.userInfo?.privacyPolicyAccepted && this.storageService.checkValueExists(LocalStorageKeys.token)) {
      this.openPopUp();
    }
    const resultData = await this.getConfigJsonData();
    this.imageData = resultData.section1;
    this.section2 = resultData.section2;
    this.section3 = resultData.section3;
    this.section4 = resultData.section4;
    this.propertyLists = resultData.propertyList;
    this.isMediumSize = this.checkLapMediumSize();
    this.showSideBar();
  }
  async getConfigJsonData() {
    this.loaderService.showLoader(true);
    let domain = getSubDomain();
    let result: string = await this.cdnContentService.getBlobData(`${domain}/${BlobName.configJson}`, environment.config.azure.configContainerName);
    this.loaderService.showLoader(false);
    return JSON.parse(result).landingPage;
  }
  async openPopUp() {
    const modal = await this.modalController.create({
      component: PolicyComponent,
      cssClass: 'policy-content-popup',
      componentProps: {
        isPopup: true,
      },
      backdropDismiss: false
    });
    modal.onDidDismiss().then((data: any) => {
      if (data && data.data && this.userInfo) {
        this.userInfo.privacyPolicyAccepted = data.data;
        this.storageService.set(LocalStorageKeys.userInfo, JSON.stringify(this.userInfo));
      }
      // this.loginService.headerSub.next(true);
      this.router.navigateByUrl(ROUTESPATH.LANDING);
    })
    modal.present();
  }
  /**
   * Handles actions when the window or element is resized.
   * @param event
   */

  @HostListener('window:resize', ['$event'])
  onResize(event: Event): void {
    this.isMobile = checkScreenSize();
    this.isMediumSize = this.checkLapMediumSize();
    this.showSideBar();
  }
  /**
   * Navigates to a specific page based on the provided search data.
   */
  onSearchEvent(event: SearchDetail) {
    try {
      this.router.navigate(['/property-list'], {
        queryParams: {
          searchQuery: btoa(JSON.stringify(event)),
        },
      });
    } catch (error) {
      this.loggingService.logException(error as Error, LogLevel.Error)
      throw error;
    }

  }
  ngOnDestroy(): void {
    // Close the modal when the component is destroyed (on route change)
    this.modalController.dismiss();
  }

  checkLapMediumSize() {
    return window.innerWidth > 992 && window.innerWidth <= 1280;
  }

  showSideBar() {
    this.sidePadding = this.commonService.applyLeftPadding()?.sidePadding;
    this.sideNavApplied = this.commonService.applyLeftPadding()?.sideNavApplied;
    // Check if any role from userole is in the role array
  }
}
