import { Injectable } from '@angular/core';
import json from '../../../../assets/json/language.json';


@Injectable({
  providedIn: 'root'
})
export class LanguageService {

  constructor() { }
  /***
   * Method to get the request housing data from the JSON file
   *  @returns 
   */
  getLogin() {
    return json.corporateLoginPage;
  }

  /***
  * Method to get the request housing data from the JSON file
  *  @returns 
  */
  getRequestHousing() {
    const requestHouse = json.requestHousing;
    return requestHouse;
  }

  /**
   *Method to get the header keys from the JSON file
   * @returns 
   */
  getHeaderKey() {
    const resultData = json.header;
    return resultData;
  }


  /**
   *Method to get the header keys from the JSON file
   * @returns 
   */
  getPolicyKey() {
    const resultData = json.policyPage;
    return resultData;
  }

  /**
   *Method to get the header keys from the JSON file
   * @returns 
   */
  getLandingKey() {
    const resultData = json.landingPage;
    return resultData;
  }

  /**
   * Method to get property list data from the JSON file
   * @returns 
   */
  getPropertyListKey() {
    const result = json.propertyList;
    return result;
  }

  /**
   *Method to get the Booking keys from the JSON file
   * @returns 
   */
  getBookingKey() {
    const resultData = json.booking;
    return resultData;
  }
  /**
   *Method to get the Property keys from the JSON file
   * @returns 
   */
  getPropertyKey() {

    const resultData = json.PropertyDetail;
    return resultData;
  }
  /**
   *Method to get the Reservation keys from the JSON file
   * @returns 
   */
  getReservationKey(): Record<string, any> {
    const resultData = json.myReservation;
    return resultData;
  }
  /**
   *Method to get the Request Service keys from the JSON file
   * @returns 
   */
  getReservationServiceKey() {
    const resultData = json.requestService;
    return resultData;
  }
  /**
   *Method to get the Cancel Reservation keys from the JSON file
   * @returns 
   */
  getCancelReservationKey() {
    const resultData = json.cancelReservation;
    return resultData;
  }

  /**
  *Method to get the Modify Reservation keys from the JSON file
  * @returns 
  */
  getModifyReservationKey() {
    const resultData = json.modifyReservation;
    return resultData;
  }

  /**
   *Method to get the profile keys from the JSON file
   * @returns 
   */
  getProfileKey() {
    const resultData = json.ProfileDetails;
    return resultData;

  }
  /**
  *Method to get the Housing Request keys from the JSON file
  * @returns 
  */
  getHousingRequest() {
    const resultData = json.myHousingRequests;
    return resultData;
  }
  /**
  *Method to get the Accommodation keys from the JSON file
  * @returns 
  */
  getAccommodationKey() {
    const resultData = json.accommodationOptions;
    return resultData;
  }
  getCommonKey() {
    const resultData = json.commonLabel;
    return resultData;
  }

  /**
 *Method to get the days and Date keys from the JSON file
 * @returns 
 */
  getDateKey() {
    const resultData = json.daysAndDate;
    return resultData;
  }

  /**
*Method to get the days and Date keys from the JSON file
* @returns 
*/
  getUserKey() {
    const resultData = json.userManagement;
    return resultData;
  }
  /**
*Method to get the days and Date keys from the JSON file
* @returns 
*/
  getShareProperty() {
    const resultData = json.shareProperty;
    return resultData;
  }
}
