import { Component, Input, OnInit } from '@angular/core';
import { LegendItemModel } from '../../models/legend.model';
import { LegendModelType } from '../../enum/legend';

@Component({
  selector: 'lib-legend',
  templateUrl: './legend.component.html',
  styleUrl: './legend.component.scss',
})
export class LegendComponent {

  @Input() legendItems: LegendItemModel[] = [];

  // Method to check if item is of type TEXT
  isTextType(item: LegendItemModel): boolean {
    return item.type === LegendModelType.TEXT;
  }

  // Method to check if item is of type ICON
  isIconType(item: LegendItemModel): boolean {
    return item.type === LegendModelType.ICON;
  }
}
