import { Component, ElementRef, HostListener, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TopNavInput, TopNavOption } from '../../models';

@Component({
  selector: 'lib-top-nav',
  templateUrl: './top-nav.component.html',
  styleUrls: ['./top-nav.component.scss'],
})
export class TopNavComponent implements OnInit {
  @Input() data: TopNavInput = {
    img: '',
    label: 'label',
    dropdownOptions: [{
      img: '',
      label: 'label',
      url: ''
    }]
  };
  showDropdown = false;
  constructor(private elementRef: ElementRef, private router: Router) { }

  ngOnInit() { }

  /**
 * Closes the dropdown if a click occurs outside the component.
 * @param target - The target element that was clicked.
 */
  @HostListener('document:click', ['$event.target'])
  onDocumentClick(target: HTMLElement) {
    const clickedInside = this.elementRef.nativeElement.contains(target);
    if (!clickedInside) {
      this.showDropdown = false;
    }
  }

  /**
   * Toggles the visibility of the submenu items when the menu is clicked.
   * @param flag - A boolean indicating whether to show or hide the submenu.
   */
  onClick(flag: boolean) {
    this.showDropdown = !flag;
  }

  /**
   * Navigates the user to the specified page.
   * @param data - An object containing the navigation details, including the URL.
   */
  navigateToPage(data: TopNavInput) {
    this.showDropdown = false;
    if (data?.url) this.router.navigateByUrl(data.url);
  }

  navigateToOption(data: TopNavOption) {
    if (data?.url) this.router.navigateByUrl(data.url);
  }
}