<h1 *ngIf="header" class="semiBold-xl-text-36px display-flex-justify-center font-color m-0">{{header}}</h1>
<ion-grid class="ion-no-padding">
  <!--Parallax list section-->
  <ion-row>
    <ion-col class="key-info-col" [size]="isMobile ? 12 : (12/firstRow.length)" *ngFor="let value of firstRow">
      <div class="parallax-column">
        <ion-icon *ngIf="value.imageUrl" [src]="value.imageUrl" class="large"></ion-icon>
        <div class="description">
          <ion-text class="bold-xl-text-20px font-color">{{value.headingText}}</ion-text>
          <ion-text class="regular-lg-text font-color">{{value.descriptionText}}</ion-text>
        </div>
      </div>
    </ion-col>
  </ion-row>
  <ion-row>
    <ion-col *ngFor="let value of secondRow">
      <div class="parallax-column">
        <lib-icon *ngIf="value.imageUrl" [name]="value.imageUrl" [className]="'large'"></lib-icon>
        <ion-text class="semiBold-xl-text-20px font-color">{{value.headingText}}</ion-text>
        <ion-text class="regular-lg-text font-color">{{value.descriptionText}}</ion-text>
      </div>
    </ion-col>
  </ion-row>

</ion-grid>