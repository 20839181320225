import { Component, OnInit } from '@angular/core';
import { AppConstants } from 'apps/booking-portal/src/app/core/app-constant';
import * as userConfig from "../../config/user-management.config.json";
import { DropdownOptions, PaginationModel, TabInput, TableStyleConfig, TSort, TTableColumn } from 'shared-ui/src/lib/models';
import { LanguageService } from '../../../shared/services/language.service';
import { PopoverMenuComponent } from 'shared-ui/src/lib/components/popover-menu/popover-menu.component';
import { ModalController, PopoverController } from '@ionic/angular';
import { TravelerListFilter, TravelerListModel, TravelerListResponse } from 'apps/booking-portal/src/app/core/api-response-model/traveller-list.model';
import { AppLoaderService, SharedApiService, SharedData } from '@synergy-application/shared-ui';
import { UserComponent } from 'apps/booking-portal/src/app/user/user.component';
import { UserService } from '../../service/user.service';
import { ClientApiService } from 'apps/booking-portal/src/app/core/client-api.service';
import { CommonService } from '../../../shared/services/common.service';

@Component({
  selector: 'app-user-management',
  templateUrl: './user-management.component.html',
  styleUrl: './user-management.component.scss',
})
export class UserManagementComponent implements OnInit {
  constants = AppConstants;
  languageLabel: any;

  tableConfig = (userConfig as any).default.tableConfig as TTableColumn[];

  userList: Record<string, unknown>[] = [];

  pagination!: PaginationModel;

  itemPerPage: DropdownOptions = {
    id: 10,
    name: "5"
  };

  key = "id";

  tableStyles: TableStyleConfig = {
    backgroundColor: '#fff',
    borderColor: '#ddd',
    textColor: 'black',
    borderBottom: '1px solid #ddd'
  };
  allSelected = false;
  storedSortData!: TSort;
  indeterminate = false;
  sharedData = SharedData;

  selectedRows: { [key: string]: boolean } = {};
  searchData = '';
  selectedCount = 0;
  tabLabel: TabInput[] = SharedData.roleBasedTabLabel;
  isShowTab = false;
  sidePadding: any;


  constructor(private commonServiceBookingPortal: CommonService, private appLoaderService: AppLoaderService, private clientApiService: ClientApiService, private userService: UserService, private modalController: ModalController, private languageService: LanguageService, private popoverController: PopoverController) {

  }
  ngOnInit() {
    this.languageLabel = this.languageService.getUserKey();
    this.getUser();
    this.showSideBar();
  }
  onsearchUser(event: string) {
    this.searchData = event;
    this.getUser(1);
  }
  sortData(event: TSort) {
    if (!event.data.sortable) return;
    this.getUser(1, event);
  }
  toggleSelectAll(event: boolean): void {
    this.allSelected = event;
    this.indeterminate = false;
    this.userList.forEach((row: unknown) => {
      const value = row as TravelerListModel;
      const id = value.id;
      if (id) this.selectedRows[id] = this.allSelected;
    });
  }
  itemPerPageEvent(event: DropdownOptions) {
    this.itemPerPage = event;
    this.getUser(Number(event.id));
  }
  onRowSelectionChange(event: number) {
    const selectedCount = Object.values(this.selectedRows).filter(
      Boolean
    ).length;
    this.allSelected = selectedCount === this.userList.length;
    this.indeterminate = selectedCount > 0 && selectedCount < this.userList.length;
    this.selectedRows[event] = true;
    this.selectedCount = selectedCount;
  }
  getUser(page = 1, sort?: TSort) {
    if (sort) this.storedSortData = sort;
    const travelerListFilter: TravelerListFilter = {
      pagination: { page: 1, pageSize: Number(this.itemPerPage.id) }
    }
    if (sort) travelerListFilter.order = { by: sort.data.name, order: sort.sortDirection }
    if (this.searchData) travelerListFilter.search = { where: { name: { like: this.searchData } } }
    // this.userList = (data as any).default.slice(page, this.itemPerPage);
    this.appLoaderService.showLoader(true);
    this.clientApiService.getTravelerList(travelerListFilter)
      .then((res: TravelerListResponse) => {
        this.appLoaderService.showLoader(false);
        this.userList = (res.travelerLists as any);
        this.pagination = res.pagination;
      })
      .catch((error: Error) => {

      })
  }
  async onMoreAction(eventData: { event: Event; header: TTableColumn; data: Record<string, unknown> }) {
    const { event, header, data } = eventData;

    const popover = await this.popoverController.create({
      component: PopoverMenuComponent,
      cssClass: 'view-edit-popover',
      event,
      componentProps: {
        data: [{
          "id": 1,
          "name": "View",
          "value": "view",
          "icon": "/assets/icon/view-icon.svg"
        }
        // , {
        //   "id": 2,
        //   "name": "Edit",
        //   "value": "edit",
        //   "icon": "/assets/icon/edit-pen-icon.svg"
        // }, {
        //   "id": 3,
        //   "name": "Delete",
        //   "value": "delete",
        //   "icon": "/assets/icon/remove-icon.svg"
        // }
      ]
      },
      backdropDismiss: true,
      showBackdrop: false,
    });

    await popover.present();

    const { data: selectedOption } = await popover.onDidDismiss();
    if (selectedOption) {
      this.openPopup(data, selectedOption.value);
    }
  }
  showSideBar() {
    this.sidePadding = this.commonServiceBookingPortal.applyLeftPadding()?.sidePadding;
    // Check if any role from userole is in the role array
  }
  /*
  *
  * When click advanced filter button this method will get triggered.
  */
  async openPopup(data: Record<string, unknown>, action: string) {
    const modal = await this.modalController.create({
      component: UserComponent,
      cssClass: 'view-edit-content-popup',
      componentProps: {
        profileData: await this.userService.mapToResponse(data),
        mode: action,
        name: data['name']
      },
    });
    modal.onDidDismiss().then(data => {

    });

    modal.present();
  }

  onTabChange(tab: TabInput): void {
  }

}
