<div class="legend">
    <!-- Loop through each item in the legendItems array and create a legend entry -->
    <div *ngFor="let item of legendItems let isLast = last" class="legend-item">
        <ion-row>
            <ion-col *ngIf="isIconType(item)" class="legend-col" size="auto">
                <ion-icon [src]="item.name" [ngStyle]="{
                    'stroke': item.iconColor ? item.iconColor : 'var(--app-text-color)'
                  }" class="legend-icon"></ion-icon>
                <span class="semiBold-md-text">-</span>
            </ion-col>
            <!-- Display the text if the item is of text type -->
            <ion-col *ngIf="isTextType(item)" size="auto">
                <span class="semiBold-md-text">{{ item.name }} -</span>
            </ion-col>
            <!-- Display the value of the legend item -->
            <ion-col size="auto">
                <span class="regular-md-text">{{ item.value }}</span>
            </ion-col>
            <ion-col *ngIf="!isLast">
                <span class="circle_container"></span>
            </ion-col>
        </ion-row>
    </div>
</div>