export enum ROUTES { 
  LOGIN = 'login',
  LANDING = 'landing',
  PROPERTYLIST = 'property-list',
  REQUESTFORM = 'request-form',
  PROPERTYDETAIL = 'property-detail',
  FAQ = 'faq',
  POLICY = 'policy',
  TERMSANDSERVICE = 'terms-and-service',
  PRIVACYPOLICY = 'privacy-policy',
  USERMANAGEMENT = 'user-management',
  PROFILE = 'profile',
  RESERVATION = 'reservation',
  MYREQUEST = 'my-request',
  RFHREQUEST="rfh-request",
  MYRESERVATION="my-reservation",
  CALLBACk = 'callback'

}

export enum ROUTESPATH { 
  LOGIN = `/${ROUTES.LOGIN}`,
  LANDING = `/${ROUTES.LANDING}`,
  PROPERTYLIST = `/${ROUTES.PROPERTYLIST}`,
  REQUESTFORM = `/${ROUTES.REQUESTFORM}`,
  PROPERTYDETAIL = `/${ROUTES.PROPERTYDETAIL}`,
  FAQ = `/${ROUTES.FAQ}`,
  POLICY = `/${ROUTES.POLICY}`,
  RFHREQUEST = `/${ROUTES.RFHREQUEST}`,
  PROFILE = `/${ROUTES.PROFILE}`,
  RESERVATION = `/${ROUTES.RESERVATION}`,
  MYREQUEST = `/${ROUTES.MYREQUEST}`,
  TERMSANDSERVICE =`/${ROUTES.TERMSANDSERVICE}`,
  PRIVACYPOLICY =`/${ROUTES.PRIVACYPOLICY}`,
  MYRESERVATION=`/${ROUTES.MYRESERVATION}`,
  USERMANAGEMENT=`/${ROUTES.USERMANAGEMENT}`
}