<ion-header class="my-reservation-header ion-no-padding">
    <ion-grid class="ion-no-padding">
        <ion-row>
            <ion-col>
                <ion-text class="semiBold-xl-text-24px header-title">{{ reservationConfigurations['header']?.toString() }}</ion-text>
            </ion-col>
        </ion-row>
        <ion-row class="tabs">
            <ion-col >
                <lib-tab class="plr-40 pad-top-24" [tabInput]="tabLists" [count]="3"></lib-tab>
            </ion-col>
        </ion-row>

        <ion-row class="filter">
            <ion-col class="flex-ai-c-jc-s" size="6" size-xs="12" size-md="6" size-lg="6">
                <lib-search></lib-search>
            </ion-col>
            <ion-col class="flex-ai-c-jc-e" size="6" size-xs="12" size-md="6" size-lg="6">
                <lib-dropdown>
                </lib-dropdown>
            </ion-col>
        </ion-row>
    </ion-grid>
</ion-header>

<ion-content class="my-reservation-content">
    <div class="my-reservation-container">
        <ion-card class="card-content flex-ai-s-jc-c">
            <div class="image-container">
                <img class="image" src="https://synergywebsourcing.blob.core.windows.net/synergy-source/propertyprofile/316abfc5-fd61-47e1-85e6-6f8c8cdaa468/168ce1cb-0c88-4806-bcfd-08129fbf40d3.jpg" alt="no-image">
            </div>
            <ion-grid class="grid-container ion-no-padding">
                <ion-row class="booking-details flex-ai-s-jc-s">
                    <ion-col class="flex-ai-s-jc-s" size="6" size-xs="12" size-md="6" size-lg="6">
                            <ion-label class="semiBold-xl-text-20px line-height-28">Booking Id</ion-label>
                            <ion-text class="semiBold-xl-text-20px line-height-28">: 2343432434</ion-text>
                    </ion-col>
                    <ion-col class="flex-ai-s-jc-e" size="6" size-xs="12" size-md="6" size-lg="6">
                        <ion-chip class="booking-status green flex-align-center">
                            <div class="rounded-circle"></div>
                            <ion-text class="medium-sm-text">Booking Confirmed</ion-text>
                        </ion-chip>
                    </ion-col>
                </ion-row>
                <ion-row class="guest-booking-details flex-align-center">
                    <ion-col size="8" size-xs="12" size-md="8" size-lg="8" class="flex-ai-s-jc-s semiBold-xl-text-20px guest-info">
                            <div class="semiBold-sm-text">
                                <ion-label>Guest name:</ion-label>
                                <ion-text> Patrik Mertz</ion-text>
                            </div>
                            <ion-text class="semiBold-xl-text-20px line-height-28">Ivery Mansion</ion-text>
                            <ion-text class="medium-md-text line-height-20 letter-spacing-1">221 Baker street, Marylebone, London, W1U 6RS, United kingdom</ion-text>
                    </ion-col>
                    <ion-col class="flex-ai-e-jc-e paid-amount ion-hide-sm-down" size="4" size-md="4" size-lg="4">
                        <ion-text class="line-height-24 regular-md-text">Paid</ion-text>
                        <ion-text class="semiBold-xl-text-24px line-height-36">$7,052</ion-text>
                    </ion-col>
                </ion-row>
                <ion-row class="check-in-details">
                    <ion-col class="flex-ai-s-jc-s flex-gap-8">
                        <div class="flex-align-center flex-gap-8">
                            <div class="small-rounded-circle bg-text-color"></div>
                            <ion-text class="medium-md-text line-height-24 letter-spacing-1">Move in after 4PM</ion-text>
                        </div>
                        <div class="flex-align-center flex-gap-8">
                            <div class="small-rounded-circle bg-text-color"></div>
                            <ion-text class="medium-md-text line-height-24 letter-spacing-1 ">Move out by 11AM</ion-text>
                        </div>
                    </ion-col>
                </ion-row>
                <ion-row class="additional-services">
                    <ion-col size="8" size-xs="12" size-md="8" size-lg="8" class="flex-ai-s-jc-s">
                        <app-days-and-date class="w-100" [dateDetails]="checkIn"></app-days-and-date>
                    </ion-col>
                    <ion-col size="4" size-xs="12" size-md="4" size-lg="4" class="flex-ai-e-jc-e flex-gap-16 flex-direction-column booking-action">
                        <div class="flex-ai-s-jc-e w-100 request-service">
                            <div class="ion-hide-sm-up flex-ai-s-jc-s flex-direction-column">
                                <ion-text class="line-height-24 regular-md-text">Paid</ion-text>
                                <ion-text class="semiBold-xl-text-24px line-height-36">$7,052</ion-text>
                            </div>
                            <lib-button class="request-service-button" is-primary is-medium>Request Service</lib-button>
                        </div>
                        <div class="flex-ai-e-jc-e flex-gap-16">
                            <ion-text (click)="openModifyReservationModal()" class="semiBold-md-text text-decoration-underline line-height-24 icon-color cursor-pointer">Modify Reservation</ion-text>
                            <ion-text class="semiBold-md-text text-decoration-underline line-height-24 cancel-color">Cancel Reservation</ion-text>
                        </div>
                    </ion-col>
                </ion-row>
            </ion-grid>
        </ion-card>
    </div>
    <!-- <ion-grid>
        <ion-row>
            <ion-col>

            </ion-col>
        </ion-row>
    </ion-grid> -->
</ion-content>
