import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MapModel, PropertyModel } from '../../models';
import { AppConstants } from '../../constants/shared-constant';
import { PopoverController } from '@ionic/angular';
import { PopoverComponent } from '../popover/popover.component';


@Component({
  selector: 'lib-property-list',
  templateUrl: './property-list.component.html',
  styleUrl: './property-list.component.scss',
})
export class PropertyListComponent implements OnInit {
  @Input() data?: PropertyModel;

  @Input() pointOfLocation?: MapModel;

  @Input() languageData: any

  @Output() navigateTo = new EventEmitter<string>();

  @Input() cardUI: boolean = false;

  showMore: boolean = false;

  instance: any;

  appConstant = AppConstants;

  constructor(private popoverController: PopoverController) {
  }
  ngOnInit() {
  }

  /**
   * Navigates the user to the property details page for the selected property.
   * @param {string} propertyId - The ID of the property to display.
   */
  navigateToPropertyDetail(propertyId: string) {
    this.navigateTo.emit(propertyId)
  }


  /**
   * Popover to list the remaining data
   * @param event 
   */
  async openPopover(event: Event) {
  const popoverData = this.data?.units?.map((unit) => {
    return {
    key : unit.type,
      value: `${this.languageData.from} <strong>$${unit.startingAtRate}</strong>${this.languageData.night}`
    }
  });
    const popover = await this.popoverController.create({
      component: PopoverComponent,
      cssClass: 'custom-popover',
      componentProps: {
        popoverData: popoverData        
      },
      mode: 'ios',
      event,
      backdropDismiss: true,
      showBackdrop: false,
    });

    await popover.present();
  }

  /**
   * Popover to show the remaining amenities
   */
  async openAmenitiesPopover() {
    const amenitiesPopoverData = this.data?.amenitiesArray.slice(2).map(amenity => ({
      icon: `${amenity.iconPath}`,
      text: `${amenity.name}`
    }))
    const popover = await this.popoverController.create({
      component: PopoverComponent,
      cssClass: 'custom-popover',
      componentProps: {
        popoverData: amenitiesPopoverData
      },
      backdropDismiss: true,
      showBackdrop: false,
    });
    await popover.present();
  }
}