import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
// Required for MSAL
import {
  IPublicClientApplication,
  PublicClientApplication,
  InteractionType,
  BrowserCacheLocation,
} from '@azure/msal-browser';
import {
  MsalGuard,
  MsalInterceptor,
  MsalBroadcastService,
  MsalInterceptorConfiguration,
  MsalModule,
  MsalService,
  MSAL_GUARD_CONFIG,
  MSAL_INSTANCE,
  MSAL_INTERCEPTOR_CONFIG,
  MsalGuardConfiguration,
  MsalRedirectComponent,
} from '@azure/msal-angular';
import { HTTP_INTERCEPTORS, provideHttpClient } from '@angular/common/http';
import { environment } from '../environments/environment';
// import { GraphQLModule } from './services/graphql.module';
import { DatePipe } from '@angular/common';
import { CorporateLoginComponent } from './modules/login/pages/corporate-login/corporate-login.component';
import { LandingComponent } from './modules/home/components/landing/landing.component';
import { LeftImageContainerComponent } from './modules/shared/components/left-image-container/left-image-container.component';
import { ImageCardComponent } from './modules/shared/components/image-card/image-card.component';
import { HeaderComponent } from './modules/shared/components/header/header.component';
import { TextCardComponent } from './modules/shared/components/text-card/text-card.component';
import { PropertyResultComponent } from './modules/property/pages/property-result/property-result.component';
import { RequestPageComponent } from './modules/request/pages/request-page/request-page.component';
import { RequestPropertyComponent } from './modules/shared/components/request-property/request-property.component';
import { ParallaxContentComponent } from './modules/shared/components/parallax-content/parallax-content.component';
import { FilterComponent } from './modules/shared/components/filter/filter.component';
import { PropertyInfoComponent } from './modules/property/components/property-info/property-info.component';
import { PropertyDetailPageComponent } from './modules/property/pages/property-detail-page/property-detail-page.component';
import {
  ConfigService,
  SharedLibModules,
} from '@synergy-application/shared-ui';

import { FrequentQuestionComponent } from './modules/faq/components/frequent-question/frequent-question.component';
import { MyHousingRequestComponent } from './modules/booking-history/pages/my-housing-request/my-housing-request.component';
import { DaysAndDateComponent } from './modules/shared/components/days-and-date/days-and-date.component';
import { PolicyComponent } from './modules/traveler-policy/components/policy/policy.component';
import { ProfilePageComponent } from './modules/profile/pages/profile-page/profile-page.component';
import { RfhFormComponent } from './modules/rfh-form/rfh-form/rfh-form.component';
import { TermsAndServiceComponent } from './modules/traveler-policy/components/terms-and-service/terms-and-service.component';
import { PrivacyPolicyComponent } from './modules/traveler-policy/components/privacy-policy/privacy-policy.component';
import { AuthModule } from '@auth0/auth0-angular';
import { RequestServiceModalComponent } from './modules/shared/components/request-service-modal/request-service-modal.component';
import { MyReservationComponent } from './modules/booking-history/pages/my-reservation/my-reservation/my-reservation.component';
import { ModifyReservationModalComponent } from './modules/booking-history/components/modify-reservation-modal/modify-reservation-modal.component';
import { UserManagementComponent } from './modules/user-management/pages/user-management/user-management.component';
import { UserComponent } from './user/user.component';
import { RfhInstructionComponent } from './modules/shared/components/rfh-instruction/rfh-instruction.component';
import { InfoComponent } from './modules/shared/components/info/info.component';
import { SharePropertyComponent } from './modules/shared/components/share-property/share-property.component';

const redirectURL = window.location.hostname === 'localhost' ? '/' : `${window.location.protocol}//${window.location.hostname}`;

const isIE =
  window.navigator.userAgent.indexOf('MSIE ') > -1 ||
  window.navigator.userAgent.indexOf('Trident/') > -1;

export function MSALInstanceFactory(): IPublicClientApplication {
  return new PublicClientApplication({
    auth: {
      // 'Application (client) ID' of app registration in the Microsoft Entra admin center - this value is a GUID
      clientId: environment.config.microsoftClientId,
      // Full directory URL, in the form of https://login.microsoftonline.com/<tenant>
      authority: environment.config.microsoftAuthority,
      // Must be the same redirectUri as what was provided in your app registration.
      redirectUri: redirectURL,
    },
    cache: {
      cacheLocation: BrowserCacheLocation.LocalStorage,
      storeAuthStateInCookie: isIE,
    },
  });
}

// MSAL Interceptor is required to request access tokens in order to access the protected resource (Graph)
export function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {
  const protectedResourceMap = new Map<string, Array<string>>();
  protectedResourceMap.set('https://graph.microsoft.com/v1.0/me', [
    'user.read',
  ]);

  return {
    interactionType: InteractionType.Popup,
    protectedResourceMap,
  };
}

// MSAL Guard is required to protect routes and require authentication before accessing protected routes
export function MSALGuardConfigFactory(): MsalGuardConfiguration {
  return {
    interactionType: InteractionType.Popup,
    authRequest: {
      scopes: ['openid', 'profile', 'email', 'user.read'],
      prompt: 'select_account',
    },
  };
}

export function initializeApp(configService: ConfigService) {
  return () => configService.setConfig(environment.config);
}

@NgModule({
  declarations: [
    AppComponent,
    CorporateLoginComponent,
    LandingComponent,
    LeftImageContainerComponent,
    ImageCardComponent,
    HeaderComponent,
    TextCardComponent,
    PropertyResultComponent,
    RequestPropertyComponent,
    RequestPageComponent,
    ParallaxContentComponent,
    PropertyResultComponent,
    FilterComponent,
    PropertyDetailPageComponent,
    PropertyInfoComponent,
    MyHousingRequestComponent,
    TermsAndServiceComponent,
    PrivacyPolicyComponent,
    DaysAndDateComponent,
    PolicyComponent,
    ProfilePageComponent,
    FrequentQuestionComponent,
    RfhFormComponent,
    RequestServiceModalComponent,
    MyReservationComponent,
    ModifyReservationModalComponent,
    UserManagementComponent,
    UserComponent,
    RfhInstructionComponent,
    InfoComponent,
    SharePropertyComponent
  ],
  imports: [
    BrowserModule,
    IonicModule.forRoot(),
    AppRoutingModule,
    MsalModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    SharedLibModules,
    AuthModule.forRoot({
      domain: environment.config.auth0.domain,  // Auth0 domain
      clientId: environment.config.auth0.clientId,  // Auth0 client ID
      authorizationParams: {
        connection: environment.config.auth0.connection,
        redirect_uri: environment.config.auth0.redirectUri,  // Callback URL after login
        scope: environment.config.auth0.scope,  // Define the scopes you need
        response_type: environment.config.auth0.response_type,  // use the 'code' response type for PKCE
        audience: environment.config.auth0.audience
      }
    }),
  ],
  providers: [
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    DatePipe,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true
    },
    {
      provide: MSAL_INSTANCE,
      useFactory: MSALInstanceFactory,
    },
    {
      provide: MSAL_GUARD_CONFIG,
      useFactory: MSALGuardConfigFactory,
    },
    {
      provide: MSAL_INTERCEPTOR_CONFIG,
      useFactory: MSALInterceptorConfigFactory,
    },
    // {
    //   provide: ErrorHandler,
    //   useClass: ErrorHandlerService
    // },
    MsalService,
    MsalGuard,
    MsalBroadcastService,
    provideHttpClient(),
    {
      provide: APP_INITIALIZER,
      useFactory: initializeApp,
      deps: [ConfigService],
      multi: true,
    },
    {
      provide: "ALERT_SERVICE_CONFIG",
      useValue: {config:environment.config.name}
    },
  ],
  bootstrap: [AppComponent, MsalRedirectComponent],
})
export class AppModule { }

