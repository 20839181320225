import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'dynamicColumn',
  pure: true,
})
export class DynamicColumnPipe implements PipeTransform {
  transform(value: boolean): number {
    return value ? 8 : 6.7;
  }
}
