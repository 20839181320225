export interface CardInput {
  imageUrl?: string;
  title?: string;
  headingText?: string;
  descriptionText: string;
  details?: string;
  buttonText?: string;
  url?: string
}

export enum IAction {
  Past = 2,
  Active = 1
}

export const StatusDescription: Record<IAction, string> = {
  [IAction.Past]: 'Past',
  [IAction.Active]: 'Active'
};
export interface ImageDataInput {
  imageUrl?: string;
  title?: string;
  headingText?: string;
  descriptionText: string;
  details?: string;
  buttonText?: string;
  url?: string;
}

export interface IAlert {
  title?: string;
  icon?: string;
  name?: string;
  content: string;
  cssClass?: string;
  sizeOfIcon?: number;
  buttons?: string;
  handler?: Function;
  alertStyle?: string;
  showCancelButton?: boolean
}

