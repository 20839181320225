import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { StorageService } from './storage.service';
import { MsalService } from '@azure/msal-angular';
import { LocalStorageKeys } from '../constants/local-storage.constant';
import { GraphQLService } from '../core/graphql';
import { LOGIN, SSO_LOGIN } from '../core/shared-query';

@Injectable({
  providedIn: 'root'
})
export class LoginService {
  public headerSub = new Subject<any>();
  // Holds the authentication token value
  tokenValue = '';

  // BehaviorSubject to track the token state
  private tokenSubject: BehaviorSubject<string>;

  // Observable for components to subscribe to token changes
  public tokenObserver$: Observable<string>;

  constructor(
    private storageService: StorageService, // Service for handling local storage
    private authService: MsalService, // MSAL service for Azure authentication
  ) {
    // Initialize the BehaviorSubject with an empty string
    this.tokenSubject = new BehaviorSubject<string>('');
    this.tokenObserver$ = this.tokenSubject.asObservable(); // Expose the token observer as an observable

    // Check local storage for an existing token and set it
    const token = this.storageService.get(LocalStorageKeys.token);
    if (token) {
      this.setToken(token);
    }
  }
  showHeader() {
    return this.headerSub.asObservable();
  }
  /**
   * Displays a logout message to the user.
   * Currently, this function is a placeholder for future implementation.
   */
  showLogoutMessage() {
    // future implementation
  }

  /**
   * Sets the authentication token in local storage.
   * @param token The authentication token to be stored. If not provided, it removes the token.
   * @returns The stored token.
   */
  setToken(token?: string) {
    this.tokenValue = token || ''; // Set the token value
    if (token) {
      // Store the token if provided
      this.storageService.set(LocalStorageKeys.token, token);
    } else {
      // Remove the token from local storage if not provided
      this.storageService.remove(LocalStorageKeys.token);
    }

    // Notify observers about the token change
    this.tokenSubject.next(token || '');
    return token;
  }

  /**
   * Retrieves the authentication token from local storage.
   * @returns The stored authentication token or an empty string if not found.
   */
  getToken() {
    const storedToken = this.storageService.get(LocalStorageKeys.token); // Fetch token from storage
    if (storedToken) {
      this.tokenValue = storedToken; // Update the token value
      this.tokenSubject.next(storedToken); // Notify observers
    }
    return this.tokenValue; // Return the current token value
  }

  /**
   * Handles Single Sign-On (SSO) login using Azure.
   * @param graphqLService An instance of the GraphQLService to make GraphQL requests.
   * @returns A promise indicating the success or failure of the SSO login attempt.
   */
  onSsoLogin(graphqLService: GraphQLService): Promise<any> {
    return new Promise((resolve, reject) => {
      // Initiate the login popup
      this.authService.loginPopup()
        .subscribe({
          next: async (result) => {
            resolve(await this.validateMicrosoftToken(graphqLService, result?.accessToken));
          },
          error: (error) => {
            reject(error); // Reject the promise on error
          }
        });
    });
  }
  callValidateToken(graphqLService: GraphQLService, accessToken: string,idToken?:string) {
    return new Promise((resolve, reject) => {
      let variable = {
        accessToken: accessToken,
        idToken : idToken
      };
      // Define the GraphQL mutation for token validation
      const GET_DATA = `${SSO_LOGIN}`;
      // Execute the mutation
      graphqLService.mutate(GET_DATA, variable).then((result: any) => {
        this.setToken(result.data.authenticateUser); // Set the token if successful
        resolve(result.data.authenticateUser)
      }).catch(error => {
        reject(error);
        console.error('Error fetching data:', error);
      });
    });
  }
  validateMicrosoftToken(graphqLService: GraphQLService, accessToken: string) {
    return new Promise((resolve, reject) => {
      let variable = {
        token: accessToken
      };
      // Define the GraphQL mutation for token validation
      const GET_DATA = `${LOGIN}`;
      // Execute the mutation
      graphqLService.mutate(GET_DATA, variable).then((result: any) => {
        this.setToken(result.data.validateMicrosoftToken); // Set the token if successful
        resolve(result.data.validateMicrosoftToken)
      }).catch(error => {
        reject(error);
        console.error('Error fetching data:', error);
      });
    });
  }
}
