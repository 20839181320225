<div *ngIf="options.length > 1" class="option-picker-container">
    <ion-text class="option-picker-label regular-xs-text">Choose Preference</ion-text>
    <div class="picker-option-list ">
        @for(option of options; track $index) {
            <div 
                [ngClass]="{
                    selected: option === selection,
                    disabled: selection !== option && optionsAlreadyTaken.includes(option)
                }"
                class="picker-option semiBold-sm-text flex-align-center cursor-pointer"
                (click)="handleOptionChange(option)"
            >{{option}}</div>
        }
    </div>
</div>
