import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import {
  CountriesModel,
  CountryOptionModel,
  DateInput,
  DropDownCommonResponseModel,
  InputData,
  LocationItem,
  OptionModel,
  TextAreaInput,
  ToggleInput,
} from '../../models';
import { AppConstants, DROP_DOWN_TYPES } from '../../constants/shared-constant';
import { CommonService } from '../../services/common.service';
import { LocationService } from '../../services/location.service';
import { cloneDeep } from '@apollo/client/utilities';

@Component({
  selector: 'lib-dynamic-form',
  templateUrl: './dynamic-form.component.html',
  styleUrl: './dynamic-form.component.scss',
})
export class DynamicFormComponent implements OnInit {
  @Input() value: any;
  @Output() focusoutEvent = new EventEmitter<InputData | ToggleInput | TextAreaInput>();
  @Output() dateChange = new EventEmitter<DateInput>();
  @Output() selectedLocation = new EventEmitter<LocationItem>();

  @Output() inputChange = new EventEmitter<InputData | ToggleInput | TextAreaInput>();
  appConstants = AppConstants;
  countryDropdown = {
    placeholder: 'Select the Country',
    value: '',
    label: '  ',
    showErrorMessage: 'Please select the country',
    isShowErrorMessage: false,
    type: 'dropdown',
    name: DROP_DOWN_TYPES.COUNTRIES,
    options: [],
    isSearch: true,
    pageLimit: 10,
    allowApiSearch: true,
    totalCount: 0,
    currentPage: 1
  };

  @Input() languageLabel: any;

  @Input() requestLabel:any;

  countryOptions: CountriesModel[] = [];


  selectedCountryOption!: CountryOptionModel

  ngOnInit(): void {

  }

  constructor(
    private commonService: CommonService,
    private locationService: LocationService
  ) {

  }



  /**
   * 
   * @param key find the keys
   * @param array assign the array to the key values
   */
  findKey(key: string, array: any) {
    this.value?.fields?.forEach((ele: any) => {
      if (ele.name == key) {
        ele.option = array;
      }
    });
  }

  /**
   * Emits the event when a field loses focus, typically for validation.
   * @param event The event object triggered on focus out.
   */
  focusoutValidation(event: InputData) {
    this.focusoutEvent.emit(event);
  }


  phoneNumberValidation(form:InputData){
    this.commonService.phoneNumberValidation(form,this.commonService.selectedCountryOption,this.requestLabel);
  }
  /**
   * Emits the event when the toggle input changes.
   * @param event The ToggleInput event containing the new state.
   */
  onToggleChange(event: ToggleInput) {
    this.inputChange.emit(event);
  }
  /**
   *  Handles the selection of a location from a list or map.
   * @param $event 
   */
  async onSelectLocation(event: LocationItem, form: any) {
    if (event) {
      if (event.placeId) {
        const res = await this.locationService.getLatLongFromPlaceId(event.placeId);
        event.latitude = res.lat;
        event.longitude = res.lng;
      }
      form.location = event;
      this.selectedLocation.emit(event);
    }
  }
  /**
   * Emits the event when the input value changes.
   * @param event The InputData event containing the new input value.
   */
  onInputChange(event: InputData | TextAreaInput | ToggleInput) {
    this.inputChange.emit(event);
  }


  /**
   * Emits the event when the date input changes.
   * Processes the date range if applicable.
   * @param event The DateInput event containing the new date value.
   * @param form The DateInput form configuration, including mode (e.g., 'range').
   */
  onDateChange(event: DateInput, form: DateInput) {
    this.dateChange.emit(event);
  }

  getCountryCode(event: DropDownCommonResponseModel, form: InputData) {
    form.isShowErrorMessage = false;
    form.placeholder = `${event.placeHolder}`;
    this.commonService.selectedCountryOption = event;
    this.commonService.phoneNumberValidation(form,event,this.requestLabel);
  }
  /**
   * emit the event when selecting the option 
   */
  onRadioValueChange($event: OptionModel, field: any) {
    field.selectedOption = $event;
  }
  /**
   * 
   * @param event - scroll or click 
   * @param field - dropdown fields
   */
  getDropDownValue(event: any, field: any) {
    this.commonService.getDropDownValue(event, field);
  }

}
