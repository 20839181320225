import { Component, OnInit, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LanguageService } from '../../services/language.service';

@Component({
  selector: 'app-rfh-instruction',
  templateUrl: './rfh-instruction.component.html',
  styleUrl: './rfh-instruction.component.scss',
})
export class RfhInstructionComponent implements OnInit {
  @Input() instruction: any;
  languageLabel: any;
  constructor(private languageService: LanguageService) { }

  ngOnInit() {
    this.languageLabel = this.languageService.getRequestHousing();

  }

}

