import { Component, Input, OnInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';


@Component({
  selector: 'lib-image-tour-viewer',
  templateUrl: './image-tour-viewer.component.html',
  styleUrl: './image-tour-viewer.component.scss',
})
export class ImageTourViewerComponent implements OnInit {
  @Input() data!: string;

  safeURL!: SafeResourceUrl;

  constructor(
    private domSanitizer: DomSanitizer
  ) { }

  ngOnInit() {
    this.safeURL = this.getSafeUrl();
  }
  /**
 * Returns a safe URL by sanitizing the input data.
 * 
 * This method utilizes Angular's DomSanitizer to bypass 
 * security and mark the URL as safe for resource use. 
 * Make sure the input data is trusted to avoid security issues.
 * 
 * @returns {string} A sanitized resource URL.
 */
  getSafeUrl() {
    return this.domSanitizer.bypassSecurityTrustResourceUrl(this.data) as string;
  }


}
