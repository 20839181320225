import { AfterContentChecked, AfterContentInit, AfterViewInit, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { IDistanceMatrixConfig, MapModel } from '../../models';
import { LocationService } from '../../services/location.service';
import { ViewWillEnter } from '@ionic/angular';


@Component({
  selector: 'lib-google-info-window',
  templateUrl: './google-info-window.component.html',
  styleUrl: './google-info-window.component.scss',
})
export class GoogleInfoWindowComponent implements OnInit, OnChanges {

  @Input() data!: MapModel;

  @Input() pointOfLocation!: MapModel;

  placeDetails!: google.maps.places.PlaceResult;

  @Output() navigateTo = new EventEmitter<string>();

  attachments: string[] = [];

  distanceMatrix: IDistanceMatrixConfig[] = [
    {
      icon: "walk-outline",
      apiKey: google.maps.TravelMode.WALKING,
      id: 0,
      distance: '',
      duration: ''
    },
    {
      icon: "car-outline",
      apiKey: google.maps.TravelMode.DRIVING,
      id: 1,
      distance: '',
      duration: ''
    },
    {
      icon: "bus-outline",
      apiKey: google.maps.TravelMode.TRANSIT,
      id: 2,
      distance: '',
      duration: ''
    },
  ];

  selectedIcon: number = 1

  constructor(
    private locationService: LocationService
  ) { }

  ngOnChanges(changes: SimpleChanges) {
    if (changes["data"]) {
      this.data = changes["data"].currentValue;
      if (this.data) {
        const placeId = this.data && this.data.id ? String(this.data.id) : undefined;
        this.fetchPlaceDetails(placeId);
        this.calculateDistanceMatrix();
      }
    }


  }

  ngOnInit() {
    if (this.data) {
      const placeId = this.data && this.data.id ? String(this.data.id) : undefined;
      this.fetchPlaceDetails(placeId);
    }

  }

  fetchPlaceDetails(placeId: string | undefined) {
    if (placeId) {
      this.locationService.fetchPlaceDetails(placeId)
        .then((res) => {
          this.placeDetails = res;
          this.attachments = this.placeDetails && this.placeDetails.photos ? this.placeDetails.photos.map(el => {
            return el.getUrl()
          }) : []
        })
        .catch(ex => {
          console.error(ex);
        })
    }
  }

  async calculateDistanceMatrix() {
    for (const [index, item] of this.distanceMatrix.entries()) {
      let element = { ...this.distanceMatrix[index] };
      let result = await this.locationService.calculateDistance({lat: this.data.latitude,lng: this.data.longitude},{lat: this.pointOfLocation.latitude,lng: this.pointOfLocation.longitude},false,element.apiKey);
      element.distance = result.distance.text;
      element.duration = result.duration.text;
      this.distanceMatrix[index] = element;
    }

  }

  iconClick(item: IDistanceMatrixConfig) {
    this.selectedIcon = item.id;
  }

}