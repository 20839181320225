<div class="position-relative card-main">
  <div class="image-container">
    <img [src]="data.imageUrl" alt="" class="imagecard-class" />
    <div class="overlay">
    </div>
  </div>

  <!--Image details-->
  <ion-grid class="text-grid p-0">
    <ion-grid class="p-0 grid-with-gap m-0">
      <!--Displays title-->
      <ion-row>
        <ion-col class="p-0">
          <ion-text class="semiBold-xl-text-36px white-color-text">{{data.title}}</ion-text>
        </ion-col>

      </ion-row>

      <!--Displays header-->
      <ion-row>
        <ion-col class="p-0">
          <ion-text class="semiBold-xl-text-32px white-color-text">{{data.headingText}}</ion-text>
        </ion-col>
      </ion-row>

      <!--Displays description-->
      <ion-row>
        <ion-col class="p-0">
          <ion-text class="semiBold-xl-text-20px white-color-text">{{data.descriptionText}}</ion-text>
        </ion-col>
      </ion-row>
      <!--View details button-->
      <ion-row *ngIf="data.buttonText">
        <ion-col class="p-0">
          <lib-button is-primary is-medium>{{data.buttonText}}<ion-icon name="arrow-forward-outline"></ion-icon>
          </lib-button>
        </ion-col>

      </ion-row>
    </ion-grid>

    <div>
      <lib-property-search [buttonUI]="buttonUI" [language]="language"
        (searchEmit)="onSearchEvent($event)"></lib-property-search>
    </div>
  </ion-grid>


</div>