import { IPagination } from "./shared-common.model";

export interface CurrencyModel {
  id: string
  currencyId: string;  // The ID of the currency, which could be a string or number
  currencySymbol: string;       // The symbol of the currency (e.g., $, €, ¥)
  currencyCode: string;         // The code of the currency (e.g., USD, EUR, JPY)
}

export interface CurrencyResponse { 
  currenciesList: CurrencyModel[],
  pagination: IPagination
}