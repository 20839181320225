export interface ToastModel {
  message: string;
  header?: string;
  duration?: number;
  position?: 'top' | 'middle' | 'bottom';
  icon?: string;
}
export enum ToastModelEnum {
  Success = 'success',
  Error = 'error',
  Warning = 'warning',
  Info = 'info',
}