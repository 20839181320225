import { Injectable } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { fieldCommonModel } from '../models';

@Injectable({
  providedIn: 'root'
})
export class AppFormsService {

  constructor(
    private fb: FormBuilder
  ) {
  }

  getFormGroup(sections: any) {
   
    const formBuilder = this.fb.group({});
    if (sections) { 
      Object.keys(sections).forEach((singleGroup) => {
        const sectionGroup = this.fb.group({});
        sections[singleGroup].fieldType?.forEach((el: fieldCommonModel) => {
          const control = this.fb.control(
            el.value ?? null,
            this.getValidators(el)
          )

          if (el.isDisabled) control.disable();
          
          sectionGroup.addControl(el.name, control);
        })
        formBuilder.addControl(singleGroup, sectionGroup);
      })
    }
    return formBuilder;
  }

  private getValidators(section: fieldCommonModel) {
    const formValidator = [];
    if (section.isRequired) formValidator.push(Validators.required);
    return formValidator;
  }
}
