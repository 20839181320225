<!-- Displays checkboxes for each field in checkboxData with labels. -->
<div class="checkbox-content" *ngIf="data" [class.in-active]="data.isDisabled ? data.isDisabled : isDisable">
    <ion-checkbox slot="start" (ngModelChange)="onModelChange($event)" [(ngModel)]="data.isChecked" name="name"
        [class]="data.size ?? 'medium'" 
        [disabled]="data.isDisabled ? data.isDisabled : isDisable"
        [ngClass]="!data.isFilled ? 'filled-checkbox' : 'non-filled-checkbox'">
    </ion-checkbox>
    <div [innerHTML]="label || data.label" *ngIf="data?.label" class="medium-sm-text label-color-text"></div>
</div>
<div class="description">
    <span></span>
    <div *ngIf="data?.description">{{description || data.description}}</div>

</div>