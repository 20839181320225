import { Component, OnInit } from '@angular/core';
import { CdnContentService } from '../../../../../../../../shared-ui/src/lib/services/cdn-content.service';
import { Router } from '@angular/router';
import { AppLoaderService, CommonService, getSubDomain, GraphQLService, LoggingService, LoginService, SharedApiService, StorageService } from '@synergy-application/shared-ui';
import { LanguageService } from '../../../shared/services/language.service';
import { CorporateLogin, InputData, LoginResponse, UserProfile } from 'shared-ui/src/lib/models';
import { AppConstants, BlobName } from 'shared-ui/src/lib/constants/shared-constant';
import { ROUTESPATH } from 'apps/booking-portal/src/app/core/routes-constant';
import { AuthService, GetTokenSilentlyOptions } from '@auth0/auth0-angular';
import { environment } from 'apps/booking-portal/src/environments/environment';
import { LocalStorageKeys } from '../../../shared/constants/local-storage.constant';
import { LogLevel } from 'shared-ui/src/lib/enum/logger';


@Component({
  selector: 'app-corporate-login',
  templateUrl: './corporate-login.component.html',
  styleUrl: './corporate-login.component.scss'
})
export class CorporateLoginComponent implements OnInit {
  isError = true;
  loginData?: CorporateLogin;
  languageLabel: any;
  inputData!: InputData;
  appConstants = AppConstants;
  constructor(
    private commonService: CommonService,
    private loginService: LoginService,
    private languageService: LanguageService,
    private router: Router,
    private cdnContentService: CdnContentService,
    private sharedApiService: SharedApiService,
    private graphQLService: GraphQLService,
    private storageService: StorageService,
    private loaderService: AppLoaderService,
    private auth: AuthService,
    private loggingService: LoggingService
  ) { }

  ngOnInit() {
    // this.loginService.headerSub.next(false);
    if (this.storageService.checkValueExists(LocalStorageKeys.token)) {
      // this.loginService.headerSub.next(true);
      this.router.navigateByUrl(ROUTESPATH.LANDING);
    }
    this.languageLabel = this.languageService.getLogin();
    this.getClientData();
  }

  /**
 * Fetch user login details from the server and store them in local storage.
 */
  async getClientData() {
    // Get the subdomain dynamically (e.g., 'example' from 'example.domain.com')
    const domain = getSubDomain();

    // Fetch the client data (config.json) from the Azure container.
    this.cdnContentService.getClientData(`${domain}/${BlobName.configJson}`, environment.config.azure.configContainerName)
      .then(async (resultData: CorporateLogin) => {
        // Store the fetched data in localStorage under 'tenantInfo' key
        await this.storageService.set(LocalStorageKeys.tenantInfo, JSON.stringify(resultData));

        // Store the result data for further usage
        this.loginData = resultData;

        // Set initial input field data, such as labels, error messages, and validation configurations
        this.inputData = {
          placeholder: this.languageLabel?.placeholder,          // Placeholder text for the input field
          errorMessage: this.languageLabel?.errorMessage,        // Error message to display
          isRegexErrorMessage: this.languageLabel?.isRegexErrorMessage, // Regex error message flag
          label: this.languageLabel?.label,                      // Label for the input field
          value: '',                                             // Initial value of the input field
          showErrorMessage: '',                                  // Message to show on error
          isShowErrorMessage: false,                             // Whether to show error message
          isRequired: true,                                      // Whether the field is required
          type: this.appConstants.fieldType.text,                // Field type (text, number, etc.)
          maxLength: this.appConstants.input.max,                // Max length for the input
          minLength: this.appConstants.input.min,                // Min length for the input
        };
      })
      .catch((error: Error) => {
        // Handle any error that occurs during the fetching process
        console.error('Error fetching client data:', error);
      });
  }

  /**
   * Perform Single Sign-On (SSO) login for third-party authentication.
   */
  onSsoLogin() {
    // Start the SSO login process by calling the login service
    this.loginService.onSsoLogin(this.graphQLService)
      .then(async (response: LoginResponse) => {
        // Show a loading indicator while processing the login
        this.loaderService.showLoader(true);

        // If the login is successful, fetch the user's profile
        if (response) {
          await this.getProfile();
        }
        // this.loaderService.showLoader(false);
      })
      .catch((error: Error) => {
        // Hide the loader and navigate to the login page if there's an error
        this.loaderService.showLoader(false);
        this.router.navigateByUrl(ROUTESPATH.LOGIN);
        this.loggingService.logException(error, LogLevel.Error)
      });
  }

  /**
   * Fetch the user's profile information after successful login.
   */
  getProfile() {
    this.loaderService.showLoader(true);
    // Fetch the user's profile using the shared API service
    this.sharedApiService.getMyProfile(this.graphQLService)
      .then(async (res: UserProfile) => {
        // Hide the loader after receiving the response
        this.loaderService.showLoader(false);

        // Store the profile information in localStorage
        await this.storageService.set(LocalStorageKeys.userInfo, JSON.stringify(res));
        this.loginService.headerSub.next(true);

        // Notify other parts of the app that the user is logged in
        // this.loginService.headerSub.next(true);

        // Navigate to the landing page after successful login
        this.router.navigateByUrl(ROUTESPATH.LANDING);
      });
  }

  /**
   * Validate input fields on blur to provide instant feedback to users.
   * @param field The field data that needs to be validated.
   */
  focusoutValidation(field: InputData) {
    // Call the common service to validate the field based on its rules
    this.commonService.validateInputFields(field);

    // Set error flag if the input field has an error to display
    this.isError = !!(this.inputData?.isShowErrorMessage && this.inputData?.showErrorMessage);
  }

  /**
   * Handle Okta login using a popup for authentication.
   */
  oktaLogin() {
    // Start the Okta login process using a popup
    this.auth.loginWithPopup()
      .subscribe({
        next: async (result) => {
          // Set up options to fetch the token silently
          const options: GetTokenSilentlyOptions = {
            detailedResponse: true, // Request detailed response for the token
          };

          // Show the loader while fetching the token
          this.loaderService.showLoader(true);

          // Get the access token silently without requiring user interaction
          await this.auth.getAccessTokenSilently(options).subscribe({
            next: async (res: any) => {
              // Validate the received token and fetch the user's profile
              await this.loginService.callValidateToken(this.graphQLService, res.access_token, res.id_token);
              await this.getProfile();
            },
            error: (err) => {
              this.loaderService.showLoader(false);
              // Handle errors while getting the access token
              console.error('Error getting Access Token:', err);
            }
          });
        },
        error: (error) => {
          // Show the loader even if login fails (e.g., to indicate something went wrong)
          this.loaderService.showLoader(false);
        }
      });
  }

  /**
   * Navigate to a specific detail page, e.g., Terms of Service or Privacy Policy.
   * @param text The link text to determine which page to open (Terms or Privacy Policy).
   */
  goToDetail(text: String) {
    // If the text matches 'terms', open the terms and conditions page
    if (text == this.appConstants.login.terms) {
      window.open(ROUTESPATH.TERMSANDSERVICE, '_blank');
    }
    // If the text matches 'policy', open the privacy policy page
    else if (text == this.appConstants.login.policy) {
      window.open(ROUTESPATH.PRIVACYPOLICY, '_blank');
    }
  }


}