<div class="modal-container">
    <div class="modal-header">
        <ion-label class="bold-xl-text-20px">{{languageLabel.sharePropertyLabel}}</ion-label>
        <ion-icon class="close-icon" name="close-outline" (click)="onCancel()"></ion-icon>
    </div>

    <div class="modal-body">
        <div class="share-options">
            <ion-label class="bold-md-text">{{languageLabel.shareInfoLabel}}</ion-label>
            <lib-button class="button-align regular-md-text" is-secondary is-medium>
                <img [src]="iconPath.mail" alt=""> <span class="text-align">{{languageLabel.emailLabel}}</span>
            </lib-button>
        </div>

        <div class="copy-link">
            <ion-label class="bold-md-text">{{languageLabel.orCopyLink}}</ion-label>
                <div class="copy-area">
                    <div class="label-align"><ion-label class="label-text-align regular-md-text">{{ url }}</ion-label></div>
                    <div class="copy-button-align">
                        <lib-button is-primary is-medium class="copy-button" (click)="onCopy()"> <ion-icon class="info-icon" [src]="iconPath.copy"
                                alt=""></ion-icon><span class="copy-text-align">{{languageLabel.buttonLabel}}</span></lib-button>
                    </div>
                </div>
            </div>
        </div>
    </div>