<!-- Image-logo-carousel -> used for show a multiple images and Number of items to scroll is specified with the numScroll option. -->
<p-carousel [value]="value" [numVisible]="numVisible" [numScroll]="numScroll" [responsiveOptions]="responsiveOptions()"
  [circular]="circular" [autoplayInterval]="autoplayInterval" [showIndicators]="showIndicators">
  <ng-template pTemplate="item" let-attachment>
    @if (render === appConstant.type.button) {
    <div class="button-class" tabindex="0">
      <lib-button [image]="image" class="highlight" is-secondary [isSelected]="attachment.isSelected" tabindex="0"
        (click)="buttonClick(attachment)" [icon]="attachment.url">
        {{ attachment.label ?? '' }}
      </lib-button>
    </div>
    } @else {
    <div class="award-item">
      <img [src]="apiKey ? attachment[apiKey] : attachment" [alt]="" (error)="onImageError($event)" />
    </div>
    }

  </ng-template>
</p-carousel>