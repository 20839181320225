export interface AlertConfig {
  title: string;
  description: string | undefined;
  subText?:string | undefined;
  type: string;
  buttons?: AlertButtonModel[] | undefined;
  textarea?: AlertTextAreaModel;
  position?: AlertPosition;

}

export enum OrientationEnum { 
  Top = 'Top',
  Center = 'Center',
  Bottom = 'Bottom'
}

type AlertPosition = OrientationEnum.Top | OrientationEnum.Center | OrientationEnum.Bottom

export interface AlertTextAreaModel {
  placeholder?: string;
  value?: string;
  label?: string
  type?: string;
  minLength?: number;
  maxLength?: number;
  isRequired?: boolean;
}

export interface AlertButtonModel {
  label: string;
  attributes: string[];
  handler?: (data?: { [key: string]: unknown }) => void;
}

export interface AlertModel extends AlertConfig {
  type: string;
}

export enum AlertType {
  success = 'success',
  error = 'error',
  warning = 'warning',
  confirmation = 'confirmation',
  delete = 'delete'
}

export enum AlertButtonEnum {
  Yes = 0,
  No = 1
}

export interface AlertSericeConfig {
  name: string;
}
 