import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DropdownModule } from 'primeng/dropdown';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { IonicModule } from '@ionic/angular';
import { ToggleComponent } from '../components/toggle/toggle.component';
import { InputComponent } from '../components/input/input.component';
import { TabComponent } from '../components/tab/tab.component';
import { CalendarModule } from 'primeng/calendar';
import { DatePickerComponent } from '../components/date-picker/date-picker.component';
import { CheckboxComponent } from '../components/checkbox/checkbox.component';
import { ButtonComponent } from '../components/button/button.component';
import { PropertySearchComponent } from '../components/property-search/property-search.component';
import { SearchComponent } from '../components/search/search.component';
import { CarouselModule } from 'primeng/carousel';
import { GalleriaModule } from 'primeng/galleria';
import { GoogleMapsModule } from '@angular/google-maps';
import { LocationDropdownComponent } from '../components/location-dropdown/location-dropdown.component';
import { TopNavComponent } from '../components/top-nav/top-nav.component';
import { MultiSelectModule } from 'primeng/multiselect';
import { RadioButtonComponent } from '../components/radio-button/radio-button.component';
import { TextAreaComponent } from '../components/text-area/text-area.component';
import { DropdownComponent } from '../components/dropdown/dropdown.component';
import { PropertyListComponent } from '../components/property-list/property-list.component';
import { GalleryComponent } from '../components/carousel-components/gallery/gallery.component';
import { CarouselComponent } from '../components/carousel-components/carousel/carousel.component';
import { IconComponent } from '../components/icon/icon.component';
import { DynamicFormComponent } from '../components/dynamic-form/dynamic-form.component';
import { ImageTourViewerComponent } from '../components/image-tour-viewer/image-tour-viewer.component';
import { StepperComponent } from '../components/stepper/stepper.component';
import { GoogleInfoWindowComponent } from '../components/google-info-window/google-info-window.component';
import { GoogleMapViewComponent } from '../components/google-map-view/google-map-view.component';
import { LoaderComponent } from '../components/loader/loader.component';
import { SideBarComponent } from '../components/side-bar/side-bar.component';
import { SidebarModule } from 'primeng/sidebar';
import { TableViewComponent } from '../components/table-view/table-view.component';
import { FilterComponent } from '../components/filter/filter.component';
import { AlertComponent } from '../components/alert/alert.component';
import { PopoverComponent } from '../components/popover/popover.component';
import { MultiSelectDropdownComponent } from '../components/multi-select-dropdown/multi-select-dropdown.component';
import { FilterSizePipe } from '../pipe/filter/filter-size.pipe';
import { AddMoreFilterPipe } from '../pipe/filter/add-more-filter.pipe';
import { DynamicColumnPipe } from '../pipe/filter/dynamic-column.pipe';
import { MoreFilterComponent } from '../components/more-filter/more-filter.component';
import { PopoverMenuComponent } from '../components/popover-menu/popover-menu.component';
import { AppPhoneFormatDirective } from '../directive/app-phone-format.directive';
import { FilterDetailsComponent } from '../components/filter-details/filter-details.component';
import { ContentCardComponent } from '../components/content-card/content-card.component';
import { OptionPickerComponent } from '../components/option-picker/option-picker.component';
import { TooltipDirective } from '../directive/tooltip.directive';
import { BrowseFileComponent } from '../components/browse-file/browse-file.component';
import { UploadFileProgressComponent } from '../components/upload-file-progress/upload-file-progress.component';
import { AttachedFileDetailComponent } from '../components/attached-file-detail/attached-file-detail.component';
import { HighlightTextPipe } from '../pipe/highlight-text.pipe';
import { ChipStyleDirective } from '../directive/chip-style.directive';
import { FormatDatePipe } from '../pipe/format-date.pipe';
import { ChipComponent } from '../components/chip/chip.component';
import { ChipStylerDirective } from '../directive/chip-styler.directive';
import { DetailViewComponent } from '../components/detail-view/detail-view.component';
import { LegendComponent } from '../components/legend/legend.component';
import { KeyValueMapperPipe } from '../pipe/common/key-value-mapper.pipe';
import { TypeChangePipe } from '../pipe/table/type-change.pipe';
import { CompanyFormComponent } from '../components/company-form/company-form.component';
import { SideNavComponent } from '../components/side-nav/side-nav.component';
import { FlattenJsonPipe } from '../pipe/common/flatten-json.pipe';
import { CustomFormComponent } from '../components/custom-form/custom-form.component';
import { PickValueFromObjectPipe } from '../pipe/pick-object.pipe';
import { IsArrayPipe } from '../pipe/common/is-array.pipe';


@NgModule({
  declarations: [
    DynamicFormComponent,
    ToggleComponent,
    InputComponent,
    TopNavComponent,
    TabComponent,
    DatePickerComponent,
    CheckboxComponent,
    ButtonComponent,
    GoogleMapViewComponent,
    PropertySearchComponent,
    SearchComponent,
    StepperComponent,
    LocationDropdownComponent,
    TabComponent,
    CarouselComponent,
    GalleryComponent,
    RadioButtonComponent,
    TextAreaComponent,
    DropdownComponent,
    PropertyListComponent,
    ImageTourViewerComponent,
    IconComponent,
    GoogleInfoWindowComponent,
    LoaderComponent,
    SideBarComponent,
    TableViewComponent,
    FilterComponent,
    AlertComponent,
    CompanyFormComponent,
    MultiSelectDropdownComponent,
    PopoverComponent,
    AppPhoneFormatDirective,
    FilterDetailsComponent,
    DetailViewComponent,
    ContentCardComponent,
    OptionPickerComponent,
    TooltipDirective,
    FilterSizePipe,
    AddMoreFilterPipe,
    DynamicColumnPipe,
    MoreFilterComponent,
    PopoverMenuComponent,
    BrowseFileComponent,
    UploadFileProgressComponent,
    AttachedFileDetailComponent,
    HighlightTextPipe,
    ChipStyleDirective,
    FormatDatePipe,
    ChipStylerDirective,
    ChipComponent,
    LegendComponent,
    KeyValueMapperPipe,
    TypeChangePipe,
    SideNavComponent,
    FlattenJsonPipe,
    CustomFormComponent,
    PickValueFromObjectPipe,
    IsArrayPipe
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    DropdownModule,
    IonicModule,
    CalendarModule,
    SidebarModule,
    CarouselModule,
    GalleriaModule,
    GoogleMapsModule,
    MultiSelectModule,
  ],
  exports: [
    DynamicFormComponent,
    ToggleComponent,
    RadioButtonComponent,
    InputComponent,
    TopNavComponent,
    LocationDropdownComponent,
    DatePickerComponent,
    CheckboxComponent,
    ButtonComponent,
    GoogleMapViewComponent,
    PropertySearchComponent,
    SearchComponent,
    StepperComponent,
    TabComponent,
    CarouselComponent,
    GalleryComponent,
    TextAreaComponent,
    DropdownComponent,
    PropertyListComponent,
    IconComponent,
    ImageTourViewerComponent,
    GoogleInfoWindowComponent,
    LoaderComponent,
    SideBarComponent,
    TableViewComponent,
    FilterComponent,
    AlertComponent,
    MultiSelectDropdownComponent,
    PopoverComponent,
    AppPhoneFormatDirective,
    FilterDetailsComponent,
    ContentCardComponent,
    OptionPickerComponent,
    TooltipDirective,
    FilterSizePipe,
    AddMoreFilterPipe,
    DynamicColumnPipe,
    MoreFilterComponent,
    PopoverMenuComponent,
    BrowseFileComponent,
    UploadFileProgressComponent,
    AttachedFileDetailComponent,
    HighlightTextPipe,
    ChipStyleDirective,
    FormatDatePipe,
    DetailViewComponent,
    LegendComponent,
    KeyValueMapperPipe,
    TypeChangePipe,
    CompanyFormComponent,
    SideNavComponent,
    FlattenJsonPipe,
    CustomFormComponent,
    PickValueFromObjectPipe,
    IsArrayPipe
  ],
  providers: [
    KeyValueMapperPipe, 
    FlattenJsonPipe,
    IsArrayPipe
  ]
})
export class SharedLibModules {}
