import { FilterType } from "../../enum/filter"
import { DateInput, InputData } from "../shared-common.model"

export type FilterFieldModel = {
    id: number,

    type: FilterType,

    placeHolder?: string,

    value: any,

    name: string,

    displayName?: string,

    grid?: number,

    child?: FilterFieldModel[],
    
    dateInput?: DateInput,

    disabled?: boolean,

    selectedValue: any,

    isMultiple?: boolean
}
