<!-- Image Carousel Component -> used as a common component for image gallery where users can view and navigate through images.-->
<p-galleria *ngIf="attachments?.length" [value]="attachments?.length ? attachments : [{ source: '/assets/svg/photo-unavailable.svg' }]"  [showItemNavigators]="showItemNavigators" [showThumbnails]="showThumbnails"
    [showIndicators]="showIndicators" [showIndicatorsOnItem]="showIndicatorsOnItem"
    [responsiveOptions]="responsiveOptions" [containerStyle]="containerStyle" [visible]="visible"
    [numVisible]="numVisible" [circular]="circular" [fullScreen]="fullScreen" [containerClass]="containerClass"
    (visibleChange)="visibleChange($event)" (activeIndexChange)="activeIndexChange($event)">

    @if(imageTour) {
    <ng-template pTemplate="item" let-item>
        <lib-image-tour-viewer [data]="attachments" class="imageTour"></lib-image-tour-viewer>
    </ng-template>
    } @else {
    <ng-template pTemplate="item" let-item>
        <img [src]="(apiKey ? item[apiKey] : item)"  [class.source-img]="!apiKey" (error)="onImageError($event)" (click)="onImageClick($event)" />
    </ng-template>
    }
    `
    <ng-template pTemplate="thumbnail" let-item>
        <div class="grid grid-nogutter justify-content-center">
            <img [src]="(apiKey ? item[apiKey] : item)" (error)="onImageError($event)" class="d-block" />
        </div>
    </ng-template>
`
    <ng-template pTemplate="closeicon">
        <div class="close-container">
            <ion-icon class="close-icon" name="close-outline"></ion-icon>
        </div>
    </ng-template>

    <ng-template pTemplate="previousthumbnailicon">
        <div [class.none]="visible && attachments.length === 1">
            <ion-icon class="nav-icon" name="chevron-back-circle"></ion-icon>
        </div>
    </ng-template>
    <ng-template pTemplate="nextthumbnailicon">
        <div [class.none]="visible && attachments.length === 1">
            <ion-icon class="nav-icon" name="chevron-forward-circle"></ion-icon>
        </div>
    </ng-template>


    @if(showCount) {
        <ng-template pTemplate="footer">
            <div class="footer">
                <ion-label class="medium-xl-text-24px white-color-text">
                    {{ current }} of {{ attachments?.length }}
                </ion-label>
            </div>
        </ng-template>
    }
</p-galleria>