import { AfterContentChecked, AfterContentInit, AfterViewInit, Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, Type, ViewChild, ViewContainerRef } from '@angular/core';
import { MarkerClusterer } from "@googlemaps/markerclusterer";
import { PropertyListComponent } from '../property-list/property-list.component';
import { sourceIcon } from "../../constants/source-icon"
import { IMapCollectionView, MapModel, PropertyModel } from '../../models';
import { GoogleInfoWindowComponent } from '../google-info-window/google-info-window.component';
import { AppConstants } from '../../constants/shared-constant';
import { cloneDeep } from '@apollo/client/utilities';
interface IMapCluster {
  [key: string]: google.maps.marker.AdvancedMarkerElement[];
}

type CommonComponent = PropertyListComponent | GoogleInfoWindowComponent;

@Component({
  selector: 'lib-google-map-view',
  templateUrl: './google-map-view.component.html',
  styleUrls: ['./google-map-view.component.scss'],
})
export class GoogleMapViewComponent implements OnInit, OnChanges {

  icon = {
    bell: "/assets/svg/bell.svg",
    star: "/assets/svg/star.svg",
    settleStay: "/assets/svg/settle-stay.svg",
    care: "/assets/svg/care.svg",
    earthSearch: "/assets/svg/earth-search.svg",
    fileEdit: "/assets/svg/file-edit.svg",
    earthLens: "/assets/svg/earth-with-lens.svg",
    smileys: "/assets/svg/smileys.svg",
    medal: "/assets/svg/medal-star.svg",
    filter: "/assets/svg/filter-icon.svg",
    wifi: "/assets/icon/wifi.svg",
    pet: "/assets/icon/Pet.svg",
    dishwasher: "/assets/icon/dishwasher.svg",
    elevator: "/assets/icon/elevator.svg",
    towel: "/assets/icon/towel.svg",
    dryer: "/assets/icon/hairdryer.svg",
    microwave: "/assets/icon/microwave-oven.svg",
    bus_station: "./assets/svg/tour-bus.svg",
    walk: "./assets/svg/walk.svg",
    car: "./assets/svg/car.svg",
    shopping_mall: "/assets/svg/shopping.svg",
    movie_theater: "./assets/svg/Entertainment.svg",
    natural_feature: "./assets/svg/beaches.svg",
    parking: "./assets/svg/Parking-car.svg",
    gym: "./assets/svg/Gym.svg",
    restaurant: "./assets/svg/restaurant.svg",
    grocery_or_supermarket: "./assets/svg/grocery.svg",
    school: "./assets/icon/school.svg",
    hospital: "./assets/icon/hospital.svg",
  }

  @ViewChild('map', { static: true }) mapElement!: ElementRef;

  @Output() navigateTo = new EventEmitter<string>();

  @Input({ required: false }) data!: MapModel;

  @Input({ alias: "places", required: false }) nearByData!: IMapCollectionView;

  @Input({ required: false }) searchPlace!: MapModel;

  @Input({ required: false }) propertyList?: PropertyModel[] = [];

  @Input() languageData: any;

  mapBounds!: google.maps.LatLngBounds;

  map!: google.maps.Map;

  infoWindow!: google.maps.InfoWindow;

  constructor(private viewContainerRef: ViewContainerRef) { }

  ngOnInit() {
    this.initMap();
  }

  /**
   * Description
   * @param changes:SimpleChanges
   * @returns
   */
  ngOnChanges(changes: SimpleChanges) {
    if (changes['nearByData']) this.initMap();
    if (changes['searchPlace']) this.initMap();
  }
  /**
  * Initializes the map with a specified zoom level and center.
  * 
  * This method loads the Google Maps libraries, sets up the map with initial
  * parameters, adds a marker if data is available, and clusters nearby markers.
  * It also adjusts the map bounds to fit all markers.
  */
  async initMap() {
    const { LatLngBounds } = await google.maps.importLibrary("core") as google.maps.CoreLibrary;
    const { Map, InfoWindow, MapTypeId } = await google.maps.importLibrary("maps") as google.maps.MapsLibrary;
    const { AdvancedMarkerElement } = await google.maps.importLibrary("marker") as google.maps.MarkerLibrary;

    // Create map bounds object
    this.mapBounds = new LatLngBounds();

    // Initialize the map
    this.map = new Map(
      this.mapElement.nativeElement,
      {
        zoom: 4,
        center: { lat: -28.024, lng: 140.887 },
        mapId: "8ecb7e92ed4a1c46",
        mapTypeId: MapTypeId.ROADMAP,
        fullscreenControl: false,
        streetViewControl: false,
        minZoom: AppConstants.map.minZoom, // Minimum zoom level
        maxZoom: AppConstants.map.maxZoom,  // Maximum zoom level
        mapTypeControlOptions: {
          position: google.maps.ControlPosition.RIGHT_BOTTOM
        },
        zoomControlOptions: {
          position: google.maps.ControlPosition.INLINE_END_BLOCK_CENTER
        },
      }
    );

    // Initialize the info window
    this.infoWindow = new InfoWindow({
      content: "",
      disableAutoPan: true,
    });

    // Add a main marker if data is provided
    if (this.data) {
      this.addMarker(AdvancedMarkerElement, this.data);
    }

    if (this.searchPlace) {
      this.addMarker(AdvancedMarkerElement, this.searchPlace);
    }

    // Add nearby markers if available
    if (this.nearByData) {
      const keys = Object.keys(this.nearByData);
      const pinsCollection = keys.reduce((accumulator: IMapCluster, currentElement: string) => {
        const pin = this.nearByData[currentElement].map((place: MapModel) => {
          return this.addMarker(AdvancedMarkerElement, place);
        });
        if (!accumulator[currentElement]) accumulator[currentElement] = [];
        accumulator[currentElement] = [...pin];
        return accumulator;
      }, {});

      // Add clusters for nearby markers
      for (const element of keys) {
        const markersCollection = pinsCollection[element as keyof typeof pinsCollection] as unknown as google.maps.marker.AdvancedMarkerElement[];
        // this.addCluster(markersCollection, element);
        const icon = document.createElement('ion-icon');
        icon.src = this.icon[element as keyof typeof this.icon] ?? ''; // Default icon if none provided
        icon.className = element === AppConstants.property ? 'property-icon' : 'large-icon'
        const labelElement = document.createElement('label');
        labelElement.className = element === AppConstants['property-cluster'] ? "center" : "cluster-badge-label";

        const content = document.createElement('div');
        content.className = element === AppConstants['property-cluster'] ? "cluster-badge" : 'amenity-badge';
        content.appendChild(icon);
        content.appendChild(labelElement);
        this.addCluster(markersCollection, element);
      }
    }
    // Apply fitBounds with the padding
    this.map.fitBounds(this.mapBounds);
  }

  /**
  * Opens a popup info window for the specified marker.
  * 
  * @param source - Data associated with the marker.
  * @param marker - The marker element to associate with the info window.
  */
  openPopUp(source: MapModel, marker: google.maps.marker.AdvancedMarkerElement) {
    if (this.infoWindow) {
      this.infoWindow.close();
    }

    const component: Type<CommonComponent> = source.type === AppConstants.property ? PropertyListComponent : GoogleInfoWindowComponent
    // Create a new component instance
    const componentRef = this.viewContainerRef.createComponent(component);
    // Navigate to the property detail page 
    componentRef.instance.navigateTo.subscribe((emittedData) => {
      this.navigateTo.emit(emittedData);
    });

    if (source.type !== AppConstants.property) {
      componentRef.instance.pointOfLocation = this.data;
      componentRef.setInput("data", cloneDeep(source));
      componentRef.instance.ngOnInit();
    }

    else {
      if (this.propertyList && this.propertyList?.length) {
        const propertyModel = this.propertyList.find(el => el.propertyId === source.id);
        if (propertyModel) {
          componentRef.instance.data = propertyModel;
          (componentRef.instance as PropertyListComponent).cardUI = true;
          (componentRef.instance as PropertyListComponent).languageData = this.languageData;
        }
      }
    }

    // Create a new InfoWindow instance and set its content
    this.infoWindow = new google.maps.InfoWindow({
      maxWidth: 270,
      headerDisabled: false,
      content: componentRef.location.nativeElement
    });

    this.infoWindow.open(this.map, marker);
  }

  /**
  * Adds a marker to the map based on provided data.
  * 
  * @param AdvancedMarkerElement - The class for creating advanced markers.
  * @param source - Data for the marker, including position and icon.
  * @returns The created marker element.
  */
  addMarker(AdvancedMarkerElement: typeof google.maps.marker.AdvancedMarkerElement, source: MapModel): google.maps.marker.AdvancedMarkerElement {
    const content = document.createElement('ion-icon');
    content.src = source.icon ?? sourceIcon.default; // Default icon if none provided
    content.className = source.type === AppConstants.property ? 'property-icon' :
      source.type === AppConstants.office ? 'large-office' : source.type === AppConstants.neighbor ? "neighbor" :
        'large-marker'
    const marker = new AdvancedMarkerElement({
      map: this.map,
      content,
      position: { lat: source.latitude ?? -28.024, lng: source.longitude ?? 140.887 },
    });

    // Extend map bounds with the marker's position
    if (marker.position) this.mapBounds.extend(marker.position);

    // Add click listener if marker is clickable
    if (source.clickable) {
      marker.addListener("click", () => {
        this.openPopUp(source, marker);
      });
    }

    return marker;
  }

  /**
  * Groups markers into a cluster and displays it on the map.
  * 
  * @param markers - An array of advanced marker elements to cluster.
  * @param type - The type of icon to use for the cluster.
  * @returns The created marker cluster instance.
  */
  addCluster(markers: google.maps.marker.AdvancedMarkerElement[], type: string): MarkerClusterer {
    const sourceIcon = this.icon[type as keyof typeof this.icon] ?? ''; // Default icon if none provided
    const cluster = new MarkerClusterer({
      map: this.map,
      markers,
      renderer: {
        render({ count, position }) {
          const iconElement = document.createElement('ion-icon');
          iconElement.src = sourceIcon; // Default icon if none provided
          iconElement.className = type === AppConstants.property ? 'property-icon' : 'large-icon'
          const labelElement = document.createElement('label');
          labelElement.className = type === AppConstants['property-cluster'] ? "center" : "cluster-badge-label";
          const content = document.createElement('div');
          content.className = type === AppConstants['property-cluster'] ? "cluster-badge" : 'amenity-badge';
          content.appendChild(iconElement);
          content.appendChild(labelElement);
          labelElement.textContent = String(count);
          const advanceMarkerElement = new google.maps.marker.AdvancedMarkerElement({
            position,
            content
          });
          return advanceMarkerElement;
        }
      }
    });

    return cluster;
  }

}