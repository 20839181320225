import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'lib-icon',
  templateUrl: './icon.component.html',
  styleUrl: './icon.component.scss',
})
export class IconComponent implements OnInit {
  @Input({ alias: 'name' }) identifier: string = "";

  @Input() source!: string;

  @Input({ required: false }) className: string | undefined;

  ngOnInit() {
    if (this.identifier) this.source = `assets/svg/booking-sprite-icons.svg#${this.identifier}`;
  }
}
