import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { CheckBoxInput, CompanyChildModel, CompanyFieldParentModel, childTemplate, newBillingParty, newCompanyTemplate } from '../../models';
import { AbstractControl, FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'lib-company-form',
  templateUrl: './company-form.component.html',
  styleUrl: './company-form.component.scss',
})
export class CompanyFormComponent implements OnInit {
  @Input() data!: Array<CompanyFieldParentModel>;

  formGroup!: FormGroup;

  private isManuallyChanged: boolean = false;

  constructor(
    private fb: FormBuilder
  ) { }

  ngOnInit() {
    this.initializeForm();
  }

  setUpCompanyListeners(index: number) {
    this.companyArray.controls[index].get('value')?.valueChanges.subscribe((el) => {
      if (el && Object.keys(el).length) {
        this.pushChildGroup(this.companyArray.controls[index] as FormGroup, index);
        if (this.data[index].child.length) this.data[index].child[0].header = el.name ?? ''
        this.setupIsBookerListeners(index);
      }
    })
  }

  pushChildGroup(formArray: FormGroup, i: number) {
    const childArray = formArray.get('child') as FormArray;
    const template: Array<CompanyChildModel> = new newBillingParty().child;
    this.data[i].child = [...template];
    template.forEach(child => {
      childArray.push(this.getChildren(child));
    });
  }

  initializeForm() {
    this.formGroup = this.fb.group({
      company: this.fb.array(this.data.map((company, i) => {
        const companyGroup = this.createCompanyGroup(company);
        this.setUpCompanyListeners(i)
        return companyGroup;
      }))
    })
  }

  setupIsBookerListeners(index: number) {
    this.companyArray.controls.forEach((companyGroup: AbstractControl, j: number) => {
      if (index === j) {
        const childArray = (companyGroup.get('child') as FormArray);
        childArray.controls.forEach((childGroup: AbstractControl) => {
          this.setupIsBookerListener(childGroup as FormGroup, j);
        });
      }
    });
  }

  setupIsBookerListener(childGroup: FormGroup, pIndex: number = 0) {
    const isBookerControl = childGroup.get('isBooker') as FormControl;
    isBookerControl.valueChanges.subscribe((isBookerValue: boolean) => {
      if (this.isManuallyChanged) return;
      this.toggleIsBookerControls(isBookerValue, isBookerControl, pIndex);
    });
  }

  toggleIsBookerControls(isBookerValue: boolean, triggeringControl: FormControl, pIndex: number) {
    if (this.isManuallyChanged) return;
    this.isManuallyChanged = true;
    this.companyArray.controls.forEach((companyGroup: AbstractControl, j: number) => {
      if (pIndex === j) {
        const childArray = (companyGroup.get('child') as FormArray);
        childArray.controls.forEach((childGroup: AbstractControl, i: number) => {
          const isBookerControl = childGroup.get('isBooker') as FormControl;
          if (isBookerControl === triggeringControl) return;
          if (isBookerValue) {
            isBookerControl.disable({ emitEvent: false });
          } else {
            if (isBookerControl.disabled && isBookerValue) {
              isBookerControl.disable({ emitEvent: false });
            } else { 
              isBookerControl.enable({ emitEvent: true });
            }
          }
        });
      }
    });
    this.isManuallyChanged = false;
  }

  checkForBookerControlStatus(childArray: FormArray) { 
    return childArray.getRawValue().some(controls => controls.isBooker);
  }

  addPerson(index: number) {
    const childArray: FormArray = this.getChildArray(index);
    // if (childArray.length > 2) return;
    const data = this.data[index].child ?? [];
    if (data.length) {
      const template = new childTemplate(childArray.length ?? 0) as CompanyChildModel;
      data.push(template);
      const newChildGroup = this.getChildren(template, this.checkForBookerControlStatus(childArray) ?? false);
      childArray.push(newChildGroup);
      this.setupIsBookerListener(newChildGroup, index);
    }
  }

  remove(i: number, j: number) {
    const childArray = this.getChildArray(i);
    childArray.removeAt(j);
    this.resetDisabledBookerControls(childArray);
  }

  resetDisabledBookerControls(childArray: FormArray) {
    childArray.controls.forEach((el) => {
      const bookerControl = el.get('isBooker');
      const haveIsBooker = this.checkForBookerControlStatus(childArray);
      if (haveIsBooker && bookerControl?.value) bookerControl?.enable({ emitEvent: true });
      else bookerControl?.enable({ emitEvent: true });
    })
  }

  get companyArray() {
    return (this.formGroup.get('company') as FormArray);
  }

  createCompanyGroup(company: CompanyFieldParentModel): FormGroup {
    return this.fb.group({
      value: [company.value],
      child: this.fb.array(company.child.map(billingCompany => this.getChildren(billingCompany)))
    });
  }

  getChildArray(index: number): FormArray {
    return (this.companyArray.at(index).get('child') as FormArray);
  }

  resetForm(childArray: FormArray, j: number) {
    childArray.controls[j].reset();
    this.isManuallyChanged = true;
    childArray.controls.forEach((el) => {
      const haveIsBooker = this.checkForBookerControlStatus(childArray);
      if (haveIsBooker && !el.get('isBooker')?.value) el.get('isBooker')?.disable({ emitEvent: true });
    })
    this.isManuallyChanged = false;
  }

  getChildren(billingCompany: CompanyChildModel, isBookerDisable: boolean = false): FormGroup {
    let formGroup;
    if (billingCompany.personCompany) formGroup = this.fb.group({
      value: [billingCompany.value],
      isBookerCompany: [billingCompany.isBookerCompany ?? false],
      isBooker: [billingCompany.isBookerCompany ?? false],
      isResident: [billingCompany.isBookerCompany ?? false],
      billingParty: [billingCompany.personCompany.value ?? {}]
    });
    else formGroup = this.fb.group({
      value: [billingCompany.value],
      isBookerCompany: [billingCompany.isBookerCompany ?? false],
      isBooker: [billingCompany.isBookerCompany ?? false],
      isResident: [billingCompany.isBookerCompany ?? false],
    });
    if (isBookerDisable) formGroup.controls['isBooker'].disable();
    return formGroup;
  }

  addNewCompany() {
    const newCompany = new newCompanyTemplate();
    this.data.push(newCompany);
    this.companyArray.push(this.createCompanyGroup(newCompany));
    this.setUpCompanyListeners(this.data.length - 1);
  }

  attachListeners() {
    this.companyArray.controls.map((companyGroup: AbstractControl, i: number) => {
      this.setUpCompanyListeners(i)
    });
  }

  removeCompany(index: number) {
    this.data = this.data.filter((el, j) => index !== j);
    this.companyArray.removeAt(index);
    this.attachListeners();
  }
}
