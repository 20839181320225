import { Inject, Injectable } from '@angular/core';
import { Subject, Observable } from "rxjs";
import { AlertConfig, AlertModel, AlertSericeConfig, AlertType, OrientationEnum } from '../models';
import { getErrorMessage } from '../core/helper';
import { AppConstants, ENVIRONMENT } from '../constants/shared-constant';

@Injectable({
  providedIn: 'root'
})
export class AlertService {
  /**
   * Subject to emit alert messages.
   * Components can subscribe to this to react to new alerts.
   */
  public subject = new Subject<AlertModel>();

  constructor(
    @Inject('ALERT_SERVICE_CONFIG') private config: AlertSericeConfig
  ) {
  }

  /**
   * Returns an observable for alert messages.
   * Components can use this to subscribe and handle incoming alerts.
   * @returns Observable<AlertModel> - Observable stream of alert messages.
   */
  getAlert(): Observable<AlertModel> {
    return this.subject.asObservable();
  }

  /**
   * Emits a success alert.
   * @param options - Alert configuration containing properties like title and message.
   */
  success(options: AlertConfig) {
    this.subject.next({
      ...options,
      type: AlertType.success,
    })
  }

  /**
  * Emits an error alert.
  * @param options - Alert configuration containing properties like title and message.
  */
  error(err: Error) {
    this.subject.next({
      title: "Error",
      description: this.config.name === ENVIRONMENT.DEV ? getErrorMessage(err) :  AppConstants.errorMessage,
      type: AlertType.error,
      position: OrientationEnum.Top
    })
  }

  /**
   * Emits a confirmation alert.
   * @param options - Alert configuration containing properties like title and message.
   */
  confirmation(options: AlertConfig) {
    this.subject.next({
      ...options,
      type: AlertType.confirmation
    })
  }

  /**
   * Emits a warning alert.
   * @param options - Alert configuration containing properties like title and message.
   */
  warning(options: AlertConfig) {
    this.subject.next({
      ...options,
      type: AlertType.warning
    })
  }

  /**
  * Emits a delete alert.
  * Typically used for confirmations before deleting items.
  * @param options - Alert configuration containing properties like title and message.
  */
  delete(options: AlertConfig) {
    this.subject.next({
      ...options,
      type: AlertType.delete
    })
  }
}
