<ion-content scroll-y="false" [fullscreen]="true" *ngIf="languageLabel" class="faq-card">

  <div class="scroll-css {{sidePadding}}">
    <ion-label class="semiBold-xl-text-24px">{{languageLabel.frequentlyAskedQuestion}}</ion-label>


    <ion-grid class="faq-align">
      <ion-row class="faq-content">

        <ion-col size="12" size-md="6" *ngFor="let section of faQArray">
          <div class="faq-container">
            <div class="faq-grid regular-lg-text" [ngClass]="{ 'expanded': section.isExpanded }"
              (click)="expand(section)">
              <ion-label class="semiBold-lg-text">{{ section.question }}</ion-label>
              <ion-icon [name]="section.isExpanded ? 'remove' : 'add'"></ion-icon>
            </div>
            <div class="answer-grid regular-md-text" *ngIf="section.isExpanded">
              <p class="regular-sm-text">{{ section.answer }}</p>
            </div>
          </div>
        </ion-col>
      </ion-row>
    </ion-grid>

  </div>

</ion-content>