<ion-grid class="dynamic-grid">
    <ion-row *ngIf="value?.labelName">
        <ion-col class="first-col">
            <ion-label class="semiBold-lg-text label">{{ value.labelName}}</ion-label>
        </ion-col>
    </ion-row>
    <ion-row class="row-margin">
        <ion-col [ngStyle]="{display : form.isHide ? 'none' : ''}" class="dynamic-col" size="12"
            [attr.size-sm]="form.size" [attr.size-md]="12" [attr.size-xl]="form.size" [attr.size-lg]="form.size"
            *ngFor="let form of value.fields">
            <ng-container [ngSwitch]="form.type">

                <ng-container *ngSwitchCase="appConstants.fieldType.text">
                    <lib-input class="input-textbox" [inputData]="form" (focusoutEvent)="onInputChange($event)">
                    </lib-input>
                </ng-container>

                <ng-container *ngSwitchCase="appConstants.fieldType.locationDropdown">
                    <div *ngIf="!form.isHide">
                        <lib-location-dropdown [styleCss]="'custom-css'" [label]="form.label" [language]="languageLabel"
                            [inputData]="form" [showBorder]="true"
                            (selectedItem)="onSelectLocation($event,form)"></lib-location-dropdown>
                    </div>
                </ng-container>

                <ng-container *ngSwitchCase=" appConstants.fieldType.email">
                    <lib-input class="input-textbox" [inputData]="form" (focusoutEvent)="onInputChange($event)">
                    </lib-input>
                </ng-container>

                <ng-container *ngSwitchCase=appConstants.fieldType.number>
                    <lib-input class="input-textbox" [inputData]="form" (focusoutEvent)="onInputChange($event)">
                    </lib-input>
                </ng-container>

                <ng-container *ngSwitchCase=appConstants.fieldType.textarea>
                    <lib-text-area [infoMsg] = form.infoMsg class="input-textbox" [inputData]="form" (focusoutEvent)="onInputChange($event)">
                    </lib-text-area>
                </ng-container>

                <ng-container *ngSwitchCase=appConstants.fieldType.toggle>
                    <lib-toggle class="input-textbox" [data]="form" (toggleChange)="onToggleChange($event)">
                    </lib-toggle>
                </ng-container>

                <ng-container *ngSwitchCase=appConstants.fieldType.date >
                    <div class="date-css request-form-date">
                        <lib-date-picker [inputData]="form" [numberOfMonths]=1 [showIcon]="true"
                        (dateObj)=onDateChange($event,form)></lib-date-picker>
                    </div>
                </ng-container>

                <ng-container *ngSwitchCase="appConstants.fieldType.radioOption">
                    <lib-radio-button [infoMsg]="form.infoMsg" [label]="form.label" [data]="form.options"
                        (selectedValueChange)=onRadioValueChange($event,form)
                        [selectedOption]="form.selectedOption"></lib-radio-button>
                </ng-container>

                <ng-container *ngSwitchCase=appConstants.fieldType.phoneNumber>
                    <div class="phone-number-css">
                        <lib-dropdown [displaySecondLabel] = "'mobileNumber'"  [data]="countryDropdown.options" (ngModelChange)="getCountryCode($event,form)"
                            [(ngModel)]="countryDropdown.value" [label]="countryDropdown.label" displayBy="value"
                            value="countryCode" [searchable]="countryDropdown.isSearch" ngDefaultControl
                            [loadOnDemand]="true" [totalCount]="countryDropdown.totalCount"
                            (ngClick)="getDropDownValue($event,countryDropdown)"
                            [allowApiSearch]="countryDropdown.allowApiSearch" [pageLimit]="10"
                            (ngSearch)="getDropDownValue($event,countryDropdown)"
                            (ngScroll)="getDropDownValue($event,countryDropdown)">
                        </lib-dropdown>

                        <lib-input (focusEvent)="phoneNumberValidation($event)" class="input-textbox"  [inputData]="form" 
                            (focusoutEvent)="phoneNumberValidation($event)">
                        </lib-input>
                    </div>

                </ng-container>

                <ng-container *ngSwitchCase=appConstants.fieldType.dropdown>
                    <lib-dropdown [label]="form.label" [data]="form.options" [(ngModel)]="form.value" displayBy="value"
                        value="key" [required]="form.isRequired" ngDefaultControl
                        (ngClick)="getDropDownValue($event, form)" [loadOnDemand]="true" [pageLimit]="10"
                        [searchable]="form.isSearchable" [allowApiSearch]="form.allowApiSearch"
                        (ngScroll)="getDropDownValue($event, form)" (ngSearch)="getDropDownValue($event, form)"
                        [totalCount]="form.totalCount"></lib-dropdown>
                </ng-container>
            </ng-container>
        </ion-col>
    </ion-row>
</ion-grid>