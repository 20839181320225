import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import {
  LeadStatusDescription,
  TableModel,
  TableStyleConfig,
  TCheckBoxChangeEvent,
  TSort,
  TTableColumn,
} from '../../models/table.model';
import { LeadStatus, SortDirection, statusLabel } from '../../enum/table';
import { DatePipe } from '@angular/common';
import { PopoverController } from '@ionic/angular';
import { DropdownOptions, DropDownType, OptionModel, Pagination } from '../../models';

@Component({
  selector: 'lib-table-view',
  templateUrl: './table-view.component.html',
  styleUrl: './table-view.component.scss',
})
export class TableViewComponent implements OnChanges {
  @Input() data: (Record<string, unknown> & TableModel | { [key: string]: any })[] = [];

  @Input() headers: TTableColumn[] = [];

  @Input() innerTableHeaders: TTableColumn[] = [];

  @Input() currentPage = 1; // Current page

  @Input() itemsPerPage: DropdownOptions = {
    id: 10,
    name: "10"
  }; // Number of items per page

  @Input() totalItems = 5; // Total number of items from the API

  @Input() goToPageInput = 1; // For input field to navigate pages

  @Input() selectedRows: { [key: string]: boolean } = {}; // Track selected rows

  @Input() allSelected = false; // Tracks the "Select All" checkbox

  @Input() indeterminate = false; // For showing the indeterminate state of the checkbox

  @Input() isPagination = true;

  @Input() styleConfig: TableStyleConfig = {};

  @Input() key: string = '';

  @Input() pagination: Pagination = {
    page: 1,
    pageSize: 10,
    totalCount: 0
  };

  @Output() apiCalled = new EventEmitter<number>();

  @Output() itemPerPage = new EventEmitter<DropdownOptions>();

  @Output() selectAll = new EventEmitter<boolean>();

  @Output() rowSelection = new EventEmitter<number>();

  @Output() sort = new EventEmitter<TSort>();

  @Output() checkBoxModelChange = new EventEmitter<TCheckBoxChangeEvent>();

  @Output() link = new EventEmitter<Record<string, unknown>>();

  @Output() rowExpand = new EventEmitter<number>();

  @Output() click = new EventEmitter<{ header: TTableColumn, data: Record<string, unknown> }>();

  @Output() moreAction = new EventEmitter<{ event: Event, header: TTableColumn, data: Record<string, unknown> }>();

  @Output() rowInput = new EventEmitter<Event>();

  @Output() dropdownModelChange = new EventEmitter();

  expandedRows: Set<number> = new Set<number>();

  // Options for items per page
  itemsPerPageOptions: DropdownOptions[] = [
    {
      id: 5,
      name: "5"
    },
    {
      id: 10,
      name: "10"
    },
    {
      id: 20,
      name: "20"
    },
    {
      id: 50,
      name: "50"
    }
  ];

  // Sorting state
  sortColumn = '';

  sortDirection: SortDirection = SortDirection.ASC;

  testFlag = false;

  customTextDropDownType = DropDownType.customText;

  defaultStatus = statusLabel;

  constructor(private datePipe: DatePipe, private popoverController: PopoverController) { }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['totalItems']) this.totalItems = changes['totalItems'].currentValue;

    if (changes['pagination']) this.pagination = changes['pagination'].currentValue;
  }

  toggleSelectAll(event: boolean) {
    this.selectAll.emit(event);
  }

  onRowSelectionChange(value: unknown) {
    const id = value as number;
    if (id) this.rowSelection.emit(id);
  }

  getRowId(row: Record<string, unknown>): string {
    return row[this.key] as string;
  }

  toggleRowExpansion(index: number) {
    if (this.expandedRows.has(index)) {
      this.expandedRows.delete(index);
    } else {
      this.rowExpand.emit(index);
      this.expandedRows.add(index);
    }
  }

  dropdownSelectedValue(event: OptionModel, key: unknown) {
    this.dropdownModelChange.emit({ event, key })
  }

  isRowExpanded(index: number): boolean {
    return this.expandedRows.has(index);
  }

  tableInputData(event: Event) {
    this.rowInput.emit(event)
  }
  get totalPages() {
    return Math.ceil(this.totalItems / Number(this.itemsPerPage.name));
  }

  fetchData() {
    this.resetData()
    this.apiCalled.emit(this.currentPage);
  }

  goToPage(page: number) {
    if (!page || page > this.totalPages) return;
    this.currentPage = page;
    this.goToPageInput = page;
    this.apiCalled.emit(this.currentPage);
  }

  nextPage() {
    if (this.currentPage < this.totalPages) {
      this.currentPage++;
      this.fetchData();
    }
  }

  previousPage() {
    if (this.currentPage > 1) {
      this.currentPage--;
      this.fetchData();
    }
  }

  goToFirstPage() {
    this.currentPage = 1;
    this.fetchData();
  }

  goToLastPage() {
    this.currentPage = this.totalPages;
    this.fetchData();
  }

  // Function to calculate the range of pages to display in the pagination
  getPageNumbers() {
    const pages = [];
    const startPage = Math.max(1, this.currentPage - 1);
    const endPage = Math.min(this.totalPages, this.currentPage + 1);

    for (let i = startPage; i <= endPage; i++) {
      pages.push(i);
    }

    return pages;
  }

  // Validate the Go to Page input
  isValidPageInput(): boolean {
    // return  this.goToPageInput <= this.totalPages;
    return true;
  }

  // When items per page selection is changed
  onItemsPerPageChange(event: any) {
    this.itemsPerPage = event;
    this.currentPage = 1; // Reset to first page when items per page changes
    this.goToPageInput = 1;
    this.itemPerPage.emit(this.itemsPerPage);
  }

  sortData(data: TTableColumn) {
    if (!data.sortable) return;

    if (this.sortColumn === data.name) {
      this.sortDirection =
        this.sortDirection === SortDirection.ASC
          ? SortDirection.DESC
          : SortDirection.ASC;
    } else {
      this.sortColumn = data.name;
      this.sortDirection = SortDirection.ASC;
    }

    this.sort.emit({ data, sortDirection: this.sortDirection });
  }

  checkBoxChange(data: unknown, isChecked: unknown) {
    this.testFlag = !this.testFlag
    const state = isChecked as boolean;
    this.checkBoxModelChange.emit({ data, state });
  }

  getValue(key: TTableColumn, value: unknown): any {
    if (typeof value === 'object' && value !== null && key.valueName && key.valueName in (value as Record<string, unknown>)) {
      return String((value as Record<string, unknown>)[key.valueName]);
    }

    return '';
  }

  linkClicked(event: Record<string, unknown>) {
    this.link.emit(event);
  }

  private resetData() {
    this.goToPageInput = 1;
    this.expandedRows.clear();
  }

  leadStatusDescription(key: unknown): any {
    const status = key as LeadStatus;
    if (status) return LeadStatusDescription[status];
    return '';
  }

  keyDown(): void { }

  rowClick(header: TTableColumn, data: Record<string, unknown>) {
    this.click.emit({ header, data });
  }

  moreActionClicked(event: Event, header: TTableColumn, data: Record<string, unknown>) {
    this.moreAction.emit({ event, header, data });
  }
}
