import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FileDetailsModel } from '../../models/shared-common.model';
import { AppConstants, ImagePath } from '../../constants/shared-constant';

@Component({
  selector: 'lib-attached-file-detail',
  templateUrl: './attached-file-detail.component.html',
  styleUrl: './attached-file-detail.component.scss',
})
export class AttachedFileDetailComponent {
  @Input() file: FileDetailsModel = {
    imageUrl: '',
    name: '',
    size: 0,
    uploadedBy: '',
  };

  @Input() showDelete: boolean = false;

  @Output() fileRemoved = new EventEmitter();

  appConstants = AppConstants;
  imagePath = ImagePath;

  removeFile() {
    this.fileRemoved.emit();
  }
}