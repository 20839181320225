<ion-grid class="company-form-grid pad-0">
  <form [formGroup]="formGroup">
    <div formArrayName="company">
      <ng-container *ngFor="let form of companyArray.controls; let i=index ">
        <ion-row class="form-row main-company" [formGroupName]="i">

          <ion-col class="company-col pad-0">

            <lib-dropdown [label]="data[i].label ? data[i].label : '' " [data]="data[i].options ? data[i].options : []"
              displayBy="name" value="id" ngDefaultControl formControlName="value"
              [ngModel]="companyArray.controls[i].get('value')?.value"></lib-dropdown>
            <lib-button is-secondary is-medium (click)="removeCompany(i)">Remove Company</lib-button>

          </ion-col>
          <ion-col class="pad-0">
            @if (data[i].child.length) {
            <div class="form-main" formArrayName="child">
              <ng-container *ngFor="let child of getChildArray(i).controls; let j=index">
                <ion-grid class="pad-0 grid-col" [formGroupName]="j">


                  @if (data[i].child[j].header) {
                  <ion-row>
                    <ion-col class="pad-0">
                      <ion-label class="medium-md-text label-color-text">{{ data[i].child[j].header ?
                        data[i].child[j].header : '' }}</ion-label>
                    </ion-col>
                    <ion-col class="pad-0 action">
                      @if (data[i].child[j].header && data[i].child[j].group === 'company') {
                      <ng-container
                        *ngTemplateOutlet="actions; context: { child: child, parentIndex: i, childIndex: j }">
                      </ng-container>
                      }
                    </ion-col>
                  </ion-row>
                  }

                  @if(data[i].child[j].group === 'company') {
                  <ion-row class="sub-level-row">
                    <ion-col class="pad-0 relative">
                      <lib-dropdown [label]="data[i].child[j].label" [data]="data[i].child[j].options" displayBy="name"
                        value="id" [ngModel]="child.get('value')?.value" formControlName="value"
                        ngDefaultControl></lib-dropdown>

                      <div class="text-only-button medium-sm-text" [class.in-active]="!j">
                        Add New
                      </div>

                    </ion-col>
                    <ion-col class="pad-0 action-col">
                      <div class="action-box">
                        <lib-checkbox [label]="'Booker Company'" formControlName="isBookerCompany"></lib-checkbox>
                      </div>

                    </ion-col>
                  </ion-row>
                  } @else {
                  <ng-container
                    *ngTemplateOutlet="addPerson; context: { child: child, parentIndex: i, childIndex: j }"></ng-container>

                  <ng-template #addPerson let-child="child" let-i="parentIndex" let-j="childIndex"
                    [formGroup]="formGroup">
                    <ng-container formArrayName="company">
                      <ng-container [formGroupName]="i">
                        <ng-container formArrayName="child">
                          <div class="dynamic-content" [formGroupName]="j">
                            <ion-row>
                              <ion-col class="pad-0">
                                <lib-dropdown [label]="data[i].child[j].label"
                                  [data]="data[i].child[j].options ? data[i].child[j].options : []" displayBy="name"
                                  value="id" formControlName="value" ngDefaultControl
                                  [ngModel]="child.get('value')?.value"></lib-dropdown>
                              </ion-col>
                              <ion-col class="pad-0 align-flex-end">
                                <ng-container
                                  *ngTemplateOutlet="actions; context: { child: child, parentIndex: i, childIndex: j }">
                                </ng-container>
                              </ion-col>
                            </ion-row>
                            <ion-row class="sub-level-row">
                              <ion-col class="pad-0">
                                <lib-dropdown [label]="data[i].child[j].personCompany?.label ?? ''"
                                  [data]="data[i].child[j].personCompany?.options ?? []" displayBy="name" value="id"
                                  [ngModel]="child.get('billingParty')?.value" ngDefaultControl
                                  formControlName="billingParty"></lib-dropdown>
                                <div class="text-only-button medium-sm-text" [class.in-active]=false>
                                  Add New
                                </div>
                              </ion-col>
                              <ion-col class="pad-0 action-col">
                                <div class="action-box">
                                  <lib-checkbox [label]="'isBooker'" [isDisable]="child.get('isBooker')?.disabled"
                                    formControlName="isBooker"></lib-checkbox>
                                  <lib-checkbox [label]="'Resident'" formControlName="isResident"></lib-checkbox>
                                </div>
                              </ion-col>
                            </ion-row>
                          </div>
                        </ng-container>
                      </ng-container>
                    </ng-container>
                  </ng-template>
                  }
                </ion-grid>
              </ng-container>

              <div class="add-person-button">
                <lib-button is-secondary is-medium [disabled]="!!(getChildArray(i).length > 10)"
                  (click)="addPerson(i)">Add Person</lib-button>
              </div>
            </div>
            }
          </ion-col>
        </ion-row>
      </ng-container>
    </div>
    <div class="add-control">
      <lib-button  is-secondary is-medium (click)="addNewCompany()">Add Company</lib-button>
    </div>
    
  </form>

</ion-grid>


<ng-template let-child="child" let-i="parentIndex" let-j="childIndex" #actions>
  <div class="action">
    <span>
      <ion-icon class="action-icons" src="./assets/svg/redirect.svg"></ion-icon>
    </span>

    <span (click)="resetForm(getChildArray(i), j)">
      <ion-icon class="action-icons" src="./assets/svg/refresh.svg"></ion-icon>
    </span>

    @if(data[i].child[j].group !== 'company') {
    <span (click)="remove(i, j)">
      <ion-icon class="action-icons" src="./assets/svg/bin.svg"></ion-icon>
    </span>
    }
  </div>
</ng-template>