export enum ViewType {
    CHECKBOX = 'checkBox',
    DATE = 'date',
    DROPDOWN = 'dropDown',
    TOGGLE = 'toggle',
    VIEW = 'view',
    DOWNLOAD = 'download',
    TIME = 'time',
    CURRENCY = 'currency',
    TEXT = 'text',
    dateTime = "dateTime",
    CHECKBOX_GROUP = 'checkBoxGroup',
}