import { Component } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-modify-reservation-modal',
  templateUrl: './modify-reservation-modal.component.html',
  styleUrl: './modify-reservation-modal.component.scss',
})
export class ModifyReservationModalComponent {

  constructor(
    private modalController: ModalController
  ) {}


  closeModal() {
    this.modalController.dismiss();
  }
}
