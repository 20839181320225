import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Pipe({
  name: 'highlightText',
})
export class HighlightTextPipe implements PipeTransform {
  constructor(private sanitizer: DomSanitizer) {}

  transform( 
    text: string | unknown,
    coloredPart?: string | null,
    highlightColor: string | null = '--app-text-color',
    appendText?: string | null,
    appendColor: string | null = '--app-text-color'
  ): unknown {
     // Ensure the input text is a valid string
     const inputText = typeof text === 'string' ? text : '';

     // Handle empty or invalid text input
     if (!inputText) return '';
 
     let transformed = inputText;
 
     // Highlight the specific part of the text if `coloredPart` is provided
     if (coloredPart) {
       const regex = new RegExp(`(${coloredPart})`, 'gi');
       transformed = transformed.replace(
         regex,
         `<span style="color: var(${highlightColor});font-family: 'inherit' !important; font-size: 'inherit' !important;">$1</span>`
       );
     } else if (highlightColor) {
       // Apply the highlight color to the entire text if `highlightColor` is available
       transformed = `<span style="color: var(${highlightColor});">${transformed}</span>`;
     }
 
     // Append additional text if `appendText` is provided
     if (appendText) {
       transformed += ` <span style="color: var(${appendColor}); text-decoration: none; font-family: 'inherit'; font-size: 'inherit';">${appendText}</span>`;
     }

     return this.sanitizer.bypassSecurityTrustHtml(transformed);
  }
}
