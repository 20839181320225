import { Component, EventEmitter, Input, OnInit, Output, input } from '@angular/core';
import { AppConstants } from 'shared-ui/src/lib/constants/shared-constant';
import { CarouselResponsive, TCarousel } from 'shared-ui/src/lib/models';
@Component({
  selector: 'lib-carousel',
  templateUrl: './carousel.component.html',
  styleUrls: ['./carousel.component.scss'],
})
export class CarouselComponent {
  @Input() numVisible: number = 6;

  @Input() numScroll: number = 6;

  @Input() circular: boolean = false;

  @Input() showIndicators: boolean = false;

  @Input() autoplayInterval: number = 0;

  @Input() value: any;

  @Input() render = AppConstants.type.image;

  @Input() apiKey: string | undefined;

  @Input() image: boolean = false;
  
  appConstant = AppConstants

  responsiveOptions = input<CarouselResponsive[] | []>([
    {
      breakpoint: AppConstants.responsive.screen2,
      numVisible: 1,
      numScroll: 1
    },
    {
      breakpoint: AppConstants.responsive.screen3,
      numVisible: 1,
      numScroll: 1
    },
    {
      breakpoint: AppConstants.responsive.screen5,
      numVisible: 1,
      numScroll: 1
    }
  ]);


  @Output() clickEmitter: EventEmitter<TCarousel> = new EventEmitter()

  constructor() { }

  /**
   * 
   * @param event - emit the button status when clicking the button
   */
  buttonClick(event: TCarousel) {
    event.isSelected = !event.isSelected;
    this.clickEmitter.emit(event);
  }

   /**
    * To show the photo is unavailable if there is no attachments in src
    * @param event - The event of image.
    */

   onImageError(event: Event): void {
    const target = event.target as HTMLImageElement;
    target.src = '/assets/svg/photo-unavailable.svg';
  }
}
