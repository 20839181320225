<div class="property-detail override-galleria-item {{sidePadding}}" *ngIf="propertyData">
    <!--Properties image carousal list-->
    <ion-grid class="carousel-grid ion-no-padding ">
        <lib-carousel [value]="propertyData.propertyImage" [numVisible]="numVisible" [numScroll]="numScroll"
            [responsiveOptions]="responsiveOptions" [apiKey]="'url'"></lib-carousel>
        <div class="view-all">
            <lib-button is-small is-secondary (click)="showAllImage($event, false)">
                <lib-icon [name]="'frame'" [className]="'frame'"></lib-icon>
                {{propertyLabel?.viewAll ?? ''}} {{ propertyData.propertyImage.length }} {{
                propertyLabel?.photos
                ?? ''}}</lib-button>
            <!-- <lib-button id="open-modal" is-small is-secondary (click)="showAllImage($event, true)">
                <lib-icon [name]="'videoLibrary'" [className]="'frame'"></lib-icon>
                {{propertyLabel?.tourText ?? ''}}</lib-button> -->
        </div>
    </ion-grid>
    <ion-grid class="ion-no-padding detail-grid" [ngClass] = "isShowSideMenu ? 'page-content-css' : 'page-content' " >
        <ion-row class="swap">
            <!-- property Detail Left column -->
            <ion-col size-xs="12" size-sm="12" size-md="12" size-lg="8" size-xl="8" class="left-column">
                <app-info-detail [propertyData]="propertyData" [propertyLabel]="propertyLabel"></app-info-detail>
                <div class="flex-gap-20">
                    <ion-row class="amenities-filter">
                        <ion-col class="pad-0">
                            <lib-carousel [value]="features" render="button" [circular]="false"
                                [responsiveOptions]="amenitiesResponsiveOptions" [numVisible]="4" [numScroll]="1"
                                (clickEmitter)="clickListener($event)"></lib-carousel>
                        </ion-col>
                    </ion-row>
                    <div class="g-map">
                        <lib-google-map-view [data]="mapSource" [places]="nearByPlaces"
                            [searchPlace]="searchPlace"></lib-google-map-view>
                        <lib-location-dropdown [language]="propertyLabel" [inputData]="locationSearchData"
                            (locationSearch)="onLocationSearch($event)"
                            (selectedItem)="onSelectLocation($event)"></lib-location-dropdown>
                    </div>
                </div>
                <div class="cancellationPolicy">
                    <ion-label class="semiBold-xl-text-20px label-color-text">{{ propertyLabel?.cancellationPolicyLabel
                        ?? ''
                        }}</ion-label>
                    <ion-text class="regular-sm-text">{{ propertyData.cancellationPolicy }}</ion-text>
                </div>
            </ion-col>
            <!-- Property Detail Right Column -->
            <ion-col size-xs="12" size-sm="12" size-md="12" size-lg="4" size-xl="4" class="right-column">
                <div class="sticky">
                    <div class="label-class">
                        <lib-icon [name]="'workspace'" [className]="'medium'"></lib-icon>
                        <ion-label class="semiBold-sm-text">{{propertyLabel?.matchSentence ?? ''}}</ion-label>
                    </div>
                    <!-- Request Housing Form -->
                    <div class="request-form">
                        <div *ngFor="let value of formDetail.sections">
                            <lib-dynamic-form (dateChange)="getDateFromDatepicker($event)"
                                (inputChange)="getGuestCount($event)" [value]="value"></lib-dynamic-form>
                        </div>
                        <lib-button is-primary is-medium class="button-class" [disabled]="!objRequestDetails.noOfGuest"
                            (click)="objRequestDetails.noOfGuest && requestHousingMethod()">{{propertyLabel?.buttonLabel
                            ?? ''}}</lib-button>

                    </div>
                </div>
            </ion-col>
        </ion-row>
    </ion-grid>
</div>