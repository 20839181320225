import { Component, OnInit, Output, EventEmitter, input, output, signal, OnDestroy, InputSignal, Input } from '@angular/core';
import { AppConstants, dataTypes } from 'shared-ui/src/lib/constants/shared-constant';
import { GalleriaResponsive, GalleryOptionsModel } from 'shared-ui/src/lib/models';
import { GalleryService } from 'shared-ui/src/lib/services/gallery.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'lib-gallery',
  templateUrl: './gallery.component.html',
  styleUrls: ['./gallery.component.scss'],
})
export class GalleryComponent implements OnInit, OnDestroy {
  // Input for the attachments to be displayed in the gallery (required).
  @Input({ required: false }) attachments: any = [];

  // Input to determine if item navigators should be shown.
  @Input() showItemNavigators: boolean = false;

  // Input to control the visibility of thumbnails.
  @Input() showThumbnails: boolean = true;

  // Input to determine if indicators should be shown.
  @Input() showIndicators: boolean = false;

  // Input to specify if indicators should be displayed on the item.
  @Input() showIndicatorsOnItem: boolean = false;

  // Input for an optional API key.
  @Input() apiKey: string | undefined = undefined;

  @Input() imageTour: boolean = false;
  // Input to show the count of images.
  @Input() showCount: boolean = false;

  // Responsive options for the gallery based on screen size.
  @Input() responsiveOptions: GalleriaResponsive[] | [] = [
    { breakpoint: AppConstants.responsive.screen2, numVisible: 5 },
    { breakpoint: AppConstants.responsive.screen3, numVisible: 3 },
    { breakpoint: AppConstants.responsive.screen4, numVisible: 1 }
  ];

  // Style object for the gallery container.
  @Input() containerStyle: { [key: string]: string } | null = null;

  // Class for the gallery container.
  @Input() containerClass: string | undefined = undefined;

  // Input to control the visibility of the gallery.
  @Input() visible: boolean = false;

  // Number of images to be visible at a time.
  @Input() numVisible: number = 3;

  // Input to allow circular navigation through images.
  @Input() circular: boolean = false;

  // Input to enable full screen mode for the gallery.
  @Input() fullScreen: boolean = false;

  // Output event emitter for image click events.
  @Output() imageClick: EventEmitter<void> = new EventEmitter<void>();

  // Output event for visibility changes.
  @Output() visibilityChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  // Signal to track the current active image index.
  current: undefined | number = 1;

  gallerySubscription!: Subscription;

  constructor(
    private galleryService: GalleryService
  ) { }

  ngOnInit() {
    this.gallerySubscription = this.galleryService.getGallery().subscribe((options: GalleryOptionsModel) => {
      if (options.attachments) this.attachments = options.attachments;
      if (typeof options.showItemNavigators !== "undefined") this.showItemNavigators = options.showItemNavigators;
      if (typeof options.showThumbnails !== "undefined") this.showThumbnails = options.showThumbnails;
      if (typeof options.showIndicators !== "undefined") this.showIndicators = options.showIndicators;
      if (typeof options.showIndicatorsOnItem !== "undefined") this.showIndicatorsOnItem = options.showIndicatorsOnItem;
      if (options.apiKey && typeof options.apiKey === "string") this.apiKey = options.apiKey;
      if (typeof options.imageTour !== "undefined") this.imageTour = options.imageTour;
      if (typeof options.showCount !== "undefined") this.showCount = options.showCount;
      if (options.responsiveOptions) this.responsiveOptions = [...options.responsiveOptions];
      if (typeof options.containerStyle !== "undefined") this.containerStyle = {...options.containerStyle };
      if (options.containerClass) this.containerClass = options.containerClass;
      if (typeof options.visible !== "undefined") this.visible = options.visible;
      if (options.numVisible) this.numVisible = options.numVisible;
      if (typeof options.circular !== "undefined") this.circular = options.circular;
      if (typeof options.fullScreen !== "undefined") this.fullScreen = options.fullScreen;
    })
  }

  ngOnDestroy() {
    this.gallerySubscription.unsubscribe();
  }
  /**
   * Stops event propagation and emits the imageClick event.
   * @param event - The click event.
   */
  onImageClick(event: Event) {
    event.stopPropagation(); // Prevents the event from bubbling up.
    this.imageClick.emit(); // Emits the image click event.
  }

  /**
   * Emits the visibility change event.
   * @param event - The new visibility state.
   */
  visibleChange(event: boolean) {
    this.visible = event;
    this.visibilityChange.emit(event);
    this.galleryService.dismissGallery();
  }

  /**
   * Updates the current active index based on user clicks.
   * @param index - The index of the clicked image.
   */
  activeIndexChange(index: number) {
    if (this.current) this.current = (index + 1); // Updates the current index.

  }

  /**
    * To show the photo is unavailable if there is no attachments in src
    * @param event - The event of image.
    */

  onImageError(event: Event): void {
    const target = event.target as HTMLImageElement;
    target.src = '/assets/svg/photo-unavailable.svg';
  }
}