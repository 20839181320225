<ion-content *ngIf="!isPopup" scroll-y="false" [fullscreen]="true">

    <ion-grid class="traveller-policy page-content {{sidePadding}}">
        <div class="pad-css">
            <ion-label class="semiBold-xl-text-20px">{{languageLabel.travelPolicy}}</ion-label>
        </div>
        <div [innerHTML]="safeHTML" #dynamicContent></div>
    </ion-grid>
</ion-content>


<ion-header class="ion-header-horizontal" *ngIf="isPopup">
    <ion-toolbar>
        <div class="header-section">
            <ion-label class="semiBold-xl-text-20px">{{languageLabel.header}}</ion-label>
        </div>
    </ion-toolbar>
</ion-header>
<ion-content scroll-y="false" *ngIf="isPopup">
    <div class="scroll-css">
        <ion-grid class="grid-content">
            <div [innerHTML]="safeHTML" #dynamicContent></div>
            @if (isRendered) {
            <div class="checkbox-css">
                <lib-checkbox [data]="data"></lib-checkbox>
            </div>
          
            <footer class="button-class" *ngIf="isPopup">
                <lib-button class="button-css" is-primary is-medium [disabled] = "!data.isChecked"
                    (click)="data.isChecked && agreePolicy()">{{languageLabel.agree}}</lib-button>
            </footer>
            }
        </ion-grid>
    </div>
</ion-content>