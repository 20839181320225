import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TabInput } from '../../models/shared-common.model';

@Component({
  selector: 'lib-tab',
  templateUrl: './tab.component.html',
  styleUrls: ['./tab.component.scss'],
})
export class TabComponent implements OnInit {
  @Input() tabInput: TabInput[]  =[];
  @Output() dataToParent = new EventEmitter<TabInput>();
  @Input() selectedTab = 1;
  @Input() count = 0;
  constructor() { }

  ngOnInit() { }

  /**
   * Handles the selection of a tab.
   * @param id The ID of the selected tab.
   */
  sendData(tab: TabInput) {
    this.selectedTab = tab.id;
    this.dataToParent.emit(tab);
  }
}



