<!-- Dynamic input field with label and validation. -->
<div *ngIf="inputData" [attr.id]="inputData.leadRef" class="input-containers">
  <ion-label class="input-text semiBold-sm-text">{{ inputData.label ?? ''}} <span *ngIf="inputData.isRequired"
      class="red-color-text">*</span></ion-label>

  <div class="position-relative">
    <input class="input regular-sm-text" id="numberInput" [type]="inputData.type" [id]="inputData.label"
      [placeholder]="inputData.placeholder" [min]="inputData.minLength" [max]="inputData.maxLength"
      [readOnly]="inputData.readOnly" [(ngModel)]="inputData.value" (ngModelChange)="onInputChange($event,inputData)"
      [value]="inputData.value" (focusout)="focusoutInput(inputData)" (focus)="focusInput(inputData)" 
      [disabled]="inputData.isDisabled ?? false"/>
    <button *ngIf="inputData.type === 'number' && showArrow" class="up-arrow" (click)="increment(inputData.value)"><svg width="10"
        height="6" viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M1.77083 5.50039L5.00417 2.26706L8.2375 5.50039C8.5625 5.82539 9.0875 5.82539 9.4125 5.50039C9.7375 5.17539 9.7375 4.65039 9.4125 4.32539L5.5875 0.500391C5.2625 0.17539 4.7375 0.17539 4.4125 0.500391L0.5875 4.32539C0.2625 4.65039 0.2625 5.17539 0.5875 5.50039C0.9125 5.81706 1.44583 5.82539 1.77083 5.50039Z"
          fill="#404242" />
      </svg></button>
    <button *ngIf="inputData.type === 'number' && showArrow" class="down-arrow" (click)="decrement(inputData.value)"><svg width="10"
        height="6" viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M1.77083 5.50039L5.00417 2.26706L8.2375 5.50039C8.5625 5.82539 9.0875 5.82539 9.4125 5.50039C9.7375 5.17539 9.7375 4.65039 9.4125 4.32539L5.5875 0.500391C5.2625 0.17539 4.7375 0.17539 4.4125 0.500391L0.5875 4.32539C0.2625 4.65039 0.2625 5.17539 0.5875 5.50039C0.9125 5.81706 1.44583 5.82539 1.77083 5.50039Z"
          fill="#404242" />
      </svg></button>
  </div>
  <!--Shows Error message-->
  <small *ngIf="inputData?.isShowErrorMessage && inputData?.showErrorMessage"
    class="red-color-text">{{inputData.showErrorMessage }}</small>
</div>